﻿html {
    min-height: 100%;
    position: relative;
}

body {
    -ms-overflow-style: scrollbar;

    .login-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

.abandon-container {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 7px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: white 2px solid;
}

#wrap {
    height: auto !important;
    margin: 0 auto;
    min-height: 100%;
    padding: 0 0 85px 0;
}

&.white-bg {
    background-color: @color-white;
}

.worklist {
    background-color: @color-white;
    padding: 30px;
}

.container {
    max-width: 1320px;

    @media only screen and (min-width: @screen-sm-min) {
        padding: 0 30px;
        width: 100%;
    }

    @media only screen and (max-width: 1024px) {
        padding: 0 10px;
        width: 100%;
    }

    &.account-container,
    &.search-container,
    &.registration-container {
        padding-top: 40px;

        .title {
            margin-bottom: 10px;
        }
    }
}

.fixed-height-container-ras {
    overflow-y: auto;
    max-height: 80vh;
}

.fixed-height-container {
    overflow-y: auto;
}

.mixin-fixed-height-container(@size) {
    .fixed-height-container {
        &.size-@{size} {
            height: ~"@{size}px";

            &.max {
                height: auto;
                max-height: ~"@{size}px";
            }
        }
    }
}

.container-grey {
    &:extend(.container);
    background-color: @color-extra-light-grey;
}

.about-cp {
    margin-top: 60px;
}

.about-cp-container {
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.exisiting-su-column {
    margin: 20px 0;

    @media all and (min-width: @screen-md-min) {
        margin: 0;
    }
}

.border-seperator {
    text-align: center;
    width: 100%;
    border-bottom: solid 1px @color-light-grey;
    margin: auto;
    margin-top: 16px;
}

.roomy-above-10 {
    padding-top: 10px;
}

.roomy-above {
    padding-top: 15px;
}

.roomy-below {
    padding-bottom: 15px;
}

.roomy-below-lg {
    padding-bottom: 25px;
}

.margin-left-sm {
    margin-left: 8px;
}

.break-word {
    display: inline-block;
    word-break: break-all;
}

.cp-logo-font {
    line-height: 2em;
    height: 70px;
    position: relative;
    top: -30px;

    .imo {
        font-size: 40px;
    }

    .name {
        font-family: "Sofia Pro", Arial;
        font-size: 30px;
        text-transform: lowercase;

        span{
            font-size: 70px;
            display: block;
        }
    }
}

.tick-box-container {
    .tick-box-input-container {
        margin-left: 10px;
        margin-bottom: 10px;
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 1em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .tick-box-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border-radius: 4px;
        border: 2px solid @color-data-entry-button-border;
        transition: background-color linear 0.1s;
        transition: border linear 0.1s;
    }

    .tick-box-input-container input ~ .checkmark:after {
        display: block;
        position: absolute;
    }

    .tick-box-input-container input:checked ~ .checkmark:after {
        display: block;
        content: "";
        position: absolute;
    }

    .tick-box-input-container .checkmark {
        background-color: @color-data-entry-button-background;
    }

    .tick-box-input-container input:checked ~ .checkmark {
        border-color: @color-blue;
    }

    .tick-box-input-container input ~ .checkmark:after {
        color: @color-blue;
        font-family: ImosphereIcons;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
    }

    .tick-box-input-container input:checked ~ .checkmark:after {
        content: "\e865";
        font-size: 16px;
    }

    .checkbox-container .checkmark:hover {
        background-color: @color-data-entry-button-border;
        border-color: @color-data-entry-button-border;
    }

    .label-text {
        margin-left: 26px;
    }

    &.tick-box-container-vue {
        width: 22px;

        input {
            opacity: 0;
        }

        .tick-box-input-container {
            margin: 3px 0 0;
            display: inline-block;
        }
    }
}

.tick-box-container-label {
    width: 100%;
    padding-left: 0;
}

//new
footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: auto;
    background-color: #e5e5e5;

    .container {
        width: 100%;
        margin: 0;
        border-top: 1px solid @color-white;
        max-width: 100%;
        padding: 12px 15px;
        font-size: 0.875em;
        line-height: 1em;
        color: #666;

        .row {
            max-width: 100%;

            &.management {
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .footer-item {
            display: block;
            float: left;

            &.logo {
                font-size: 40px;
                color: @color-medium-grey;
                margin: 0 8px 0 0;
            }

            &.product-details {
                .name {
                    font-weight: 600;
                    display: block;
                    font-size: 1.8em;
                    font-family: sofia pro;
                    line-height: 0.9em;
                    margin-bottom: 6px;
                    text-transform: lowercase;
                }

                .version {
                    display: block;
                }
            }
        }

        .copyright {
            line-height: 1.4em;
        }
    }
}

//smaller layout

.focus-area {
    float: left;
    position: relative;
    width: 66.666667%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.side-panel {
    width: 33.333333%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.page-caselist {
    margin: 20px;
}

.coc-registry-container {
    overflow-x: scroll;
    overflow-y: auto;
    position: relative;
}

.coc-registry-outer-container {
    position: relative;

    .patch {
        background-color: @color-light-grey;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
    }

    .current-section-title {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        padding: 5px 8px;
        font-family: sofia pro;
        font-weight: 600;
        font-size: 0.867em;
        background-color: @color-light-grey;
        display: none;
    }
}

.no-data-container {
    position: absolute;
    left: 50%;
    top: 33%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
