.personal-details-container {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
}

.personal-details-history,
.other-personal-details-history {
    tr.highlight-current {
        td {
            .current {
                display: none;
            }
        }
    }
    tr.highlight-current:first-child {
        td:first-child {
            .current {
                display: inline-block;
                margin-left: 24px;
            }
            .current::after {
                content: "current";
            }
        }
    }
}
