﻿@import (reference) "colorPalette.less";
// Typography LESS
@base-font-family: "FSMeWeb", helvetica, arial, sans-serif;
@base-font-size: 13px;
@base-font-weight: 400;
@base-line-height: 21px;

@heading-font-family: "Sofia Pro", helvetica, arial, sans-serif;

@font-size-h1: ceil((@base-font-size * 1.85)); // 28px
@line-height-h1: ceil((@font-size-h1 * 1.5));

@font-size-h2: ceil((@base-font-size * 1.6)); // 24px
@line-height-h2: ceil((@font-size-h2 * 1.5));

@font-size-h3: ceil((@base-font-size * 1.3)); // 20px
@line-height-h3: ceil((@font-size-h3 * 1.475));

@font-size-h4: floor((@base-font-size * 1.2)); // 16px
@line-height-h4: ceil((@font-size-h4 * 1.35));

/* Semi-Bold*/
@font-face {
    font-family: "Sofia Pro";
    src: url("Content/Fonts/sofiapro-semibold-webfont.woff?v3");
    font-weight: 600;
    font-style: normal;
}

/* Bold */
@font-face {
    font-family: "Sofia Pro";
    src: url("Content/Fonts/sofiapro-bold-webfont.woff?v3");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "FSMeWeb";
    src: url("Content/Fonts/FSMeWeb-Regular.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FSMeWeb";
    src: url("Content/Fonts/FSMeWeb-Bold.woff");
    font-weight: bold;
    font-style: normal;
}

html,
body {
    color: @color-body;
    font-family: @base-font-family;
    font-size: @base-font-size;
    font-weight: @base-font-weight;
    line-height: @base-line-height;
    text-rendering: optimizeLegibility;
    transition: background linear 0.3s;
    min-height: 100%;
    background-image: 
        url(/content/images/rectangle-graphic.svg); // Top right
    background-size: 800px;
    background-repeat: no-repeat;
    background-position: right -200px top -150px;
    background-color: #d7dae6;
}

.text-color {
    color: @color-body;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-lg {
    font-size: 1.13333333rem; // 17px
}

.text-sm {
    font-size: 1rem; // 13px
    line-height: 1.267rem;
}

.text-emphasis {
    color: lighten(@color-body, 4%);
    font-weight: 600;
    font-family: "Sofia Pro";
}

.text-italic{
    font-style: italic;
}

.text-muted {
    color: darken(@color-medium-grey, 6%);
}

.text-uppercase {
    text-transform: uppercase;
}

.text-action {
    color: @color-warning;
}

.text-success {
    color: @color-success;
}

.text-has-error {
    color: @color-has-error;
}

.text-bold {
    font-weight: 600;
}

.text-underlined {
    text-decoration: underline !important;
}

.bold-font-small {
    font-family: "Sofia Pro";
    font-size: 15px;
    line-height: 1.3em;
}

.line-height-sm {
    line-height: 1.357rem;
}

b,
strong {
    color: lighten(@color-body, 4%);
    font-weight: 600;
}

a,
a.text-emphasis {
    color: @color-link;

    &:hover {
        color: darken(@color-link, 6%);
    }
}

.worklist-user-name {
    color: @color-blue !important;

    &:hover {
        border-color: @color-blue !important;
    }

    .bold-font-small();
}

// Headings
h1,
h2,
h3,
h4,
.as-h1 {
    color: @color-blue;
    margin: 0;
}

h1,
.as-h1 {
    font: 600 @font-size-h1 / @line-height-h1 @heading-font-family;
}

h2,
.as-h2 {
    font: 600 @font-size-h2 / @line-height-h2 @heading-font-family;

    .text-muted {
        font-weight: 400;
    }

    &.contact-role-name {
        max-width: 65%;
    }

    &.black {
        color: @color-body;
    }
}

h3 {
    font: 600 @font-size-h3 / @line-height-h3 @heading-font-family;
}

h4 {
    font: 600 @font-size-h4 / @line-height-h4 @heading-font-family;

    &.about-org-title {
        margin-top: @unit*8;
    }
}

p {
    &.about-cp-desc {
        margin-bottom: 60px;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        h1,
        h2,
        h3,
        h4,
        .text-color-tint {
            color: @theme-color;

            &.lighter {
                color: lighten(@theme-color, 10%);
            }
        }
    }
}

.identifier,
.list-link-text {
    .bold-font-small();
}

.panel-title {
    font-size: 16px !important;
}

// No Data
.no-data {
    color: @color-medium-grey;
    padding: 30px;
    text-align: center;
    background-color: @color-white;
    margin-bottom: 15px;

    h2,
    h3 {
        color: @color-medium-grey !important;
    }
}

.no-data-summary {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    text-align: left;

    h2,
    h3 {
        color: @color-medium-grey !important;
    }
}

.no-data-page {
    text-align: center;
    color: #111;
}

.diagnosis-code {
    .text-muted;
    .text-sm;
    display: block;
}

@media (min-width: 1299px) {
    .number-counts {
        font-size: 40px;
        color: #3a3a3a;
        font-weight: bold;
        margin-bottom: 0px;

        &.small {
            font-size: 15px;
        }
    }

    .count-text {
        .text-sm;
        margin-bottom: 0px;
    }
}

@media (max-width: 1299px) {
    .number-counts {
        font-size: 40px;
        color: #3a3a3a;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 0px;
    }

    .count-text {
        display: inline-block;
        margin-bottom: 0px;
    }
}

.summary-view-more {
    font-weight: normal;
    font-size: 15px;
}

.activity-goals-selected-count {
    font-weight: normal;
    font-size: 15px;
    color: @color-white !important;
}

@media print {
    * {
        background: white !important;
        border: none !important;
        border-radius: 0 !important;
        color: @color-black !important;
        font-size: 9pt !important;
        margin: 0 !important;
        padding: 0 !important;
        line-height: 13pt !important;
    }

    // Typographic elements
    h1,
    h1 span {
        font-size: 14pt !important;
        line-height: 20pt !important;
    }

    .banner h1,
    .banner h1 span {
        font-size: 16pt !important;
        line-height: 22pt !important;
    }

    h2 {
        font-size: 11pt !important;
        line-height: 18pt !important;
    }

    h3 {
        font-size: 10pt !important;
        line-height: 15pt !important;
    }

    h4 {
        font-size: 9pt !important;
        line-height: 14pt !important;
    }
}

.markdown {
    p {
        max-width: 650px;
        margin-bottom: 10px !important;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: 18px;
        color: @color-dark-grey;
    }

    h2 {
        font-size: 16px;
        color: @color-dark-grey;
    }

    h3 {
        font-size: 14px;
        color: @color-dark-grey;
    }

    h4, h5, h6 {
        font-size: 13px;
        color: @color-dark-grey;
    }

    p, table, ul, ol, blockquote {
        font-size: 12px;
        color: @color-dark-grey;
        font-family: @base-font-family;
    }

    blockquote {
        border-left-color: #ddd;
        margin: 15px 0 !important;

        &:last-of-type {
            margin-bottom: 0;
        }

        p {
            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }

    ul, ol {
        max-width: 650px;
        padding-left: 20px;
        margin: 10px 0 !important;

        li {
            margin-bottom: 10px !important;

            &:last-of-type {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 10px !important;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    a {
        .text-underlined;
        font-family: @base-font-family;
    }

    hr {
        border-top-color: #ddd;
    }
}
