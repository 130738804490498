﻿// Styles for the faux print preview, used in activities and care plans
.page-print {
    color: black;
    font-size: 0.933rem;
    margin: 10px 20px 0;
    padding-bottom: 0;
    .print-logo-icon {
        font-size: 25px;
        color: @color-dark-grey !important;

        .name {
            font-family: "Sofia Pro";
            color: @color-dark-grey !important;
        }
    }

    footer {
        background-color: transparent;
    }

    .page-activities {
        .strengths-needs {
            .heading {
                margin-bottom: 3.75pt;
            }
        }
    }

    .print-container {
        position: relative;
    }

    .btn-print {
        margin-top: 3px;
    }

    .btn-close-preview {
        margin-top: 2px;
        outline: none;

        [class^="cp-icon-"] {
            color: @color-medium-grey;
            font-size: 1.75rem;

            &:hover {
                color: darken(@color-body, 4%);
            }
        }

        &:hover {
            .cp-icon-cross:before {
                color: @color-medium-grey;
                content: "\e924";
            }
        }

        &:active {
            box-shadow: none;
        }
    }

    .print-controls {
        .border-radius-sm;
        border: solid 1px @color-light-grey;
        padding: 20px;
        background-color: @color-white;

        .btn-primary {
            .text-lg;
        }

        .control-label {
            .text-sm;
            display: block;
            font-weight: 400;
            margin: 0 5px 0 0;
        }

        .form-control {
            padding-left: 10px;
            padding-right: 12px;
        }
    }

    .print-preview-title {
        font-size: @font-size-h3;
        color: @color-medium-grey;
        margin-bottom: 10px;
    }
    // Hidden elements
    .banner,
    .banner-container,
    .navbar,
    .blank-tabs-container,
    .cp-icon-star,
    .fixed-banner,
    &.fixed-banner.visibile {
        display: none;
        visibility: hidden;
    }
    // Container - force to fluid
    .container {
        max-width: 100%;
    }
    // Print Preview
    .print-preview {
        border: solid 1px @color-light-grey;
        box-shadow: 0 0 8px 3px @color-light-grey;
        padding: 20px;
        background-color: @color-white;

        .form-title {
            margin-bottom: -2px;
            padding: 20px 0 0;
        }

        .date-assessed {
            margin-bottom: -2px;
            padding: 10px 0 0;
        }

        .sk-spinner {
            display: none;
        }
        .loading-message {
            background-color: transparent;
        }
        // Loading
        &.loading {
            .table-print {
                opacity: 0.5;
            }
            // Spinner
            .sk-spinner {
                display: block;
                opacity: 1;
                position: absolute;
                top: 240px;
                left: 50%;
                margin-left: -20px;
                z-index: 10;
            }
        }
    }
    // Icons
    [class^="cp-icon-"],
    [class*="cp-icon-"] {
        display: none;
    }

    .cp-icon-cross {
        display: block;
    }

    .cp-icon-care-plan-associations {
        display: inline-block;
        margin: 2px 0 0 4px;

        &:hover,
        &:focus {
            color: @color-black;
        }
    }

    .cp-icon-function {
        color: @color-body;
        display: inline-block;

        &:focus,
        &:hover {
            color: @color-body;
        }
    }
    // Typography
    h1,
    h2,
    h3,
    h4 {
        background: none !important;
        color: black;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 21px;
        line-height: 26px;
    }

    h3 {
        font-size: 16px;
        line-height: 20px;
    }

    .text-sm {
        font-size: 13px !important;
    }

    // Note
    .note {
        border: none !important;
    }

    .text-emphasis {
        color: black;
    }
    // Guidance
    .guidance-note {
        font-size: 7.5pt !important;
        font-style: italic;
        font-weight: 400;
        line-height: 1.267rem;
        white-space: pre-wrap;
        clear: both;
        margin: 0;
        padding: 0 0 8px;
    }

    #footer {
        display: none;
        visibility: hidden;
    }
}

.table-print {
    border: none !important;
    border-collapse: separate !important;
    height: 99%;
    width: 100%;

    thead {
        display: table-header-group;
    }

    tbody {
        display: table-row-group;
    }

    tfoot {
        display: table-footer-group;

        .print-information {
            th,
            td {
                padding: 5pt 0 0 !important;
            }
        }
    }

    th,
    td {
        border: solid 1px #cccccc !important;
        border-spacing: 0;
        font-weight: 400 !important;
        margin: 0; /* following is for Opera and IE compatibility */
        padding: 3pt 5pt !important;
        vertical-align: top;
        empty-cells: show;
        white-space: normal;

        &.cell-emphasis,
        &.cell-emphasis p,
        &.cell-emphasis span {
            background: #e3e3e3 !important;
        }

        &.text-emphasis {
            font-weight: 600 !important;
        }

        &.section-title {
            border-bottom: none !important;
        }

        &.form-item-cell {
            border: none !important;
            padding: 3pt 0 0 !important;
        }

        &.xs {
            width: 20%;
        }

        &.sm {
            width: 35%;
        }

        &.md {
            width: 50%;
        }

        &.lg {
            width: 75%;
        }
    }

    .cell-borderless {
        border: none !important;

        th,
        td {
            border: none !important;
            padding: 0 !important;
        }

        .table-print.table-bordered {
            th,
            td {
                border: solid 1px #cccccc !important;
                padding: 3pt 5pt !important;
            }

            .cell-borderless {
                border: none !important;

                th,
                td {
                    border: none !important;
                    padding: 0 !important;
                }
            }
        }
    }
    // Print Information
    .print-information,
    .su-banner,
    .page-title,
    .page-content,
    .table-print-borderless {
        th,
        td {
            border: none !important;
            padding: 0 0 5pt !important;
        }

        table {
            width: 100% !important;
        }

        .print-copy {
            display: inline-block !important;
            margin-right: 8pt !important;
        }

        .print-status {
            margin-top: 10px;
        }
    }
    // SU Banner
    .su-banner {
        th,
        table tr th,
        table tr td {
            border: none !important;
            padding-top: 0 !important;
            padding-left: 0 !important;
        }

        table {
            border-bottom: solid 1pt @color-black !important;
            margin-bottom: 10pt !important;

            th,
            td {
                border: solid 1px #cccccc !important;
                padding: 3pt 5pt !important;
            }
        }

        .print-su-identifier {
            display: inline-block !important;
            padding-right: 10pt !important;
        }
    }
    // Page Title
    .page-title {
        th,
        td {
            border: none !important;
            padding: 2pt 0 !important;

            h1 {
                font-size: 15pt !important;
            }
        }

        h2 {
            margin: 10pt 0 0 !important;
        }

        .coc {
            display: inline-block !important;
            margin-right: 10pt !important;
        }
    }

    .sub-title {
        margin: 10pt 0 -3pt !important;
    }

    .section-subtitle {
        color: black;
        display: inline-block;
        font-family: "Sofia Pro";
        font-size: 1.2em;
        font-weight: 600;
    }

    .form {
        position: relative;
        top: -3pt !important;
    }

    .form-section-title {
        margin: 10pt 0 0 !important;
    }

    // Care Plans
    .care-plan-h2 {
        margin-top: 10pt !important;
    }

    .care-plan-h3 {
        margin: 10pt 0 3pt !important;
    }

    .center-of-care {
        margin: 2pt 0 5pt !important;
    }

    .spacer td {
        border: none !important;
        height: 20pt !important;
    }

    .table-interventions table {
        margin-bottom: 10pt;
    }

    .table-interventions:only-child table {
        margin-bottom: 0;
    }
    // Answer History
    .answer-history-h3 {
        margin: 10pt 0 3pt !important;
    }
    // Activity List
    .activity-filter {
        padding-right: 10pt;
    }

    .list-tags {
        .tags-list-commas {
            &:after {
                content: ", ";
            }
            &:last-child:after {
                content: " ";
            }
        }
    }
}

.table-custom-print {
    &.table-bordered {
        border-color: #cccccc !important;

        tr:first-child td.print-information {
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-left-color: transparent !important;

            .print-copy {
                display: inline-block !important;
                margin-right: 8pt !important;
            }
        }

        thead > tr > td,
        thead tr:first-child td {
            border: solid 2px #cccccc !important;
        }

        thead > tr td.blank-cell {
            border-color: transparent !important;

            &.trailing-blank {
                border-bottom: none !important;
            }
        }

        td,
        tr:first-child td {
            border: solid 1px #cccccc !important;
            empty-cells: show;
            line-height: 18pt !important;
            padding: 0 4pt !important;
            vertical-align: top;
            white-space: normal;

            &.blank-cell {
                border-right-color: transparent !important;
                border-left-color: transparent !important;
                height: 12pt !important;
                padding: 4pt !important;
            }
        }

        .sub-header {
            display: inline-block !important;
            margin-right: -4px !important;
            padding: 3pt !important;
        }

        .sub-header {
            float: none !important;

            h4 {
                font-weight: 400 !important;
            }
        }

        .form-section-title {
            padding-top: 2pt !important;
            padding-bottom: 2pt !important;
        }

        .question {
            border-top: solid 1px #cccccc !important;
            margin-left: -4pt !important;
            margin-right: -4pt !important;
            padding-top: 4pt !important;
            padding-left: 4pt !important;
            padding-right: 4pt !important;

            .marked-as {
                padding-left: 6pt !important;
            }
        }

        .answer {
            color: #333 !important;
            margin-left: -4pt !important;
            margin-top: -4pt !important;
            margin-right: -4pt !important;
            padding-left: 4pt !important;
            padding-right: 4pt !important;
            padding-bottom: 4pt !important;

            p {
                white-space: pre-wrap;
            }
        }

        .has-note {
            border: solid 1px #cccccc !important;
            padding: 5pt !important;
            margin: 2pt 2pt 6pt !important;
        }

        .children {
            padding-left: 20pt !important;

            .question,
            .answer {
                padding-left: 0 !important;
            }

            .has-note {
                margin-left: 0 !important;
            }
        }
        // Pathways
        .pathways-h3 {
            margin: 2pt 0 !important;
        }

        // Care Plan
        .care-plan-h3 {
            margin: 6pt -4pt 1pt !important;
        }

        .center-of-care {
            margin: 5pt 0 !important;
        }

        .cell-emphasis-light {
            background-color: #f3f3f3 !important;
        }

        .cell-emphasis-medium {
            background-color: #e4e4e4 !important;
        }

        tbody:nth-of-type(n + 2) {
            & > tr:last-of-type {
                & > .blank-cell {
                    border-color: transparent !important;
                }
            }
        }
    }
}

.question {
    margin-left: -4pt !important;
    margin-right: -4pt !important;
    padding-top: 4pt !important;
    padding-left: 4pt !important;
    padding-right: 4pt !important;

    .marked-as {
        padding-left: 6pt !important;
    }
}

.answer {
    color: #333 !important;
    margin-left: -4pt !important;
    margin-top: -4pt !important;
    margin-right: -4pt !important;
    padding-left: 4pt !important;
    padding-right: 4pt !important;
    padding-bottom: 8pt !important;

    p {
        white-space: pre-wrap;
    }
}

@page {
    margin: 1cm;
}

@media print {
    html,
    body {
        height: 99%;
    }

    // remove url to a elements
    a[href]:after {
        content: none !important;
    }

    .page-print {
        // Typography
        h1 {
            font-size: 18pt !important;
        }

        h2 {
            font-size: 14pt !important;
            line-height: 28px;
        }

        h3 {
            font-size: 11pt !important;
            line-height: 24px;
        }

        h4 {
            font-size: 10pt !important;
            line-height: 21px;
        }

        .text-sm {
            font-size: 7.4pt !important;
        }
    }

    .print-container {
        position: relative;
        top: 0 !important;
    }
    // Table printing overrides
    .print-bottom-border-override {
        border-bottom: solid 1px #cccccc !important;
    }

    .print-shift-notes {
        h1,
        h1 span {
            background: transparent !important;
            font-size: 26px !important;
            padding: 2px !important;
        }

        table td {
            padding: 5px 8px !important;
        }
    }

    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        width: 100%;
    }

    /* GU Truncate */
    .gutruncate-ellipsis {
        display: none !important;
    }

    .gutruncate-more {
        display: inline !important;
    }

    .gutruncate-more-link {
        display: none;
    }

    .nav-tabs.tabs-borderless li > a {
        display: none;
        font-weight: 600;
        padding: 10px 0;
    }
}

tr {
    // Overides bootstrap css that causes first page breaks in IE
    page-break-inside: auto !important;
}
