﻿// Involvement Summary panel
.activity-details {
    margin-bottom: 0.5em;
}

.current-involvement-details {
    .panel {
        margin-bottom: 10px;
    }

    .label {
        margin-right: 0.5em;
        position: relative;
        top: -1px;
    }

    .end-date {
        margin-left: 1em;
    }
}

.inovolvement-stats {
    .text-sm;
    display: inline-block;
    margin-right: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;

    .badge {
        position: relative;
        top: -2px;
    }

    > .involvement-stats {
        margin-bottom: 0;
        padding: 0.25em 0;
    }
}

.destination-involvement-details {
    margin-top: 22px;

    .panel {
        margin-bottom: 0;
    }

    > .involvement-stats .badge {
        margin-top: -2px;
    }
}

.destination-details {
    .form-group:last-of-type {
        margin-bottom: 0;
    }
}
