/* Care Plans LESS */
.page-care-plans {
    margin-bottom: 30px;
    padding-right: 15px;

    .focus-area {
        margin-bottom: 100px;
    }
    // Headers
    .centre-of-care {
        color: @color-body;
        font-weight: 400;
    }
    // Icon
    .cp-icon-checkbox {
        margin-right: 0.25em;
    }
    // Label
    .label {
        &.label-review {
            background-color: lighten(@color-blue, 8%);
        }
    }
    // Panels
    .panel {
        &.global-plan-sidebar {
            margin-top: 2em;
            transition: margin-top 0.2s ease;

            &.sticky {
                margin-top: 0;
            }
        }

        .need.selected {
            .need-name {
                color: @color-blue;
                cursor: pointer;

                &:hover {
                    color: @color-blue;
                }
            }
        }

        .panel-body-modal {
            &:first-child {
                .section {
                    .section-title-highlight {
                        background: @color-blue;
                        color: @color-extra-light-grey;
                    }
                }
            }
        }

        .text-emphasis {
            font-family: @heading-font-family;
            font-size: 1.067em;
        }
    }
    // Tracker
    .fixed-sidebar {
        .tracker-content {
            padding-top: 8px;
            clear: both;
        }
    }
    // Required Icon
    .required {
        &:after {
            content: "*";
            color: #ef4444;
            font-size: 1.5rem;
            line-height: 1px;
            padding-right: calc(@unit - 2);
            position: relative;
            top: calc(@unit + 2);
        }
    }

    .need {
        border-color: @color-light-grey;
    }

    .status-current {
        background: @color-extra-light-grey;
        color: @color-blue;
    }

    .need-name {
        font-family: @heading-font-family;
        font-size: 1.067em;
        line-height: 1em;

        &:hover {
            color: @color-blue;
            cursor: pointer;

            .checkbox {
                background-color: @color-data-entry-button-border;
                border-color: @color-data-entry-button-border;
            }
        }

        .checkbox {
            background-color: @color-data-entry-button-background;
            border-radius: 4px;
            border: 2px solid @color-data-entry-button-background;
            height: 22px;
            left: 0;
            margin: 0 8px 0 0;
            top: 0;
            transition: background-color linear 0.1s;
            transition: border linear 0.1s;
            width: 22px;
        }

        .text {
            display: inline-block;
            line-height: 22px;
        }

        &.selected {
            .checkbox:after {
                font-family: "ImosphereIcons";
                content: "\e865";
                font-size: 16px;
                width: 24px;
                height: 24px;
                text-align: center;
                margin-left: 1px;
            }

            .checkbox {
                border-color: @color-blue;
                color: @color-blue;
                background-color: transparent;
            }

            &:focus,
            &:hover {
                .checkbox {
                    color: darken(@color-blue, 10%);
                    border-color: darken(@color-blue, 10%);
                }
            }
        }
    }

    .guidance {
        margin-left: 30px;
    }

    .need-hidden {
        margin: 0 30px;
    }

    .section {
        margin: 0 0 20px;
        padding: 0;

        &.last-section,
        &:only-child {
            padding-bottom: 0;
        }

        .section-title {
            color: @color-body;
            font-family: @heading-font-family;
            font-size: 1.2em;

            &.required {
                &:after {
                    content: "*";
                    color: #ed3a3a;
                    font-size: 1.5rem;
                    line-height: 1px;
                    padding-right: calc(@unit - 2);
                    position: relative;
                    top: calc(@unit + 2);
                    top: 4px;
                }
            }
        }

        .needs-list {
            margin-top: -@unit;

            .list-item {
                .clearfix;
            }

            .list-item:last-of-type {
                border-bottom: none;
                padding-bottom: 0;

                .additional-information {
                    padding-bottom: 0;
                }
            }
        }

        .needs-edit {
            .list-item {
                .need-name {
                    &.isReadOnly {
                        pointer-events: none !important;
                    }

                    .guidance-notes {
                        pointer-events: auto !important;
                    }
                }

                .cp-icon-checkbox,
                .cp-icon-check {
                    margin-left: -1.25em;
                    margin-top: 1px;

                    &.isReadOnly {
                        background-color: #eee;
                        pointer-events: none;
                    }
                }
            }
        }

        .needs-view {
            .cp-icon-chevron-up {
                top: 7px;
            }
        }

        .intervention-list {
            margin-top: -@unit;
            margin-bottom: @unit*3;

            &.list-collapsable > .list-item .cp-icon-chevron-up {
                top: 21px;
            }

            .btn-xs {
                margin-top: @unit;
            }
        }

        .intervention-list-view {
            margin-top: -@unit;

            .list-item:last-of-type {
                border-bottom: none;

                .additional-information {
                    padding-bottom: 0;
                }
            }

            &.list-collapsable > .list-item .cp-icon-chevron-up {
                top: 20px;
            }
        }

        .view-more-collapse:after {
            content: "";
            content: "less";
        }

        .view-more-collapse {
            &.collapsed:after {
                content: "";
                content: "more";
            }
        }

        .view-more {
            color: @color-blue;
        }

        label {
            font-family: @heading-font-family;
            font-size: 1.067em;
        }
    }

    .role-name,
    .relationship-name {
        &::before {
            content: ",";
            display: inline-block;
            margin-left: -2px;
        }
    }

    .relationship-name {
        &::before {
            content: "Relation: ";
            margin: 0;
        }
    }
    // Current / Open Care Plans
    .care-plan-panel {
        .border-radius-xs;
        border: solid 1px @color-white;
        -webkit-flex-direction: column;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .care-plan-panel > .care-plan-name {
        padding: 10px;
    }

    .care-plan-panel > .care-plan-detail {
        .text-sm;
        border-top: solid 1px @color-light-grey;
        padding: 10px;

        .status {
            color: @color-body;
            padding: 0;
        }
    }

    .cp-label {
        .text-muted;

        &.cp-coc {
            color: @color-body;
            font-weight: 500;
        }
    }

    .cp-review-date {
        margin-bottom: 20px;
    }

    #guprogress-target {
        .guprogress-bar {
            background-color: @color-green;
            z-index: 100;
            display: block;
            position: absolute;
            width: 100%;
            height: 3px;
        }

        &.progress-danger {
            .guprogress-bar {
                background-color: @color-red;
            }
        }
    }
}

@media (min-width: @screen-md-min) {
    .page-care-plans {
        margin-bottom: 0;

        .cp-review-date {
            margin-bottom: 0;
        }
    }
}

@media all and (min-width: 1000px) {
    .page-care-plans {
        .care-plan-panel {
            border-left: solid 3px lighten(@color-blue, 24%);
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row;
            flex-flow: row;
            margin-bottom: 10px;

            &.current {
                border-left-color: @color-blue;
            }
        }

        .care-plan-panel > .care-plan-detail {
            -webkit-align-self: stretch;
            align-self: stretch;
            border-left: 1px solid darken(@color-extra-light-grey, 2%);
            border-top: none;
            padding: 10px 15px;

            &.date {
                max-width: 186px;
                width: 18%;
            }

            &.created-by {
                max-width: 200px;
                width: 20%;
            }
        }

        .care-plan-panel > .care-plan-name {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            padding-left: 20px;
        }

        .care-plan-panel > .care-plan-detail {
            display: -webkit-flex;
            display: flex;
            text-align: center;
        }

        .care-plan-involvement,
        .contact-record,
        .status,
        .date-created,
        .staff-member {
            -webkit-align-self: center;
            align-self: center;
            margin: 0 auto;
        }

        .care-plan-involvement {
            margin: 0;
        }

        .cp-label {
            display: block;
        }
    }
}
