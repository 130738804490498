﻿@import (reference) "../colorPalette.less";
// Activities Page
.page-activities {
    // collapse panel margins
    @panel-panel-body-padding: 20px;
    @panel-border-radius-height: 6px;

    .formInstanceDescription {
        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .readOnlyInput {
        .panel-heading,
        .panel-body {
            pointer-events: none;
        }

        .form-item-checkbox-radio-group {
            background-color: #eee !important;
        }

        .js-collapse-section,
        .btn-view-history {
            pointer-events: auto !important;
        }

        .note {
            a {
                pointer-events: auto !important;
            }
        }

        .guidance-notes {
            pointer-events: auto !important;
        }
    }

    .focus-area {
        margin-bottom: 100% !important;

        .service-user-panel {
            flex-wrap: nowrap !important;

            .view-form-item {
                flex-basis: auto !important;
                max-width: 550px !important;

                &.centre-of-care-item {
                    min-width: 160px;
                }
            }

            .service-user-panel-field {
                display: flex;
                margin-bottom: 0;

                .input-field {
                    &.dropdown-input-field {
                        .dropdown-input-container {
                            display: flex;

                            .dropdown-input {
                                width: 400px;
                                flex-grow: 0;
                            }
                        }
                    }
                }

                input.form-control,
                .dropdown-button {
                    min-height: 35px;
                }

                .btn {
                    margin-top: auto;
                    margin-left: 5px;
                    position: relative;
                }
            }
        }
    }

    .edit-form {
        .panel-body {
            display: flex;
            display: -webkit-flex;
            display: -ms-flexbox;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: row;

            .form-item-checkbox-radio-group {
                flex-grow: 1;
                flex-basis: 100%;

                &.bi-state,
                &.tri-state {
                    flex-basis: calc(50% - 6px);
                    max-width: calc(50% - 6px);
                    position: relative;

                    .checkbox-group-buttons-container {
                        .buttons-container {
                            width: 190px;
                            position: absolute;
                            top: 5px;
                            right: 5px;

                            &.icons-only {
                                width: 114px;
                            }

                            > div {
                                display: flex;
                                justify-content: flex-end;
                            }
                        }
                    }

                    .tri-state-label,
                    label {
                        max-width: calc(100% - 190px);
                    }
                    // Child form items, should span the full width of the checkbox/radio button container
                    .form-item {
                        max-width: 100%;
                        flex-basis: 100%;
                        margin: 10px 0;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                @media screen and (max-width: 1280px) {
                    flex-basis: 100%;
                }

                label {
                    &:focus {
                        outline: none;
                    }
                }

                &:nth-child(odd):last-of-type {
                    flex-grow: 0;
                }
            }

            .form-item-row:not(.form-item-checkbox-radio-group) {
                flex-basis: 100%;
            }

            .flex-full-width {
                flex-basis: 100%;

                .additional-questions-inner {
                    margin: 9px 0;
                }
            }

            .panel-body {
                padding: 0;
            }

            &.service-user-panel {
                flex-direction: column;

                .form-item-group {
                    margin-bottom: 20px;
                }
            }
        }

        .form-item-children {
            margin-top: 15px;
        }
    }

    .flex-item {
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        flex-wrap: wrap;
        align-content: center;
    }

    .flex-full-width {
        width: 100%;
    }

    .collapse-enabled-hover-state {
        cursor: pointer;

        &:hover {
            .cp-icon-chevron-up {
                color: @color-blue;
            }

            + .collapse-enabled-hover-state {
                .cp-icon-chevron-up {
                    color: @color-blue;
                }
            }
        }
    }

    .panel-heading {
        .collapse-enabled-hover-state {
            cursor: pointer;

            &:hover {
                .cp-icon-chevron-up {
                    color: rgba(255, 255, 255, 0.7);
                }

                + .collapse-enabled-hover-state {
                    .cp-icon-chevron-up {
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
        }
    }

    .autoFillWand {
        color: @color-warning;
    }

    .buttons-row {
        padding: 4px 0;
    }

    .triggers-menu {
        width: 245px;

        > li > a {
            white-space: normal;
        }
    }

    .toggle-answer-container {
        margin-bottom: -9px;

        .toggle-label {
            .text-sm;
        }

        .btn-link {
            margin-left: -2px;
            padding: 4px;
        }
    }

    .form-title {
        .clearfix;

        .select2-basic {
            display: inline-block;

            .select2-container--default
                .select2-selection--single
                .select2-selection__arrow {
                right: 3px;
                top: 13px;
            }
        }
    }
    // Modals
    .modal {
        overflow-y: scroll;

        h3 {
            margin-top: 0;
        }

        #item-history-chart {
            margin-bottom: 20px;
        }

        .answer-history-seperator {
            margin-left: -25px;
            margin-right: -25px;
        }

        .close-form-group {
            margin-bottom: 0;
        }

        .unanswered-toggle {
            margin-bottom: 30px;
        }

        .unanswered-questions {
            margin-bottom: 20px;
        }

        hr {
            border-color: @color-light-grey;

            &.trigger-divide {
                margin: 25px 0 20px;
            }
        }

        .triggers {
            margin-bottom: 20px;
            margin: 10px 0 10px 15px;

            .triggers-row {
                .clearfix;
                border-bottom: solid 1px @color-light-grey;
                display: flex;

                .triggers-name {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    float: left;
                    margin: -4px 0 4px;
                    width: 75%;

                    label {
                        flex-basis: 100%;
                    }
                }

                .triggers-toggle {
                    float: left;
                    text-align: right;
                    width: 25%;
                }

                &:only-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }

        .answer-toggle {
            margin: 0;
        }

        .cp-icon-chevron-up {
            margin: 3px 0 0 4px;
        }

        .no-data {
            margin-top: 12px;
        }

        .activity-actions {
            margin-top: 25px;
        }

        .modal-footer {
            .hr {
                margin-top: 0;
            }
        }
    }

    .ras-response {
        .alert-info {
            p {
                margin-bottom: 0px !important;
            }

            b {
                font-weight: bold;
            }
        }

        .alert-success {
            p {
                margin-bottom: 0px !important;
            }

            b {
                color: #00856b;
                font-weight: bold;
            }
        }

        .rasTable thead {
            font-weight: bold;
        }

        .rasCell {
            border-left: solid 1px @color-light-grey;
        }

        .chcMissing {
            background-color: #bbe1bb;

            .fa-check {
                color: white !important;
            }
        }

        .chcC {
            background-color: #a4d7a4;

            .fa-check {
                color: white !important;
            }
        }

        .chcB {
            background-color: #85ca85;

            .fa-check {
                color: white !important;
            }
        }

        .chcA {
            background-color: #3a743a;

            .fa-check {
                color: white !important;
            }
        }

        .budget-container {
            display: flex;
            flex-wrap: nowrap;
            align-items: start;
            margin-bottom: 15px;

            .estimated-budget {
                padding: 10px 20px;
                border: 2px solid @color-light-grey;
                border-radius: 4px;
                background-color: @color-extra-light-grey;
                flex-grow: 1;
                align-self: normal;
                min-width: 49%;

                b.budget {
                    color: @color-blue;
                    display: block;
                    font-size: 35px;
                    padding: 5px;
                }
            }

            .formulate-request-info {
                flex-grow: 1;
            }

            .recalculate {
                flex-grow: 0;
            }
        }

        .actual-budget-container {
            .variation-reason {
                display: inline-block;
                margin-bottom: 20px;
            }

            .submit-budget {
                margin-top: 23px;
            }
        }

        .tab-panel {
            .nav.nav-tabs {
                margin-bottom: 15px;

                li {
                    cursor: pointer;
                }
            }

            .allocation-list {
                .key {
                    background-color: #f9f9f9;
                    width: 40%;
                }

                .value {
                    width: 60%;
                }
            }

            .tab-content {
                margin: 15px 0 0 0;

                p {
                    margin-bottom: @unit*2;
                }
            }
        }
    }

    .text-success {
        color: darken(@color-green, 10%);
    }

    .text-danger {
        color: darken(@color-red, 10%);
    }

    #item-history-chart,
    .highcharts-container {
        width: 100% !important;
    }
    // Edit & View shared styling
    .edit-form,
    .view-form {
        // Label
        label {
            display: inline-block;
        }

        .form-item-group {
            .answer-row {
                .clearfix;
            }

            .note {
                margin: 6px 0 0;
            }

            p {
                margin-bottom: 0;
            }

            .filedrag {
                .drag-instructions {
                    width: 100%;
                }

                .imageholder {
                    margin-right: 18px;
                }
            }

            & + .panel-body {
                margin-top: 20px;
            }

            & + .panel-body {
                margin-top: 20px;
            }
        }
        // Subheader
        .form-header {
            .clearfix;
            margin: 0 0 10px 0;
            padding: 0;
        }
        // Children
        .form-item:first-of-type .form-header {
            padding-top: 0;
        }

        .form-item-children {
            .form-item:first-of-type .form-header {
                padding-top: 15px;
            }
        }

        .checkbox-group-buttons-container,
        .buttons-container {
            .btn-key-item,
            .btn-alert,
            .btn-view-history {
                &:last-child {
                    margin-right: 0;
                }

                &:first-child {
                    margin-left: 2px;
                }
            }

            .btn-icon-small {
                .btn-key-item,
                .btn-alert,
                .btn-view-history {
                    font-size: 14px;
                    width: 30px;
                    height: 32px;
                }
            }

            .btn-text-long {
                .btn-key-item,
                .btn-alert,
                .btn-view-history {
                    width: auto;
                    font-size: 12px;
                }
            }
        }

        .checkbox-group-buttons-container.bi-state {
            padding: 7px;
        }
    }
    // Edit form
    .edit-form {
        .form-item {
            margin-bottom: 20px;

            &:nth-child(1) {
                > .header {
                    .activity-subtitle {
                        margin-top: 0px;
                    }
                }
            }

            .header {
                .activity-subtitle {
                    margin-top: 20px;
                }
            }

            .form-item-group {
                padding: 0;
                transition: background linear 0.3s;
            }

            &:only-child,
            &:last-child {
                margin-bottom: 0;
            }

            &:first-child .form-item-group {
                padding-top: 0;
            }

            .checkbox-question {
                margin-left: 28px;
                display: inline-block;
            }

            .checkbox-answers-container {
                .checkbox-container {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 22px;
                    width: 22px;
                    border-radius: 4px;
                    border: 2px solid @color-data-entry-button-background;
                    transition: background-color linear 0.1s;
                    transition: border linear 0.1s;
                }

                .checkbox-container input ~ .checkmark:after {
                    display: block;
                    position: absolute;
                }

                .checkbox-container input:checked ~ .checkmark:after {
                    display: block;
                    content: "";
                    position: absolute;
                }

                .checkbox-container .checkmark {
                    background-color: @color-data-entry-button-border;
                }

                .checkbox-container input:checked ~ .checkmark {
                    border-color: @color-blue;
                    background-color: transparent;
                }

                .checkbox-container input ~ .checkmark:after {
                    color: @color-blue;
                    font-family: ImosphereIcons;
                    top: 50%;
                    left: 52%;
                    transform: translate(-50%, -50%);
                }

                .checkbox-container input:checked ~ .checkmark:after {
                    content: "\e865";
                    font-size: 16px;
                }

                .checkbox-container .checkmark:hover {
                    background-color: @color-data-entry-button-border;
                    border-color: @color-data-entry-button-border;
                }
            }

            &.form-item-checkbox-radio-group {
                background-color: #fbfbfb;
                position: relative;
                margin: 3px;
                padding: 7px 10px 10px;

                &.tri-state {
                    padding: 7px;
                    background-color: #fbfbfb;
                    display: block;
                    border-radius: 4px;

                    label {
                        padding: 0;

                        &:hover,
                        &:focus-within {
                            background-color: transparent;

                            .checkmark {
                                border-color: #c4d9d7;

                                &:after {
                                    background-color: #c4d9d7;
                                }
                            }

                            input:checked ~ .checkmark {
                                border-color: @color-medium-dark-grey;
                                background-color: @color-white;

                                &:after {
                                    background-color: @color-medium-dark-grey;
                                }
                            }
                        }
                    }

                    & + .form-item-row:not(tri-state) {
                        margin-top: 20px;
                    }
                }

                label {
                    padding: 0;
                    background-color: #fbfbfb;
                    display: block;
                    border-radius: 4px;
                    transition: background-color linear 0.2s;
                    cursor: pointer;
                    line-height: 22px;
                    margin: 0;
                    border: 4px solid #fbfbfb;

                    &:not(.radio-button-label) {
                        &:hover,
                        &:focus-within {
                            outline: none;
                            background-color: @color-data-entry-button-border;
                            border-color: @color-data-entry-button-border;

                            .checkmark {
                                background-color: @color-white;
                                border-color: @color-medium-dark-grey;
                            }

                            input:checked ~ .checkmark {
                                border-color: @color-medium-dark-grey;
                                background-color: @color-white;

                                &:after {
                                    color: @color-medium-dark-grey;
                                }
                            }
                        }
                    }
                }

                .tri-state-label,
                .question-label {
                    font-weight: 600;
                    font-family: "Sofia Pro", helvetica, arial, sans-serif;
                    font-size: 1.067em;
                    color: @color-medium-dark-grey;
                    padding: 4px;
                }

                .form-item-checkbox-radio-group {
                    padding: 0px !important;

                    .checkbox-group-buttons-container {
                        margin: 4px;
                    }
                }

                &.clear-style {
                    padding: 0;
                    background-color: transparent;
                    margin: 0;

                    .radio-button-answers-container {
                        margin-top: 0;
                    }
                }

                .form-item-group {
                    padding: 0;
                    display: flex;

                    .question-item {
                        flex-grow: 1;
                        flex-basis: calc(100% - 266px);
                    }
                }

                .radio-button-question-container {
                    height: auto;
                    line-height: 22px;
                    margin: 2px;
                }

                .radio-button-answers-container {
                    margin-top: 6px;

                    .radio-button-label {
                        position: relative;
                        font-weight: normal;
                        padding-left: 30px;
                        padding-right: 10px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        font-family: @base-font-family;
                        font-size: 1em;
                        margin-bottom: 6px;
                        margin-right: 2px;
                        line-height: 22px;
                        padding: 0 15px 0 30px;
                        display: inline-flex;

                        .guidance {
                            display: none;
                        }

                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;

                            &:checked {
                                & ~ .checkmark {
                                    border-color: @color-blue;
                                    background-color: transparent;

                                    &:after {
                                        background-color: @color-blue;
                                    }
                                }
                            }
                        }

                        .checkmark {
                            position: absolute;
                            border-radius: 50%;
                            top: 0;
                            left: 0;
                            height: 22px;
                            width: 22px;
                            background-color: @color-data-entry-button-background;
                            border: 2px solid
                                darken(@color-data-entry-button-border, 10);
                            margin: 0 7px 0 0;
                            transition: background-color linear 0.1s;
                            transition: border-color linear 0.1s;

                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                                left: 4px;
                                top: 4px;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                display: block;
                                background-color: darken(
                                    @color-data-entry-button-border,
                                    10
                                );
                                transition: background-color linear 0.1s;
                            }
                        }

                        &:hover,
                        &:focus-within {
                            .checkmark {
                                border-color: darken(
                                    @color-data-entry-button-border,
                                    30
                                );

                                &:after {
                                    background-color: darken(
                                        @color-data-entry-button-border,
                                        30
                                    );
                                }
                            }

                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;

                                &:checked {
                                    & ~ .checkmark {
                                        background-color: #3d4d8494;
                                    }
                                }
                            }
                        }
                    }

                    &.with-help-text {
                        display: flex;
                        flex-wrap: wrap;

                        .radio-button-label {
                            flex: 0 0 24%;
                            display: initial;

                            .answer-option,
                            .guidance {
                                display: block;
                            }

                            @media only screen and (max-width: 1600px) {
                                flex: 0 0 32%;
                            }

                            @media only screen and (max-width: 1400px) {
                                flex: 0 0 49%;
                            }
                        }
                    }

                    .form-guidance {
                        display: block;
                    }
                }

                .checkbox-group-buttons {
                    position: absolute;
                    bottom: 4px;
                    right: 6px;
                }
            }
        }

        .auto-fill-enabled {
            .field-group-required {
                &::before {
                    content: "*";
                    color: lighten(@color-red, 10%);
                }
            }

            .panel-body-mandatory {
                border-radius: 0;
                border-bottom: @color-extra-light-grey;
            }

            .additional-questions-section {
                padding: 6px 15px;
                background-color: #fbfbfb;

                .form-item-checkbox-radio-group {
                    background-color: @color-panel-background;
                }

                .padding-adjustment {
                    color: @color-body;
                    font-size: 0.9em;
                    line-height: 21px;
                    margin-left: 4px;
                    margin-top: 1px;
                    transition: color linear 0.1s;
                }

                .heading {
                    font-family: @heading-font-family;
                    font-size: 1.067em;
                }

                &:hover {
                    .padding-adjustment {
                        color: @color-blue;
                    }
                }

                &.non-relevant-section {
                    opacity: 0.7;
                    font-style: italic;
                }
            }
        }

        .flex-container-input {
            display: flex; /* or inline-flex */
            flex-wrap: nowrap;
            display: -webkit-flex;
            display: -ms-flexbox;
            justify-content: flex-end;
            width: 100%;
            height: 32px;

            &.flex-item-input-short {
                width: 50%;
            }
        }

        .form-item {
            &.form-item-checkbox-radio-group {
                .form-item-group {
                    .flex-container-input {
                        &.flex-item-input-short {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .flex-item-input {
            flex-basis: 100%;
            flex-grow: 4;
            flex-shrink: 9;
        }

        .flex-item-buttons {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 0;
        }

        .repeating-section {
            .form-item {
                margin-bottom: 0;
                line-height: 32px;
            }

            .collapse,
            .collapsing {
                .form-item {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .buttons-container {
        .btn {
            &.btn-text {
                background-color: @color-data-entry-button-background;
                border: 2px solid @color-data-entry-button-border;
                border-radius: 16px;
                color: @color-dark-grey;
                font-size: 12px;
                float: left;
                height: 32px;
                line-height: 30px;
                margin: 0 3px;
                padding: 0 9px;
                transition: all linear 0.1s;

                &:not([disabled]) {
                    &:hover,
                    &:focus {
                        border-color: @color-blue;
                        background-color: @color-data-entry-button-background;
                    }
                }

                &.active {
                    border-color: @color-blue;
                    background-color: @color-blue;
                    box-shadow: none;
                    color: @color-white;

                    &:hover {
                        border-color: darken(@color-blue, 10%);
                        background-color: darken(@color-blue, 10%);
                    }

                    &:focus {
                        border-color: darken(@color-blue, 10%);
                        background-color: darken(@color-blue, 10%);
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 6px;
            }

            &.history:after {
                content: "History";
            }

            &.addnote:after {
                content: "Add Note";
            }

            &.hasnote:after {
                content: "Edit Note";
            }

            &.keyitem:after {
                content: "Key Item";
            }

            &.alert:after {
                content: "Alert";
            }
        }

        &.icons-only {
            .btn {
                &.btn-text {
                    font-size: 1.2em;
                    padding: 0;
                    text-align: center;
                    width: 32px;
                    position: relative;
                    font-family: FontAwesome;
                }

                &.history:after {
                    content: "\f017";
                }

                &.addnote:after {
                    content: "\f040";
                }

                &.hasnote:after {
                    content: "\f040";
                }

                &.keyitem:after {
                    content: "\f084";
                }

                &.alert:after {
                    content: "\f12a";
                }
            }
        }
    }

    .goal > li {
        border-bottom: solid 1px @color-extra-light-grey;
        padding: 1.25em 1.5em;

        .cp-icon-checkbox {
            cursor: pointer;
            margin-left: -1.25em;
            margin-top: 1px;

            &.selected {
                &:before {
                    content: "\e900";
                }

                color: @color-blue;

                &:focus,
                &:hover {
                    color: darken(@color-blue, 8%);
                }
            }
        }

        .cp-icon-check {
            color: @color-blue;
        }

        .goal-name {
            cursor: pointer;
            margin-bottom: 0;
        }

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    .strengths-needs {
        .text-sm;
        padding-top: 0.5em;

        .heading {
            display: block;
        }

        .value + .value {
            margin-top: 0.375em;
        }
    }
    // View form
    .view-form {
        .panel-body,
        .form-item-children-container {
            display: flex;
            display: -webkit-flex;
            display: -ms-flexbox;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: row;

            .form-item,
            .form-item-children {
                flex-basis: 100%;
                max-width: 100%;
            }

            .checkbox-item {
                flex-basis: calc(50% - 6px);

                .form-item,
                .form-item-children-checkbox {
                    flex-basis: 100%;
                    padding: 0;
                    margin: 0;
                }

                .form-item-children-checkbox {
                    margin: 10px 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .form-item-children-checkbox {
                flex-basis: 50%;
            }
        }

        .goal > li {
            padding-left: 0;
        }

        .buttons-col {
            padding-left: 0;
        }

        .buttons-container {
            height: 32px;
            clear: both !important;
            font-style: normal !important;

            .btn-key-item,
            .btn-alert,
            .btn-view-history {
                font-size: 16px;
                width: 34px;
                height: 36px;
            }
        }

        .form-item-children-container {
            .form-item-children-checkbox:last-child .checkbox-item {
                margin-bottom: 13px;
            }
        }

        .checkbox-item {
            padding: 7px 10px 10px;
            background-color: #fbfbfb;
            margin: 3px;

            + .form-item:not(.checkbox-item) {
                .question-col,
                .buttons-col {
                    padding-top: 10px;
                }
            }

            .row {
                margin-left: 0;
                margin-right: 0;

                .question-col,
                .buttons-col {
                    padding-right: 0;
                    position: relative;
                    min-height: 45px;
                    float: left;
                }

                &.icons-only .buttons-col {
                    width: 115px;
                }

                &.icons-only .question-col {
                    width: calc(100% - 115px);
                    padding: 0;
                }
            }

            .form-item-group {
                padding: 0 !important;
            }

            .tick-box-container {
                .checkmark {
                    display: block;
                    position: absolute;
                    top: unset;
                    left: unset;
                    width: 18px;
                    height: 18px;
                    border: 2px solid #3a3a3a;
                }

                .checkmark:after {
                    display: block;
                    position: absolute;
                    font-family: ImosphereIcons;
                    top: 50%;
                    left: 52%;
                    transform: translate(-50%, -50%);
                    color: #3a3a3a;
                }

                .checkmark.checked:after {
                    content: "\e865";
                    font-size: 16px;
                }

                .checkbox-question {
                    position: relative;
                    display: block;
                    width: calc(100% - 25px);
                    left: 25px;
                }
            }
        }

        .table-data-entry-container {
            margin-bottom: 24px;
        }
    }

    .show-all-questions {
        .form-item-group,
        .form-item-children .form-item:last-of-type .form-item-group,
        .form-item-children:last-of-type .form-item .form-item-group {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .form-item:last-of-type .form-item-group,
        .form-item:last-of-type
            .form-item-children:last-of-type
            .form-item:last-of-type
            .form-item-group {
            border-bottom: none;
            padding-bottom: 10px;
        }

        .form-header + .form-item-children .form-item-group {
            padding-top: 2px !important;
        }

        .form-item:not(:last-of-type)
            .form-item-children:last-of-type
            .form-item:last-of-type
            .form-item-group {
            padding-bottom: 10px;
        }

        .form-item-group {
            &.unanswered {
                color: @color-medium-grey;
                font-style: italic;

                label {
                    color: @color-medium-grey;
                }
            }
        }
    }

    .show-only-answered {
        .form-item-group {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .answered-item.first {
            .answered {
                padding-top: 0;
            }

            .form-item-children {
                .answered {
                    padding-top: 20px;
                }
            }
        }

        .first-header {
            padding-top: 0;
        }

        .answered-item.last {
            .answered {
                border-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        .form-header + .answered-child {
            .answered {
                padding-top: 0;
            }
        }
    }

    #guprogress-target {
        .guprogress-bar {
            background-color: @color-green;
            z-index: 100;
            display: block;
            position: absolute;
            width: 100%;
            height: 3px;
        }

        &.progress-danger {
            .guprogress-bar {
                background-color: @color-red;
            }
        }
    }

    .collapse-enabled-hover-state {
        cursor: pointer;

        &:hover {
            .cp-icon-chevron-up {
            }

            + .collapse-enabled-hover-state {
                .cp-icon-chevron-up {
                    color: @color-white;
                }
            }
        }
    }

    .form-instance-tags-add-btn {
        margin: 4px;
        padding: 4px;
        color: @color-blue;
        font-size: 13px;

        &.text-muted {
            color: darken(@color-medium-grey, 6%);
        }
    }

    .form-instance-tags-container {
        margin: 8px 0 0;
    }

    .form-instance-tag {
        background-color: @color-data-entry-button-background;
        border-radius: 4px;
        display: inline-block;
        font-size: 13px;
        font-weight: 300;
        line-height: 24px;
        margin: 4px 2px;
        padding: 0 10px 0 10px;
        position: relative;
        transition: background-color linear 0.1s;

        &.tag-edit {
            padding: 0 10px 0 10px;
        }
    }
}

.show-more-toggle {
    color: @color-blue;
    cursor: pointer;
}

.state-component {
    background-color: @color-white;
    padding: 10px;
    margin-bottom: 10px;
    align-content: center;
    max-height: 160px;

    .component {
        padding-top: 7px;
        height: 34px;
        margin-left: 21%;

        .component-tracker {
            display: flex;
            margin: 0;
            counter-reset: item;
            list-style-type: none;
            padding: 0;

            .step {
                &:not(.active) {
                    .step-name {
                        background-color: @color-blue;
                        transition: background-color ease-in-out 0.5s;
                    }
                }

                &.after {
                    .step-name {
                        background-color: @color-data-entry-button-border;
                    }
                }

                .circle {
                    border-radius: 20px;
                    content: "";
                    font-size: 18px;
                    line-height: 1.8em;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    z-index: 10;
                    background-color: @color-white;
                    transition: background-color 0.7s ease;

                    &:not(.active) {
                        background-color: @color-blue;

                        &:before {
                            color: @color-white;
                            content: "\E865";
                            display: block;
                            font-family: ImosphereIcons;
                        }
                    }

                    &.active {
                        background-color: @color-white;
                        border: 6px solid @color-blue;
                    }

                    &.after {
                        width: 22px;
                        height: 22px;
                        margin-top: 2px;
                        border: 4px @color-data-entry-button-border solid;
                        background-color: @color-white;

                        &:before {
                            color: @color-white;
                        }
                    }
                }

                flex: 1;

                &.active-pending {
                    &:before {
                        background: lighten(@color-blue, 30%);
                    }
                }

                .step-name {
                    display: inline-flex;
                    margin: 0 0 0 15px;
                    position: relative;
                    background: @color-data-entry-button-border;
                    height: 4px;
                    width: 100%;
                    align-items: center;
                    z-index: 1;
                    padding: 0 30px;
                }
            }
        }
    }

    .current-state {
        font-size: 11px;
        color: grey;
        display: block;
        text-align: center;
    }

    .current-state-update {
        font-size: 16px;
        text-align: center;
        display: block;
        color: @color-blue;
        font-weight: 600;
        font-family: sofia pro;
    }

    .component-buttons {
        height: 40px;

        .btn {
            background-color: @color-blue;
            color: @color-white;
            display: inline-block;
            border-radius: 0;
            padding: 10px 18px;
            -moz-transition: all 0.2s;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
        }

        .main-text {
            font-size: 14px;
        }
    }
}

.state-component-buttons {
    background-color: #f9f9f9;
    padding: 0;
    margin-top: -10px;
    margin-bottom: 10px;
    height: 34px;
    display: flex;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
    }

    &:after {
        content: "";
        clear: both;
        display: table;
    }

    span,
    a {
        background-color: #f9f9f9;
        color: grey;
        padding: 7px 0;
        flex-grow: 1;
        max-width: 50%;
        font-size: 14px;
        text-align: center;

        &:first-child {
            border-right: 2px solid white;
        }

        &.pull-left {
            align-self: flex-start;
        }

        &.pull-right {
            align-self: flex-end;
            margin-left: auto;
        }
    }

    a {
        @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            width: 50%;
        }

        cursor: pointer;

        &:hover,
        &:focus {
            background-color: darken(#f9f9f9, 10%);
            color: grey;
            text-decoration: none;
        }
    }
}

.centre-of-care-event {
    border-radius: 0px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    height: 47px;
    background-color: @color-white;
    border: none;
    margin-bottom: 10px !important;
    width: 100%;

    .imo {
        font-size: 22px;
        color: @color-blue;
        padding-top: 7px;
    }

    .centre-of-care-event-name {
        font-size: 15px;
        padding-top: 8px;
    }
}

.form-tracker-container {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -20px;
    padding: 0 20px 20px;
    max-height: 550px;
}

body.highlight-mandatory {
    .not-nullable {
        margin: -5px;
        background: rgba(255, 0, 0, 0.2);
        border-radius: 4px;
        padding: 5px !important;
    }
}

.tooltip-estimated-budget {
    font-size: 3em;
    font-family: "Sofia Pro", sans-serif;
    color: @color-blue;
}

.additional-items {
    background: transparent;
    border: none;
}

.form-heading-actions-container {
    display: flex;
    flex-direction: row;

    .form-heading {
        flex-grow: 1;
        line-height: 50px;
        flex-basis: max-content;

        &.normal-line-height {
            line-height: 1.2em;
        }
    }

    .form-actions {
        flex-grow: 1;
    }
}

.form-instance-meta-data {
    background-color: #fafafa;
    padding: 8px 15px;

    h3 {
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;

        li {
            min-width: 25%;
            margin-bottom: 8px;

            .key {
                display: block;
                font-weight: 600;
                font-family: "Sofia Pro";
                font-size: 14px;
            }
        }
    }
}
// Medium Devices, Desktops - 992
@media only screen and (max-width: @screen-md-min) {
    .page-activities {
        // Panels
        .panel {
            &.panel-border-top {
                &:last-of-type {
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: @screen-lg-min) {
    .page-activities {
        .triggers-menu {
            width: auto;
        }
    }
}
// Faux Print Preview
.page-print {
    // Guidance
    .form-guidance {
        font-style: italic;
        font-weight: 400;
        padding: 0;
    }

    .status-label {
        margin: 0 4px !important;

        &:last-of-type {
            margin-right: 0 !important;
        }
    }

    .form-item-children {
        &:last-of-type table {
            margin-bottom: 5px !important;
        }
    }

    #footer {
        display: none;
        visibility: hidden;
    }
}

@media print {
    .page-print {
        .btn {
            &.btn-print {
                display: none !important;
                visibility: hidden !important;
            }
        }
    }
}

@media (min-width: 768px) {
    .row.equal {
        display: flex;
        flex-wrap: wrap;
    }
}
