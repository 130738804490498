.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-wrap {
  white-space: nowrap;
}
.pre-wrap {
  white-space: pre-wrap;
}
.word-break {
  word-wrap: break-word;
}
.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-input::-ms-clear {
  display: none;
}
.form-control::-ms-clear {
  display: none;
}
.remove-ie-clear::-ms-clear {
  display: none;
}
.color-tint-danger {
  color: #ed3a3a;
}
.theme-default .badge {
  background-color: #3d4d84;
  font-weight: 400;
}
.badge.badge-default {
  background-color: #3d4d84 !important;
}
.theme-default .btn.btn-text:hover,
.theme-default .btn.btn-text:focus {
  color: #3d4d84;
}
.theme-default .btn.add-note,
.theme-default .btn.btn-view-history {
  color: #111;
}
.theme-default .btn.add-note.focus,
.theme-default .btn.btn-view-history.focus,
.theme-default .btn.add-note:focus,
.theme-default .btn.btn-view-history:focus,
.theme-default .btn.add-note:hover,
.theme-default .btn.btn-view-history:hover {
  background-color: transparent;
  color: #3d4d84;
}
.theme-default .btn.add-note.disabled,
.theme-default .btn.btn-view-history.disabled,
.theme-default .btn.add-note:disabled,
.theme-default .btn.btn-view-history:disabled {
  color: #111;
}
.theme-default .btn.add-note {
  margin-top: 0.125em;
  margin-left: -5px;
}
.theme-default .btn.has-note {
  color: #3d4d84;
}
.theme-default .btn.btn-key-item,
.theme-default .btn.btn-alert {
  color: #111;
}
.theme-default .btn.btn-key-item:focus,
.theme-default .btn.btn-alert:focus {
  background-color: transparent;
}
.theme-default .btn.btn-key-item:hover,
.theme-default .btn.btn-alert:hover {
  background-color: transparent;
  border: solid 1px #ececec;
  color: #3d4d84;
}
.theme-default .btn.btn-key-item:disabled:hover,
.theme-default .btn.btn-alert:disabled:hover {
  background-color: transparent;
  border: solid 1px #707070;
  color: #111;
}
.theme-default .btn.btn-key-item.active,
.theme-default .btn.btn-alert.active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-key-item.active:hover,
.theme-default .btn.btn-alert.active:hover {
  background-color: #3d4d84;
  border-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-key-item.active:hover:disabled,
.theme-default .btn.btn-alert.active:hover:disabled {
  border: solid 1px #707070;
  background-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-key-item.active:active,
.theme-default .btn.btn-alert.active:active {
  box-shadow: inset 1px 1px 4px -1px #1b233b;
  background-color: #374577;
}
.theme-default .btn.btn-key-item,
.theme-default .btn.btn-alert,
.theme-default .btn.btn-view-history {
  border: solid 1px #707070;
  background-color: #fff;
}
.theme-default .btn.btn-print-history {
  margin-right: 4px;
}
.theme-default .btn.btn-show-more {
  color: #3d4d84;
}
.theme-default .btn.btn-show-more.collapsed:before {
  content: "Show More";
}
.theme-default .btn.btn-show-more:before {
  content: "Show Less";
}
.theme-default .btn.btn-summary-quick-link {
  background-color: #3d4d84;
  border-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-summary-quick-link.align-btn {
  margin-left: 155px;
  margin-top: -90px;
  z-index: 2;
}
.theme-default .btn.btn-summary-quick-link:focus,
.theme-default .btn.btn-summary-quick-link.focus,
.theme-default .btn.btn-summary-quick-link:hover {
  background-color: #374577;
  border-color: #374577;
  color: #fff;
}
.theme-default .btn.btn-summary-quick-link:active,
.theme-default .btn.btn-summary-quick-link.active {
  box-shadow: inset 1px 1px 4px -1px #1b233b;
  background-color: #374577;
  border-color: #2b365c;
}
.theme-default .btn.btn-summary-quick-link:active:hover,
.theme-default .btn.btn-summary-quick-link.active:hover,
.theme-default .btn.btn-summary-quick-link:active:focus,
.theme-default .btn.btn-summary-quick-link.active:focus,
.theme-default .btn.btn-summary-quick-link:active.focus,
.theme-default .btn.btn-summary-quick-link.active.focus {
  box-shadow: inset 1px 1px 4px -1px #283256;
  background-color: #3a497d;
  border-color: #374577;
}
.theme-default .btn.btn-summary-quick-link.disabled,
.theme-default .btn.btn-summary-quick-link[disabled],
.theme-default .btn.btn-summary-quick-link.disabled:hover,
.theme-default .btn.btn-summary-quick-link[disabled]:hover,
.theme-default .btn.btn-summary-quick-link.disabled:focus,
.theme-default .btn.btn-summary-quick-link[disabled]:focus,
.theme-default .btn.btn-summary-quick-link.disabled.focus,
.theme-default .btn.btn-summary-quick-link[disabled].focus,
.theme-default .btn.btn-summary-quick-link.disabled:active,
.theme-default .btn.btn-summary-quick-link[disabled]:active,
.theme-default .btn.btn-summary-quick-link.disabled.active,
.theme-default .btn.btn-summary-quick-link[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #3d4d84;
  border-color: #3d4d84;
  cursor: auto;
}
.theme-default .btn.btn-summary-quick-link .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.theme-default .btn.btn-summary-quick-link .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.theme-default .btn.btn-summary-trigger {
  background-color: #8f9397;
  border-color: #8f9397;
  color: #fff;
}
.theme-default .btn.btn-summary-trigger.align-btn {
  margin-left: 155px;
  margin-top: -90px;
  z-index: 2;
}
.theme-default .btn.btn-summary-trigger:focus,
.theme-default .btn.btn-summary-trigger.focus,
.theme-default .btn.btn-summary-trigger:hover {
  background-color: #818488;
  border-color: #818488;
  color: #fff;
}
.theme-default .btn.btn-summary-trigger:active,
.theme-default .btn.btn-summary-trigger.active {
  box-shadow: inset 1px 1px 4px -1px #404244;
  background-color: #818488;
  border-color: #64676a;
}
.theme-default .btn.btn-summary-trigger:active:hover,
.theme-default .btn.btn-summary-trigger.active:hover,
.theme-default .btn.btn-summary-trigger:active:focus,
.theme-default .btn.btn-summary-trigger.active:focus,
.theme-default .btn.btn-summary-trigger:active.focus,
.theme-default .btn.btn-summary-trigger.active.focus {
  box-shadow: inset 1px 1px 4px -1px #5d6062;
  background-color: #888c8f;
  border-color: #818488;
}
.theme-default .btn.btn-summary-trigger.disabled,
.theme-default .btn.btn-summary-trigger[disabled],
.theme-default .btn.btn-summary-trigger.disabled:hover,
.theme-default .btn.btn-summary-trigger[disabled]:hover,
.theme-default .btn.btn-summary-trigger.disabled:focus,
.theme-default .btn.btn-summary-trigger[disabled]:focus,
.theme-default .btn.btn-summary-trigger.disabled.focus,
.theme-default .btn.btn-summary-trigger[disabled].focus,
.theme-default .btn.btn-summary-trigger.disabled:active,
.theme-default .btn.btn-summary-trigger[disabled]:active,
.theme-default .btn.btn-summary-trigger.disabled.active,
.theme-default .btn.btn-summary-trigger[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #8f9397;
  border-color: #8f9397;
  cursor: auto;
}
.theme-default .btn.btn-summary-trigger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.theme-default .btn.btn-summary-trigger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.theme-default .btn.btn-primary {
  background-color: #3d4d84;
  border-color: #3d4d84;
}
.theme-default .btn.btn-primary:focus,
.theme-default .btn.btn-primary.focus,
.theme-default .btn.btn-primary:hover {
  background-color: #505f90;
  /*border-color: mix(@theme-color, black, 80%);*/
  color: #fff;
}
.theme-default .btn.btn-primary:active,
.theme-default .btn.btn-primary.active {
  box-shadow: inset 1px 1px 4px -1px #1b233b;
  background-color: #374577;
  border-color: #2b365c;
}
.theme-default .btn.btn-primary:active:hover,
.theme-default .btn.btn-primary.active:hover,
.theme-default .btn.btn-primary:active:focus,
.theme-default .btn.btn-primary.active:focus,
.theme-default .btn.btn-primary:active.focus,
.theme-default .btn.btn-primary.active.focus {
  box-shadow: inset 1px 1px 4px -1px #283256;
  background-color: #3a497d;
  border-color: #374577;
}
.theme-default .btn.btn-primary.disabled,
.theme-default .btn.btn-primary[disabled],
.theme-default .btn.btn-primary.disabled:hover,
.theme-default .btn.btn-primary[disabled]:hover,
.theme-default .btn.btn-primary.disabled:focus,
.theme-default .btn.btn-primary[disabled]:focus,
.theme-default .btn.btn-primary.disabled.focus,
.theme-default .btn.btn-primary[disabled].focus,
.theme-default .btn.btn-primary.disabled:active,
.theme-default .btn.btn-primary[disabled]:active,
.theme-default .btn.btn-primary.disabled.active,
.theme-default .btn.btn-primary[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #3d4d84;
  border-color: #3d4d84;
  cursor: auto;
}
.theme-default .btn.btn-primary .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.theme-default .btn.btn-primary .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.theme-default .btn.btn-default {
  background-color: #fff;
  border-color: #444;
  color: #202020;
}
.theme-default .btn.btn-default.alternate {
  background-color: #ececec;
  border-color: #ececec;
}
.theme-default .btn.btn-default.alternate:hover,
.theme-default .btn.btn-default.alternate:focus {
  background-color: #e0e0e0;
  border-color: #fff;
  color: #111;
}
.theme-default .btn.btn-default.alternate:active,
.theme-default .btn.btn-default.alternate.active {
  background-color: #fff;
  color: #111;
}
.theme-default .btn.btn-default.white-border {
  border-color: #fff;
}
.theme-default .btn.btn-default:focus,
.theme-default .btn.btn-default.focus,
.theme-default .btn.btn-default:hover {
  background-color: #fff;
  border-color: #68749f;
  color: #3d4d84;
}
.theme-default .btn.btn-default:focus [class^="cp-icon-"],
.theme-default .btn.btn-default.focus [class^="cp-icon-"],
.theme-default .btn.btn-default:hover [class^="cp-icon-"] {
  color: inherit;
}
.theme-default .btn.btn-default:active,
.theme-default .btn.btn-default.active {
  box-shadow: inset 1px 1px 4px -1px #283256;
  background-color: #818baf;
  border-color: #3d4d84;
  color: #111;
}
.theme-default .btn.btn-default:active [class^="cp-icon-"],
.theme-default .btn.btn-default.active [class^="cp-icon-"] {
  color: inherit;
}
.theme-default .btn.btn-default:active:hover,
.theme-default .btn.btn-default.active:hover,
.theme-default .btn.btn-default:active:focus,
.theme-default .btn.btn-default.active:focus,
.theme-default .btn.btn-default:active.focus,
.theme-default .btn.btn-default.active.focus {
  box-shadow: none;
  background-color: #7782a9;
  border-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-default:active:hover [class^="cp-icon-"],
.theme-default .btn.btn-default.active:hover [class^="cp-icon-"],
.theme-default .btn.btn-default:active:focus [class^="cp-icon-"],
.theme-default .btn.btn-default.active:focus [class^="cp-icon-"],
.theme-default .btn.btn-default:active.focus [class^="cp-icon-"],
.theme-default .btn.btn-default.active.focus [class^="cp-icon-"] {
  color: inherit;
}
.theme-default .btn.btn-default.disabled,
.theme-default .btn.btn-default[disabled],
.theme-default .btn.btn-default.disabled:hover,
.theme-default .btn.btn-default[disabled]:hover,
.theme-default .btn.btn-default.disabled:focus,
.theme-default .btn.btn-default[disabled]:focus,
.theme-default .btn.btn-default.disabled.focus,
.theme-default .btn.btn-default[disabled].focus,
.theme-default .btn.btn-default.disabled:active,
.theme-default .btn.btn-default[disabled]:active,
.theme-default .btn.btn-default.disabled.active,
.theme-default .btn.btn-default[disabled].active {
  box-shadow: inset 1px 1px 4px -1px rgba(0, 0, 0, 0);
  background-color: #fff;
  border-color: #ececec;
  color: #202020;
  cursor: auto;
}
.theme-default .btn.btn-default.disabled [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled] [class^="cp-icon-"],
.theme-default .btn.btn-default.disabled:hover [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled]:hover [class^="cp-icon-"],
.theme-default .btn.btn-default.disabled:focus [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled]:focus [class^="cp-icon-"],
.theme-default .btn.btn-default.disabled.focus [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled].focus [class^="cp-icon-"],
.theme-default .btn.btn-default.disabled:active [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled]:active [class^="cp-icon-"],
.theme-default .btn.btn-default.disabled.active [class^="cp-icon-"],
.theme-default .btn.btn-default[disabled].active [class^="cp-icon-"] {
  color: #202020;
}
.theme-default .btn.btn-default[disabled].active.active-disabled {
  background-color: #818baf;
}
.theme-default .btn.btn-themed-border {
  background-color: #fff;
  border-color: #3d4d84;
  color: #3d4d84;
  cursor: auto;
}
.theme-default .btn.btn-themed-border:focus,
.theme-default .btn.btn-themed-border.focus,
.theme-default .btn.btn-themed-border:hover {
  background-color: #505f90;
  border-color: #313e6a;
  color: #fff;
}
.theme-default .btn.btn-themed-border:active,
.theme-default .btn.btn-themed-border.active {
  box-shadow: inset 1px 1px 4px -1px #1b233b;
  background-color: #374577;
  border-color: #2b365c;
}
.theme-default .btn.btn-themed-border:active:hover,
.theme-default .btn.btn-themed-border.active:hover,
.theme-default .btn.btn-themed-border:active:focus,
.theme-default .btn.btn-themed-border.active:focus,
.theme-default .btn.btn-themed-border:active.focus,
.theme-default .btn.btn-themed-border.active.focus {
  box-shadow: inset 1px 1px 4px -1px #283256;
  background-color: #3a497d;
  border-color: #374577;
}
.theme-default .btn.btn-themed-border.disabled,
.theme-default .btn.btn-themed-border[disabled],
.theme-default .btn.btn-themed-border.disabled:hover,
.theme-default .btn.btn-themed-border[disabled]:hover,
.theme-default .btn.btn-themed-border.disabled:focus,
.theme-default .btn.btn-themed-border[disabled]:focus,
.theme-default .btn.btn-themed-border.disabled.focus,
.theme-default .btn.btn-themed-border[disabled].focus,
.theme-default .btn.btn-themed-border.disabled:active,
.theme-default .btn.btn-themed-border[disabled]:active,
.theme-default .btn.btn-themed-border.disabled.active,
.theme-default .btn.btn-themed-border[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #3d4d84;
  border-color: #3d4d84;
  cursor: auto;
}
.theme-default .btn.btn-themed-border .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.theme-default .btn.btn-themed-border .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.theme-default .btn.btn-action {
  border-color: #fca744;
}
.theme-default .btn.btn-fa {
  border-color: none;
  background: none;
}
.theme-default .btn.btn-fa .fa-btn-alert {
  color: #ed3a3a;
}
.theme-default .btn.btn-pin.active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.btn-pin.active.focus,
.theme-default .btn.btn-pin.active:focus,
.theme-default .btn.btn-pin.active:hover {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #505f90;
  border-color: #2b365c;
}
.theme-default .btn.btn-pin.active:active {
  box-shadow: inset 1px 1px 4px -1px #1b233b;
  background-color: #374577;
}
.theme-default .btn.back-to-top {
  background-color: #3d4d84;
  color: #fff;
}
.theme-default .btn.back-to-top:hover {
  background-color: #2d3961;
}
.theme-default .btn.btn-as-h2:active,
.theme-default .btn.btn-as-h2:focus,
.theme-default .btn.btn-as-h2:hover {
  color: #3d4d84;
}
.theme-default .btn-group.primary {
  background-color: #3d4d84;
}
.theme-default .btn-group .btn.btn-default:first-child:last-child:hover,
.theme-default .btn-group .btn.btn-default:first-child:last-child:focus {
  background-color: #fff;
  color: #3d4d84;
}
.theme-default .btn-group.no-padding {
  padding: 0;
}
.theme-default .btn-group.no-padding .btn {
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
}
.theme-default .btn-group.no-padding .btn.btn-default.btn-icon:hover,
.theme-default .btn-group.no-padding .btn.btn-default.btn-icon:focus {
  border-color: #3d4d84;
}
.input-group-btn .btn.btn-default {
  background-color: #ececec;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #111;
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  padding: 0 8px;
  text-align: center;
  transition: background-color linear 0.1s;
}
.input-group-btn .btn.btn-default:hover,
.input-group-btn .btn.btn-default:focus,
.input-group-btn .btn.btn-default:active {
  background-color: #d3d3d3;
  color: #111;
}
.btn-toolbar .btn {
  margin-left: 10px;
}
.btn-toolbar .btn.btn-default {
  border: none;
}
.theme-default .datepicker table .switch:hover {
  color: #3d4d84;
  text-decoration: underline;
}
.theme-default .datepicker table .prev:hover,
.theme-default .datepicker table .next:hover,
.theme-default .datepicker table .month.current,
.theme-default .datepicker table .day:hover,
.theme-default .datepicker table .month:hover,
.theme-default .datepicker table .year:hover,
.theme-default .datepicker table .day.current,
.theme-default .datepicker table .month.current,
.theme-default .datepicker table .year.current {
  color: #3d4d84;
}
.theme-default .datepicker table .day.active,
.theme-default .datepicker table .month.active,
.theme-default .datepicker table .year.active {
  background: #3d4d84;
}
.theme-default .datepicker table .day.active:hover,
.theme-default .datepicker table .month.active:hover,
.theme-default .datepicker table .year.active:hover {
  background: #374576;
}
.theme-default .datepicker table .day.current,
.theme-default .datepicker table .month.current,
.theme-default .datepicker table .year.current {
  color: #3d4d84;
}
.theme-default .code-picker li > div.selected,
.theme-default .code-picker li > div.selectable.selected:hover {
  border-radius: 3px;
  background: #8f9ccb;
}
.theme-default .code-picker li > div.selectable:hover {
  background: #fafafa;
}
.theme-default .code-picker li > div.disabled .label-primary {
  background: #707070;
}
.theme-default .code-picker li > div.disabled:hover {
  background: none;
}
.theme-default .code-picker .cp-icon-caret-right,
.theme-default .code-picker .cp-icon-caret-down {
  color: #111 !important;
}
.theme-default .code-picker .label.label-default {
  background-color: rgba(61, 77, 132, 0.4);
  display: inline-block;
  min-width: 60px;
  padding: 4px 6px;
}
.theme-default .dropdown-menu > li > a:focus,
.theme-default .dropdown-menu > li > a:hover {
  background-color: #ffffff;
  color: #3d4d84;
}
.theme-default .dropdown-menu > li > a:focus b,
.theme-default .dropdown-menu > li > a:hover b,
.theme-default .dropdown-menu > li > a:focus strong,
.theme-default .dropdown-menu > li > a:hover strong {
  color: #3d4d84;
}
.theme-default .dropdown-menu > li.selected > a,
.theme-default .dropdown-menu > li > a.selected,
.theme-default .dropdown-menu > li.active > a,
.theme-default .dropdown-menu > li > a.active {
  background-color: #3d4d84;
  color: #fff;
}
.theme-default .dropdown-menu .fixed-height-container > ul > li > a.active {
  background-color: #3d4d84;
}
.theme-default .dropdown-menu .fixed-height-container > ul > li > a:hover,
.theme-default .dropdown-menu .fixed-height-container > ul > li > a:focus {
  background-color: #ffffff;
  color: #3d4d84;
}
.theme-default legend {
  background-color: #3d4d84;
}
.theme-default .form-control:focus {
  /*border-color: @theme-color;
                box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.10);*/
}
.theme-default .profile-photo-upload.has-error .btn-default {
  border-color: rgba(61, 77, 132, 0.8);
  box-shadow: 0 0 6px rgba(61, 77, 132, 0.8);
}
.theme-default .profile-photo-upload.has-error .btn-danger {
  border-color: #eb1515;
  box-shadow: none;
}
.theme-default .select2-container.select2-container--default .select2-selection--single[aria-expanded="true"] {
  border-color: #3d4d84;
}
.theme-default .select2-container.select2-container--default .select2-selection--single:hover:not([aria-disabled="true"]),
.theme-default .select2-container.select2-container--default .select2-selection--single:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.theme-default .select2-container.select2-container--default .select2-dropdown.select2-dropdown--below {
  border-color: #3d4d84;
}
.theme-default .select2-container.select2-container--default .select2-selection--multiple[aria-expanded="true"] {
  border-color: #3d4d84;
}
.theme-default .select2-container.select2-container--default .select2-selection--multiple:hover:not([aria-disabled="true"]),
.theme-default .select2-container.select2-container--default .select2-selection--multiple:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.theme-default .form-control:hover:not([aria-disabled="true"]),
.theme-default .form-control:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.theme-default [class^="cp-icon-"]:focus,
.theme-default [class*="cp-icon-"]:focus,
.theme-default [class^="cp-icon-"]:hover,
.theme-default [class*="cp-icon-"]:hover,
.theme-default [class^="cp-icon-"].active,
.theme-default [class*="cp-icon-"].active,
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes.gutruncate_open:before,
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes.gutruncate_open:before {
  color: #3d4d84;
  outline: none;
}
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes:not(.collapsed),
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes:not(.collapsed) {
  color: #3d4d84;
}
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):hover,
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):hover,
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):focus,
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):focus {
  color: #2d3961;
}
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):hover:before,
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):hover:before,
.theme-default [class^="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):focus:before,
.theme-default [class*="cp-icon-"].cp-icon-guidance-notes:not(.collapsed):focus:before {
  color: inherit;
}
.theme-default .cp-icon-chevron-down,
.theme-default .cp-icon-chevron-up {
  cursor: pointer;
}
.theme-default .cp-icon-cake {
  color: #3d4d84;
}
.theme-default .cp-icon-paperclip:focus,
.theme-default .cp-icon-paperclip:hover {
  color: #707070;
}
.theme-default .cp-icon-plus:not(.static-icon):focus:before,
.theme-default .cp-icon-plus:not(.static-icon):hover:before {
  content: "\e918";
}
.theme-default .cp-icon-cross:focus,
.theme-default .cp-icon-cross:hover {
  color: #bf201f;
}
.theme-default .cp-icon-cross:focus:before,
.theme-default .cp-icon-cross:hover:before {
  content: "\e924";
}
.theme-default .cp-icon-bell,
.theme-default .cp-icon-bell-s {
  color: #3d4d84;
}
.theme-default .cp-icon-bell:focus,
.theme-default .cp-icon-bell-s:focus,
.theme-default .cp-icon-bell:hover,
.theme-default .cp-icon-bell-s:hover {
  color: #3d4d84;
}
.theme-default .cp-icon-bell-slash:focus,
.theme-default .cp-icon-bell-slash:hover,
.theme-default .cp-icon-refresh:focus,
.theme-default .cp-icon-refresh:hover {
  color: #707070;
}
.theme-default .cp-icon-function,
.theme-default .cp-icon-function:focus,
.theme-default .cp-icon-function:hover {
  color: #707070;
}
.theme-default .label.label-open,
.theme-default .label.label-primary,
.theme-default .label.label-tint {
  background-color: #3d4d84;
}
.theme-default .label.label-key-item,
.theme-default .label.label-alert {
  background-color: #3d4d84;
}
.theme-default .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.theme-default .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #3d4d84;
}
.sk-spinner.theme-default.sk-spinner-double-bounce .sk-double-bounce1,
.sk-spinner.theme-default.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #3d4d84;
}
.theme-default .modal-dialog .modal-content .modal-title {
  color: #3d4d84;
}
.theme-default.modal-alerts .modal-content .modal-header .modal-title {
  color: #3d4d84;
}
.theme-default .pagination > li > a:focus,
.theme-default .pagination > li > a:hover,
.theme-default .pagination > li > span:focus,
.theme-default .pagination > li > span:hover {
  border-color: #3d4d84;
  color: #3d4d84;
  z-index: 15;
}
.theme-default .pagination > li > a:focus > [class^="cp-icon-"],
.theme-default .pagination > li > a:hover > [class^="cp-icon-"] {
  color: #3d4d84;
}
.theme-default .pagination > li .pagination-detail:focus,
.theme-default .pagination > li .pagination-detail:hover {
  border-color: #707070 !important;
  color: #111 !important;
}
.theme-default .pagination > li.disabled > a {
  box-shadow: inset 1px 1px 4px -1px transparent;
}
.pagination.pagination-default > li > a:focus,
.pagination.pagination-default > li > a:hover,
.pagination.pagination-default > li > span:focus,
.pagination.pagination-default > li > span:hover {
  border-color: #3d4d84 !important;
  color: #3d4d84 !important;
}
.pagination.pagination-default > li > a:focus > [class^="cp-icon-"],
.pagination.pagination-default > li > a:hover > [class^="cp-icon-"] {
  color: #3d4d84 !important;
}
.pagination.pagination-default > li.disabled > a {
  box-shadow: inset 1px 1px 4px -1px transparent !important;
}
.theme-default .activity-subtitle.as-header {
  color: #fff;
  background-color: #3d4d84;
  padding: 7px 40px 8px 15px;
  display: block;
  margin: -15px -15px 0;
}
.theme-default .activity-subtitle.as-header .guidance-toggle {
  color: #fff;
  transition: color linear 0.2s;
}
.theme-default .activity-subtitle.as-header .guidance-toggle:hover,
.theme-default .activity-subtitle.as-header .guidance-toggle:focus {
  color: rgba(255, 255, 255, 0.7);
}
.theme-default .activity-subtitle + .form-guidance-container {
  margin-top: 15px;
}
.theme-default .activity-subtitle + .form-guidance-container p {
  padding: 0;
}
.theme-default .panel .panel-heading .panel-title {
  color: #3d4d84;
}
.theme-default .panel.panel-border-top,
.theme-default .panel-summary-personal-details,
.theme-default .panel-summary-calendar,
.theme-default .panel-summary-diagnoses,
.theme-default .panel-summary-alerts,
.theme-default .panel-summary-reminders,
.theme-default .panel-summary-activities,
.theme-default .summary-panel {
  border-top-color: #3d4d84;
  border-radius: 0 0 0px 0px;
}
.theme-default .panel.panel-solid-header {
  border-radius: 0 0 0px 0px;
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title {
  color: #fff;
  background-color: #3d4d84;
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title:hover,
.theme-default .panel.panel-solid-header .panel-heading .panel-title:focus {
  color: #fff;
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle {
  color: #fff;
  transition: color linear 0.1s;
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes:hover,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle:hover,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes:focus,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle:focus {
  color: rgba(255, 255, 255, 0.7);
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes[aria-expanded="true"],
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle[aria-expanded="true"] {
  color: rgba(255, 255, 255, 0.7);
}
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes[aria-expanded="true"]:hover,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle[aria-expanded="true"]:hover,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .cp-icon-guidance-notes[aria-expanded="true"]:focus,
.theme-default .panel.panel-solid-header .panel-heading .panel-title .guidance-toggle[aria-expanded="true"]:focus {
  color: #fff;
}
.theme-default .panel.panel-border-left {
  border-left-color: #3d4d84;
  border-radius: 0 0 0px 0px;
}
@media (min-width: 992px) {
  .theme-default .panel .task-detail .label.label-open {
    background-color: #3d4d84;
  }
}
.theme-default .progress.progress-bar {
  background-color: #3d4d84;
  border-color: #3d4d84;
}
.theme-default .progress-collapse:hover [class^="cp-icon-"] {
  color: #3d4d84;
}
.theme-default .select2-container--default .select2-selection--single:focus,
.theme-default .select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #3d4d84;
}
.theme-default .select2-container--default .select2-results__option[aria-selected="true"],
.theme-default .select2-container--default .select2-results__option--highlighted[aria-selected="true"] {
  background: #3d4d84;
  color: #fff;
}
.theme-default .select2-container--default .select2-results__option[aria-selected="true"] .text-muted,
.theme-default .select2-container--default .select2-results__option--highlighted[aria-selected="true"] .text-muted {
  color: #fff !important;
}
.theme-default .select2-container--default .select2-results__option .badge,
.theme-default .select2-container--default .select2-results__option--highlighted[aria-selected="true"] .badge {
  background-color: #6c7ebb !important;
  color: #fff !important;
}
.theme-default .select2-basic .select2-results__option[aria-selected="true"] b,
.theme-default .select2-basic .select2-results__option[aria-selected="true"] .text-emphasis,
.theme-default .select2-basic .select2-results__option[aria-selected="true"] strong {
  color: #fff;
}
.theme-default table thead tr th.sortable .cp-icon-caret-up,
.theme-default table thead tr th.sortable .cp-icon-caret-down {
  color: #3d4d84;
}
.theme-default table thead tr th.sortable:hover {
  color: #3d4d84;
  cursor: pointer;
}
.theme-default table thead tr th.sortable button {
  border: none;
  background: transparent;
  padding: 0;
}
.su-tabs .tab-default:hover > a,
.su-tabs .tab-default:focus > a,
.su-tabs .tab-default > a:hover,
.su-tabs .tab-default > a:focus {
  background: none;
  color: #3d4d84 !important;
}
.su-tabs .tab-default.active > a {
  border-color: #3d4d84;
  color: #3d4d84;
}
.su-tabs .tab-default > a:after {
  background: #3d4d84;
}
.su-tabs .tab-default.active:after {
  background-color: #3d4d84 !important;
}
.theme-default .horizontal-chevron {
  color: #3d4d84;
  cursor: pointer;
}
.theme-default .horizontal-chevron.month-overview {
  margin-top: 70px;
}
.theme-default .horizontal-chevron:focus,
.theme-default .horizontal-chevron.focus,
.theme-default .horizontal-chevron:hover {
  color: #505f90;
}
.theme-default h1,
.theme-default h2,
.theme-default h3,
.theme-default h4,
.theme-default .text-color-tint {
  color: #3d4d84;
}
.theme-default h1.lighter,
.theme-default h2.lighter,
.theme-default h3.lighter,
.theme-default h4.lighter,
.theme-default .text-color-tint.lighter {
  color: #4d61a7;
}
mark {
  background-color: #feead1;
  color: #111;
  padding: 0;
}
.text-pre-wrap {
  white-space: pre-wrap;
}
/* Media Queries */
.inline {
  display: inline-block !important;
}
.block {
  display: block !important;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.margin-top-xs {
  margin-top: 4px !important;
}
.margin-top-s {
  margin-top: 8px !important;
}
.margin-top-m {
  margin-top: 16px !important;
}
.margin-top-l {
  margin-top: 32px !important;
}
.margin-top-xl {
  margin-top: 50px !important;
}
.margin-left-xs {
  margin-left: 4px !important;
}
.margin-left-s {
  margin-left: 8px !important;
}
.margin-left-m {
  margin-left: 16px !important;
}
.margin-left-l {
  margin-left: 32px !important;
}
.margin-left-xl {
  margin-left: 50px !important;
}
.margin-bottom-xs {
  margin-bottom: 4px !important;
}
.margin-bottom-s {
  margin-bottom: 8px !important;
}
.margin-bottom-m {
  margin-bottom: 16px !important;
}
.margin-bottom-l {
  margin-bottom: 32px !important;
}
.margin-bottom-xl {
  margin-bottom: 50px !important;
}
.margin-bottom-300 {
  margin-bottom: 300px !important;
}
.margin-right-xs {
  margin-right: 4px !important;
}
.margin-right-s {
  margin-right: 8px !important;
}
.margin-right-m {
  margin-right: 16px !important;
}
.margin-right-l {
  margin-right: 32px !important;
}
.margin-right-xl {
  margin-right: 50px !important;
}
.margin-top-none {
  margin-top: 0 !important;
}
.margin-right-none {
  margin-right: 0 !important;
}
.margin-bottom-none {
  margin-bottom: 0 !important;
}
.margin-left-none {
  margin-left: 0 !important;
}
.margin-none {
  margin: 0px !important;
}
.padding-right-none {
  padding-right: 0 !important;
}
.padding-left-none {
  padding-left: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}
.padding-top-none {
  padding-top: 0 !important;
}
.padding-none {
  padding: 0 !important;
}
.padding-xs {
  padding: 4px !important;
}
.padding-s {
  padding: 8px !important;
}
.padding-m {
  padding: 16px !important;
}
.padding-l {
  padding: 32px !important;
}
.padding-left-xs {
  padding-left: 4px !important;
}
.padding-left-s {
  padding-left: 8px !important;
}
.padding-left-m {
  padding-left: 16px !important;
}
.padding-left-l {
  padding-left: 32px !important;
}
.padding-right-l {
  padding-right: 32px !important;
}
.fixed-height-container.size-300 {
  height: 300px;
}
.fixed-height-container.size-300.max {
  height: auto;
  max-height: 300px;
}
.fixed-height-container.size-400 {
  height: 400px;
}
.fixed-height-container.size-400.max {
  height: auto;
  max-height: 400px;
}
/* Animations LESS */
@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 0.5;
    transform: scale(1.32);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.shake {
  animation: shake 0.85s cubic-bezier(0.28, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-6px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(6px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-10px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}
.fade-in {
  transition: opacity 0.5s ease-in;
}
/* ~ Sliding Tabs
==================================================*/
.nav-tabs.tabs-borderless .gu-sliding-bar {
  background: #3d4d84;
  transition: all 0.15s ease;
  -webkit-transform: translate3d(0, 0, 0);
}
/* ~ Icons
==================================================*/
[class^="cp-icon-"].rotate {
  display: inline-block;
  transition: all 0.1s linear;
}
[class^="cp-icon-"].collapsed {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.collapsed .rotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
@keyframes progress-bar-stripes-custom {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
html {
  min-height: 100%;
  position: relative;
}
body {
  -ms-overflow-style: scrollbar;
}
body .login-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.abandon-container {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: white 2px solid;
}
#wrap {
  height: auto !important;
  margin: 0 auto;
  min-height: 100%;
  padding: 0 0 85px 0;
}
.white-bg {
  background-color: #fff;
}
.worklist {
  background-color: #fff;
  padding: 30px;
}
.container,
.container-grey {
  max-width: 1320px;
}
@media only screen and (min-width: 768px) {
  .container,
  .container-grey {
    padding: 0 30px;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .container,
  .container-grey {
    padding: 0 10px;
    width: 100%;
  }
}
.container.account-container,
.container.search-container,
.container.registration-container {
  padding-top: 40px;
}
.container.account-container .title,
.container.search-container .title,
.container.registration-container .title {
  margin-bottom: 10px;
}
.fixed-height-container-ras {
  overflow-y: auto;
  max-height: 80vh;
}
.fixed-height-container {
  overflow-y: auto;
}
.container-grey {
  background-color: #fafafa;
}
.about-cp {
  margin-top: 60px;
}
.about-cp-container {
  margin-bottom: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}
.exisiting-su-column {
  margin: 20px 0;
}
@media all and (min-width: 992px) {
  .exisiting-su-column {
    margin: 0;
  }
}
.border-seperator {
  text-align: center;
  width: 100%;
  border-bottom: solid 1px #ececec;
  margin: auto;
  margin-top: 16px;
}
.roomy-above-10 {
  padding-top: 10px;
}
.roomy-above {
  padding-top: 15px;
}
.roomy-below {
  padding-bottom: 15px;
}
.roomy-below-lg {
  padding-bottom: 25px;
}
.margin-left-sm {
  margin-left: 8px;
}
.break-word {
  display: inline-block;
  word-break: break-all;
}
.cp-logo-font {
  line-height: 2em;
  height: 70px;
  position: relative;
  top: -30px;
}
.cp-logo-font .imo {
  font-size: 40px;
}
.cp-logo-font .name {
  font-family: "Sofia Pro", Arial;
  font-size: 30px;
  text-transform: lowercase;
}
.cp-logo-font .name span {
  font-size: 70px;
  display: block;
}
.tick-box-container .tick-box-input-container {
  margin-left: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tick-box-container .tick-box-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.tick-box-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  border: 2px solid #ececec;
  transition: background-color linear 0.1s;
  transition: border linear 0.1s;
}
.tick-box-container .tick-box-input-container input ~ .checkmark:after {
  display: block;
  position: absolute;
}
.tick-box-container .tick-box-input-container input:checked ~ .checkmark:after {
  display: block;
  content: "";
  position: absolute;
}
.tick-box-container .tick-box-input-container .checkmark {
  background-color: #fafafa;
}
.tick-box-container .tick-box-input-container input:checked ~ .checkmark {
  border-color: #3d4d84;
}
.tick-box-container .tick-box-input-container input ~ .checkmark:after {
  color: #3d4d84;
  font-family: ImosphereIcons;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}
.tick-box-container .tick-box-input-container input:checked ~ .checkmark:after {
  content: "\e865";
  font-size: 16px;
}
.tick-box-container .checkbox-container .checkmark:hover {
  background-color: #ececec;
  border-color: #ececec;
}
.tick-box-container .label-text {
  margin-left: 26px;
}
.tick-box-container.tick-box-container-vue {
  width: 22px;
}
.tick-box-container.tick-box-container-vue input {
  opacity: 0;
}
.tick-box-container.tick-box-container-vue .tick-box-input-container {
  margin: 3px 0 0;
  display: inline-block;
}
.tick-box-container-label {
  width: 100%;
  padding-left: 0;
}
footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  background-color: #e5e5e5;
}
footer .container {
  width: 100%;
  margin: 0;
  border-top: 1px solid #fff;
  max-width: 100%;
  padding: 12px 15px;
  font-size: 0.875em;
  line-height: 1em;
  color: #666;
}
footer .container .row {
  max-width: 100%;
}
footer .container .row.management {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .container .footer-item {
  display: block;
  float: left;
}
footer .container .footer-item.logo {
  font-size: 40px;
  color: #707070;
  margin: 0 8px 0 0;
}
footer .container .footer-item.product-details .name {
  font-weight: 600;
  display: block;
  font-size: 1.8em;
  font-family: sofia pro;
  line-height: 0.9em;
  margin-bottom: 6px;
  text-transform: lowercase;
}
footer .container .footer-item.product-details .version {
  display: block;
}
footer .container .copyright {
  line-height: 1.4em;
}
.focus-area {
  float: left;
  position: relative;
  width: 66.666667%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.side-panel {
  width: 33.333333%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.page-caselist {
  margin: 20px;
}
.coc-registry-container {
  overflow-x: scroll;
  overflow-y: auto;
  position: relative;
}
.coc-registry-outer-container {
  position: relative;
}
.coc-registry-outer-container .patch {
  background-color: #ececec;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
}
.coc-registry-outer-container .current-section-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  padding: 5px 8px;
  font-family: sofia pro;
  font-weight: 600;
  font-size: 0.867em;
  background-color: #ececec;
  display: none;
}
.no-data-container {
  position: absolute;
  left: 50%;
  top: 33%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alert,
.browser-alert {
  border-radius: 6px;
  border: none;
  color: #fff;
  font-weight: 400;
  padding: 8px 15px;
  display: flex;
}
.alert.alert-icon-detail {
  padding: 0;
  overflow: hidden;
}
.alert.alert-icon-detail .icon {
  flex-grow: 0;
  background-color: #777;
  color: #fff;
  padding: 8px 12px;
}
.alert.alert-icon-detail .details {
  flex-grow: 1;
  max-width: 650px;
  padding: 8px 12px;
  color: #111;
}
.alert.alert-icon-detail .details p:last-of-type {
  margin: 0;
}
.alert.alert-icon-detail.alert-danger,
.alert.alert-icon-detail.alert-info,
.alert.alert-icon-detail.alert-success,
.alert.alert-icon-detail.alert-warning {
  background-color: #ececec;
}
.alert.alert-icon-detail.alert-danger b,
.alert.alert-icon-detail.alert-info b,
.alert.alert-icon-detail.alert-success b,
.alert.alert-icon-detail.alert-warning b {
  color: #111;
  font-weight: 600;
}
.alert.alert-icon-detail.alert-danger .icon {
  background-color: #d13746;
}
.alert.alert-icon-detail.alert-info .icon {
  background-color: #097cbf;
}
.alert.alert-icon-detail.alert-success .icon {
  background-color: #dcffef;
}
.alert.alert-icon-detail.alert-warning .icon {
  background-color: #ff5519;
}
.alert.alert-danger a,
.alert.alert-info a,
.alert.alert-success a,
.alert.alert-warning a,
.alert.alert-message a {
  text-decoration: underline;
}
.alert.alert-danger a,
.alert.alert-info a,
.alert.alert-success a,
.alert.alert-warning a,
.alert.alert-message a,
.alert.alert-danger .text-emphasis,
.alert.alert-info .text-emphasis,
.alert.alert-success .text-emphasis,
.alert.alert-warning .text-emphasis,
.alert.alert-message .text-emphasis,
.alert.alert-danger b,
.alert.alert-info b,
.alert.alert-success b,
.alert.alert-warning b,
.alert.alert-message b,
.alert.alert-danger strong,
.alert.alert-info strong,
.alert.alert-success strong,
.alert.alert-warning strong,
.alert.alert-message strong {
  color: #fff;
}
.alert.alert-danger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.alert.alert-info .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.alert.alert-success .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.alert.alert-warning .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.alert.alert-message .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.alert.alert-danger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.alert.alert-info .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.alert.alert-success .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.alert.alert-warning .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.alert.alert-message .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.alert.alert-danger.alert-auto-request-banner,
.alert.alert-info.alert-auto-request-banner,
.alert.alert-success.alert-auto-request-banner,
.alert.alert-warning.alert-auto-request-banner,
.alert.alert-message.alert-auto-request-banner {
  display: inline-block;
  font-size: 1.1em;
  margin: 10px 0 0;
}
.alert.alert-danger.alert-auto-request-banner .content,
.alert.alert-info.alert-auto-request-banner .content,
.alert.alert-success.alert-auto-request-banner .content,
.alert.alert-warning.alert-auto-request-banner .content,
.alert.alert-message.alert-auto-request-banner .content {
  display: flex;
  flex-direction: row;
}
.alert.alert-danger.alert-auto-request-banner .content .icon,
.alert.alert-info.alert-auto-request-banner .content .icon,
.alert.alert-success.alert-auto-request-banner .content .icon,
.alert.alert-warning.alert-auto-request-banner .content .icon,
.alert.alert-message.alert-auto-request-banner .content .icon {
  font-size: 1.1em;
  line-height: 1.25em;
  margin-right: 8px;
  flex-grow: 0;
}
.alert.alert-danger.alert-auto-request-banner .content .info,
.alert.alert-info.alert-auto-request-banner .content .info,
.alert.alert-success.alert-auto-request-banner .content .info,
.alert.alert-warning.alert-auto-request-banner .content .info,
.alert.alert-message.alert-auto-request-banner .content .info {
  line-height: 1.4em;
  letter-spacing: 0.008em;
}
.alert.alert-danger.alert-auto-request-banner .content .info b,
.alert.alert-info.alert-auto-request-banner .content .info b,
.alert.alert-success.alert-auto-request-banner .content .info b,
.alert.alert-warning.alert-auto-request-banner .content .info b,
.alert.alert-message.alert-auto-request-banner .content .info b {
  font-weight: 600;
}
.alert:not(:first-child) {
  margin: 20px 0 0;
}
.alert.alert-danger {
  background-color: #d13746;
}
.alert.alert-info {
  background-color: #097cbf;
}
.alert.alert-info.alert-info-muted {
  background-color: #9ad7fa;
  border: 2px solid #097cbf;
  color: #111;
}
.alert.alert-info .form-name {
  color: #111;
  font-weight: 600;
  font-style: italic;
}
.alert.alert-success {
  background-color: #dcffef;
  color: #00856b;
}
.alert.alert-warning {
  background-color: #ff5519;
  color: #111;
}
.alert.alert-warning a,
.alert.alert-warning .text-emphasis,
.alert.alert-warning b,
.alert.alert-warning strong {
  color: #111;
}
.alert.alert-message {
  background-color: #3d4d84;
  color: #fff;
  border-radius: 0px;
  margin-top: 10px;
  font-size: 1.1em;
}
.alert.alert-default {
  background-color: #fafafa;
  color: #111;
}
.alert.alert-default .text-emphasis,
.alert.alert-default b,
.alert.alert-default strong {
  color: #111;
}
.alert.alert-default.alert-default-muted {
  background-color: #fafafa;
  border: 2px solid #ececec;
  color: #111;
}
.alert.alert-readonly {
  background-color: #ff5519;
  color: #111;
  margin-bottom: 0px;
}
.alert.alert-readonly a,
.alert.alert-readonly .text-emphasis,
.alert.alert-readonly b,
.alert.alert-readonly strong {
  color: #111;
}
.alert ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}
.alert ul.list-bullet {
  margin-left: 16px;
}
.alert ul.list-bullet li {
  position: relative;
}
.alert ul.list-bullet li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  left: -16px;
  position: absolute;
  background-color: #111;
  top: 4px;
  border-radius: 50%;
}
.alert .sk-spinner.sk-spinner-double-bounce.sk-spinner {
  width: 20px;
  height: 20px;
}
.alert.login-message {
  border-radius: 0;
  border: none;
  display: block;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
}
.alert.login-message-in-broadcast {
  border-radius: 0;
  border: none;
  display: block;
  text-align: center;
  margin-right: 20px;
  margin-left: -0.75em;
}
.alert.alert-unsupported-browser {
  border-radius: 2px;
  padding: 0.75em 1em;
}
.alert.broadcast-message {
  border-radius: 0;
  font-size: 1rem;
  line-height: 1.267rem;
  background: #fff;
  color: #111;
  padding-top: 10px;
  padding-bottom: 10px;
}
.alert.broadcast-message .cp-icon-comment {
  float: left;
  color: #e2e2e2;
  margin-left: -24px;
  margin-top: 0.15em;
}
.alert.broadcast-message p {
  margin-bottom: 0;
  white-space: pre-line;
}
@media only screen and (min-width: 992px) {
  .alert.broadcast-message p {
    font-size: 13px;
    line-height: 21px;
  }
}
.alert.broadcast-message a {
  font-weight: 600;
  text-decoration: none;
}
.alert.broadcast-message a:hover {
  text-decoration: underline;
}
.alert.broadcast-message-prev {
  background-color: #ececec;
  color: #111;
}
.alert.broadcast-message-prev a {
  color: #111;
  font-weight: 600;
  text-decoration: none;
}
.alert.broadcast-message-prev a:hover {
  text-decoration: underline;
}
.alert.alert-no-top-margin {
  margin-top: 0;
}
.browser-alert {
  background-color: #d13746;
  margin-top: 30px;
}
.AdditionalQuestionsSection .filedrag {
  background-color: #ffffff;
  border: 2px dashed #666666;
}
.filedrag {
  border-radius: 6px;
  background-color: #fafafa;
  border: none;
  margin: 0;
  min-height: 70px;
  padding: 15px;
  transition: all 0.15s ease;
  width: 100%;
}
.filedrag .icon-container {
  width: 50px;
  display: inline-block;
}
.filedrag .clearfix > div {
  float: left;
}
.filedrag .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
.filedrag .imageholder {
  position: relative;
  height: 50px;
  width: 50px;
}
.filedrag .imageholder div {
  display: table;
  margin: 0 auto;
  width: 25px;
}
.filedrag .imageholder img {
  cursor: pointer;
  position: absolute;
  top: 0;
}
.filedrag .imageholder .thumb {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.filedrag .drag-instructions {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: 1.15rem;
  margin-bottom: 0;
  max-width: 80%;
  position: relative;
}
.filedrag .drag-instructions.attached-file {
  top: 12px;
}
.filedrag .file-size {
  color: #707070;
}
.filedrag .btn-file {
  background-color: #fafafa;
  cursor: pointer;
  float: right;
  margin-top: 5px;
  vertical-align: top;
  transition: background-color linear 0.1s;
  padding: 0 15px;
  line-height: 32px;
  font-size: 12px;
  border-radius: 16px;
}
.filedrag .btn-file input[type="file"] {
  cursor: pointer;
}
.filedrag .btn-file:hover {
  background-color: #ececec;
}
.filedrag .change-file,
.filedrag .delete-file {
  margin-top: 0px;
}
.filedrag .sk-spinner {
  display: inline-block;
}
.filedrag .sk-spinner .loading-message {
  font-size: 1rem;
  line-height: 1.267rem;
  color: #616161;
  background-color: transparent;
  margin-top: 3px;
  margin-left: 28px;
  position: static;
  text-align: left;
}
.filedrag.hover {
  border: 2px dashed #5e72b5;
  background-color: #c7cde5;
  box-shadow: none;
}
.filedrag.disabled {
  pointer-events: none;
}
.attachment-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  width: 90%;
  word-break: break-word;
}
.attachment {
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  margin-top: 2px;
  overflow: hidden;
  padding: 5px 8px;
}
.attachment .cp-icon-paperclip {
  float: left;
  margin-top: 2px;
  margin-right: 5px;
}
.attachment-details {
  font-size: 1rem;
  line-height: 1.267rem;
  color: #616161;
  display: block;
}
.has-error .filedrag {
  background-color: rgba(186, 10, 60, 0.06);
  border-color: #d70c45;
}
.has-error .filedrag .btn,
.has-error .filedrag .btn.btn-default {
  box-shadow: none;
}
.has-error .filedrag .drag-instructions {
  color: #d70c45;
}
.current-attachment {
  display: flex;
  flex-direction: row;
}
.current-attachment .icon-container {
  flex-basis: 50px;
  flex-grow: 0;
}
.current-attachment .delete-file-container {
  flex-basis: 100px;
  flex-grow: 0;
}
.current-attachment .instructions-container {
  flex-grow: 1;
  width: 0;
}
.badge {
  font-weight: 400;
}
.breadcrumb {
  font-size: 1rem;
  line-height: 1.267rem;
  background-color: transparent;
  margin-top: -0.4375em;
  margin-bottom: 1.125em;
  padding: 0;
}
.btn-toggle {
  line-height: 1em;
  margin-bottom: 8px;
}
.btn-toggle .btn-toggle-option-text {
  vertical-align: top;
  margin-right: 8px;
}
.btn-toggle .btn-toggle-option-text .help-component {
  top: -6px;
  position: relative;
}
.btn-toggle .btn-toggle-text {
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
}
.btn-toggle .btn-toggle-text:first-of-type {
  margin-right: 4px;
}
.btn-toggle .btn-toggle-text:last-of-type {
  line-height: 1.4em;
  margin-left: 4px;
}
.btn-toggle .btn-toggle-text.as-heading {
  font-size: 1.2em;
  line-height: 1.2em;
}
.btn-toggle .btn-toggle-control + .btn-toggle-label {
  background: #707070;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  outline: none;
  position: relative;
  padding: 1px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: bottom;
  width: 40px;
  overflow: hidden;
}
.btn-toggle .btn-toggle-control + .btn-toggle-label:after {
  background: #ececec;
  border-radius: 50%;
  box-shadow: 0;
  content: "";
  display: block;
  height: 14px;
  left: 2px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 14px;
  margin-top: -12px;
}
.btn-toggle .btn-toggle-control + .btn-toggle-label:before {
  content: "\f00d";
  font-family: "FontAwesome";
  color: #fff;
  font-size: 14px;
  right: -22px;
  top: 1px;
  position: relative;
}
.btn-toggle .btn-toggle-control:checked + .btn-toggle-label {
  background: #3d4d84;
}
.btn-toggle .btn-toggle-control:checked + .btn-toggle-label:after {
  left: 22px;
  margin-top: -13px;
}
.btn-toggle .btn-toggle-control:checked + .btn-toggle-label:before {
  color: #fff;
  content: "\E865";
  font-family: "ImosphereIcons";
  position: relative;
  font-size: 12px;
  right: -6px;
  top: 1px;
}
.btn-toggle input[type="checkbox"],
.btn-toggle input[type="radio"] {
  width: 0;
  height: 0;
  left: -99999px;
  position: relative;
}
.btn {
  border-radius: 17px;
  font-size: 14px;
  font-weight: 400;
  height: 34px;
  line-height: 32px;
  padding: 0 9px;
  transition: background-color linear 0.1s;
  border-width: 2px;
  border-style: solid;
}
.btn:hover,
.btn:focus,
.btn.focus {
  text-decoration: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}
.btn .sk-spinner.sk-spinner-double-bounce.sk-spinner {
  height: 23px;
  width: 23px;
}
.btn.btn-lg {
  border-radius: 24px;
  font-size: 1.26666666rem;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
}
.btn.btn-lg .sk-spinner.sk-spinner-double-bounce.sk-spinner {
  height: 26px;
  width: 26px;
}
.btn.btn-sm {
  font-size: 0.8667rem;
}
.btn.btn-xs {
  border-radius: 12px;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 9px;
}
.btn.btn-warning {
  background-color: #fff;
  border-color: #ccc;
  color: #000;
}
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:hover {
  border-color: #eb1515;
  color: #eb1515;
}
.btn.btn-warning:focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-warning.focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-warning:hover .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-warning:focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.btn.btn-warning.focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.btn.btn-warning:hover .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.btn.btn-warning:active,
.btn.btn-warning.active {
  box-shadow: inset 1px 1px 4px -1px #760b0b;
  background-color: #d41313;
  border-color: #a50f0f;
  color: #fff;
}
.btn.btn-warning:active:hover,
.btn.btn-warning.active:hover,
.btn.btn-warning:active:focus,
.btn.btn-warning.active:focus,
.btn.btn-warning:active.focus,
.btn.btn-warning.active.focus {
  box-shadow: inset 1px 1px 4px -1px #a50f0f;
  background-color: #eb1515;
  border-color: #d41313;
  color: #fff;
}
.btn.btn-warning.disabled,
.btn.btn-warning[disabled],
.btn.btn-warning.disabled:hover,
.btn.btn-warning[disabled]:hover,
.btn.btn-warning.disabled:focus,
.btn.btn-warning[disabled]:focus,
.btn.btn-warning.disabled.focus,
.btn.btn-warning[disabled].focus,
.btn.btn-warning.disabled:active,
.btn.btn-warning[disabled]:active,
.btn.btn-warning.disabled.active,
.btn.btn-warning[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #fff;
  border-color: #eb1515;
  color: #eb1515;
  cursor: auto;
}
.btn.btn-warning .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-warning .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #eb1515;
}
.btn.btn-danger {
  background-color: #eb1515;
  border-color: #eb1515;
  color: #fff;
}
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:hover {
  background: #fff;
  border-color: #eb1515;
  color: #eb1515;
}
.btn.btn-danger:focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-danger.focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-danger:hover .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-danger:focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.btn.btn-danger.focus .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2,
.btn.btn-danger:hover .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #fff;
}
.btn.btn-danger:active,
.btn.btn-danger.active {
  box-shadow: inset 1px 1px 4px -1px #760b0b;
  background-color: #d41313;
  border-color: #a50f0f;
  color: #fff;
}
.btn.btn-danger:active:hover,
.btn.btn-danger.active:hover,
.btn.btn-danger:active:focus,
.btn.btn-danger.active:focus,
.btn.btn-danger:active.focus,
.btn.btn-danger.active.focus {
  box-shadow: inset 1px 1px 4px -1px #a50f0f;
  background-color: #eb1515;
  border-color: #d41313;
  color: #fff;
}
.btn.btn-danger.disabled,
.btn.btn-danger[disabled],
.btn.btn-danger.disabled:hover,
.btn.btn-danger[disabled]:hover,
.btn.btn-danger.disabled:focus,
.btn.btn-danger[disabled]:focus,
.btn.btn-danger.disabled.focus,
.btn.btn-danger[disabled].focus,
.btn.btn-danger.disabled:active,
.btn.btn-danger[disabled]:active,
.btn.btn-danger.disabled.active,
.btn.btn-danger[disabled].active {
  box-shadow: inset 1px 1px 4px -1px transparent;
  background-color: #fff;
  border-color: #eb1515;
  color: #eb1515;
  cursor: auto;
}
.btn.btn-danger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.btn.btn-danger .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: #eb1515;
}
.btn.btn-primary.btn-danger {
  background-color: #eb1515;
  border-color: #eb1515;
  color: #fff;
}
.btn.btn-primary.btn-danger:hover,
.btn.btn-primary.btn-danger:focus {
  background-color: #ef4444;
}
.btn.btn-primary.btn-danger.disabled,
.btn.btn-primary.btn-danger[disabled],
.btn.btn-primary.btn-danger.disabled:hover,
.btn.btn-primary.btn-danger[disabled]:hover,
.btn.btn-primary.btn-danger.disabled:focus,
.btn.btn-primary.btn-danger[disabled]:focus,
.btn.btn-primary.btn-danger.disabled.focus,
.btn.btn-primary.btn-danger[disabled].focus,
.btn.btn-primary.btn-danger.disabled:active,
.btn.btn-primary.btn-danger[disabled]:active,
.btn.btn-primary.btn-danger.disabled.active,
.btn.btn-primary.btn-danger[disabled].active {
  background-color: #eb1515;
  border-color: #eb1515;
  color: #fff;
  cursor: auto;
}
.btn.btn-action {
  border-color: #fca744;
}
.btn.btn-link {
  border: none;
  padding: 0;
  color: #40518b;
  transition: color linear 0.2s;
}
.btn.btn-link:not([disabled]):focus,
.btn.btn-link:not([disabled]).focus,
.btn.btn-link:not([disabled]):hover {
  text-decoration: underline;
  color: #000;
}
.btn.btn-link.btn-new-subform:hover,
.btn.btn-link.btn-new-subform:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.btn.btn-show-details {
  color: #3d4d84;
}
.btn.btn-show-details.collapsed:before {
  content: "Show details";
  display: block;
}
.btn.btn-show-details:before {
  content: "Hide details";
}
.btn.btn-icon {
  border-radius: 50%;
  padding: 0;
  text-align: center;
  width: 34px;
}
.btn:disabled,
.btn.disabled {
  cursor: default;
  /*&:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }*/
}
@media only screen and (max-width: 992px) {
  .btn.print-contact-record {
    float: right;
  }
}
.btn.back-to-top {
  background-color: #ddd;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  font-size: 1.6em;
  height: 48px;
  line-height: 48px;
  padding: 0;
  text-align: center;
  transition: background-color linear 0.1s;
  width: 48px;
}
.btn.back-to-top.fixed {
  bottom: 50%;
  margin-left: -62px;
  position: fixed;
  display: none;
}
.btn.back-to-top.fixed.shown {
  display: block;
  animation: fadeIn linear 0.3s;
}
@media only screen and (max-width: 1366px) {
  .btn.back-to-top.back-to-top {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 1.1em;
  }
  .btn.back-to-top.back-to-top.fixed {
    margin-left: -22px;
  }
}
.btn.btn-as-h2 {
  font: 600 21px / 32px "Sofia Pro", helvetica, arial, sans-serif;
  padding: 0;
  background-color: transparent;
  transition: color linear 0.1s;
}
.btn.btn-as-h2:active,
.btn.btn-as-h2:focus {
  box-shadow: none;
}
.btn.btn-as-h2 .imo {
  font-size: 0.8em;
}
.guidance-toggle {
  background: transparent;
  padding: 0;
  border: 0;
  font-size: 16px;
  transition: color linear 0.2s;
  color: #3d4d84;
  cursor: pointer;
}
.guidance-toggle:hover,
.guidance-toggle:focus {
  color: #1d243e;
  outline: none;
}
.guidance-toggle.collapsed {
  color: #3d4d84;
}
.guidance-toggle.collapsed:hover,
.guidance-toggle.collapsed:focus {
  color: #1d243e;
  outline: none;
}
.btn.btn-text {
  line-height: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  height: inherit;
  background: transparent;
}
.btn.btn-text:focus,
.btn.btn-text:hover {
  box-shadow: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.text-truncate {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.event-dd {
  position: relative;
}
.event-dd .btn.dropdown-section {
  border-radius: 0px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  height: 47px;
  background-color: #fff;
  border: none;
  margin-bottom: 10px !important;
  width: calc(100% - 50px);
  box-shadow: none;
  -webkit-box-shadow: none;
}
.event-dd .btn.dropdown-section .imo {
  font-size: 22px;
  color: #3d4d84;
  padding-top: 5px;
}
.event-dd .btn.dropdown-section:hover {
  background-color: #f6f7f8;
}
.event-dd .btn.remove-section {
  border-radius: 0px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  height: 47px;
  background-color: #fff;
  border: none;
  margin-bottom: 10px !important;
  width: 46px;
}
.event-dd .btn.remove-section .imo {
  font-size: 22px;
  color: #eb1515;
  padding-top: 5px;
}
.event-dd .btn.remove-section.disabled .imo {
  color: #c1c1c1;
}
.event-dd .btn.remove-section:hover {
  background-color: #f9f9f9;
}
.event-dd .btn.remove-section:hover.disabled {
  background-color: #fff;
}
.event-dd .dropdown-menu {
  padding: 0 !important;
  top: auto;
  margin-top: -11px;
  border: 1px solid #fff;
  border-radius: 0px;
  width: calc(100% - 50px);
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.175);
  max-height: 300px;
  overflow-y: auto;
}
.event-dd .dropdown-menu li a {
  padding: 10px;
}
.event-dd .dropdown-menu.no-results {
  padding: 10px !important;
}
.btn-filter.active {
  background-color: #707070;
  border: solid 2px #fff;
  color: #fff;
  padding-right: 15px;
}
.btn-filter .indicator {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #fff;
  top: 4px;
  right: 6px;
  border-radius: 50%;
}
.short-text-group {
  width: 100%;
}
.short-text-group .form-control:only-child {
  border-radius: 4px;
}
.short-text-group.shortDataInputNumeric {
  width: 50% !important;
}
.btn-group {
  height: 34px;
  background-color: #fff;
  padding: 2px;
  border-radius: 17px;
}
.btn-group.no-background {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  height: auto;
}
.btn-group.no-padding {
  padding: 0;
}
.btn-group.no-padding .btn {
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
}
.btn-group.no-padding .btn.btn-default.btn-icon {
  border-width: 2px;
  border-style: solid;
  line-height: 30px;
}
.btn-group.no-padding .btn.btn-default.btn-icon:hover {
  background-color: #fff;
}
.btn-group .btn {
  height: 30px;
  border: none;
  line-height: 30px;
  border-radius: 15px;
}
.btn-group .btn.btn-default:hover {
  background-color: #ececec;
}
.btn-group .btn.btn-default.active,
.btn-group .btn.btn-default:active,
.btn-group .btn.btn-default:focus {
  box-shadow: none;
  background-color: #ececec;
  color: #111;
}
.btn-group .btn.btn-default:first-child:last-child:hover,
.btn-group .btn.btn-default:first-child:last-child:focus {
  background-color: #fff;
}
.btn-group .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group .btn:last-child:not(:first-child),
.btn-group .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: inherit;
}
.btn-group .btn + .btn {
  margin-left: 2px;
}
.btn-group .btn.as-h1 {
  height: auto;
  background-color: transparent;
  line-height: initial;
  padding: 0;
}
.btn-group .btn.as-h1:focus {
  box-shadow: none;
}
.btn-group.alternate {
  background-color: #ececec;
}
.btn-group.alternate .btn.btn-default {
  background-color: #ececec;
}
.btn-group.alternate .btn.btn-default:hover,
.btn-group.alternate .btn.btn-default:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: #111;
}
.btn-group.alternate .btn.btn-default:active,
.btn-group.alternate .btn.btn-default.active {
  background-color: #fff;
  color: #111;
}
.btn-group.alternate .btn.btn-default:active:hover,
.btn-group.alternate .btn.btn-default.active:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #111;
}
.btn-group.alternate .btn.btn-default:active:focus,
.btn-group.alternate .btn.btn-default.active:focus {
  background-color: #fff;
  color: #111;
}
.btn-group.linked .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#full-calendar {
  transition: 0.3s ease;
}
#full-calendar.is-loading {
  opacity: 0.5;
}
.fc-event-inner {
  line-height: 1.357rem;
  padding: 1px 2px 0 3px;
}
.event-time,
.event-category {
  color: #757575;
}
.agenda-events {
  padding-top: 17px;
}
.calendar-view.is-hidden {
  height: 0;
  overflow: hidden;
}
.event-continuation-end {
  display: none;
  float: right;
}
.event-continuation-start {
  display: none;
  float: left;
  padding-right: 5px;
}
.fc-not-start .event-time-start,
.fc-not-end .event-time-end {
  display: none;
}
.fc-not-start .event-continuation-start,
.fc-not-end .event-continuation-end {
  display: block;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row {
  border-color: #ececec;
}
.fc-unthemed .fc-divider {
  background: #fafafa;
}
.fc-unthemed .fc-today {
  background: rgba(61, 77, 132, 0.06);
}
.fc-highlight {
  /* when user is selecting cells */
  background: rgba(61, 77, 132, 0.9);
}
.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}
.fc-now-indicator {
  border-color: rgba(255, 0, 0, 0.65);
}
hr.fc-divider {
  padding: 0 0 3px;
  /* height is unreliable across browsers, so use padding */
}
/* Tables
--------------------------------------------------------------------------------------------------*/
.fc th {
  padding: 5px 0;
  text-align: center;
}
/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  background-color: #fff;
  /* default BACKGROUND color */
  border: 1px solid #ececec;
  /* default BORDER color */
  border-left: solid 3px #3d4d84;
  border-radius: 4px;
  box-shadow: 3px 3px 5px -3px #ececec;
  cursor: pointer;
  font-size: 0.85em;
  font-weight: 400;
  /* undo jqui's ui-widget-header bold */
  line-height: 1.3;
  transition: background-color 0.2s ease;
}
.fc-event:hover,
.fc-event.event-hover {
  background-color: #fafafa;
}
.fc-event:hover.birthday,
.fc-event.event-hover.birthday {
  color: #354373;
}
.fc-event .event-header {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: nowrap;
  /* Safari */
  flex-wrap: nowrap;
}
.fc-event .event-header .event-time-start {
  padding-right: 3px;
}
.fc-event .event-header .event-time-end {
  padding-left: 3px;
}
.fc-event .event-header .event-title {
  -webkit-flex-grow: 1;
  /* Safari */
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
.fc-event .event-header .event-icons {
  -webkit-flex-shrink: 0;
  /* Safari */
  flex-shrink: 0;
  padding-right: 3px;
}
.fc-event.event-past {
  background-color: #ffffff;
  border-left-color: #707070;
}
.fc-event.event-past [class^="cp-icon-"],
.fc-event.event-past [class*=" cp-icon-"] {
  color: #707070;
}
.fc-event.event-past:hover,
.fc-event.event-past.event-hover {
  background-color: #f0f0f0;
}
.fc-event.event-past:hover [class^="cp-icon-"],
.fc-event.event-past.event-hover [class^="cp-icon-"],
.fc-event.event-past:hover [class*=" cp-icon-"],
.fc-event.event-past.event-hover [class*=" cp-icon-"] {
  color: #707070;
}
.fc-event.event-past.event-attendance-full {
  border-left-color: #1ad44e;
}
.fc-event.event-past.event-attendance-partial {
  border-left-color: #ffba3a;
}
.fc-event.event-past.event-attendance-none {
  border-left-color: #ed3a3a;
}
.fc-event.birthday {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: rgba(61, 77, 132, 0.4);
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
  color: #354373;
  cursor: default;
}
.fc-event.last-in-series [class^="cp-icon-"],
.fc-event.last-in-series [class*=" cp-icon-"] {
  color: #fca744;
  font-weight: 600;
}
/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #111;
  /* default TEXT color */
}
/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: solid;
  border-color: #f1f1f1;
}
.fc-time-grid .fc-minor td.fc-axis {
  border-color: transparent;
}
.datepicker table tr td .day.active,
.datepicker table tr td .month.active,
.datepicker table tr td .year.active {
  background: #3d4d84;
}
.datepicker table tr td .day.active:hover,
.datepicker table tr td .month.active:hover,
.datepicker table tr td .year.active:hover {
  background: #374576;
}
.datepicker table tr td .active,
.datepicker table tr td tr td {
  background-image: none;
  border: none;
  text-shadow: none;
}
.datepicker table tr td.day {
  border-radius: 50%;
  background-image: none;
  border: none;
  line-height: 30px;
  padding: 0;
  text-shadow: none;
  width: 30px;
  height: 30px;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day:focus {
  background-color: #fafafa;
  outline: none;
}
.datepicker table tr td.day.active:hover,
.datepicker table tr td.day.current.active:hover {
  color: #fff;
  text-shadow: none;
}
.datepicker table tr td.day.current,
.datepicker table tr td .month.current,
.datepicker table tr td .year.current {
  font-weight: 600;
  text-shadow: none;
}
.datepicker table tr td.day.current:hover,
.datepicker table tr td .month.current:hover,
.datepicker table tr td .year.current:hover {
  background: #e7e7e7;
  text-shadow: none;
}
.datepicker table tr td.current.active {
  font-weight: 400;
  color: #fff !important;
  text-shadow: none;
}
.datepicker table tr td .day.current.active,
.datepicker table tr td .month.current.active,
.datepicker table tr td .year.current.active,
.datepicker table tr td .day.current.active:hover,
.datepicker table tr td .month.current.active:hover,
.datepicker table tr td .year.current.active:hover,
.datepicker table tr td .day.active:hover,
.datepicker table tr td .month.active:hover,
.datepicker table tr td .year.active:hover {
  color: #fff !important;
  text-shadow: none;
}
.datepicker table tr td .month,
.datepicker table tr td .year {
  border-radius: 50%;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.date-time-picker [class^="col-"]:first-of-type {
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .date-time-picker [class^="col-"]:first-of-type {
    padding-bottom: initial;
  }
}
dl {
  margin: 0;
}
dl dt,
dl dd {
  line-height: inherit;
  margin: 0;
}
dl.horizontal {
  display: inline-block;
  padding-right: 15px;
}
dl.horizontal:last-child {
  padding-right: 0;
}
dl dt.horizontal,
dl dt.horizontal + dd {
  display: inline-block;
}
dl.vertical {
  margin: 0 0 10px;
}
dl.vertical.vertical-sm {
  margin: 0 0 0.375em;
}
dl.vertical.vertical-sm.small {
  margin: 0 0 0.25em;
}
dl.vertical.vertical-lg {
  margin: 0 0 15px;
}
dl.vertical:last-child {
  margin: 0;
}
dl.vertical .horizontal {
  display: inherit;
  float: left;
  margin-right: 4px;
}
dl.vertical dt.horizontal + dd {
  display: inherit;
}
dl dt.horizontal + dd {
  display: inherit;
}
.sm dt,
.sm dd {
  font-size: 1rem;
  line-height: 1.267rem;
}
dl dt,
dl dd {
  font-weight: normal;
}
.coding-picker-wrapper .input-group-btn:first-child > .btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.coding-picker-wrapper .input-group-btn + .form-control {
  border-left: 1px solid #fafafa;
}
.code-picker {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  border: 1px solid #ececec;
  height: 282px;
  margin: 3px 0 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px 0;
}
.code-picker li > div {
  padding: 5px;
  cursor: pointer;
}
.code-picker li > div.disabled,
.code-picker li > div.selected {
  cursor: default;
}
.code-picker li > ul.children {
  margin: 0 0 0 20px;
  padding: 0;
  list-style-type: none;
  display: none;
}
.code-picker li.show-children > ul.children {
  display: block;
}
.code-picker .label {
  display: inline-block;
  position: relative;
  top: -1px;
  min-width: 60px;
  padding: 4px 6px;
}
.code-picker .label.label-primary {
  margin-left: 0px;
}
.code-picker .label.label-primary.last-child {
  margin-left: 15px;
}
.code-picker .sk-spinner {
  margin: 2px 5px 0 0;
}
.code-picker .diagnosis-detail,
.code-picker .diagnosis-name {
  display: table-cell;
}
.code-picker .diagnosis-detail {
  padding-right: 4px;
  white-space: nowrap;
}
.has-error .code-picker,
.has-error .input-group-btn .btn {
  border: solid 1px #bf201f;
  box-shadow: 0 0 6px #bf201f;
  opacity: 1;
}
.dropdown-menu {
  z-index: 100000;
}
.dropdown-menu > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdown-menu .fixed-height-container > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdown-menu .fixed-height-container > ul > li > a {
  cursor: pointer;
  display: block;
  padding: 6px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu .fixed-height-container > ul > li > a.active {
  color: #fff;
}
.dropdown-menu .fixed-height-container > ul > li > a:hover,
.dropdown-menu .fixed-height-container > ul > li > a:focus {
  text-decoration: none;
}
.dropdown-menu > li > a {
  cursor: pointer;
}
.dropdown-menu li.disabled > a:hover {
  background: inherit;
  color: #707070;
}
.dropdown-menu .dropdown-list-item {
  border-bottom: solid 1px #ececec;
  clear: both;
  color: #111;
  cursor: pointer;
  display: block;
  padding: 12px 20px;
  white-space: normal;
}
.dropdown-menu .dropdown-list-item:hover {
  background-color: #fafafa;
}
.dropdown-menu .dropdown-list-item:last-child,
.dropdown-menu .dropdown-list-item:only-child {
  border-bottom: none;
}
.dropdown-menu .dropdown-list-item.disabled {
  color: #707070;
}
.dropdown-menu .dropdown-list-item.disabled:hover {
  background: transparent;
  cursor: auto;
}
.dropdown-menu .divider {
  margin: 0.35em 0;
}
.dropdown-menu > li > a.color-tint-danger,
.dropdown-menu > li > a.color-tint-danger:focus,
.dropdown-menu > li > a.color-tint-danger:hover {
  color: #ed3a3a;
  outline: none;
}
.dropdown-menu .edit-contact-name {
  max-width: 350px;
}
.dropdown-menu.dropdown-menu-with-arrow {
  margin-top: 10px;
}
.dropdown-menu.dropdown-menu-with-arrow:before {
  border-bottom: 8px solid rgba(0, 0, 0, 0.15);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  display: block;
  left: 8px;
  height: 0;
  position: absolute;
  top: -9px;
  width: 0;
}
.dropdown-menu.dropdown-menu-with-arrow:after {
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 8px;
  top: -8px;
  position: absolute;
  width: 0;
}
.dropdown-menu.dropdown-menu-with-arrow.scroll {
  max-height: 490px;
  overflow-y: scroll;
}
.dropdown-menu.dropdown-menu-right.dropdown-menu-with-arrow:before,
.dropdown-menu.dropdown-menu-right.dropdown-menu-with-arrow:after {
  left: auto;
  right: 8px;
}
.dropdown .caret {
  margin-left: 2px;
}
.dropdown-input-field.has-success .dropdown-button,
.dropdown-input-field.has-success input.form-control {
  background-color: #29cf87;
  border: 2px solid #29cf87;
}
.dropdown-input-field .option-id {
  border: solid 1px #000;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px 1px 1px 2px;
  font-size: 0.875em;
}
.dropdown-input {
  width: 100%;
}
.dropdown-input .form-control {
  text-align: left;
  position: relative;
}
.dropdown-input .form-control .cp-icon-check {
  position: absolute;
  right: -2px;
  top: -1px;
  margin: 8px;
}
.dropdown-input .form-control .label {
  display: inline-block;
  cursor: default;
  margin-right: 7px;
  position: relative;
  padding: 4px 6px;
  cursor: pointer;
  border: none;
}
.dropdown-input .form-control .label .remove-option {
  margin-left: 2px;
}
.dropdown-input .form-control .label:hover,
.dropdown-input .form-control .label:focus {
  background-color: #2d3961;
}
.dropdown-input .dropdown-content {
  z-index: 99999;
  background-color: #fafafa;
  min-width: 100px;
  border: 2px solid #3d4d84;
  margin-top: -2px;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  position: fixed;
}
.dropdown-input .dropdown-content .no-data-message {
  padding: 6px 12px;
  color: #666666;
}
.dropdown-input .dropdown-content ul {
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
}
.dropdown-input .dropdown-content ul li {
  cursor: pointer;
  font-size: 1rem;
  padding: 6px 12px;
  color: #666666;
  transition: background-color linear 0.1s;
}
.dropdown-input .dropdown-content ul li:hover,
.dropdown-input .dropdown-content ul li.focus {
  background-color: #ececec;
  color: #555555;
}
.dropdown-input .dropdown-content ul li.active {
  background-color: #efefef;
  color: #555555;
}
.dropdown-input .dropdown-content ul li.active:hover,
.dropdown-input .dropdown-content ul li.active.focus {
  background-color: #dfdfdf;
  color: #444;
}
.dropdown-input .dropdown-content ul li.addOption {
  background-color: #ececec;
  border-top: 1px solid #444;
}
.dropdown-input .dropdown-button {
  height: auto;
  min-height: 32px;
  padding-top: 4px;
}
.dropdown-input .dropdown-button .selected-item {
  white-space: nowrap;
  overflow-x: hidden;
  width: calc(100% - 25px);
  display: inline-block;
}
.dropdown-input .dropdown-button .multiselect-labels {
  white-space: nowrap;
  overflow-x: hidden;
  width: calc(100% - 25px);
}
.dropdown-input .dropdown-button:not(.multi-select) {
  height: 32px;
  line-height: 21px;
}
.dropdown-input .chevron {
  margin-top: 3px;
}
.dropdown-input .clear-all {
  right: 40px;
  position: absolute;
  font-size: 18px;
  line-height: 22px;
  margin-top: -1px;
}
.dropdown-input .clear-all:hover {
  text-shadow: #000 0px 0px 1px;
}
.dropdown-input .selected-options {
  width: calc(100% - 24px);
}
.dropdown-input .search-input-wrapper,
.dropdown-input .filter-input-wrapper {
  position: relative;
}
.dropdown-input .search-input-wrapper .form-control,
.dropdown-input .filter-input-wrapper .form-control {
  position: initial;
}
.dropdown-input .search-input-wrapper label[class^="cp-icon-"],
.dropdown-input .filter-input-wrapper label[class^="cp-icon-"],
.dropdown-input .search-input-wrapper label[class*=" cp-icon-"],
.dropdown-input .filter-input-wrapper label[class*=" cp-icon-"] {
  font-family: "care-partner-font" !important;
  font-size: 16px;
}
.dropdown-input .search-input-wrapper .cp-icon-search,
.dropdown-input .filter-input-wrapper .cp-icon-search,
.dropdown-input .search-input-wrapper .icon-filter,
.dropdown-input .filter-input-wrapper .icon-filter,
.dropdown-input .search-input-wrapper .cp-icon-check,
.dropdown-input .filter-input-wrapper .cp-icon-check {
  position: absolute;
  margin: 8px;
  cursor: text;
}
.dropdown-input .search-input-wrapper .cp-icon-search:focus,
.dropdown-input .filter-input-wrapper .cp-icon-search:focus,
.dropdown-input .search-input-wrapper .icon-filter:focus,
.dropdown-input .filter-input-wrapper .icon-filter:focus,
.dropdown-input .search-input-wrapper .cp-icon-check:focus,
.dropdown-input .filter-input-wrapper .cp-icon-check:focus,
.dropdown-input .search-input-wrapper .cp-icon-search:hover,
.dropdown-input .filter-input-wrapper .cp-icon-search:hover,
.dropdown-input .search-input-wrapper .icon-filter:hover,
.dropdown-input .filter-input-wrapper .icon-filter:hover,
.dropdown-input .search-input-wrapper .cp-icon-check:hover,
.dropdown-input .filter-input-wrapper .cp-icon-check:hover {
  color: #707070;
}
.dropdown-input .search-input-wrapper .cp-icon-check,
.dropdown-input .filter-input-wrapper .cp-icon-check {
  top: 1px;
  right: 0;
}
.dropdown-input .search-input-wrapper input,
.dropdown-input .filter-input-wrapper input {
  padding-left: 2em;
}
.dropdown-input .filter-input-wrapper .cp-icon-search {
  margin-left: 12px;
}
.dropdown-input .filter-input-wrapper .icon-filter {
  margin-left: 15px;
  margin-top: 10px;
}
.dropdown-input .filter-input-wrapper input {
  background-color: #fafafa;
  margin: 6px;
  width: calc(100% - 12px);
  padding-left: 2.3em;
}
.dropdown-input .dropdown-content {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.dropdown-input.expanded .form-control {
  border-color: #3d4d84;
}
.dropdown-input.expanded .search-input-wrapper input,
.dropdown-input.expanded .dropdown-button {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.rating > div {
  height: fit-content;
}
.rating > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.rating > input:focus + label:after {
  display: block;
  content: '';
  height: 4px;
  border-radius: 2px;
  background: #e4b892;
  position: relative;
  bottom: -10px;
  margin-left: 10px;
  margin-right: 10px;
}
.rating > label {
  position: relative;
  width: 1.1em;
  font-size: 70px;
  height: 80px;
  font-weight: lighter;
  color: #e4b892;
  cursor: pointer;
  text-align: center;
  line-height: 70px;
  margin-bottom: 20px;
}
.rating > label:before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}
.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}
.rating > input:checked ~ label:before {
  opacity: 1;
}
.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}
.rating .feedback-text > textarea {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 104px;
}
fieldset {
  background-color: #fff;
  margin: 0 0 15px;
  padding: 50px 20px 15px;
  position: relative;
}
fieldset legend {
  border: none;
  color: #fff;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  padding: 7px 40px 8px 15px;
  display: block;
  font-size: 18px;
  font-family: "Sofia Pro";
  margin-left: -20px;
}
fieldset .changed {
  background: rgba(61, 77, 132, 0.22);
  border-bottom-color: #3d4d84;
}
fieldset .history-form-date {
  position: relative;
  top: -10px;
}
fieldset.new-activitiy-fieldset {
  padding-bottom: 20px;
}
fieldset.new-activitiy-fieldset .form-group:last-of-type {
  margin-bottom: 0;
}
body.admin fieldset {
  border: 1px solid #ececec;
}
body.admin .account-modal-content fieldset {
  border: none;
}
body.admin .account-modal-content fieldset legend {
  font-size: 15px;
}
#filters-toggle {
  position: relative;
  margin-bottom: 20px;
}
.filters .filters-container {
  border-radius: 15px;
  background: #fff;
  padding: 25px 25px 10px;
  position: relative;
}
.modal .filters .filters-container {
  background-color: #fafafa;
}
.collapsing .btn {
  position: static;
}
.filters .filters-container {
  border-radius: 4px;
  padding: 10px;
  position: relative;
  background-color: #ECECEC;
}
.fixed-sidebar.affix {
  position: initial;
  width: 100%;
}
.sidebar-container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 20;
}
@media screen and (max-width: 900px) {
  .sidebar-container {
    position: inherit;
  }
}
.sidebar-container .panel {
  background: transparent;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
}
.sidebar-container .panel .autosave-label {
  display: none;
}
.sidebar-container .panel .btn-primary {
  float: right;
}
.sidebar-container .panel .panel-body {
  padding: 15px;
}
.sidebar-container .panel .panel-body .tracker-content {
  padding-top: 0;
}
.sidebar-container .panel .panel-body .alert {
  float: left;
  max-width: 540px;
}
.sidebar-container .panel .tab-content {
  background-color: #fff;
}
.sidebar-container .panel .nav.nav-tabs {
  border-bottom: none;
  display: flex;
}
.sidebar-container .panel .nav.nav-tabs > li {
  flex-grow: 1;
}
.sidebar-container .panel .nav.nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 16px;
  padding: 7px 15px 8px;
  line-height: 21px;
}
@media (max-width: 1130px) {
  .sidebar-container .panel .nav.nav-tabs > li > a {
    font-size: 0.9em;
  }
}
.sidebar-container .panel .nav.nav-tabs > li.active a {
  pointer-events: none;
  background-color: #3d4d84;
  color: #fff;
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  .sidebar-container {
    position: relative;
    z-index: auto;
  }
  .sidebar-container .panel {
    border-radius: 6px;
    margin-bottom: 0;
  }
  .sidebar-container .panel .autosave-label {
    display: block;
  }
  .sidebar-container .panel .btn-primary {
    float: none;
  }
  .sidebar-container .panel .panel-body {
    padding: 20px;
  }
  .sidebar-container .panel .panel-body .alert {
    float: none;
    max-width: none;
  }
  .sidebar-container .answer-options .dropdown-menu {
    bottom: auto;
    left: auto;
    right: 0;
    top: 96%;
  }
}
@media only screen and (min-width: 1350px) {
  .sidebar-container .answer-options .dropdown-menu {
    left: 0;
    right: auto;
  }
}
@media only screen and (min-width: 900px) {
  .sidebar-container {
    position: relative;
    z-index: auto;
  }
}
.form-control {
  border: none;
  font-size: 1rem;
  height: 32px;
  background-color: #fafafa;
  border: 2px solid #ececec;
  transition: border-color linear 0.1s;
  box-shadow: none;
  color: inherit;
}
.form-control::placeholder {
  color: #606060;
}
.form-control:hover,
.form-control:focus {
  box-shadow: none;
}
.form-control.input-lg {
  font-size: 1.26666666rem;
  height: 48px;
}
.form-control.centre-of-care-select {
  margin-top: 5px;
  margin-bottom: 5px;
}
@media only screen and (min-width: 768px) {
  .form-control.centre-of-care-select {
    margin: 0 0 0 -15px;
  }
}
.form-control.calculated-field,
.form-control.calculated-field:focus {
  padding: 0 12px 0 32px;
  line-height: 32px;
  position: relative;
}
.form-control.calculated-field .label-field-type,
.form-control.calculated-field:focus .label-field-type {
  left: 0;
  line-height: 30px;
  text-align: center;
  position: absolute;
  top: 0;
  width: 32px;
  color: #111;
}
.form-control.calculated-field:hover {
  background-color: #fafafa;
}
.form-control.admin-tabs {
  background-color: #fff;
  max-width: 190px;
}
.form-control.text-input.readonly {
  pointer-events: none;
}
.form-control.text-input.readonly .label.label-default {
  color: #000;
  background-color: transparent;
  border: solid 1px #000;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px 1px 1px 2px;
  font-size: 0.875em;
}
.search-control {
  position: relative;
}
.search-control .form-control-search {
  padding-left: 2em;
  padding-right: 1.75em;
}
.search-control .cp-icon-search {
  cursor: text;
  left: 8px;
  position: absolute;
  top: 8px;
}
.search-control .cp-icon-search:focus,
.search-control .cp-icon-search:hover {
  color: #707070;
}
.search-control .cp-icon-cross {
  position: absolute;
  right: 6px;
  top: 3px;
}
.search-control .btn.btn-link.cp-icon-cross:focus,
.search-control .btn.btn-link.cp-icon-cross:hover {
  color: #ed3a3a;
  text-decoration: none;
}
.search-control.alternate .form-control {
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color linear 0.1s;
}
.search-control.alternate .form-control:focus {
  background-color: #fff;
}
.form-group {
  margin-bottom: 20px;
}
.form-group.form-group-sm {
  margin-bottom: 6px !important;
}
.form-group.add-field {
  margin-top: -15px;
}
.form-group.add-field .btn {
  padding-left: 0;
  padding-right: 0;
}
.form-group.add-field .btn-link:focus {
  background-color: transparent;
}
.form-group.add-field .btn-link:focus:hover {
  background-color: #fafafa;
}
.form-group.add-field [class^="cp-icon-"] {
  color: #3d4d84;
}
.form-group.form-group-required label:after {
  content: "*";
  color: #ef4444;
  padding-right: 2px;
}
.form-group .label-text-top {
  vertical-align: top;
  cursor: pointer;
}
.form-group .label-text-top .text-muted {
  display: block;
  font-family: FSMeWeb;
  font-weight: 500;
  font-size: 0.875em;
}
.form-group .tag {
  background-color: #000;
  border-radius: 4px;
  color: #fff;
  font-size: 0.9em;
  font-weight: 600;
  padding: 2px;
}
.form-horizontal.history-form-view .form-group {
  border-top: solid 1px transparent;
  border-bottom: solid 1px #ececec;
  margin: 0 -20px;
  padding: 4px 0;
}
.form-horizontal.history-form-view .form-group:last-of-type {
  border: none;
}
.form-horizontal .cp-icon-user {
  margin-right: 0.25em;
  vertical-align: middle;
}
.form-horizontal .cp-icon-user:focus,
.form-horizontal .cp-icon-user:hover {
  color: #707070;
}
.validation-block {
  display: block;
  margin: 3px 0;
  font-size: 0.875em;
}
.clearfix .validation-block {
  margin: 0;
}
.form-guidance {
  color: #616161;
  font-size: 1rem;
  line-height: 1.267rem;
  clear: both;
  max-height: 400px;
  overflow-y: scroll;
  background-color: #ececec;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
}
.form-guidance p {
  max-width: 650px;
  margin-bottom: 10px !important;
}
.form-guidance p:last-of-type {
  margin-bottom: 0;
}
.form-guidance h1 {
  font-size: 18px;
  color: #111;
}
.form-guidance h2 {
  font-size: 16px;
  color: #111;
}
.form-guidance h3 {
  font-size: 14px;
  color: #111;
}
.form-guidance h4,
.form-guidance h5,
.form-guidance h6 {
  font-size: 13px;
  color: #111;
}
.form-guidance p,
.form-guidance table,
.form-guidance ul,
.form-guidance ol,
.form-guidance blockquote {
  font-size: 12px;
  color: #111;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
}
.form-guidance blockquote {
  border-left-color: #ddd;
  margin: 15px 0 !important;
}
.form-guidance blockquote:last-of-type {
  margin-bottom: 0;
}
.form-guidance blockquote p:last-of-type {
  margin-bottom: 0 !important;
}
.form-guidance ul,
.form-guidance ol {
  max-width: 650px;
  padding-left: 20px;
  margin: 10px 0 !important;
}
.form-guidance ul li,
.form-guidance ol li {
  margin-bottom: 10px !important;
}
.form-guidance ul li:last-of-type,
.form-guidance ol li:last-of-type {
  margin-bottom: 0;
}
.form-guidance ul li p,
.form-guidance ol li p {
  margin-bottom: 10px !important;
}
.form-guidance ul li p:last-of-type,
.form-guidance ol li p:last-of-type {
  margin-bottom: 0;
}
.form-guidance ul {
  list-style-type: disc;
}
.form-guidance ol {
  list-style-type: decimal;
}
.form-guidance a {
  text-decoration: underline !important;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
}
.form-guidance hr {
  border-top-color: #ddd;
}
.form-guidance.gutruncate_closed {
  display: none;
}
.form-guidance.radio-button-guidance {
  line-height: unset;
  padding-left: 0px;
}
.form-guidance.bistate-guidance {
  padding-top: 8px;
}
label {
  color: #1b1b1b;
  margin-bottom: 2px;
}
textarea {
  resize: vertical;
}
textarea.form-control {
  line-height: 22px;
  padding: 6px 12px;
}
.disabled-control {
  opacity: 0.5;
  pointer-events: none;
}
.registration-form {
  margin-bottom: 15px;
}
.registration-form .mandatory-view {
  margin-bottom: 15px;
}
.registration-form .mandatory-view fieldset {
  border: none;
}
.registration-form .panel-collapse {
  width: 100%;
  border-radius: 0;
}
.registration-form fieldset {
  width: 100%;
}
.service-user-personal-details .panel .form-horizontal {
  width: 100%;
}
.service-user-personal-details .mandatory-view .panel-collapse {
  width: 100%;
  border-radius: 0;
}
.service-user-personal-details .mandatory-view fieldset {
  width: 100%;
}
.service-user-personal-details .mandatory-view fieldset.mandatory-information .view-form {
  margin-bottom: 0;
}
.form-tracker-container li.list-item a.link-item.has-error {
  color: #bf201f;
  font-weight: 500;
}
.form-tracker-container .list-item.active > a.link-item.has-error {
  color: #bf201f;
  font-weight: 500;
}
.has-error .form-control,
.has-error .btn,
.has-error .btn.btn-default,
.has-error .input-group-addon,
.has-error .select2-container--default .select2-selection--multiple,
.has-error .select2-container--default .select2-selection--single {
  background-color: #fbb3b3;
}
.has-error .btn.btn-link.btn-new-subform {
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: 1px solid #bf201f;
}
.has-error .btn.btn-link.btn-new-subform:hover {
  background-color: #fafafa !important;
}
.has-error .radio-button-label .checkmark {
  background-color: #fbb3b3 !important;
  border-color: #fbb3b3 !important;
}
.has-error .radio-button-label .checkmark:after {
  background-color: #fbb3b3 !important;
}
.has-error .form-control:focus,
.has-error .form-control:focus,
.has-error .input-group-addon:focus .select2-container--default .select2-selection--single {
  background-color: #fbb3b3;
}
.has-error .calculated-field {
  background-color: #fbb3b3;
}
.has-error .calculated-field:focus {
  background-color: none;
  box-shadow: none;
}
.has-error .btn-group {
  border-radius: 4px;
  box-shadow: 0 0 6px #bf201f;
}
.has-error .btn-group .btn,
.has-error .btn-group .btn.active,
.has-error .btn-group .btn-default.active {
  border-color: #bf201f !important;
  box-shadow: none !important;
}
.has-error .question-label {
  color: #bf201f;
}
.has-error .validation-block {
  color: #bf201f;
}
.has-error .help-block {
  color: #111;
}
.auto-filled {
  color: #0d76b8;
  padding-bottom: 8px;
}
.file-upload-control .form-control {
  overflow: hidden;
  width: auto;
  max-width: 100%;
}
.file-upload-control .btn-file {
  float: right;
  margin-left: -1px;
}
.file-upload-control p.form-control {
  margin: 0;
}
.btn-file {
  overflow: hidden;
  position: relative;
}
.filedrag {
  position: relative;
}
.filedrag input[type="file"] {
  background: #fafafa;
  cursor: inherit;
  display: block;
  font-size: 100px;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  width: 0;
  height: 0;
}
.filedrag input[type="file"]:hover + .btn-file,
.filedrag input[type="file"]:focus + .btn-file {
  background: #ececec;
}
.input-group-addon {
  background: #ececec;
  border: none;
  color: #111;
  font-size: 0.867em;
  line-height: 32px;
  padding: 0 8px;
}
textarea.clone {
  width: 300px;
  height: 250px;
  z-index: 9999;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
textarea.edit-form-description,
textarea.clone {
  resize: none;
}
.select2-container.select2-container--default .select2-selection--single[aria-expanded="true"] {
  border-color: #3d4d84;
}
.select2-container.select2-container--default .select2-selection--single:hover:not([aria-disabled="true"]),
.select2-container.select2-container--default .select2-selection--single:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.select2-container.select2-container--default .select2-dropdown.select2-dropdown--below {
  border-color: #3d4d84;
}
.select2-container.select2-container--default .select2-selection--multiple[aria-expanded="true"] {
  border-color: #3d4d84;
}
.select2-container.select2-container--default .select2-selection--multiple:hover:not([aria-disabled="true"]),
.select2-container.select2-container--default .select2-selection--multiple:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.form-control:hover:not([aria-disabled="true"]),
.form-control:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
.form-control[aria-disabled="true"]:hover,
.form-control[aria-disabled="true"]:focus {
  border-color: #ececec;
}
#guprogress-target .guprogress-bar {
  background-color: #42ce56;
  z-index: 100;
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
}
#guprogress-target.progress-danger .guprogress-bar {
  background-color: #eb1515;
}
.short-text-group {
  width: 100%;
}
.short-text-group .form-control:only-child {
  border-radius: 4px;
}
.short-text-group.shortDataInputNumeric {
  width: 50% !important;
}
.form-item-row .select2-container {
  position: relative;
  z-index: inherit;
  width: 100%;
  margin-bottom: 0;
  display: table;
  table-layout: fixed;
}
.warning-badge {
  background-color: #fff;
  position: relative;
  height: 21px;
  width: 21px;
  padding: 0px 1px;
  border-radius: 85px;
  padding-top: 0px;
  display: inline-block;
}
.warning-badge .imo {
  font-size: 20px;
  color: #bf201f;
}
.not-nullable-asterisk {
  color: #bf201f;
}
.mandatory-items-module {
  background: rgba(255, 0, 0, 0.1);
  padding: 4px 8px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.mandatory-items-module h3 {
  font-size: 1.1em;
  color: #aa0000;
}
.mandatory-items-module .btn.btn-link {
  padding: 0;
  color: #aa0000;
}
.mandatory-items-module .btn.btn-link:hover,
.mandatory-items-module .btn.btn-link:focus {
  background-color: transparent;
  text-decoration: underline;
}
.btn-new-subform {
  max-width: 500px;
  height: auto;
  white-space: pre-wrap;
  text-align: center;
  display: inline-block;
  min-height: 34px;
  padding: 8px 4px;
  line-height: 1.2em;
}
.readonly-text-field b {
  font-family: 'Sofia Pro';
  display: block;
  font-size: 14px;
  margin-bottom: 2px;
}
.readonly-text-field span {
  font-family: "FSMeWeb", helvetica, arial, sans-serif !important;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  background-color: #ddd;
  border-radius: 4px;
  color: #454545;
}
.readonly-text-field span mark {
  background-color: transparent;
  color: inherit;
}
.readonly-text-field span mark:last-child {
  color: #000;
}
hr.hr-small {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.icon.icon-file-drag-drop {
  background: url(Content/SVG/file.svg) no-repeat;
  background-size: contain;
  display: inline-block;
  text-indent: -999999px;
  vertical-align: top;
  width: 35px;
  height: 32px;
}
.icon.browser-icon {
  background: url(Content/Images/browser-logos.png?1.1) no-repeat;
  display: inline-block;
  width: 128px;
  height: 128px;
}
.icon.browser-icon.browser-firefox {
  background-position: 0 0;
}
.icon.browser-icon.browser-iOS {
  background-position: -512px 0;
}
.icon.browser-icon.browser-ie11 {
  background-position: -124px 0;
}
.icon.browser-icon.browser-chrome {
  background-position: -256px 0;
}
.icon.browser-icon.browser-unsupported {
  background-position: -640px 0;
}
.icon.browser-icon.browser-safari {
  background-position: -384px 0;
}
.icon.browser-icon.browser-edge {
  background-position: -768px 0;
}
.icon.notification-icon {
  color: #3d4d84;
  font-size: 30px;
  padding-top: 5px;
  padding-left: 5px;
}
.icon.notification-icon.warning {
  color: #f0ad4e !important;
}
@font-face {
  font-family: "care-partner-font";
  src: url("Content/Fonts/care-partner-font.woff?v1.29") format("woff");
  font-weight: normal;
  font-style: normal;
}
[class^="cp-icon-"],
[class*=" cp-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "care-partner-font" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="cp-icon-"].cp-x2,
[class*=" cp-icon-"].cp-x2 {
  font-size: 24px;
}
[class^="cp-icon-"].cp-x3,
[class*=" cp-icon-"].cp-x3 {
  font-size: 32px;
}
[class^="cp-icon-"].cp-x4,
[class*=" cp-icon-"].cp-x4 {
  font-size: 48px;
}
[class^="cp-icon-"].cp-x5,
[class*=" cp-icon-"].cp-x5 {
  font-size: 64px;
}
[class^="cp-icon-"].cp-fw,
[class*=" cp-icon-"].cp-fw {
  display: inline-block;
  text-align: center;
  width: 20px;
}
[class^="cp-icon-"].cp-fw,
[class*=" cp-icon-"].cp-fw {
  display: inline-block;
  text-align: center;
  width: 28px;
}
.cp-icon-care-plan-associations:before {
  content: "\e941";
}
.cp-icon-audit:before {
  content: "\e94e";
}
.cp-icon-pencil:before {
  content: "\e94f";
}
.cp-icon-toggle:before {
  content: "\e950";
}
.cp-icon-pathways:before {
  content: "\e951";
}
.cp-icon-people:before {
  content: "\e952";
}
.cp-icon-person:before {
  content: "\e953";
}
.cp-icon-more:before {
  content: "\e94d";
}
.cp-icon-function:before {
  content: "\e940";
}
.cp-icon-comment:before {
  content: "\e93f";
}
.cp-icon-calculator-s:before {
  content: "\e93d";
}
.cp-icon-calculator:before {
  content: "\e93e";
}
.cp-icon-bin-s:before {
  content: "\e93b";
}
.cp-icon-bin:before {
  content: "\e93c";
}
.cp-icon-logs:before {
  content: "\e935";
}
.cp-icon-comment-s:before {
  content: "\e936";
}
.cp-icon-eye:before {
  content: "\e937";
}
.cp-icon-database:before {
  content: "\e938";
}
.cp-icon-code:before {
  content: "\e939";
}
.cp-icon-building-s:before {
  content: "\e93a";
}
.cp-icon-chevron-left:before {
  content: "\e934";
}
.cp-icon-caret-up:before {
  content: "\e933";
}
.cp-icon-caret-down:before {
  content: "\e932";
}
.cp-icon-caret-right:before {
  content: "\e930";
}
.cp-icon-caret-left:before {
  content: "\e931";
}
.cp-icon-search:before {
  content: "\e92f";
}
.cp-icon-chevron-up:before {
  content: "\e92e";
}
.cp-icon-chevron-down:before {
  content: "\e92d";
}
.cp-icon-refresh:before {
  content: "\e92c";
}
.cp-icon-paperclip:before {
  content: "\e92b";
}
.cp-icon-cake:before {
  content: "\e92a";
}
.cp-icon-share:before {
  content: "\e919";
}
.cp-icon-share-s:before {
  content: "\e91a";
}
.cp-icon-archive:before {
  content: "\e91b";
}
.cp-icon-archive-s:before {
  content: "\e91c";
}
.cp-icon-care-plans:before {
  content: "\e90f";
}
.cp-icon-care-plans-s:before {
  content: "\e91d";
}
.cp-icon-plus:before {
  content: "\e913";
}
.cp-icon-plus-s:before {
  content: "\e918";
}
.cp-icon-star:before {
  content: "\e904";
}
.cp-icon-star-s:before {
  content: "\e914";
}
.cp-icon-bell:before {
  content: "\e907";
}
.cp-icon-bell-s:before {
  content: "\e91e";
}
.cp-icon-bell-slash:before {
  content: "\e91f";
}
.cp-icon-admin:before {
  content: "\e90b";
}
.cp-icon-admin-s:before {
  content: "\e915";
}
.cp-icon-pin:before {
  content: "\e917";
}
.cp-icon-pin-s:before {
  content: "\e920";
}
.cp-icon-copy-forward:before {
  content: "\e916";
}
.cp-icon-copy-forward-s:before {
  content: "\e921";
}
.cp-icon-dial:before {
  content: "\e912";
}
.cp-icon-dial-s:before {
  content: "\e922";
}
.cp-icon-bug:before {
  content: "\e910";
}
.cp-icon-bug-s:before {
  content: "\e923";
}
.cp-icon-cross:before {
  content: "\e9133";
}
.cp-icon-cross-s:before {
  content: "\e924";
}
.cp-icon-user:before {
  content: "\e903";
}
.cp-icon-guidance-notes:before {
  content: "\e90c";
}
.cp-icon-guidance-notes-s:before {
  content: "\e90d";
}
.cp-icon-shift-notes:before {
  content: "\e905";
}
.cp-icon-shift-notes-s:before {
  content: "\e925";
}
.cp-icon-calendar:before {
  content: "\e90a";
}
.cp-icon-calendar-s:before {
  content: "\e926";
}
.cp-icon-pause:before {
  content: "\e945";
}
.cp-icon-print:before {
  content: "\e906";
}
.cp-icon-print-s:before {
  content: "\e927";
}
.cp-icon-activities.no-data-page {
  align-items: center;
  font-size: 75px;
}
.cp-icon-activities:before {
  content: "\e90e";
}
.cp-icon-activities-s-16:before {
  content: "\e928";
}
.cp-icon-piggy:before {
  content: "\e908";
}
.cp-icon-piggy-s:before {
  content: "\e929";
}
.cp-icon-answer-history:before {
  content: "\e911";
}
.cp-icon-check:before {
  content: "\e900";
}
.cp-icon-checkbox:before {
  content: "\e901";
}
.cp-icon-trigger:before {
  content: "\e902";
}
.cp-icon-bars:before {
  content: "\e909";
}
.cp-icon-buildings-group-s:before {
  content: "\e942";
}
.cp-icon-buildings-group:before {
  content: "\e943";
}
.cp-icon-building:before {
  content: "\e944";
}
.cp-icon-chevron-down,
.cp-icon-chevron-up,
.cp-icon-chevron-right,
.cp-icon-chevron-left {
  color: #616161;
}
.cp-icon-bell-slash {
  color: #707070;
}
.cp-icon-guidance-notes {
  color: #707070;
  cursor: pointer;
}
.cp-icon-guidance-notes:before {
  content: "\e90c";
  cursor: pointer;
}
.cp-icon-guidance-notes-s:before,
.cp-icon-guidance-notes.gutruncate_open:before {
  content: "\e90d";
}
.cp-icon-search,
.cp-icon-plus,
.cp-icon-cross,
.cp-icon-checkbox,
.cp-icon-paperclip,
.cp-icon-pin {
  color: #707070;
}
.cp-icon-star {
  color: #707070;
  cursor: pointer;
}
.cp-icon-star.cp-icon-star-su {
  display: inline-block;
  transition: color 0.15s ease-in-out;
}
.cp-icon-star.cp-icon-star-su:focus,
.cp-icon-star.cp-icon-star-su:hover {
  color: #ffa500;
}
.cp-icon-star.cp-icon-star-su.active {
  animation: fade 0.2s ease-out;
  color: #ffa500;
  transition: all 0.1s ease;
}
.cp-icon-star.cp-icon-star-su.active:before {
  content: "\e914";
}
.cp-icon-star.cp-icon-star-su.active:focus,
.cp-icon-star.cp-icon-star-su.active:hover {
  transform: scale(0.8);
}
.profile-photo {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.profile-photo.active {
  cursor: pointer;
}
.about-cp-logo {
  display: block;
  margin: 70px auto 20px;
  width: 100%;
  height: 100%;
  max-width: 280px;
}
.about-cp-icon-container {
  border-radius: 24px;
  background: #fff;
  border: solid 1px #ececec;
  color: #3d4d84;
  margin: 10px auto;
  padding: 0 10px;
  width: 146px;
  height: 146px;
}
.about-cp-icon-container .build {
  font-size: 2.5rem;
  padding: 44px 0 5px;
}
.about-cp-icon-container .browser-icon {
  margin: 8px auto 0;
}
@font-face {
  font-family: "ImosphereIcons";
  src: url("Content/Fonts/ImosphereIcons.woff?v11") format("woff");
  font-weight: normal;
  font-style: normal;
}
.imo {
  display: inline-block;
  font: normal normal normal 14px/1 "ImosphereIcons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.imo.text {
  font-size: 20px;
}
@media (max-width: 1130px) {
  .imo.text {
    font-size: 16px;
  }
}
.imo-lg {
  display: inline-block;
  font: normal normal normal 14px/1 "ImosphereIcons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.imo-sm {
  display: inline-block;
  font: normal normal normal 14px/1 "ImosphereIcons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.indicator {
  position: relative;
  display: inline-block;
}
.indicator:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 50%;
}
.indicator.warning:after {
  background-color: #fca744;
}
.indicator.info:after {
  background-color: #0d76b8;
}
.indicator.success:after {
  background-color: #0db887;
}
.indicator.overdue:after {
  background-color: #DE3700;
}
.imo-2x {
  font-size: 28px;
}
.imo-3x {
  font-size: 42px;
}
.imo-4x {
  font-size: 58px;
}
.imo-5x {
  font-size: 74px;
}
.imo-6x {
  font-size: 90px;
}
.error-page {
  font-size: 200px;
  color: #3d4d84;
}
.nav-icon {
  font-size: 22px;
}
.icon-general {
  font-size: 20px !important;
}
.icon-delete:before {
  content: '\e800';
}
.icon-folder-new:before {
  content: '\e801';
}
.icon-ambulance:before {
  content: '\e802';
}
.icon-bed:before {
  content: '\e803';
}
.icon-blood-pressure:before {
  content: '\e804';
}
.icon-brain:before {
  content: '\e805';
}
.icon-bone:before {
  content: '\e806';
}
.icon-breathe:before {
  content: '\e807';
}
.icon-burger:before {
  content: '\e808';
}
.icon-calculator:before {
  content: '\e809';
}
.icon-capsule-pot:before {
  content: '\e80a';
}
.icon-capsules:before {
  content: '\e80b';
}
.icon-cogs:before {
  content: '\e80c';
}
.icon-cigarette:before {
  content: '\e80d';
}
.icon-drugs:before {
  content: '\e80e';
}
.icon-ear:before {
  content: '\e80f';
}
.icon-eye:before {
  content: '\e810';
}
.icon-female:before {
  content: '\e811';
}
.icon-fracture:before {
  content: '\e812';
}
.icon-genome:before {
  content: '\e813';
}
.icon-hermaphrodite:before {
  content: '\e814';
}
.icon-infection-2:before {
  content: '\e815';
}
.icon-house:before {
  content: '\e816';
}
.icon-infection:before {
  content: '\e817';
}
.icon-joint:before {
  content: '\e818';
}
.icon-liver:before {
  content: '\e819';
}
.icon-lungs:before {
  content: '\e81a';
}
.icon-male:before {
  content: '\e81b';
}
.icon-monitoring:before {
  content: '\e81c';
}
.icon-mental-health:before {
  content: '\e81d';
}
.icon-mouth:before {
  content: '\e81e';
}
.icon-other-brain:before {
  content: '\e81f';
}
.icon-other-gender:before {
  content: '\e820';
}
.icon-blood-2:before {
  content: '\e821';
}
.icon-blood:before {
  content: '\e822';
}
.icon-compare-ab-series:before {
  content: '\e823';
}
.icon-calander:before {
  content: '\e824';
}
.icon-chevron-left:before {
  content: '\e825';
}
.icon-chevron-right:before {
  content: '\e826';
}
.icon-clinical-observations:before {
  content: '\e827';
}
.icon-myhub-view-icon:before {
  content: '\e828';
}
.icon-cohort-discovery:before {
  content: '\e829';
}
.icon-myhub-summary-icon:before {
  content: '\e82a';
}
.icon-myhub-rocket-rotated-icon:before {
  content: '\e82b';
}
.icon-column-chart-sm:before {
  content: '\e82c';
}
.icon-cross:before {
  content: '\e82d';
}
.icon-folder-move:before {
  content: '\e82e';
}
.icon-custom-reports-lg:before {
  content: '\e82f';
}
.icon-data-export-lg:before {
  content: '\e830';
}
.icon-count-coding-system-instance:before {
  content: '\e831';
}
.icon-count-latest-multiple-choice-answer:before {
  content: '\e832';
}
.icon-multiple-code-assignment:before {
  content: '\e833';
}
.icon-incidental-code-assignment:before {
  content: '\e834';
}
.icon-switcher-code-assignment:before {
  content: '\e835';
}
.icon-text-series:before {
  content: '\e836';
}
.icon-simple-pie-series:before {
  content: '\e837';
}
.icon-pyramid:before {
  content: '\e838';
}
.icon-postcode:before {
  content: '\e839';
}
.icon-pie-series:before {
  content: '\e83a';
}
.icon-lollipop-chart:before {
  content: '\e83b';
}
.icon-distributed-spot-series:before {
  content: '\e83c';
}
.icon-count-latest-answer:before {
  content: '\e83d';
}
.icon-count-form-instances:before {
  content: '\e83e';
}
.icon-box-series:before {
  content: '\e83f';
}
.icon-bar-series:before {
  content: '\e840';
}
.icon-count-admission-location:before {
  content: '\e841';
}
.icon-data-export:before {
  content: '\e842';
}
.icon-data-type:before {
  content: '\e843';
}
.icon-diagnosis-explorer-lg:before {
  content: '\e844';
}
.icon-diagnosis-explorer:before {
  content: '\e845';
}
.icon-custom-reports:before {
  content: '\e846';
}
.icon-wheelchair:before {
  content: '\e847';
}
.icon-tooth:before {
  content: '\e848';
}
.icon-grid_count-latest-date-answer:before {
  content: '\e849';
}
.icon-open-admission:before {
  content: '\e84a';
}
.icon-upperbound:before {
  content: '\e84b';
}
.icon-lowerbound:before {
  content: '\e84c';
}
.icon-datasets:before {
  content: '\e84d';
}
.icon-closed-admission:before {
  content: '\e84e';
}
.icon-top-percentage:before {
  content: '\e84f';
}
.icon-mapping-chart:before {
  content: '\e850';
}
.icon-malevfemale:before {
  content: '\e851';
}
.icon-imosphere-ident:before {
  content: '\e852';
}
.icon-imosphere-logotype:before {
  content: '\e853';
}
.icon-library:before {
  content: '\e854';
}
.icon-chn-compare-ab:before {
  content: '\e855';
}
.icon-genderless:before {
  content: '\e856';
}
.icon-clearsense-icon:before {
  content: '\e857';
}
.icon-helix:before {
  content: '\e858';
}
.icon-scope-admissions:before {
  content: '\e859';
}
.icon-scope-staff:before {
  content: '\e85a';
}
.icon-scope-timeframe:before {
  content: '\e85b';
}
.icon-view-history:before {
  content: '\e85c';
}
.icon-chart:before {
  content: '\e85d';
}
.icon-figures-group:before {
  content: '\e85e';
}
.icon-scope-focus:before {
  content: '\e85f';
}
.icon-base10:before {
  content: '\e860';
}
.icon-save:before {
  content: '\e861';
}
.icon-scope-focus-alt:before {
  content: '\e862';
}
.icon-myhub-panel-selected-icon:before {
  content: '\e863';
}
.icon-count-form-over-time:before {
  content: '\e864';
}
.icon-myhub-panel-tick:before {
  content: '\e865';
}
.icon-indexed-list:before {
  content: '\e866';
}
.icon-clearsense-logo:before {
  content: '\e867';
}
.icon-library-sm:before {
  content: '\e868';
}
.icon-lock:before {
  content: '\e869';
}
.icon-local-login:before {
  content: '\e86a';
}
.icon-my-forms:before {
  content: '\e86b';
}
.icon-flask:before {
  content: '\e86c';
}
.icon-multiline-text-answer:before {
  content: '\e86d';
}
.icon-not-in-library-sm:before {
  content: '\e86e';
}
.icon-ldap:before {
  content: '\e86f';
}
.icon-not-in-library-lg:before {
  content: '\e870';
}
.icon-open-id:before {
  content: '\e871';
}
.icon-single-line:before {
  content: '\e872';
}
.icon-tristate-checkbox:before {
  content: '\e873';
}
.icon-share:before {
  content: '\e874';
}
.icon-calculated-answer:before {
  content: '\e875';
}
.icon-calculated-date-range:before {
  content: '\e876';
}
.icon-comment:before {
  content: '\e877';
}
.icon-checkbox:before {
  content: '\e878';
}
.icon-document:before {
  content: '\e879';
}
.icon-download:before {
  content: '\e87a';
}
.icon-dropdown:before {
  content: '\e87b';
}
.icon-export:before {
  content: '\e87c';
}
.icon-numeric-answer:before {
  content: '\e87d';
}
.icon-ontology:before {
  content: '\e87e';
}
.icon-pin:before {
  content: '\e87f';
}
.icon-received:before {
  content: '\e880';
}
.icon-projects:before {
  content: '\e881';
}
.icon-sent:before {
  content: '\e882';
}
.icon-refresh:before {
  content: '\e883';
}
.icon-unlock:before {
  content: '\e884';
}
.icon-heading:before {
  content: '\e885';
}
.icon-file:before {
  content: '\e886';
}
.icon-organizations:before {
  content: '\e887';
}
.icon-checkbox-group:before {
  content: '\e888';
}
.icon-beginning-with:before {
  content: '\e889';
}
.icon-facecode:before {
  content: '\e88a';
}
.icon-selected-branch:before {
  content: '\e88b';
}
.icon-worklist:before {
  content: '\e88c';
}
.icon-registration:before {
  content: '\e88d';
}
.icon-move-activity:before {
  content: '\e88e';
}
.icon-count-admission-type:before {
  content: '\e88f';
}
.icon-discharges-over-time:before {
  content: '\e890';
}
.icon-age-banding:before {
  content: '\e891';
}
.icon-spline-chart:before {
  content: '\e892';
}
.icon-move-activity-1:before {
  content: '\e893';
}
.icon-spline-area-chart:before {
  content: '\e894';
}
.icon-word-cloud:before {
  content: '\e895';
}
.icon-question:before {
  content: '\e896';
}
.icon-childrens:before {
  content: '\e897';
}
.icon-tag:before {
  content: '\e898';
}
.icon-repeating-sections:before {
  content: '\e899';
}
.icon-table:before {
  content: '\e89a';
}
.icon-careplan:before {
  content: '\e89b';
}
.icon-radio-btn:before {
  content: '\e89c';
}
.icon-grid-layout:before {
  content: '\e89d';
}
.icon-split-first-diagnosis:before {
  content: '\e89e';
}
.icon-not-verified:before {
  content: '\e89f';
}
.icon-pending:before {
  content: '\e8a0';
}
.icon-switch:before {
  content: '\e8a1';
}
.icon-verified:before {
  content: '\e8a2';
}
.icon-import-from-form:before {
  content: '\e8a3';
}
.icon-data-entry-and-analytics:before {
  content: '\e8a4';
}
.icon-personal-budgets:before {
  content: '\e8a5';
}
.icon-revert:before {
  content: '\e8a6';
}
.icon-testing:before {
  content: '\e8a7';
}
.icon-divide:before {
  content: '\e8a8';
}
.icon-message:before {
  content: '\e8a9';
}
.icon-mediator-failure:before {
  content: '\e8aa';
}
.icon-timeline:before {
  content: '\e8ab';
}
.icon-not-equals:before {
  content: '\e8ac';
}
.icon-back-bracket-1:before {
  content: '\e8ad';
}
.icon-envelope-closed:before {
  content: '\e8ae';
}
.icon-envelope-open-1:before {
  content: '\e8af';
}
.icon-sent-group-1:before {
  content: '\e8b0';
}
.icon-sum-costs-for-time-unit:before {
  content: '\e8b1';
}
.icon-spot-series:before {
  content: '\e8b2';
}
.icon-front-bracket-1:before {
  content: '\e8b3';
}
.icon-absolute:before {
  content: '\e8b4';
}
.icon-addition:before {
  content: '\e8b5';
}
.icon-and:before {
  content: '\e8b6';
}
.icon-list-answer:before {
  content: '\e8b7';
}
.icon-brackets:before {
  content: '\e8b8';
}
.icon-coalesce:before {
  content: '\e8b9';
}
.icon-date-diff:before {
  content: '\e8ba';
}
.icon-logout:before {
  content: '\e8bb';
}
.icon-equal-to:before {
  content: '\e8bc';
}
.icon-forumula:before {
  content: '\e8bd';
}
.icon-my-sql:before {
  content: '\e8be';
}
.icon-postgre-sql:before {
  content: '\e8bf';
}
.icon-sql-server:before {
  content: '\e8c0';
}
.icon-activity-counter:before {
  content: '\e8c1';
}
.icon-activity-counter-lg:before {
  content: '\e8c2';
}
.icon-admin-settings:before {
  content: '\e8c3';
}
.icon-admin-user:before {
  content: '\e8c4';
}
.icon-all-the-answers:before {
  content: '\e8c5';
}
.icon-all-the-answers-lg:before {
  content: '\e8c6';
}
.icon-annotation:before {
  content: '\e8c7';
}
.icon-apps:before {
  content: '\e8c8';
}
.icon-area-chart-sm:before {
  content: '\e8c9';
}
.icon-banding:before {
  content: '\e8ca';
}
.icon-atmolytics:before {
  content: '\e8cb';
}
.icon-camera:before {
  content: '\e8cc';
}
.icon-chevron-down:before {
  content: '\e8cd';
}
.icon-money-bag-euros:before {
  content: '\e8ce';
}
.icon-money-bag-pound:before {
  content: '\e8cf';
}
.icon-chevron-up:before {
  content: '\e8d0';
}
.icon-money-bag-dollars:before {
  content: '\e8d1';
}
.icon-clinical-observations-lg:before {
  content: '\e8d2';
}
.icon-clone:before {
  content: '\e8d3';
}
.icon-cohort-discovery-sm:before {
  content: '\e8d4';
}
.icon-pain:before {
  content: '\e8d5';
}
.icon-pills:before {
  content: '\e8d6';
}
.icon-costs-and-charges:before {
  content: '\e8d7';
}
.icon-costs-and-charges-lg:before {
  content: '\e8d8';
}
.icon-pulse:before {
  content: '\e8d9';
}
.icon-psychology:before {
  content: '\e8da';
}
.icon-stomach:before {
  content: '\e8db';
}
.icon-surgery:before {
  content: '\e8dc';
}
.icon-temp:before {
  content: '\e8dd';
}
.icon-syringe:before {
  content: '\e8de';
}
.icon-test-tube:before {
  content: '\e8df';
}
.icon-transgender:before {
  content: '\e8e0';
}
.icon-events-and-processes:before {
  content: '\e8e1';
}
.icon-events-and-processes-lg:before {
  content: '\e8e2';
}
.icon-expand:before {
  content: '\e8e3';
}
.icon-filter:before {
  content: '\e8e4';
}
.icon-flag:before {
  content: '\e8e5';
}
.icon-folder:before {
  content: '\e8e6';
}
.icon-image:before {
  content: '\e8e7';
}
.icon-group:before {
  content: '\e8e8';
}
.icon-group-by:before {
  content: '\e8e9';
}
.icon-graph-type:before {
  content: '\e8ea';
}
.icon-health-dimensions:before {
  content: '\e8eb';
}
.icon-health-dimensions-lg:before {
  content: '\e8ec';
}
.icon-home-large:before {
  content: '\e8ed';
}
.icon-home:before {
  content: '\e8ee';
}
.icon-identity-check:before {
  content: '\e8ef';
}
.icon-identity-check-lg:before {
  content: '\e8f0';
}
.icon-lab-tests-explorer-lg:before {
  content: '\e8f1';
}
.icon-insights:before {
  content: '\e8f2';
}
.icon-lab-tests-explorer:before {
  content: '\e8f3';
}
.icon-line-chart-sm:before {
  content: '\e8f4';
}
.icon-list-and-explorer:before {
  content: '\e8f5';
}
.icon-medications-explorer-lg:before {
  content: '\e8f6';
}
.icon-medications-explorer:before {
  content: '\e8f7';
}
.icon-minus:before {
  content: '\e8f8';
}
.icon-mortality-rates:before {
  content: '\e8f9';
}
.icon-mortality-rates-lg:before {
  content: '\e8fa';
}
.icon-noreports:before {
  content: '\e8fb';
}
.icon-nogroups:before {
  content: '\e8fc';
}
.icon-neg-stack-sm:before {
  content: '\e8fd';
}
.icon-received-group:before {
  content: '\e8fe';
}
.icon-procedures-explorer-lg:before {
  content: '\e8ff';
}
.icon-rocket:before {
  content: '\e900';
}
.icon-run:before {
  content: '\e901';
}
.icon-reports:before {
  content: '\e902';
}
.icon-sent-group:before {
  content: '\e903';
}
.icon-schedule-alt:before {
  content: '\e904';
}
.icon-schedule:before {
  content: '\e905';
}
.icon-sequence:before {
  content: '\e906';
}
.icon-exports:before {
  content: '\e907';
}
.icon-procedures-explorer:before {
  content: '\e908';
}
.icon-star-empty:before {
  content: '\e909';
}
.icon-star-fill:before {
  content: '\e90a';
}
.icon-star-fill-border:before {
  content: '\e90b';
}
.icon-subgroup:before {
  content: '\e90c';
}
.icon-summary:before {
  content: '\e90d';
}
.icon-survival-rates:before {
  content: '\e90e';
}
.icon-survival-rates-lg:before {
  content: '\e90f';
}
.icon-user-icon:before {
  content: '\e910';
}
.icon-visits-revisited-lg:before {
  content: '\e911';
}
.icon-view:before {
  content: '\e912';
}
.icon-x-axis:before {
  content: '\e913';
}
.icon-warning:before {
  content: '\e914';
}
.icon-visits-revisited:before {
  content: '\e915';
}
.icon-view-as:before {
  content: '\e916';
}
.icon-reset:before {
  content: '\e917';
}
.icon-notification:before {
  content: '\e918';
}
.icon-number-format:before {
  content: '\e919';
}
.icon-pencil:before {
  content: '\e91a';
}
.icon-pie-chart-sm:before {
  content: '\e91b';
}
.icon-plus:before {
  content: '\e91c';
}
.icon-printer:before {
  content: '\e91d';
}
.icon-spider-chart-sm:before {
  content: '\e91e';
}
.icon-details:before {
  content: '\e91f';
}
.icon-foot:before {
  content: '\e920';
}
.icon-progress:before {
  content: '\e921';
}
.icon-hand:before {
  content: '\e922';
}
.icon-heart:before {
  content: '\e923';
}
.icon-workflow:before {
  content: '\e924';
}
.icon-data-entry:before {
  content: '\e925';
}
.icon-health-and-fitness:before {
  content: '\e926';
}
.icon-analytics:before {
  content: '\e927';
}
.icon-count-patients-data:before {
  content: '\e928';
}
.icon-inpatient:before {
  content: '\e929';
}
.icon-indent-left:before {
  content: '\e92a';
}
.icon-indent-right:before {
  content: '\e92b';
}
.icon-complete-not-verified:before {
  content: '\e92c';
}
.icon-complete-verified:before {
  content: '\e92d';
}
.icon-registry-circle:before {
  content: '\e92e';
}
.icon-registry-multi:before {
  content: '\e92f';
}
.icon-incomplete:before {
  content: '\e930';
}
.icon-adults:before {
  content: '\e931';
}
.icon-assessments:before {
  content: '\e932';
}
.icon-carers:before {
  content: '\e933';
}
.icon-outpatient:before {
  content: '\e934';
}
.icon-close-eye:before {
  content: '\e935';
}
.icon-nose:before {
  content: '\e936';
}
.icon-ras:before {
  content: '\e937';
}
.icon-oxygen:before {
  content: '\e938';
}
.icon-poison:before {
  content: '\e939';
}
.icon-back-bracket:before {
  content: '\e93a';
}
.icon-front-bracket:before {
  content: '\e93b';
}
.icon-prescription:before {
  content: '\e93c';
}
.icon-my-data-overview:before {
  content: '\e93d';
}
.icon-equals:before {
  content: '\e93e';
}
.icon-mediator-edw:before {
  content: '\e93f';
}
.icon-error:before {
  content: '\e940';
}
.icon-mediator-request:before {
  content: '\e941';
}
.icon-definition:before {
  content: '\e942';
}
.icon-venn:before {
  content: '\e943';
}
.icon-my-data-preferences:before {
  content: '\e944';
}
.icon-mediator-success:before {
  content: '\e945';
}
.icon-alcohol:before {
  content: '\e946';
}
.icon-abacus:before {
  content: '\e947';
}
.icon-under-18:before {
  content: '\e948';
}
.icon-couple---heterosexual:before {
  content: '\e949';
}
.icon-couple---homosexual---female:before {
  content: '\e94a';
}
.icon-couple---homosexual---male:before {
  content: '\e94b';
}
.icon-elderly---badge:before {
  content: '\e94c';
}
.icon-figure---female---young:before {
  content: '\e94d';
}
.icon-figure---female---elderly:before {
  content: '\e94e';
}
.icon-figure---female:before {
  content: '\e94f';
}
.icon-figure---male---elderly:before {
  content: '\e950';
}
.icon-figure---male---young:before {
  content: '\e951';
}
.icon-figure:before {
  content: '\e952';
}
.icon-figure---male:before {
  content: '\e953';
}
.icon-greater-than:before {
  content: '\e954';
}
.icon-greater-than-or-equal-to:before {
  content: '\e955';
}
.icon-less-than:before {
  content: '\e956';
}
.icon-less-than-or-equal-to:before {
  content: '\e957';
}
.icon-multiply:before {
  content: '\e958';
}
.icon-not:before {
  content: '\e959';
}
.icon-not-equal-to:before {
  content: '\e95a';
}
.icon-number:before {
  content: '\e95b';
}
.icon-n:before {
  content: '\e95c';
}
.icon-calculator-alt:before,
.icon-operator-selector:before {
  content: '\e95d';
}
.icon-or:before {
  content: '\e95e';
}
.icon-power:before {
  content: '\e95f';
}
.icon-list-question:before {
  content: '\e960';
}
.icon-round-up-down:before {
  content: '\e961';
}
.icon-square-root:before {
  content: '\e962';
}
.icon-subtraction:before {
  content: '\e963';
}
.icon-sum:before {
  content: '\e964';
}
.icon-patient-level-data:before {
  content: '\e965';
}
.icon-encounter-level-data:before {
  content: '\e966';
}
.icon-files:before {
  content: '\e967';
}
.icon-tour:before {
  content: '\e968';
}
.icon-emo-happy:before {
  content: '\e969';
}
.icon-emo-unhappy:before {
  content: '\e96a';
}
.icon-file-csv:before {
  content: '\e96e';
}
.icon-upload:before {
  content: '\e96f';
}
.icon-draggable:before {
  content: '\e970';
}
.icon-knowledge-base:before {
  content: '\e971';
}
.icon-cloud:before {
  content: '\e972';
}
.icon-excel:before {
  content: '\e973';
}
.icon-my-data-data:before {
  content: '\e974';
}
.icon-my-data-encounters:before {
  content: '\e975';
}
.icon-my-data-data-shield:before {
  content: '\e976';
}
.icon-my-data-encounters-shield:before {
  content: '\e977';
}
.icon-my-data-patients:before {
  content: '\e978';
}
.icon-my-data-patients-shield:before {
  content: '\e979';
}
.icon-my-data-sheild-background:before {
  content: '\e97a';
}
.icon-visibility:before {
  content: '\e97b';
}
.icon-my-data-preferences-shield:before {
  content: '\e97c';
}
.icon-success:before {
  content: '\e97d';
}
.icon-sad-face:before {
  content: '\e97e';
}
.icon-subscription-team:before {
  content: '\e97f';
}
.icon-count-patient-by-speciality:before {
  content: '\e981';
}
.icon-uk-map:before {
  content: '\e982';
}
.icon-us-map:before {
  content: '\e983';
}
.icon-info:before {
  content: '\e984';
}
.icon-quality:before {
  content: '\e985';
}
.icon-standard-deviation:before {
  content: '\e9b1';
}
.icon-minimum:before {
  content: '\e9b2';
}
.icon-mean:before {
  content: '\e9b3';
}
.icon-maximum:before {
  content: '\e9b4';
}
.icon-data-view:before {
  content: '\e9b5';
}
.icon-count-latest-indexed-list-answer:before {
  content: '\eb00';
}
.icon-pause:before {
  content: '\e986';
}
.icon-link:before {
  content: '\e987';
}
.icon-play:before {
  content: '\e988';
}
.icon-plugin:before {
  content: '\e989';
}
.icon-areas:before {
  content: '\e98a';
}
.icon-model:before {
  content: '\e98b';
}
.activity-details {
  margin-bottom: 0.5em;
}
.current-involvement-details .panel {
  margin-bottom: 10px;
}
.current-involvement-details .label {
  margin-right: 0.5em;
  position: relative;
  top: -1px;
}
.current-involvement-details .end-date {
  margin-left: 1em;
}
.inovolvement-stats {
  font-size: 1rem;
  line-height: 1.267rem;
  display: inline-block;
  margin-right: 1em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.inovolvement-stats .badge {
  position: relative;
  top: -2px;
}
.inovolvement-stats > .involvement-stats {
  margin-bottom: 0;
  padding: 0.25em 0;
}
.destination-involvement-details {
  margin-top: 22px;
}
.destination-involvement-details .panel {
  margin-bottom: 0;
}
.destination-involvement-details > .involvement-stats .badge {
  margin-top: -2px;
}
.destination-details .form-group:last-of-type {
  margin-bottom: 0;
}
.label {
  font-size: 11px;
  font-weight: 400;
  padding: 0.2em 0.6em;
  font-family: FSMeWeb !important;
}
.label.label-abandoned {
  background-color: red;
  color: #fff;
}
.label.label-open {
  background-color: #111;
  color: #fff;
}
.label.label-not-started {
  background-color: #d1d1d1;
  color: #0f0f0f;
}
.label.label-in-progress {
  background-color: #ffc796;
  color: #a33600;
}
.label.label-closed {
  color: #fff;
  font-family: FSMeWeb !important;
  background-color: #333232;
}
.label.label-warning {
  background-color: #fca744;
  color: #111;
}
.label.label-default {
  background-color: #707070;
  color: #fff;
}
.label.label-tint {
  background: #3d4d84;
  color: #fff;
}
.label.label-coc {
  background: #fafafa;
  color: #707070;
}
.label.label-closed {
  background: #4c4c4c;
}
.label.label-draft {
  background: #3a8dd6;
  color: #fff;
}
.label.label-field-type {
  display: inline-block;
  height: 24px;
  padding: 0;
  width: 24px;
}
.label.label-field-type [class*="cp-icon-"] {
  position: relative;
  top: 3px;
}
.label.label-search {
  background: #fafafa;
  color: #707070;
  font-size: 1em;
  font-weight: 400;
  margin-right: 0.25em;
}
.label.label-read-only {
  background-color: #0a8dd8;
  color: #fff;
}
.label.label-care-plan {
  background-color: #fafafa;
  color: #2f58f2;
  font-size: 1em;
  margin: 0px 8px;
  padding: 0 0.3em 0 0.2em;
  top: 1px !important;
}
.label.label-care-plan .text {
  font-size: 75%;
  position: relative;
  top: -2px;
}
.label.large-label {
  line-height: 42px;
  font-size: 1.13333333rem;
}
.label.small-label {
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.label.small-label.has-error {
  color: #cf5d5c !important;
}
.label.label-outline {
  background-color: #fff;
  color: #707070;
  border: 1px solid #707070;
}
.label.label-from:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 7px 7px;
  border-color: transparent transparent transparent #707070;
  position: absolute;
  top: 2px;
  right: -7px;
  z-index: 1;
}
.label.label-overdue {
  background-color: #DE3700;
  color: #fff;
}
.table tbody tr td .label {
  position: relative;
  top: -1px;
}
label {
  font-weight: 600;
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
}
.list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list > .list-item {
  padding: 5px 0;
}
.list > .list-item p {
  margin: 0;
}
.list.list-unstyled .list-item {
  padding: 0;
}
.list.list-unstyled .list-item button:not(.btn-primary) {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  padding: 6px 12px;
}
.list.list-unstyled .list-item button:not(.btn-primary):hover,
.list.list-unstyled .list-item button:not(.btn-primary):focus {
  background-color: #ececec;
  color: #555555;
  outline: none;
}
.list.list-unstyled .list-item > div {
  padding: 6px 12px;
}
.list .divider {
  background-color: #ececec;
  height: 1px;
  margin: 10px -10px;
  overflow: hidden;
}
.list .selected-item {
  border-radius: 50%;
  background: #3d4d84;
  float: left;
  margin: 4px 0 0 -18px;
  position: relative;
  width: 10px;
  height: 10px;
}
.list .selected-item.list-border > .list-item {
  border-bottom: solid 1px #ececec;
  padding-top: 7px;
  padding-bottom: 7px;
}
.list.list-link > .list-item {
  padding: 0;
}
.list.list-link > .list-item > .link-item,
.list.list-link > .list-item .link-item {
  color: #707070;
  cursor: pointer;
  display: block;
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;
  padding: 5px 20px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list.list-link > .list-item > .link-item:focus,
.list.list-link > .list-item .link-item:focus {
  background: #fff;
}
.list.list-link > .list-item > .link-item:hover,
.list.list-link > .list-item .link-item:hover {
  background: #ffffff;
}
.list.list-link > .list-item.active > .link-item {
  background: #fafafa;
  color: #111;
  cursor: auto;
}
.list.list-sm {
  font-size: 1rem;
  line-height: 1.267rem;
}
.list.list-sm > .list-item {
  padding-top: 2px;
  padding-bottom: 2px;
}
.list.list-sm.list-border > .list-item {
  padding-top: 4px;
  padding-bottom: 4px;
}
.list.list-sm.list-link > .list-item {
  padding: 0;
}
.list.list-sm.list-link > .list-item > .link-item {
  padding-top: 2px;
  padding-bottom: 2px;
}
.list.list-sm.list-link > .list-item .current {
  font-weight: 700;
}
.list.list-lg > .list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.list.list-lg.list-collapsable > .list-item [class^="cp-icon-chevron"] {
  top: 12px;
}
.list.list-collapsable > .list-item {
  padding-right: 25px;
  position: relative;
}
.list.list-collapsable > .list-item [class^="cp-icon-chevron"] {
  position: absolute;
  right: 0;
  top: 3px;
}
.list.list-key-value-pair li {
  display: flex;
}
.list.list-key-value-pair li .key {
  color: #767676;
}
.list.list-key-value-pair li .key,
.list.list-key-value-pair li .value {
  flex-basis: 50%;
  flex-grow: 0;
}
.list.list-key-value-pair.stacked li {
  display: block;
  margin-bottom: 4px;
}
.list.list-key-value-pair.stacked li .key,
.list.list-key-value-pair.stacked li .value {
  display: block;
  width: 100%;
}
.list .additional-information {
  font-size: 1rem;
  line-height: 1.267rem;
  margin: 10px 0;
}
.list.list-border > .list-item {
  border-bottom: solid 1px #ececec;
}
.list.involvement-numbers > li {
  border-bottom: solid 1px #ececec;
  padding: 0;
}
.list.involvement-numbers > li:first-child {
  padding-top: 0;
}
.list.involvement-numbers > li:last-child {
  border: none;
  padding-bottom: 0;
}
.list.involvement-numbers > li.activities .label.label-open {
  background-color: #3d4d84;
}
.list.involvement-numbers > li.care-plans .label.label-current {
  background-color: #3d4d84;
}
.list.involvement-numbers > li.contacts .label.label-current {
  background-color: #3d4d84;
}
.list.involvement-numbers > li.diagnoses .label.label-current {
  background-color: #3d4d84;
}
.list.involvement-numbers > li .no-data {
  padding-top: 2em;
  padding-bottom: 3em;
}
.list.involvement-numbers .involvement-details {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  padding: 0.75em 1em 0.75em 0.25em;
}
.list.involvement-numbers .involvement-details:hover {
  background-color: #fafafa;
}
.list.involvement-numbers .badge {
  border-radius: 38px;
  display: inline-block;
  font-size: 1rem;
  min-height: 38px;
  margin-right: 8px;
  margin-left: 4px;
  padding: 11px;
  text-align: center;
  min-width: 38px;
}
.list.involvement-numbers .details {
  -webkit-align-self: auto;
  -webkit-flex: 1 1 auto;
  align-self: auto;
  flex: 1 1 auto;
  order: 0;
}
.list.involvement-numbers .text-emphasis {
  margin-right: 0.375em;
}
.list.involvement-numbers .cp-icon-chevron-up {
  transition: all 0.2s linear;
}
.list.involvement-numbers .collapsed .cp-icon-chevron-up {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.list.involvement-numbers .table.table-condensed {
  margin: 0.25em auto 0.5em;
  width: 99%;
}
.list.involvement-numbers .pagination {
  margin-bottom: 0.75em;
}
.list.form-tracker {
  margin: 1.5em 0 0;
  padding: 0;
}
.list.answer-history {
  padding: 0;
}
.list.answer-history .list-item {
  border: none;
  padding: 0 20px 35px;
}
.list.answer-history .list-item .note {
  margin: 10px 0;
}
.list.answer-history .date-qualified {
  border-bottom: solid 1px #ececec;
  margin: 0 -20px 10px;
  padding: 0 0 3px;
}
.list.answer-history .date-qualified .more-link {
  cursor: pointer;
}
.list.answer-history .date-qualified .more-link:before {
  content: "less";
}
.list.answer-history .date-qualified .more-link.collapsed:before {
  content: "more...";
}
.list.answer-history .date-qualified > .list-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.list.answer-history .date-qualified > .list-item .date-qualified {
  margin-bottom: 5px;
}
.list.answer-history .date-qualified > .list-item .cp-icon-chevron-up {
  top: 25px;
}
.list.answer-history .date-qualified > .list-item .cp-icon-chevron-up::before {
  content: "hide";
}
.list.answer-history .contact-record-title {
  margin-left: -20px;
  margin-bottom: 5px;
}
.list.answer-history .form-info {
  font-size: 1rem;
  line-height: 1.267rem;
  margin: -5px 0 5px;
}
.list.groupcal .list-item {
  padding: 0 0 0 10px;
  position: relative;
}
.list.groupcal .list-item:last-of-type {
  padding-bottom: 0.5em;
}
.list.groupcal .shift-note-container {
  font-size: 1rem;
  line-height: 1.267rem;
  border-left: solid 1px #ececec;
  padding-left: 1em;
  padding-right: 0.5em;
}
.list.groupcal .shift-note-date {
  color: #616161;
}
.list.groupcal .shift-note-date:before {
  background: #3d4d84;
  border-radius: 50%;
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 5px;
}
.list.groupcal .service-user {
  font-size: 17px;
  margin-left: -10px;
}
.list.groupcal .shift-note {
  margin-top: 0.5em;
  margin-bottom: 2em;
}
.list.shift-notes .list-item {
  padding: 0 0 0 10px;
  position: relative;
}
.list.shift-notes .list-item:last-of-type {
  padding-bottom: 0.5em;
}
.list.shift-notes .list-item a {
  color: #3d4d84;
}
.list.shift-notes .shift-note-container {
  font-size: 1rem;
  line-height: 1.267rem;
  border-left: solid 1px #ececec;
  padding-left: 1em;
  padding-right: 0.5em;
}
.list.shift-notes .shift-note-date {
  color: #616161;
}
.list.shift-notes .shift-note-date:before {
  background: #3d4d84;
  border-radius: 50%;
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 5px;
}
.list.shift-notes .service-user {
  font-size: 17px;
  margin-left: -10px;
}
.list.shift-notes .shift-note {
  margin-top: 0.5em;
  margin-bottom: 2em;
}
.list.contact-record-sidebar .sidebar-heading {
  background-color: #fafafa;
  margin: 0 -20px;
  padding: 0.375em 20px 0.3125em;
}
.list.contact-record-sidebar .list-item {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.list.contact-record-sidebar .list-item:last-of-type {
  padding-bottom: 1.5em;
}
.list.contact-record-sidebar .list-item-btn {
  padding-top: 0;
}
.list.contact-record-sidebar:first-of-type .sidebar-heading {
  margin-top: 20px;
}
.sidebar-additional-data {
  margin-bottom: 10px;
}
.sidebar-additional-data .sidebar-heading {
  background-color: #fafafa;
  margin: 0 -15px;
  padding: 0.375em 10px 0.3125em;
  font-size: 1em;
}
.sidebar-additional-data .list-additional-data-cards {
  padding: 0 10px 10px;
  background-color: #fafafa;
  margin: 0 -15px;
  max-height: 132px;
  overflow-y: auto;
}
.sidebar-additional-data .list-additional-data-cards > li {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}
.sidebar-additional-data .list-additional-data-cards > li:last-child {
  margin-bottom: 0;
}
.sidebar-additional-data .list-additional-data-cards > li ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sidebar-additional-data .list-additional-data-cards > li ul li {
  padding: 0;
}
.sk-spinner.sk-spinner-double-bounce.sk-spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
}
.sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #3d4d84;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
  animation: sk-doubleBounce 2s infinite ease-in-out;
}
.sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-spinner .title {
  clear: both;
  margin: 0 0 0 -80px;
  position: relative;
  top: 40px;
  text-align: center;
  white-space: nowrap;
  width: 200px;
}
.sk-spinner .title.sm-container {
  margin: 0 0 0 -15px !important;
  width: auto !important;
}
.sk-spinner .loading-message {
  background-color: #fff;
  clear: both;
  margin: 0 0 0 -80px;
  position: relative;
  top: 40px;
  text-align: center;
  white-space: nowrap;
  width: 200px;
}
.sk-spinner .loading-message.sm-container {
  margin: 0 0 0 -15px !important;
  width: auto !important;
}
.sk-spinner.spinner-sm.sk-spinner-double-bounce.sk-spinner {
  width: 24px;
  height: 24px;
}
.sk-spinner.sk-spinner.spinner-inline {
  margin: 0;
}
.sk-spinner.sk-spinner.spinner-inline .loading-message {
  left: 0;
  margin: 0;
  padding: 2px 0 0 28px;
  position: static;
  top: 0;
  text-align: left;
}
.spinner-xs .sk-spinner-double-bounce.sk-spinner {
  width: 16px;
  height: 16px;
}
.page-spinner .sk-spinner-double-bounce.sk-spinner {
  margin-top: 5em;
}
@-webkit-keyframes sk-doubleBounce {
  0%,
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-doubleBounce {
  0%,
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.modal.in {
  padding-right: 17px;
}
.modal.v-modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal.v-modal .modal-dialog .modal-content .modal-title {
  color: #000 !important;
  font-family: "Sofia Pro", helvetica, arial, sans-serif !important;
  font-size: 20px !important;
}
.modal.v-modal .modal-dialog .modal-content .modal-title small {
  font-family: "FSMeWeb", helvetica, arial, sans-serif !important;
  font-size: 14px;
  color: #000 !important;
}
.modal.v-modal .modal-dialog .modal-content .modal-body {
  max-height: 65vh;
  overflow-y: auto;
}
.modal .modal-dialog {
  margin-top: 80px;
}
.modal .modal-alerts table tbody td.pin {
  padding-left: 0;
  padding-right: 0;
  width: 10px;
}
.modal .modal-alerts table tbody .alert-bullet {
  background: #fff;
  border-radius: 50%;
  border: solid 1px #707070;
  margin: 4px 0 0;
  width: 10px;
  height: 10px;
}
.modal .modal-alerts table tbody .alert-bullet.pinned {
  border-color: #3d4d84;
}
.modal .modal-alerts table tbody .alert-answer {
  color: #616161;
  font-size: 1rem;
  line-height: 1.267rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  margin-bottom: 0;
}
.modal .modal-content {
  border: none;
  box-shadow: none;
  border-radius: 12px;
}
.modal .modal-content .modal-header {
  background-color: #ececec;
  border-bottom: none;
  border-radius: 12px 12px 0 0;
  padding: 15px 25px;
}
.modal .modal-content .modal-body,
.modal .modal-content .modal-footer {
  border: none;
  padding: 25px;
}
.modal .modal-content .modal-body .history-filter {
  padding-bottom: 0.75em;
}
.modal .modal-content .modal-body .history-filter:before,
.modal .modal-content .modal-body .history-filter:after {
  content: " ";
  display: table;
}
.modal .modal-content .modal-body .history-filter:after {
  clear: both;
}
.modal .modal-content .modal-body .history-filter .validation-block {
  margin: 0;
}
.modal .modal-content .modal-footer {
  padding-top: 0;
}
.modal .modal-content .modal-title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #3d4d84;
  font-size: 17px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.modal .modal-content .modal-title .text-muted {
  font-weight: 400;
}
.modal .modal-delete .modal-content .modal-title {
  color: #eb1515;
}
.modal .modal-delete .modal-content .btn-primary {
  background-color: #eb1515;
  border-color: #eb1515;
}
.modal .modal-delete .modal-content .btn-primary:hover,
.modal .modal-delete .modal-content .btn-primary:focus {
  background-color: #ef4444;
}
.modal .modal-sensitive-data .table {
  border: 1px solid #ddd;
}
.modal .modal-sensitive-data .table tbody .remove-permission-cell {
  vertical-align: middle;
  width: 32px;
}
.modal .modal-sensitive-data .table tbody tr:only-child .remove-permission-cell {
  width: 0;
}
.modal .modal-sensitive-data .table tbody tr:not(:first-child) td {
  padding-top: 0;
}
.modal .modal-sensitive-data .table tbody .select2-search {
  display: none !important;
}
.modal .modal-sensitive-data .table tfoot td {
  padding-top: 0;
}
.modal .modal-sensitive-data .table tfoot .select2 {
  width: 52% !important;
  float: left;
}
.modal .modal-sensitive-data .table tfoot .btn {
  height: 32px;
  line-height: 32px;
}
.modal .modal-sensitive-data .table tfoot tr td,
.modal .modal-sensitive-data .table tbody tr td {
  border: none;
}
.modal .modal-sensitive-data .table tfoot td .cp-icon-cross,
.modal .modal-sensitive-data .table tbody td .cp-icon-cross {
  margin-left: -4px;
}
.modal .imagepreview img {
  width: 100%;
}
.modal .cp-icon-cross {
  cursor: pointer;
  margin-top: 3px;
  margin-right: -4px;
  opacity: 1;
}
.modal .cp-icon-cross:focus,
.modal .cp-icon-cross:hover {
  color: #bf201f;
}
.modal .modal-footer {
  text-align: left;
}
.modal h3:first-of-type {
  margin-top: 0;
}
.modal.modal-full-screen {
  padding: 0 !important;
  z-index: 1060;
}
.modal.modal-full-screen .modal-dialog {
  margin: 0;
  width: 100%;
  height: 100%;
}
.modal.modal-full-screen .modal-dialog .modal-content {
  border-radius: 0;
  height: 100%;
}
.modal.modal-full-screen .modal-dialog .modal-content .modal-header,
.modal.modal-full-screen .modal-dialog .modal-content .modal-body {
  background: #fff;
  border-radius: 0;
}
.modal.modal-full-screen .modal-dialog .modal-content h1 {
  margin: 4.5% 0 0.75em;
}
.modal.modal-full-screen .modal-dialog .modal-content p {
  margin: 1em 0 2em;
}
.modal.modal-error {
  background: #fff;
  padding: 30px !important;
  overflow: auto !important;
}
.modal.modal-error .modal-dialog .modal-content {
  background: #fafafa;
}
.modal.modal-error .modal-dialog .modal-content .modal-body {
  background: #fafafa;
  text-align: center;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .return-link {
  margin-bottom: 10%;
}
@media only screen and (min-width: 768px) {
  .modal.modal-error .modal-dialog .modal-content .modal-body .return-link {
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media only screen and (min-width: 992px) {
  .modal.modal-error .modal-dialog .modal-content .modal-body .return-link {
    margin-top: 0;
    margin-bottom: 10%;
  }
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-show-error,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page {
  font-weight: 600;
  padding: 0;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page {
  color: #707070;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp [class^="cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page [class^="cp-icon-"] {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:focus,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:focus,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:hover,
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:hover {
  color: #3d4d84;
  text-decoration: none;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:focus [class^="cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:focus [class^="cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:hover [class^="cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:hover [class^="cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:focus [class*=" cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:focus [class*=" cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp:hover [class*=" cp-icon-"],
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-reload-page:hover [class*=" cp-icon-"] {
  color: #3d4d84;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-return-to-cp [class^="cp-icon-"] {
  top: -1px;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-show-error {
  color: #3d4d84;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-show-error.collapsed:before {
  content: "Show error";
  display: block;
}
.modal.modal-error .modal-dialog .modal-content .modal-body .btn-show-error:before {
  content: "Hide error";
}
.modal.modal-error .modal-dialog .modal-content .modal-body p {
  margin: 1em 0;
}
.modal.modal-error .modal-dialog .modal-content .modal-body h2 {
  color: #111 !important;
  line-height: 34px;
}
.modal.modal-error .modal-dialog .modal-content .modal-body pre {
  background: #fff;
  text-align: left;
}
@media only screen and (min-width: 992px) {
  .modal.modal-error .modal-dialog .modal-content .modal-body {
    text-align: left;
  }
  .modal.modal-error .modal-dialog .modal-content .modal-body .col-md-4 {
    text-align: center;
  }
}
@media only screen and (min-width: 1200px) {
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content,
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content .btn,
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content .btn-lg {
    font-size: calc(13px*1.3);
    line-height: calc(21px*1.3);
  }
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content h2,
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content .btn h2,
  .modal.modal-error .modal-dialog .modal-content .modal-body .error-content .btn-lg h2 {
    font-size: calc(21px*1.3);
    line-height: calc(32px*1.3);
  }
}
.modal-profile-picture {
  text-align: center;
}
.modal-profile-picture.modal-dialog {
  display: table;
  width: auto;
}
.modal-profile-picture .cp-icon-cross {
  color: #ececec;
  opacity: 1;
  position: absolute;
  right: -12px;
  text-shadow: 0 1px 0 #000;
  top: -14px;
  z-index: 10;
}
.modal-profile-picture .modal-content {
  background-color: transparent;
  display: inline-block;
}
.modal-profile-picture .modal-content .modal-body {
  padding: 0;
}
.modal-profile-picture .modal-content img {
  border-radius: 8px;
  -webkit-filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.6));
  max-width: 740px;
  width: 100%;
}
.modal-dialog.modal-updated-version {
  text-align: center;
}
.modal-dialog.modal-updated-version p {
  margin: auto;
  max-width: 500px;
}
.modal-dialog.modal-updated-version .modal-footer {
  padding-bottom: 35px;
  text-align: center;
}
.blockUI.blockMsg {
  border-radius: 6px !important;
  background-color: transparent !important;
  border: none !important;
  color: #111 !important;
  left: 45% !important;
  opacity: 1 !important;
  padding: 0 !important;
  width: auto !important;
  z-index: 200 !important;
}
.blockUI.blockOverlay {
  background: #fff !important;
  opacity: 0.75 !important;
  z-index: 150 !important;
}
.activitiy-modal-fieldset {
  padding-bottom: 20px;
}
.activitiy-modal-fieldset .form-group:last-of-type {
  margin-bottom: 0;
}
.modal.modal-answer-error .modal-title {
  color: #bf201f !important;
}
.modal.modal-answer-error .btn-primary {
  background-color: #bf201f !important;
  margin-top: -22px;
  border-color: #bf201f !important;
}
.knowledge-base-modal h2 {
  font-size: 3em;
  color: #707070;
  margin: 20px 0 10px;
}
.knowledge-base-modal .lead {
  margin: 20px 60px 20px;
  padding: 20px;
  background-color: #fafafa;
  clear: both;
  display: block;
  line-height: 1.2em;
  border-radius: 8px;
}
.knowledge-base-modal .lead b {
  font-weight: 600;
  display: block;
}
.export-dialog-options-container {
  margin: 0 20px;
  max-height: 400px;
  border: 2px solid #707070;
  margin-bottom: 20px;
}
.export-dialog-options-container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.export-dialog-options-container ul li {
  position: relative;
}
.export-dialog-options-container ul li a {
  padding: 8px 40px 8px 8px;
  position: relative;
  display: block;
}
.export-dialog-options-container ul li a.active {
  background-color: #fafafa;
}
.export-dialog-options-container ul li a.active:after {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  content: "\e865";
  font-family: "ImosphereIcons";
  line-height: 32px;
  font-size: 16px;
  color: #fff;
  background-color: #42ce56;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 12px;
  top: 12px;
  text-align: center;
}
.export-dialog-options-container .form-group-container {
  padding: 15px;
}
@media only screen and (min-width: 992px) {
  .modal-md {
    width: 700px;
  }
}
@media only screen and (min-width: 1366px) {
  .modal-xl {
    width: 1100px;
  }
}
.nav.modal-nav {
  list-style: none;
  border-bottom: 2px #ececec solid;
  margin-bottom: 10px;
}
.nav.modal-nav .nav-item {
  padding: 0;
  display: inline-block;
  color: #1a1e23;
  cursor: pointer;
  margin: 0 0 -2px 0;
  min-width: 40px;
}
.nav.modal-nav .nav-item a {
  padding: 5px 12px;
}
.nav.modal-nav .nav-item.active {
  border-bottom: 2px solid #1A59A2;
}
.nav.modal-nav .nav-item:hover {
  border-bottom: 2px solid #ff54c0;
}
.nav.nav-tabs.calculation-response-tabs {
  margin: 0 -15px;
  padding: 0 15px;
  border-bottom: 3px solid #ececec;
}
.nav.nav-tabs.calculation-response-tabs li {
  margin: 0 0 -3px 0;
}
.nav.nav-tabs.calculation-response-tabs li a {
  border: none;
  font-family: "Sofia Pro";
  font-size: 1.1em;
}
.nav.nav-tabs.calculation-response-tabs li a:hover {
  background-color: transparent;
  border-bottom: 3px solid #ff54c0;
}
.nav.nav-tabs.calculation-response-tabs li.active a {
  border-bottom: 3px solid #3d4d84;
  color: #111;
}
.nav.nav-tabs.nav-tabs-primary {
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 3px solid #ececec;
}
.nav.nav-tabs.nav-tabs-primary li {
  margin: 0 0 -3px 0;
}
.nav.nav-tabs.nav-tabs-primary li a {
  border: none;
  font-family: "Sofia Pro";
  font-size: 1.3em;
}
.nav.nav-tabs.nav-tabs-primary li a:hover {
  background-color: transparent;
  border-bottom: 3px solid #ff54c0;
}
.nav.nav-tabs.nav-tabs-primary li.active a {
  border-bottom: 3px solid #3d4d84;
  color: #111;
}
.nav.nav-tabs.nav-tabs-secondary {
  margin-top: 0;
}
.nav.nav-tabs.nav-tabs-secondary li a {
  font-family: "Sofia Pro";
  font-size: 2em;
  display: flex;
  align-items: center;
}
.nav.nav-tabs.nav-tabs-secondary li a:hover {
  background-color: transparent;
}
.nav.nav-tabs.nav-tabs-secondary li a span.imo {
  font-size: 2em;
  margin-left: 8px;
}
.nav.nav-tabs.nav-tabs-secondary + .tab-content {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.navbar {
  user-select: none;
  background: #343c46;
  border: none;
  border-radius: 0;
  font-size: 1.15em;
  height: 60px;
  min-height: 60px;
  margin: 0;
  font-family: "FsMeWeb", Arial, sans-serif;
  /* New Management Nav Bar */
}
.navbar.hide-nav-bar {
  opacity: 0;
}
.navbar .management-navbar-header {
  margin: 7px 8px;
  color: #fff;
  display: inline-block;
}
.navbar .management-navbar-header .header-item {
  display: block;
  float: left;
}
.navbar .management-navbar-header .header-item.logo {
  font-size: 40px;
  margin: 4px 8px 0 0;
}
.navbar .management-navbar-header .header-item.product-details {
  display: flex;
  flex-direction: column;
}
.navbar .management-navbar-header .header-item.product-details .app-name {
  font-weight: 600;
  font-size: 1.8em;
  font-family: sofia pro;
  line-height: 0.9em;
  margin-bottom: 2px;
  text-transform: lowercase;
}
.navbar .management-navbar-header .header-item.product-details .version {
  font-size: 0.8em;
}
.navbar.nav-management .navbar-brand {
  color: #fff;
}
.navbar.nav-management .dropdown-menu a {
  color: #fff !important;
}
.navbar.nav-management.navbar.navbar-default,
.navbar.nav-management.navbar.navbar-default,
.navbar.nav-management.navbar-nav,
.navbar.nav-management .open,
.navbar.nav-management .dropdown-menu {
  background-color: #3d4d84 !important;
}
.navbar.nav-management.navbar.navbar-default .navbar.nav-management.navbar-nav li > a:hover,
.navbar.nav-management.navbar.navbar-default .navbar-nav > li:hover > a,
.navbar.nav-management.navbar.navbar-default .navbar-nav li.dropdown > a:hover,
.navbar.nav-management.navbar.navbar-default .navbar-nav li.open .dropdown-menu,
.navbar.nav-management.navbar.navbar-default .navbar-nav li.open .dropdown-menu > li > a:focus,
.navbar.nav-management.navbar.navbar-default .navbar-nav li > a:focus,
.navbar.nav-management.navbar.navbar-default .navbar-nav li.dropdown > a:focus,
.navbar.nav-management.navbar.navbar-default .navbar-nav > .open > a,
.navbar.nav-management.navbar.navbar-default .navbar-nav > .open > a:hover,
.navbar.nav-management.navbar.navbar-default .navbar-nav > .open > a:focus {
  background-color: #374576;
}
.navbar.nav-management.navbar.navbar-default .navbar-nav .open .dropdown-menu li > a:hover,
.navbar.nav-management.navbar.navbar-default .navbar-nav li.open .dropdown-menu li > a:focus:hover {
  background-color: #303d68;
}
.navbar .nav.navbar-right {
  margin-right: 0px;
}
.navbar .nav > li.nav-environment {
  background-color: #3d4d84;
}
.navbar .nav > li.nav-environment > span {
  color: rgba(255, 255, 255, 0.5);
  line-height: 57px;
  padding: 0 20px 0 20px;
  height: 60px;
  font-family: "Sofia Pro", Arial, sans-serif;
  font-weight: 600;
}
.navbar .nav > li.nav-environment > span.environment-badge {
  color: #fff;
  line-height: 60px;
}
.navbar .nav > li > a {
  color: #a1a5a9;
  line-height: 57px;
  padding: 0 20px 0 20px;
  height: 60px;
  font-family: "Sofia Pro", Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16.1px;
  text-rendering: optimizeLegibility;
}
.navbar .nav > li > a .imo {
  font-size: 22px;
  position: relative;
  top: 2px;
}
.navbar .nav > li > a:hover,
.navbar .nav > li > a:focus {
  color: #fff;
  background: transparent;
}
.navbar .nav > li > a:hover .argon-icon,
.navbar .nav > li > a:focus .argon-icon {
  opacity: 1;
}
.navbar .nav > li > a .fa {
  font-size: 22px;
}
.navbar .nav > li > a#home .imo {
  top: 4px;
  margin-right: 0;
}
.navbar .nav > li > a#home .imo:before {
  content: "\E8EE";
}
.navbar .nav > li.active > a,
.navbar .nav > li.active > a:hover,
.navbar .nav > li.active > a:focus {
  font-weight: normal;
  color: #ffffff;
  background-color: #1a1e23;
}
.navbar .nav > li.active > a .imo,
.navbar .nav > li.active > a:hover .imo,
.navbar .nav > li.active > a:focus .imo {
  color: #fff;
}
.navbar .nav > li.active::after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 7px solid #d7dae6;
  display: block;
  content: "";
  position: relative;
  bottom: 7px;
  margin: 0 auto;
  left: 1px;
}
.navbar .nav > li.home-location a .ai {
  font-size: 16px;
}
.navbar .nav > li .notification-bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  position: absolute;
  right: 12px;
  top: 16px;
}
.navbar .nav > li .notification-bullet.visible {
  background-color: orange;
  transition: background-color linear 0.15s;
}
.navbar .nav > li.admin-menu-item a {
  border-left: 1px solid #a1a5a9;
}
.navbar .nav > li.open > a,
.navbar .nav > li.open > a:hover,
.navbar .nav > li.open > a:focus {
  background: #1a1e23;
  color: #ffffff;
}
.navbar .nav > li.open > a .argon-icon,
.navbar .nav > li.open > a:hover .argon-icon,
.navbar .nav > li.open > a:focus .argon-icon {
  opacity: 1;
}
.navbar .nav > li.open > a::after,
.navbar .nav > li.open > a:hover::after,
.navbar .nav > li.open > a:focus::after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  display: block;
  position: relative;
  bottom: 9px;
  margin: 0 auto;
  z-index: 9999;
  content: "";
}
.navbar .nav > li.open > a.text-menu-item::after,
.navbar .nav > li.open > a:hover.text-menu-item::after,
.navbar .nav > li.open > a:focus.text-menu-item::after {
  top: -12px;
}
.navbar .nav > li.open > .dropdown-menu {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.15);
  border-top-color: #fff;
  border-radius: 4px;
  padding: 0;
  top: 60px;
  min-width: 170px;
}
.navbar .nav > li.open > .dropdown-menu li a {
  color: #444;
  font-size: 1em;
  line-height: 1.2em;
  padding: 8px 16px;
  white-space: normal;
  word-wrap: break-word;
}
.navbar .nav > li.open > .dropdown-menu li a:hover,
.navbar .nav > li.open > .dropdown-menu li a:focus {
  background: #ececec;
  color: #444;
}
.navbar .nav > li.open > .dropdown-menu li.active a {
  background: #3d4d84;
  color: #fff;
}
.navbar .nav > li.open > .dropdown-menu li .dropdown-menu {
  min-width: 185px;
}
.navbar .nav > li.open > .dropdown-menu li .dropdown-menu li a {
  padding-left: 12px;
}
.navbar .nav > li.open > .dropdown-menu .notification-item.read > a {
  background: #ececec;
  opacity: 0.65;
}
.navbar .nav > li.open > .dropdown-menu .notification-item.read > a:hover {
  opacity: 1;
}
.navbar .nav > li.open > .dropdown-menu .notification-item > a b {
  font-weight: 600;
}
.navbar .nav > li.open .initial-circle {
  border-radius: 100%;
  padding: 4px 8px 9px 6px;
  height: 60px;
  width: 60px;
  text-align: center !important;
  overflow: hidden;
}
.navbar .nav > li.open .initial-circle > a.initials {
  padding: 0 !important;
  text-decoration: none;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
  margin-top: 8px;
  color: #fff !important;
  font-family: Sofia Pro;
}
.navbar .nav > li.open .initial-circle > a.initials:hover {
  background-color: transparent !important;
  color: #d9d9d9 !important;
}
.navbar .nav > li.notification-menu-item {
  width: 69px;
  height: 60px;
  padding-left: 5px;
}
.navbar .nav > li.notification-menu-item .notification-bullet {
  right: 16px;
}
.navbar .nav > li.notification-menu-item.open > a:after {
  bottom: 4px;
}
.navbar .nav > li.nav-user a {
  line-height: 62px;
}
.navbar .nav > li.nav-user.open ul.dropdown-menu {
  width: 280px;
}
.navbar .nav > li.nav-user.open ul.dropdown-menu a:focus {
  outline: none;
}
.navbar .nav > li.nav-user.open ul.dropdown-menu .nav-user-info a:focus {
  background-color: transparent;
}
.navbar .nav .home-arrow {
  padding-left: 10px;
  padding-right: 10px;
}
.navbar .nav .home-picker {
  border-radius: 0 0 4px 4px !important;
}
.navbar.nav-management .nav > li.open > a::after,
.navbar.nav-management .navbar .nav > li.open > a:hover::after,
.navbar.nav-management .navbar .nav > li.open > a:focus::after,
.navbar.nav-management .navbar .nav > li.open > a:active:after {
  bottom: 4px;
  border-bottom-color: #366186;
}
.navbar.nav-management .nav > li.open .dropdown-menu {
  min-width: 200px;
}
.navbar .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.navbar.white-arrow .nav > li.active::after {
  border-bottom-color: #fff;
  transition: border-bottom-color linear 0.15s;
}
nav.navbar .nav-icon {
  margin-right: 5px;
}
nav.navbar .nav-icon.big {
  font-size: 24px !important;
}
nav.navbar .nav-icon.n-icon {
  color: #a1a5a9;
  top: 4px;
  position: relative;
}
nav.navbar .nav-icon.pl-n-icon {
  /*animation: dissapear 0.7s;
            color: rgba(0, 0, 0, 0);*/
  font-size: 24px;
  left: 25px;
  position: absolute;
  top: 19px;
}
nav.navbar .nav-icon .icon-notification {
  top: -20px;
}
nav.navbar .nav-icon .icon-notification.animate {
  animation: ring 2s 1s ease-in-out 2;
  color: #f90;
  transform-origin: top;
  transition: color 1s linear;
}
@media screen and (max-width: 1024px) {
  nav.navbar .nav-below-1024 {
    display: none;
  }
}
.modal-open .navbar.navbar-fixed-top .container-fluid {
  padding-right: 17px;
}
.modal-open .navbar {
  margin-right: -17px;
  padding-right: 17px;
}
.nav-below-768 {
  display: none;
}
.dropdown-menu .content-heading {
  border-top: 1px solid #b6c3cc;
  background-color: #b6c3cc;
  color: #000;
  font-size: 1em;
  line-height: 2;
  cursor: default;
  padding-left: 20px;
  font-weight: 600;
  clear: both;
}
.dropdown-menu .nav-user-info {
  padding: 20px 5px 20px 5px;
  font-size: 15px !important;
  border-bottom: 1px solid #b6c3cc;
  cursor: default;
  line-height: 1;
  background-color: #fff;
}
.dropdown-menu .nav-user-info p {
  padding-bottom: 2px;
  margin: 0;
  word-break: break-word;
  text-wrap: inherit;
}
.dropdown-menu .nav-user-info .username {
  font-size: 17px;
  padding-top: 2px;
  font-family: sofia pro;
  padding-bottom: 4px;
}
.dropdown-menu .nav-user-info a {
  color: #1A59A2 !important;
  margin: 0;
  padding: 0 !important;
}
.dropdown-menu .nav-user-info a:hover {
  background-color: #fff !important;
}
.dropdown-menu .dropdown-content {
  padding: 8px 16px 12px;
  white-space: nowrap;
}
.dropdown-menu .dropdown-content.dropdown-content-condensed {
  padding: 8px 16px;
}
.dropdown-menu .radio,
.dropdown-menu .checkbox {
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 0;
}
.dropdown-menu .radio:last-of-type,
.dropdown-menu .checkbox:last-of-type {
  margin-bottom: 0;
}
.dropdown-menu .dropdown-menu-scroll {
  max-height: 270px;
  overflow-y: scroll;
}
.dropdown-menu > li > a {
  padding: 6px 20px;
}
.dropdown-menu > li > a.active {
  background: #1e6b84;
  color: #fff;
}
.dropdown-menu > li > a.active:focus {
  background: #103846;
}
.dropdown-menu > li > a.active:hover {
  cursor: default;
}
.dropdown-content {
  min-width: 50%;
}
.dropdown-menu-report-list {
  min-width: 300px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: -1px;
  right: 100%;
  margin-top: 0px;
  margin-left: 0px;
  border-top-right-radius: 0;
  padding: 0;
  max-height: auto;
  transition: max-height linear 0.3s;
}
.dropdown-submenu > .dropdown-menu li.divider {
  margin: 0;
}
@media screen and (max-height: 910px) {
  .dropdown-submenu > .dropdown-menu {
    max-height: 360px;
    overflow-y: auto;
  }
}
@media screen and (max-height: 748px) {
  .dropdown-submenu > .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
}
.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu a:focus + .dropdown-menu {
  display: block;
}
.dropdown-submenu > .dropdown-submenu-header {
  padding: 6px 20px;
  clear: both;
  display: block;
  font-weight: normal;
  line-height: 1.42857;
  white-space: nowrap;
  cursor: pointer;
}
.dropdown-submenu > .dropdown-submenu-header:before {
  content: "\E825";
  font-family: ImosphereIcons;
  margin: 0px;
  left: 0px;
  position: absolute;
}
.dropdown-submenu:hover > .dropdown-submenu-header {
  background-color: #ececec;
  color: #333;
}
.dropdown-submenu:hover > .dropdown-submenu-header:after {
  border-left-color: #333;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.initial-circle.user-colour-0 {
  background-color: #AF6307;
}
.initial-circle.user-colour-1 {
  background-color: #3b6ab2;
}
.initial-circle.user-colour-2 {
  background-color: #E52054;
}
.initial-circle.user-colour-3 {
  background-color: #b43ba4;
}
.initial-circle.user-colour-4 {
  background-color: #198758;
}
.initial-circle.user-colour-5 {
  background-color: #007CC2;
}
.initial-circle.user-colour-6 {
  background-color: #ffba00;
}
.initial-circle.user-colour-7 {
  background-color: #E3008F;
}
.initial-circle.user-colour-8 {
  background-color: #107FAA;
}
.initial-circle.user-colour-9 {
  background-color: #61798C;
}
.notification-list {
  max-height: 320px;
  overflow-y: auto;
  width: 300px;
}
.notification-list li a {
  display: block;
  padding: 8px 12px;
  white-space: normal;
  overflow: hidden;
}
.notification-list li a div {
  display: inline-block;
  vertical-align: top;
  float: left;
  width: 205px;
}
.notification-list li a div.icon-container {
  padding-right: 6px;
  padding-top: 4px;
  width: 40px;
  font-size: 1.8em;
  color: #2d3961;
}
.notification-list li a div.icon-container .imo {
  color: inherit;
}
.notification-list li.notification-banner {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 7px;
}
.notification-list li.notification-banner b {
  font-weight: 600;
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@media (max-width: 767px) {
  .nav-below-1024 {
    margin-left: 15px;
  }
  .nav-below-768 {
    display: inline-block;
    margin-left: 15px;
  }
  .notification-below-768 {
    display: none !important;
  }
  .navbar-collapse {
    background: #464646;
  }
  .navbar-collapse ul.nav li.active:after {
    display: none !important;
  }
  .navbar-collapse ul.nav li.active > a {
    background: #3b3b3b !important;
  }
}
.list-page-toolbar .form-control-search {
  margin: -2px;
  height: 34px;
  width: 210px;
  padding-bottom: 4px;
}
.list-page-toolbar.flex {
  display: flex;
}
.list-page-toolbar.flex .left-content {
  flex-grow: 1;
}
.note {
  border-radius: 3px;
  border: solid 1px #fafafa;
  background-color: #fafafa;
  font-size: 0.86888887rem;
  line-height: 1.267rem;
  margin: 10px 0;
  overflow-y: auto;
  padding: 0;
}
.note p {
  margin-bottom: 0;
}
.note .note-header {
  background-color: #fafafa;
  border-bottom: 1px solid #fafafa;
  font-weight: 600;
  line-height: 22px;
  padding: 0 8px;
}
.note .note-body {
  padding: 8px;
}
.page-print .note,
.page-print .note span {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
@media print {
  .note,
  .page-print .note,
  .note,
  .page-print span .note span {
    border: none !important;
    font-size: 7.5pt !important;
    line-height: 11pt !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .print-note-label {
    display: inline-block !important;
    padding-right: 5pt !important;
  }
}
.pagination {
  background-color: #fff;
  line-height: 30px;
  height: 30px;
  border-radius: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
}
.pagination > li > a {
  background-color: transparent;
  border: none;
  border-radius: 15px;
  color: #111;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 9px;
  text-align: center;
  z-index: 10;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a {
  width: 30px;
  border-radius: 15px;
}
.pagination > li:first-child > a:hover,
.pagination > li:last-child > a:hover,
.pagination > li:first-child > a:focus,
.pagination > li:last-child > a:focus,
.pagination > li:first-child > a:active,
.pagination > li:last-child > a:active {
  background-color: #ececec;
}
.pagination > li:first-child > a {
  padding: 0;
}
.pagination > li > .pagination-detail {
  background-color: transparent;
  border: none;
  color: #111;
  cursor: default;
  display: inline-block;
  float: left;
  outline: none;
  line-height: 10px;
  padding: 1px;
  position: relative;
  text-align: center;
  z-index: 10;
  min-width: 100px;
}
.pagination > li > .pagination-detail label {
  font-family: FSMeWeb;
  font-weight: 500;
}
.pagination > li > .pagination-detail .page-number {
  font-size: 12px;
}
.pagination > li > .pagination-detail .total-items {
  font-size: 10px;
}
.pagination > li .page-number-input {
  width: 32px;
  height: 18px;
  text-align: center;
  border: 0px;
  border-radius: 4px;
  background-color: #fafafa;
}
.pagination > li .page-number-input:not(:disabled):hover {
  border: 1px solid black;
}
.pagination > li .sk-spinner {
  display: inline-block;
  float: right;
}
.pagination > li .sk-spinner.sk-spinner-double-bounce.sk-spinner {
  width: 30px;
  height: 30px;
  float: none;
}
.pagination > li .sk-spinner.sk-spinner-double-bounce .sk-double-bounce1,
.pagination > li .sk-spinner.sk-spinner-double-bounce .sk-double-bounce2 {
  background-color: transparent;
  border: solid 1px #666666;
}
.pagination > li input[type="number"]::-webkit-inner-spin-button,
.pagination > li input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pagination > li input[type="number"] {
  -moz-appearance: textfield;
}
.pagination > li.disabled > a,
.pagination > li.disabled > a:focus,
.pagination > li.disabled > a:hover,
.pagination > li.disabled > .pagination-detail,
.pagination > li.disabled > .pagination-detail:focus,
.pagination > li.disabled > .pagination-detail:hover,
.pagination > li.disabled:hover > a,
.pagination > li.disabled:hover > a:focus,
.pagination > li.disabled:hover > a:hover,
.pagination > li.disabled:hover > span,
.pagination > li.disabled:hover > span:focus,
.pagination > li.disabled:hover > span:hover {
  background-color: transparent;
  border-color: #707070;
  color: #111;
  outline: none;
  pointer-events: none;
}
.pagination > li.disabled > a,
.pagination > li.disabled > a:focus,
.pagination > li.disabled > a:hover {
  color: #919191;
}
.pagination.pagination-sm > li > a,
.pagination.pagination-sm > li > a:focus,
.pagination.pagination-sm > li > a:hover,
.pagination.pagination-sm > li > span,
.pagination.pagination-sm > li > span:focus,
.pagination.pagination-sm > li > span:hover {
  font-size: 1rem;
  line-height: 1.267rem;
  line-height: 1.5rem;
  padding: 5px 10px;
}
.pagination.pagination-sm > li span.pagination-detail {
  min-width: 90px;
}
.alternate .pagination {
  background-color: #fafafa;
}
.alternate .pagination > li > a:hover,
.alternate .pagination > li > a:focus {
  background-color: #fff;
}
.alternate .pagination > li .page-number-input {
  background-color: #fff;
}
.page-header {
  border-bottom: none;
  margin: 0;
  padding: 0 0 10px;
}
.page-header.page-header-border {
  border-bottom: solid 1px #ececec;
  margin-bottom: 15px;
}
.page-header h1,
.page-header h2 {
  margin: 5px 0 0;
}
.page-header h2 {
  line-height: 34px;
}
@media only screen and (max-width: 900px) {
  .page-header h2 {
    font-size: 1.55em;
  }
}
.page-header .title-controls {
  margin: 7px 10px 0;
}
.page-header .activity-info {
  margin-right: -4px;
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .page-header .activity-info {
    margin-right: 0;
    width: auto;
  }
}
.page-header .notification-pin {
  margin-top: 12px;
  margin-left: 8px;
}
.page-header.admin {
  padding-top: 5px;
}
.page-header.admin h1 {
  margin: 0;
}
.page-header.personal-details-history-header {
  margin-top: -50px;
}
.panel {
  border-radius: 6px;
  box-shadow: none;
}
.panel.panel-default {
  border-color: #fff;
  border-radius: 0 0 0px 0px;
}
.panel .panel-heading {
  background: none;
  border-bottom: none;
  padding: 20px 20px 10px;
}
.panel .panel-heading .panel-title {
  color: #111;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}
.panel .panel-heading.small .panel-title {
  font-size: 1rem;
  line-height: 1.267rem;
  color: #707070 !important;
  font-weight: 400;
  line-height: 25px;
  margin-top: -5px;
  text-transform: uppercase;
}
.panel.new-form-panel {
  border: 0px;
  width: 100%;
  padding: 0;
}
.panel.new-form-panel .new-form-footer {
  font-family: "Sofia Pro";
  font-size: 1.2em;
}
.panel.new-form-panel .panel-heading {
  padding: 15px;
  height: 100px;
  display: block;
  width: 100%;
}
.panel.new-form-panel:hover .panel-heading,
.panel.new-form-panel:focus .panel-heading {
  background-color: #fafafa;
}
.panel.new-form-panel:hover .new-form-footer,
.panel.new-form-panel:focus .new-form-footer {
  background-color: #2d3961;
}
.panel .back-to-top {
  margin: 0.375em 0 1em;
  z-index: 10;
}
.panel .panel-body {
  padding: 20px;
}
.panel .panel-body .table {
  margin-bottom: 0;
}
.panel .panel-body .panel .panel-heading,
.panel .panel-body .panel .panel-body {
  border: none;
}
.panel .panel-body.new-form-footer {
  background-color: #3d4d84;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  transition: background-color linear 0.2s;
  border: none;
  width: 100%;
  display: block;
  text-align: left;
}
.panel .panel-body-modal {
  padding-top: 0;
}
.panel .panel-heading + .panel-body {
  padding-top: 15px;
}
.panel .panel-heading.small + .panel-body {
  padding-top: 5px;
}
.panel .panel-seperator .panel-section {
  border-top: 2px solid #ececec;
}
.panel .panel-seperator .panel-section:first-child {
  border-top: none;
  /* limits the scope of the previous rule */
}
.panel .panel-footer {
  background: #fff;
  border-radius: 0 0 0px 0px;
  border-top: none;
  padding: 0 20px 20px;
}
.panel .panel-highlight {
  background: #fafafa;
}
.panel.panel-border-top,
.panel-summary-personal-details,
.panel-summary-calendar,
.panel-summary-diagnoses,
.panel-summary-alerts,
.panel-summary-reminders,
.panel-summary-activities,
.summary-panel {
  border: none;
  border-top: solid 5px #ececec;
}
.panel.panel-border-top .panel-heading,
.panel.panel-border-top .panel-body,
.panel.panel-border-top .panel-footer,
.panel-summary-personal-details .panel-heading,
.panel-summary-personal-details .panel-body,
.panel-summary-personal-details .panel-footer,
.panel-summary-calendar .panel-heading,
.panel-summary-calendar .panel-body,
.panel-summary-calendar .panel-footer,
.panel-summary-diagnoses .panel-heading,
.panel-summary-diagnoses .panel-body,
.panel-summary-diagnoses .panel-footer,
.panel-summary-alerts .panel-heading,
.panel-summary-alerts .panel-body,
.panel-summary-alerts .panel-footer,
.panel-summary-reminders .panel-heading,
.panel-summary-reminders .panel-body,
.panel-summary-reminders .panel-footer,
.panel-summary-activities .panel-heading,
.panel-summary-activities .panel-body,
.panel-summary-activities .panel-footer,
.summary-panel .panel-heading,
.summary-panel .panel-body,
.summary-panel .panel-footer {
  border: solid 1px #fff;
}
.panel.panel-border-top .panel-heading,
.panel-summary-personal-details .panel-heading,
.panel-summary-calendar .panel-heading,
.panel-summary-diagnoses .panel-heading,
.panel-summary-alerts .panel-heading,
.panel-summary-reminders .panel-heading,
.panel-summary-activities .panel-heading,
.summary-panel .panel-heading {
  border-top: none;
  border-bottom: none;
  border-radius: 0;
}
.panel.panel-border-top .panel-body,
.panel-summary-personal-details .panel-body,
.panel-summary-calendar .panel-body,
.panel-summary-diagnoses .panel-body,
.panel-summary-alerts .panel-body,
.panel-summary-reminders .panel-body,
.panel-summary-activities .panel-body,
.summary-panel .panel-body {
  border-radius: 0 0 0px 0px;
  border-top: none;
}
.panel.panel-border-top .panel-footer,
.panel-summary-personal-details .panel-footer,
.panel-summary-calendar .panel-footer,
.panel-summary-diagnoses .panel-footer,
.panel-summary-alerts .panel-footer,
.panel-summary-reminders .panel-footer,
.panel-summary-activities .panel-footer,
.summary-panel .panel-footer {
  border-top: none;
  margin-top: -6px;
  padding: 15px 20px;
}
.panel.panel-solid-header {
  border: none;
  border-top: none;
  margin-bottom: 10px;
}
.panel.panel-solid-header .panel-heading,
.panel.panel-solid-header .panel-body,
.panel.panel-solid-header .panel-footer {
  border: none;
}
.panel.panel-solid-header .panel-heading {
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  padding: 0;
  position: relative;
}
.panel.panel-solid-header .panel-heading .panel-title {
  background-color: #ececec;
  padding: 7px 40px 8px 15px;
  color: #fff;
  display: block;
}
.panel.panel-solid-header .panel-heading .form-guidance {
  font-size: 0.933em;
  color: #111;
  margin: 5px;
}
.panel.panel-solid-header .panel-body {
  border-radius: 0 0 0px 0px;
  border-top: none;
  padding: 15px;
  padding-bottom: 25px;
}
.panel.panel-solid-header .panel-body .form-item .form-item-group label {
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
  margin-bottom: 3px;
}
.panel.panel-solid-header .panel-body .form-item .form-item-group label .help-block {
  margin: 0;
}
.panel.panel-solid-header .panel-body .repeating-sections-container {
  margin: 0 -15px;
}
.panel.panel-solid-header .panel-body .repeating-sections-container .repeating-section {
  padding: 15px;
}
.panel.panel-solid-header .panel-body .repeating-sections-container .repeating-section:nth-child(even) {
  background-color: #fafafa;
}
.panel.panel-solid-header .panel-body .repeating-sections-add-another-container {
  margin: -15px -15px 20px -15px;
  padding: 15px;
  position: relative;
  display: block;
  text-align: center;
}
.panel.panel-solid-header .panel-body .repeating-sections-add-another-container p {
  line-height: 34px;
  margin: 0;
}
.panel.panel-solid-header .panel-body .repeating-sections-add-another-container .repeating-section-counter {
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 34px;
}
.panel.panel-solid-header .panel-body .repeating-sections-add-another-container + .form-item {
  margin-top: 15px;
  border-top: 1px solid #ddd;
}
.panel.panel-solid-header .panel-body .table-data-entry-container + .repeating-sections-add-another-container {
  margin-top: 0;
}
.panel.panel-solid-header.has-more-pathways .panel-body {
  padding-bottom: 0;
}
.panel.panel-solid-header .panel-footer {
  border-top: none;
  margin-top: -6px;
  padding: 15px 20px;
}
.panel.panel-solid-header h5 {
  font-size: 1.1em;
  font-weight: 600;
  font-family: Sofia Pro;
  margin: 0;
  line-height: 1.4em;
}
.panel.panel-solid-header h5 + span {
  display: block;
}
.panel.panel-border-left {
  border-left: solid 5px #ececec;
}
.panel.panel-border-left .panel-footer {
  margin-top: -6px;
  padding: 15px 20px;
}
.panel.panel-shadow {
  -webkit-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
  box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
}
.panel.autosave-sidebar .panel-body {
  padding-top: 22px;
}
.panel.autosave-sidebar .gu-progress {
  border-radius: 10px 10px 0 0;
  height: 4px;
  margin: 0 0 -6px;
  /* Negative bottom margin is required when gu-progress doesn't exist */
  overflow: hidden;
  position: relative;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  /* this fixes the overflow:hidden in Chrome/Opera */
}
.panel.autosave-sidebar .autosave-label {
  color: #616161;
  font-size: 1rem;
  line-height: 1.267rem;
  line-height: 1rem;
  margin-top: -16px;
  margin-right: -12px;
  position: relative;
}
.panel.autosave-sidebar .btn {
  margin-bottom: 2px;
}
@media (max-width: 992px) {
  .panel.autosave-sidebar .autosave-content {
    display: inline-block;
  }
}
.panel .activity-section-title,
.panel .activity-subtitle {
  color: #111;
  display: inline-block;
  font-family: Sofia Pro;
  font-size: 1.2em;
  font-weight: 600;
}
.panel .header-right {
  line-height: 38px;
  position: absolute;
  right: 8px;
  top: 0;
}
.panel .header-right .rotate {
  border-radius: 50%;
  color: #fff;
  padding: 4px;
  position: relative;
  top: 1px;
}
.panel .header-right .btn {
  border: none;
}
.panel .header-right > a {
  line-height: 42px;
}
.panel .header-right ul.dropdown-menu li {
  line-height: 1.2em;
}
.panel .header-right ul.dropdown-menu li a {
  line-height: inherit;
}
.panel .notification-details .additional-information > .hr-small {
  margin-bottom: 20px;
}
.panel.panel-involvement .involvement-heading {
  border-bottom: solid 1px #ececec;
  display: -webkit-flex;
  display: flex;
  padding: 1em;
}
.panel.panel-involvement .status,
.panel.panel-involvement .date {
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  border: none;
  margin: 0;
  padding: 0;
}
.panel.panel-involvement .date {
  padding: 0.125em 0 0 0.25em;
}
.panel.panel-contact .panel-heading.contact-header {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
}
.panel.panel-contact .panel-heading.contact-header .panel-title {
  margin-right: 5px;
  margin-bottom: 2px;
  width: 90%;
}
.panel.panel-contact .panel-heading .go-to-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 74%;
}
.panel.panel-contact .profession-name {
  border-top: solid 1px #ececec;
  margin-top: 10px;
  padding-top: 10px;
}
.panel.panel-contact .contact-notes {
  overflow-y: auto;
}
.panel.panel-exisiting-service-user {
  margin: 4px 0;
}
.panel.panel-exisiting-service-user:hover {
  background: #fafafa;
}
.panel.panel-exisiting-service-user:hover a {
  text-decoration: none;
}
.panel.panel-exisiting-service-user dl {
  color: #111;
}
.panel.panel-exisiting-service-user .panel-body {
  padding: 8px 12px;
}
.panel.panel-collapse-interaction {
  height: 40px;
  padding-right: 12px;
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
}
.panel.panel-collapse-interaction p {
  margin: 10px 0 10px;
}
.panel.panel-collapse-interaction .more-less-text:before {
  content: "Show";
}
.panel.panel-collapse-interaction:not(.collapsed) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel.panel-collapse-interaction:not(.collapsed) .more-less-text:before {
  content: "Hide";
}
.panel.panel-collapse-interaction:hover span {
  color: #3d4d84;
}
.panel.panel-primary-diagnosis .panel-body,
.panel.panel-diagnosis .panel-body {
  padding: 15px 0;
}
.panel.panel-primary-diagnosis .panel-body .selected-diagnosis,
.panel.panel-diagnosis .panel-body .selected-diagnosis {
  color: #111;
  cursor: pointer;
  display: block;
  padding: 5px 20px;
}
.panel.panel-primary-diagnosis .panel-body .selected-diagnosis:hover,
.panel.panel-diagnosis .panel-body .selected-diagnosis:hover,
.panel.panel-primary-diagnosis .panel-body .selected-diagnosis:hover .text-muted,
.panel.panel-diagnosis .panel-body .selected-diagnosis:hover .text-muted {
  background: #fafafa;
  color: #3d4d84;
  text-decoration: none;
}
.panel.panel-summary-alerts .alert-bullet {
  border-radius: 50%;
  border: solid 1px transparent;
  margin-top: 4px;
  margin-left: -15px;
  width: 10px;
  height: 10px;
}
.panel.panel-summary-alerts .alert-bullet.pinned {
  border-color: #3d4d84;
}
.panel.panel-summary-alerts .summary-link {
  padding-left: 34px;
}
.panel.panel-pathway {
  margin-bottom: 0;
}
.panel.panel-pathway + .panel-pathway {
  margin-top: 5px;
}
.panel.panel-pathway:last-of-type {
  margin-bottom: 20px;
}
.panel.panel-pathway .panel-body {
  padding: 15px 20px;
}
@media (min-width: 768px) {
  .panel.panel-pathway .pathway-detail {
    float: right;
  }
}
.panel.panel-pathway.panel-border-left.abandoned {
  border-left-color: #eb1515;
}
.panel.panel-pathway.panel-border-left.paused {
  border-left-color: #707070;
}
.panel.panel-pathway.panel-border-left.closed {
  border-left-color: #505151;
}
.panel.panel-pathway.panel-border-left.open {
  border-left-color: #3d4d84;
}
.panel.panel-pathway[class*="pathway-"] .panel-heading {
  align-items: center;
  display: flex;
  padding: 0 20px 0 0;
  word-break: break-word;
}
.panel.panel-pathway[class*="pathway-"] .panel-heading:hover {
  background: #fafafa;
  cursor: pointer;
}
.panel.panel-pathway[class*="pathway-"] .panel-heading .heading-content {
  flex-grow: 1;
}
.panel.panel-pathway[class*="pathway-"] .stage-title {
  display: inline-block;
  margin-right: 1.25em;
}
.panel.panel-pathway[class*="pathway-"] .stage-duration {
  width: 100px;
  padding-right: 0;
}
.panel.panel-pathway[class*="pathway-"] .title-icon {
  margin-right: 4px;
}
.panel.panel-pathway[class*="pathway-"] .title-icon [class*="cp-icon-"],
.panel.panel-pathway[class*="pathway-"] .title-icon .panel-title {
  display: inline-block;
  margin-right: 0.25em;
}
.panel.panel-pathway[class*="pathway-"] .title-icon [class*="cp-icon-"] {
  background-color: #3d4d84;
  height: 70px;
  line-height: 70px;
  text-align: center;
  transition: background-color linear 0.1s;
  width: 70px;
}
.panel.panel-pathway[class*="pathway-"] .title-icon [class*="cp-icon-"]:before {
  color: #fff;
  font-size: 30px;
}
.panel.panel-pathway[class*="pathway-"] .panel-title {
  color: #111;
  cursor: pointer;
}
.panel.panel-pathway .summary-link {
  padding-left: 35px;
}
.panel.panel-pathway.pathway-current:not(.paused) .title-icon [class*="cp-icon-"]:before {
  content: "\e94d";
}
.panel.panel-pathway.pathway-complete:not(.paused) .title-icon [class*="cp-icon-"] {
  background-color: #42ce56;
}
.panel.panel-pathway.pathway-failed:not(.paused) .title-icon [class*="cp-icon-"] {
  background-color: #eb1515;
}
.panel.panel-pathway.pathway-failed:not(.paused) .title-icon [class*="cp-icon-"]:before {
  content: "\e9133";
  margin-left: 2px;
}
.panel.panel-pathway.paused .title-icon [class*="cp-icon-"] {
  background-color: #707070;
}
.panel.panel-pathway.paused .title-icon [class*="cp-icon-"]:before {
  content: "\e945";
}
.panel.panel-pathway.pathway-not-started {
  background-color: #fafafa;
  pointer-events: none;
}
.panel.panel-pathway.pathway-not-started .title-icon [class*="cp-icon-"] {
  background-color: #707070;
}
.panel.panel-pathway.pathway-not-started .title-icon [class*="cp-icon-"]:before {
  display: none;
}
.panel.panel-pathway.pathway-not-started [class*="cp-icon-chevron"] {
  display: none;
}
.panel.panel-pathway .task-title {
  align-items: center;
  background-color: #fafafa;
  color: #111;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  padding: 5px 20px;
  position: relative;
  word-break: break-word;
}
.panel.panel-pathway .task-title:hover {
  background-color: #f0f0f0;
}
.panel.panel-pathway .task-title .title-content {
  flex-grow: 1;
}
.panel.panel-pathway .task-title .task-duration {
  width: 100px;
  padding-right: 0;
}
.panel.panel-pathway .task-title [class*="label-"] {
  left: 5px;
  margin-right: 1.5em;
  position: relative;
  top: -1px;
}
.panel.panel-pathway .label-open {
  background-color: #3d4d84;
}
.panel.panel-pathway .label-complete {
  background-color: #42ce56;
}
.panel.panel-pathway .label-failed {
  background-color: #eb1515;
}
.panel.panel-pathway .label-abandoned {
  background-color: #eb1515;
}
.panel.panel-pathway .label-paused {
  background-color: #707070;
}
.panel.panel-pathway [class*="label-"] {
  left: 5px;
  margin-right: 1.5em;
  position: relative;
  top: -1px;
}
.panel.panel-pathway .task-current .task-body,
.panel.panel-pathway .task-current .task-title {
  border-left: 6px solid #3d4d84;
}
.panel.panel-pathway .task-complete .task-body,
.panel.panel-pathway .task-complete .task-title {
  border-left: 6px solid #42ce56;
}
.panel.panel-pathway .task-failed .task-body,
.panel.panel-pathway .task-failed .task-title {
  border-left: 6px solid #eb1515;
}
.panel.panel-pathway .pathway-restart-btn-group {
  margin-top: 15px;
}
.panel.panel-pathway .task-body,
.panel.panel-pathway .task-title {
  margin-bottom: 0;
  margin-left: -20px;
  margin-right: -20px;
}
.panel.panel-pathway .task-detail {
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
}
.panel.panel-pathway .task-detail .associate-activity {
  margin-bottom: 8px;
}
.panel.panel-pathway .task-detail .hr-small.last {
  margin-bottom: 0;
}
.panel .sidebar-pathway {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
}
.panel .sidebar-pathway .flex-half {
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .panel .sidebar-pathway .flex-half p {
    flex-grow: 1;
    margin: 0;
  }
}
@media (max-width: 991px) {
  .panel .sidebar-pathway .flex-half.chart {
    display: none;
  }
}
.panel .sidebar-pathway .flex-half.chart .chart-text {
  color: #009680;
  flex-basis: 100%;
}
.panel .sidebar-pathway .flex-half.chart .overdue-chart-text {
  color: #cf5d5c;
  flex-basis: 100%;
}
.panel .sidebar-pathway .flex-half .sidebar-mobile-duration {
  display: block;
}
.panel .sidebar-pathway .flex-half .duration-information-block p:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .panel .sidebar-pathway .flex-half .duration-information-block {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .panel .sidebar-pathway .flex-half .sidebar-mobile-duration {
    display: none;
  }
}
.panel .sidebar-pathway .sidebar-header .title {
  display: none;
}
@media (min-width: 992px) {
  .panel .sidebar-pathway .sidebar-header {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    margin-bottom: 5px;
  }
  .panel .sidebar-pathway .sidebar-header .title {
    display: block;
    flex-grow: 1;
    margin: 0;
  }
}
.panel.panel-summary-reminders .summary-link {
  padding-left: 42px;
}
.panel.panel-summary-reminders [class^="cp-icon-"],
.panel.panel-summary-reminders [class*=" cp-icon-"] {
  float: left;
  margin-top: 2px;
  margin-left: -22px;
}
.panel.panel-summary-personal-details .form-group {
  margin-bottom: 1em;
}
.panel.panel-summary-personal-details .label.label-open {
  background-color: #00c089;
  margin-top: 3px;
  margin-left: 5px;
}
.panel .summary-link {
  color: #111;
  display: block;
  margin: 0 -15px;
  padding: 0.5em 15px;
}
.panel .summary-link:focus,
.panel .summary-link:hover {
  background-color: #fafafa;
  text-decoration: none;
}
.panel .summary-list-no-link {
  color: #111;
  display: block;
  margin: 0 -20px;
  padding: 0.5em 20px;
}
.panel-summary-personal-details p,
.panel-summary-calendar p,
.panel-summary-diagnoses p,
.panel-summary-alerts p,
.panel-summary-reminders p,
.panel-summary-activities p {
  margin-bottom: 0;
}
.summary-background-colour {
  background-color: #ececec;
}
.banner {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.banner .name {
  overflow: hidden;
  flex-grow: 1;
}
.banner h1 {
  color: #111;
  display: inline-block;
  overflow: hidden;
  margin: auto;
  max-width: calc(100% - 65px);
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .banner h1 {
    font-size: 1.8em;
  }
}
.banner .cp-icon-star-su {
  font-size: 22px;
  position: relative;
  top: 10px;
  vertical-align: top;
}
.banner .cp-icon-star-su.active {
  color: #ffb035;
}
.banner .cp-icon-cake {
  color: #435592;
  font-size: 26px;
  position: relative;
  top: -16px;
}
.banner dl.horizontal {
  line-height: 1.75rem;
}
.banner .preferred-name {
  margin-top: -8px;
}
@media only screen and (min-width: 992px) {
  .banner {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    min-height: 58px;
    padding-top: 12px;
    text-align: left;
  }
  .banner .avatar {
    float: left;
    margin-right: 0.25em;
  }
  .banner .preferred-name {
    margin-left: 40px;
  }
  .banner .identifier {
    font-size: 0.9333rem;
    margin-top: 12px;
    padding-left: 2.5em;
    text-align: right;
    white-space: nowrap;
  }
}
.banner-container {
  border-bottom: solid 1px transparent;
  padding-top: 30px;
}
.banner-container.deceased {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.banner-container.deceased .banner {
  color: #fff;
}
.banner-container.deceased .banner h1,
.banner-container.deceased .banner .text-emphasis {
  color: #fff;
}
.banner-container.deceased .banner .cp-icon-star-su {
  font-size: 24px;
}
.banner-container.deceased .banner .text-muted {
  color: rgba(255, 255, 255, 0.8) !important;
}
.banner-container.deceased .banner .cp-icon-user {
  color: #707070;
}
.banner-container.deceased.fixed-banner {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-color: rgba(0, 0, 0, 0.9);
}
.banner-container.deceased.fixed-banner .identifier-deceased {
  display: none;
}
.page-container {
  transition: padding-bottom 0.4s ease;
  display: block;
  /*padding-bottom:80px;*/
}
.page-container.pathways-dropdown-open {
  padding-bottom: 320px;
}
.nav-banner {
  background-color: #fff;
  border-bottom: solid 1px #ececec;
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .nav-banner {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.banner-container-addon {
  display: none;
  background-color: rgba(247, 247, 247, 0.54);
  padding: 0 15px;
  -webkit-box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);
  -moz-box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);
  box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);
}
.banner-container-addon .toggle-answer-container {
  top: -3px;
  display: inline-block;
  float: right;
  position: relative;
}
.banner-container-addon .toggle-answer-container .toggle-answers {
  height: 28px;
}
.banner-container-addon .toggle-answer-container .toggle-answers .dropdown-toggle {
  height: 24px;
}
.banner-container-addon .toggle-answer-container .toggle-answers .dropdown-toggle .current-toggle-view {
  top: -3px;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .form-banner-container.fixed-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background: rgba(255, 255, 255, 0.9);
  }
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .form-banner-container.fixed-banner {
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.75) !important;
    }
  }
  .form-banner-container.fixed-banner .banner-container-addon {
    display: block;
  }
  .banner-container.fixed-banner {
    background: rgba(255, 255, 255, 0.9);
    border-color: #ececec;
    padding-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .banner-container.fixed-banner {
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.75) !important;
    }
  }
  .banner-container.fixed-banner .preferred-name {
    display: none;
  }
  .banner-container.deceased .identifier-deceased {
    float: left;
    margin-top: -0.375em;
  }
  .nav-banner.fixed-banner-container {
    padding-top: 88px;
  }
  .cp-icon-star-su {
    font-size: 22px;
    transition: none;
  }
  .patient-identifier {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .banner .identifier {
    font-size: 1rem;
  }
}
@media print {
  .banner {
    border-bottom: solid 1px #000 !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }
  .banner .patient-identifier dl.horizontal {
    padding-right: 10px !important;
  }
}
.progress {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar.active {
  animation: progress-bar-stripes-custom linear 2s infinite;
}
.progress-bar-horizontal {
  height: 10px;
  line-height: 1.46666666rem;
}
.progress-bar-info {
  display: inline-block;
  width: 90%;
}
.progress-bar-info-icon {
  display: inline-block;
  width: 8%;
  margin-bottom: 18px;
}
.progress-bar-section-pagination {
  height: 5px;
  line-height: 1.46666666rem;
  width: 90%;
  margin-left: 17px;
  margin-top: 10px;
}
.progress-bar-success {
  background-color: #0d76b8;
  border-color: #0d76b8;
}
.progress-bar-warning {
  background-color: #1b9def;
  border-color: #1b9def;
}
.progress-bar-default {
  background-color: #084d78;
  border-color: #084d78;
}
.progress-bar-pagination {
  background-color: #3d4d84;
  border-color: #0d76b8;
}
.progress-bar-background {
  background-color: #ececec;
  border-color: #ececec;
}
.progress-bar-questions {
  background-color: transparent;
  border-color: transparent;
}
.event-attendance-full {
  background-color: #1ad44e;
}
.event-attendance-partial {
  background-color: #ffba3a;
}
.event-attendance-none {
  background-color: #ed3a3a;
}
.label-progress {
  width: 26px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
}
.select2 {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  border: none;
  background-color: #fafafa;
  border: 2px solid #ececec;
  box-shadow: none;
  height: 32px;
  line-height: 32px;
  padding: 5px 32px 5px 10px;
  transition: border-color linear 0.1s;
}
.select2-container--default .select2-selection--single[aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.select2-container--default .select2-selection--single[aria-expanded="true"]:hover {
  background-color: #fafafa;
}
.select2-container--default .select2-selection--single[aria-disabled="true"] {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.select2-container--default .select2-selection--single:hover:not([aria-disabled="true"]),
.select2-container--default .select2-selection--single:focus:not([aria-disabled="true"]) {
  background-color: #fafafa;
}
.select2-container--default .select2-selection--single:focus {
  border-color: #fafafa;
  outline: none;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 32px;
  right: 0;
  top: 0;
  width: 32px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
  height: 32px;
  left: auto;
  margin: 0;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0px;
  width: 32px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  border: none;
  content: "\e8cd";
  font-family: ImosphereIcons;
  font-size: 13px;
  line-height: 32px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 21px;
  padding-left: 0;
  padding-right: 8px;
  color: #111;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  right: -8px;
  top: 0;
  width: 16px;
  height: 16px;
  position: relative;
  font-size: 0;
}
.select2-container--default .select2-selection--single .select2-selection__clear:after {
  font-family: "ImosphereIcons";
  content: "\e82d";
  display: block;
  font-size: 12px;
  color: #111;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}
.select2-container--default .select2-selection--single .select2-selection__clear:after:hover {
  color: #2b2b2b;
}
.select2-container--default .select2-selection--single .select2-selection__clear:hover {
  background-position-x: -12px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  visibility: hidden;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:before {
  content: "\e8d0";
  font-family: ImosphereIcons;
}
.select2-container--default .select2-dropdown {
  border: none;
}
.select2-container--default .select2-dropdown.select2-dropdown--below {
  background-color: #fafafa;
  border: 2px solid #ececec;
  border-top-color: transparent;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: -2px;
}
.select2-container--default .select2-dropdown.select2-dropdown--above {
  background-color: #fafafa;
  border-bottom: none;
  box-shadow: 0 -5px 0 -10px rgba(0, 0, 0, 0.2), 0 -5px 5px 0 rgba(0, 0, 0, 0.2);
}
.select2-container--default .select2-search--dropdown {
  padding: 5px;
  position: relative;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 3px;
  border: 2px solid #ececec;
  height: 32px;
  padding: 5px 10px 5px 32px;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--default .select2-search--dropdown:before {
  content: "\e8e4";
  display: block;
  font-family: ImosphereIcons;
  font-size: 16px;
  height: 32px;
  left: 5px;
  line-height: 32px;
  position: absolute;
  text-align: center;
  width: 32px;
}
.select2-container--default .select2-results__option {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  transition: background-color linear 0.1s;
}
.select2-container--default .select2-results__option .badge {
  background-color: #ececec;
  color: #111;
  float: right;
}
.select2-container--default .select2-results__option .read-only:after {
  font-size: 11px;
  font-weight: 400;
  padding: 0.2em 0.6em;
  font-family: FSMeWeb !important;
  content: "read only";
  background-color: #3d4d84;
  color: #fff;
  border-radius: 0.25em;
}
.select2-container--default .select2-results__option .read-only .wrap-under {
  display: block;
}
.select2-container--default .select2-results__option .read-only .text-answer-guidance {
  color: #999;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ececec;
  color: #111;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] .badge {
  background-color: #fff;
  color: #111;
  float: right;
}
.select2-container--default .select2-results__option[aria-selected="true"] .badge,
.select2-container--default .select2-results__option[aria-selected="true"]:hover .badge {
  background-color: #ececec;
}
.select2-container--default .contact-type {
  font-weight: 400;
  margin-left: 4px;
}
.select2-container--default dd {
  margin-left: 2px;
  margin-right: 10px;
}
.collapse:not(.in) .select2-dropdown,
.collapsing:not(.in) .select2-dropdown {
  display: none;
}
.select2-results {
  padding: 5px;
}
.option.load-more {
  font-size: 1rem;
  line-height: 1.267rem;
  color: #616161;
  padding: 6px;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  box-shadow: none;
  background-color: #fafafa;
  border: 2px solid #ececec;
  transition: background-color linear 0.1s;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__clear {
  color: #111;
  font-size: 2em;
  height: 12px;
  margin-right: 5px;
  position: relative;
  top: 1px;
  width: 12px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear:hover,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__clear:hover {
  color: #2b2b2b;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__choice {
  background-color: #ececec;
  font-size: 1rem;
  line-height: 1.6em;
  border: none;
  margin: 7px 5px 0 2px;
  padding: 0px 8px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice:hover,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__choice:hover {
  background-color: #c6c6c6;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__choice__remove {
  position: relative;
  top: 1px;
  width: 12px;
  height: 12px;
}
.select2-container--default .select2-container .select2-search--inline,
.select2-container--default.select2-container--focus .select2-container .select2-search--inline {
  clear: both;
}
.select2-container .select2-search--inline {
  float: none;
}
.text-answer-guidance {
  font-size: 1rem;
  line-height: 1.267rem;
  display: block;
}
.text-answer-guidance.text-muted {
  color: #5e5e5e;
}
.select2-basic .select2-container--default .select2-selection--single {
  border: none;
  box-shadow: none;
  color: #3d4d84;
  height: inherit;
  padding: 0;
  margin: 0;
  margin-top: -1px;
  margin-left: -2px;
  padding-left: 0;
  padding-right: 8px;
}
.select2-basic .select2-container--default .select2-selection--single:hover {
  background-color: #fafafa;
}
.select2-basic .select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-basic .select2-search {
  display: none !important;
}
.select2-basic .select2-results {
  padding: 0;
}
.select2-basic .select2-dropdown.select2-dropdown--below {
  border-radius: 4px;
  border: 1px solid rgba(112, 112, 112, 0.6);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin: 0;
  min-width: 320px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.select2-basic .select2-results__option {
  padding: 5px 15px;
}
.select2-basic .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-right: 20px;
}
.select2-basic .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
  font-family: "care-partner-font" !important;
  color: #707070;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  height: 0;
  left: auto;
  top: 0;
  margin: 0;
  text-transform: none;
  line-height: 1;
  speak: none;
}
.select2-basic .select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  font-family: "ImosphereIcons";
  content: "\e8cd";
}
.select2-basic .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:before {
  content: "\e8d0";
}
.select2-config-terms {
  margin-top: -2px;
}
.select2-config-terms .select2 {
  width: 150px !important;
}
.select2-config-terms .select2-container--default .select2-selection--single {
  margin: 0;
  background-color: transparent;
}
.select2-config-terms .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 18px;
  top: 0px;
}
.select2-config-terms .select2-container--default .select2-selection--single:hover {
  background: none;
}
.select2-config-terms .select2-container--default .select2-selection--single:hover .select2-selection__arrow b {
  color: #111;
}
.select2-config-terms .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #3d4d84;
  padding-bottom: 4px;
}
.select2-config-terms .select2-container--default .select2-selection--single .select2-selection__rendered::after {
  background-color: #3d4d84;
  bottom: -4px;
  content: "";
  display: block;
  height: 3px;
  left: 5px;
  position: relative;
  width: 20px;
}
.select2-config-terms .select2-container--default .select2-selection--single .select2-selection__arrow b {
  color: #707070;
}
.select2-config-terms .select2-dropdown.select2-dropdown--below {
  background: rgba(255, 255, 255, 0.95);
  margin-top: -4px;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .select2-config-terms .select2-dropdown.select2-dropdown--below {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}
.select2-config-terms .select2-container--default .select2-results > .select2-results__options {
  max-height: 405px;
}
.select2-contact-record + .select2-container--default {
  margin-top: 3px;
  margin-bottom: 2px;
}
.select2-contact-record + .select2-container--default .select2-selection--single {
  border-color: #ececec;
  box-shadow: none;
  height: 30px;
  padding: 3px 6px;
}
.select2-contact-record + .select2-container--default .select2-selection--single:hover,
.select2-contact-record + .select2-container--default .select2-selection--single:focus {
  border-color: #d3d3d3;
}
.select2-contact-record + .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #111;
}
.select2-contact-record + .select2-container--default .select2-selection__arrow {
  right: 6px;
}
.select2-contact-record + .select2-container--default .select2-selection__arrow b {
  border: none;
  margin-top: 0;
}
.select2-contact-record + .select2-container--default .select2-selection__arrow b:before {
  font-family: ImosphereIcons;
  content: "\e8cd";
}
.select2-contact-record + .select2-container--default .select2-results__option:before,
.select2-contact-record + .select2-container--default .select2-results__option:after {
  content: " ";
  display: table;
}
.select2-contact-record + .select2-container--default .select2-results__option:after {
  clear: both;
}
.select2-contact-record + .select2-container--default .select2-results__option .validation-block {
  margin: 0;
}
.select2-contact-record + .select2-container--default .contact-record-name {
  display: block;
  width: 100%;
}
@media (min-width: 1270px) {
  .select2-contact-record + .select2-container--default .contact-record-name {
    float: left;
    width: 54%;
  }
}
@media (min-width: 1270px) {
  .select2-contact-record + .select2-container--default .date-assessed {
    float: right;
  }
}
.select2-contact-record + .select2-container--open .select2-selection--single {
  border-color: #ececec;
}
.select2-contact-record + .select2-container--open .select2-search--dropdown {
  display: none;
}
.select2-contact-record + .select2-container--open .select2-selection__arrow b:before {
  content: "\e92e";
}
.select2-contact-record + .select2-container--open .select2-results {
  padding: 0;
}
.select2-contact-record + .select2-container--open .select2-results__options {
  margin-top: 2px;
}
.status {
  border-radius: 6px;
  color: #fff;
  font-weight: 400;
  margin: 0 0 20px;
  padding: 0.4375em 0.75em;
}
.status.status-closed {
  background: #505151;
}
.status.status-draft {
  background: #ddeefb;
  color: #20548a;
}
.status.status-open {
  background-color: #00c089;
}
.status.status-abandoned {
  background-color: #eb1515;
}
.status.status-paused {
  background-color: #707070;
}
.table-controls {
  padding-bottom: 15px;
}
.table-controls .pagination {
  margin-bottom: -6px;
  background-color: #fff;
}
.table-controls:after {
  clear: both;
  content: "";
  display: table;
}
.activityIsOverdue {
  background-color: #ff5519;
}
.table-responsive {
  border: none;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 15px;
}
.table-responsive.registry {
  overflow-x: visible;
  min-height: 120px !important;
  display: block;
}
.table-responsive.table-scroll {
  overflow-y: auto;
  max-height: 500px;
}
.table-responsive.table-scroll thead th {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.table-responsive thead th .checkbox-input-field {
  margin-top: -3px;
}
@media screen and (max-width: 900px) {
  .table-responsive {
    padding: 10px;
  }
}
td.align-center {
  text-align: center;
}
td input.offset-chevron {
  margin-right: 18px;
}
th.align-center {
  text-align: center;
}
.coc-registry {
  table-layout: fixed;
  position: relative;
  border-collapse: collapse;
  border: none;
  font-size: 0.8em;
}
.coc-registry thead tr th {
  position: sticky;
  top: 31px;
  background: #f4f4f4;
  z-index: 3;
  border: none;
  font-family: "FSMeWeb" !important;
  font-weight: 500 !important;
  text-align: center;
}
.coc-registry thead tr th.show-section-marker:not(:first-of-type),
.coc-registry thead tr th.first-in-group:not(:first-of-type) {
  border-left: 2px solid #ccc;
}
.coc-registry thead tr th.border-left {
  border-left: 1px solid #ddd !important;
}
.coc-registry thead tr:first-of-type th {
  top: 0;
  background: #ececec;
  font-family: Sofia Pro !important;
  font-weight: 600 !important;
  text-align: left;
}
.coc-registry tbody th {
  position: sticky;
  left: 0;
  background: #ececec;
  z-index: 3;
  border: none;
}
.coc-registry tbody td {
  border: none;
  text-align: center;
}
.coc-registry tbody td.with-border {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.coc-registry tbody td.marker {
  vertical-align: middle !important;
}
.coc-registry tbody td .imo {
  font-size: 20px !important;
  margin: 0;
}
.coc-registry tbody td.first-in-group:not(:first-of-type) {
  border-left: 2px solid #ccc;
}
.coc-registry .service-user-name {
  width: 200px;
  word-break: break-all;
}
.coc-registry .service-user-name.no-border {
  border-color: transparent;
}
.coc-registry .service-user-name a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.3em;
}
.coc-registry tr {
  position: relative;
}
.coc-registry tr th[scope="col"] {
  width: 100px;
  word-break: break-word;
  font-weight: 500;
}
.modal .table-responsive {
  padding: 15px;
}
body.admin .table-responsive {
  padding: 0px;
  background-color: transparent;
}
body.admin .table-responsive-fixed {
  padding: 0px;
  background-color: transparent;
}
body.admin .table-responsive-fixed .role-name-control {
  width: 40%;
}
body.admin .table-responsive-fixed .role-toggle-control {
  width: 50%;
}
body.management .table-responsive {
  padding: 0px;
  background-color: transparent;
}
body.management .table-responsive-fixed {
  padding: 0px;
  background-color: transparent;
}
body.management .table-responsive-fixed .role-name-control {
  width: 40%;
}
body.management .table-responsive-fixed .role-toggle-control {
  width: 50%;
}
.table > thead > tr > th {
  font-weight: 600;
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
  vertical-align: text-top;
}
.table > thead > tr > th.no-border {
  border: none;
  padding-bottom: 0 !important;
}
.table > thead > tr.sub-heading th {
  font-family: FSMeWeb;
  font-size: 1em;
  font-weight: normal;
  border-top: none;
  padding-top: 0 !important;
}
.table > tbody > tr.hidden-row > td {
  border: none;
  padding: 0;
}
.table > tbody > tr.row-status-success {
  background-color: #e1fdf5;
}
.table.no-header-row tr:first-child td {
  border-top: none;
}
.table.table-borderless tbody > tr > td {
  border: none;
}
.table.table-worklist thead > tr > th.edge-trim {
  padding: 8px 0;
  width: 10px;
}
.table.table-worklist tbody > tr > td {
  padding: 10px 8px;
}
.table.table-worklist tbody > tr > td:first-child {
  padding: 8px 6px 8px 0;
}
.table.table-worklist tbody > tr > td:last-child {
  padding-right: 20px;
}
.table.table-worklist tbody > tr > td .edge-trim {
  background-color: #ececec;
  width: 4px;
  height: 23px;
}
.table.table-worklist tbody > tr > td .edge-trim.has-involvement {
  background-color: #3d4d84;
}
.table.table-worklist tbody > tr > td .toggle-icon {
  font-size: 18px;
}
.table.table-worklist tbody > tr > td > a {
  border-bottom: 2px solid transparent;
  font-weight: 600;
}
.table.table-worklist tbody > tr > td > a:hover {
  border-color: #3d4d84;
  color: #3d4d84;
  text-decoration: none;
}
.table.table-worklist tbody > tr.deceased > td {
  background: #e7e7e7;
  border-top-color: #cdcdcd;
}
.table.table-worklist tbody > tr.deceased > td .edge-trim {
  background-color: #111;
}
.table.table-merge-su td p {
  margin: 6px 0;
}
.table.table-merge-su td p:last-of-type {
  margin-bottom: 0;
}
.table.table-merge-su td p.title {
  margin: 0;
}
.table.table-pathway-associate-activities {
  margin-top: 20px;
}
.table .table {
  border-top: solid 2px transparent;
  margin-top: -4px;
}
.table .cp-icon-cake {
  color: #3d4d84;
  position: relative;
  top: -1px;
}
.table .cp-icon-cake:focus,
.table .cp-icon-cake:hover {
  color: #3d4d84;
}
.table .cp-icon-plus {
  color: #3d4d84;
  text-decoration: none;
}
.table .icon-my-forms,
.table .icon-view-history,
.table .cp-icon-plus {
  font-size: 20px;
}
.table .icon-my-forms:hover,
.table .icon-view-history:hover,
.table .cp-icon-plus:hover {
  color: #4d61a7;
}
.table .cp-icon-star {
  font-size: 20px;
  position: relative;
  top: 1px;
}
.table .cp-icon-care-plan-associations {
  color: #707070;
  margin-left: 3px;
}
.table .cp-icon-care-plan-associations:focus,
.table .cp-icon-care-plan-associations:hover {
  color: #707070;
}
@media only screen and (min-width: 768px) {
  .table {
    table-layout: fixed;
  }
}
.table.table-data-entry {
  table-layout: fixed;
  background-color: #fff;
}
.table.table-data-entry tr {
  border: none;
}
.table.table-data-entry tr td,
.table.table-data-entry tr th {
  border: none !important;
  padding: 8px;
}
.table.table-data-entry tr th {
  background-color: #ececec;
  min-height: 34px;
  padding: 0px 5px 5px;
  word-break: break-word;
  width: 150px;
}
.table.table-data-entry tr th.remove-row-column {
  width: 55px;
}
.table.table-data-entry tr th.date {
  width: 150px;
}
.table.table-data-entry tr th.dropdown,
.table.table-data-entry tr th.text {
  width: 250px;
}
.table.table-data-entry tr th.long-text {
  width: 300px;
}
.table.table-data-entry tr th.attachment {
  width: 300px;
  border-radius: 0;
}
.table.table-data-entry tr td .form-item-group {
  position: relative;
}
.table.table-data-entry tr td textarea {
  height: 32px;
  resize: none;
}
.table.table-data-entry tr td .filedrag {
  padding: 0;
  display: flex;
  min-height: inherit;
  height: 32px;
  background: transparent;
}
.table.table-data-entry tr td .filedrag .drag-instructions {
  width: auto !important;
  margin-right: 8px;
}
.table.table-data-entry tr td .filedrag .icon-container {
  width: 30px;
}
.table.table-data-entry tr td .filedrag .btn {
  height: 32px;
  margin: 0 !important;
  font-size: 1em !important;
  font-family: "Source Sans Pro" !important;
}
.table.table-data-entry tr td .filedrag .btn:hover,
.table.table-data-entry tr td .filedrag .btn:focus {
  background-color: #c4d9d7;
}
.table .no-data {
  height: 540px;
}
.table .no-data td {
  background-color: #fff;
}
.table.table-form-definitions {
  height: 600px;
  overflow-y: auto;
}
.table.table-form-definitions tbody {
  width: 100%;
  display: table;
}
.table.table-form-definitions td {
  width: 100%;
}
.table.table-tooltip {
  width: 350px;
  border: none;
}
.table.table-tooltip tbody tr {
  border: none;
}
.table.table-tooltip tbody tr td {
  border: none;
}
.table.table-tooltip tbody tr b {
  font-weight: 500;
}
.table.table-budget-history.body thead tr th {
  padding: 0;
  height: 0;
  border: none;
}
.table.table-budget-history.body thead tr th:last-child {
  margin-right: 0;
}
.table.table-budget-history thead tr th {
  border: none;
}
.table.table-budget-history thead tr th:last-child {
  margin-right: 0;
}
.table.table-budget-history tbody tr td .change {
  border-radius: 4px;
  padding-left: 20px;
  display: inline-block;
  text-align: right;
  position: relative;
  text-decoration: none;
}
.table.table-budget-history tbody tr td .change:before {
  display: block;
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
}
.table.table-budget-history tbody tr td .change.up {
  color: #006400;
}
.table.table-budget-history tbody tr td .change.up:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent #006400 transparent;
}
.table.table-budget-history tbody tr td .change.down {
  color: #ac0f0f;
}
.table.table-budget-history tbody tr td .change.down:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #ac0f0f transparent transparent transparent;
}
.table.table-budget-history tbody tr td .change.same {
  color: #777;
}
.table.table-budget-history tbody tr td .change.same:before {
  width: 10px;
  height: 2px;
  background-color: #777;
  top: 8px;
}
.table.table-budget-history tbody tr:nth-child(1) td {
  border-top-width: 0;
}
.table-data-entry-container {
  position: relative;
  border: 8px solid #ececec;
  background-color: #ececec;
}
.table-data-entry-container .table-data-entry-container-data {
  width: calc(100% - 55px);
  overflow-x: scroll;
  overflow-y: hidden;
}
.table-data-entry-container .table-data-entry-container-data.full-width {
  width: 100%;
}
.table-data-entry-container .table {
  table-layout: fixed;
}
.table-data-entry-container .table.table-remove-rows {
  position: absolute;
  right: 0;
  bottom: 21px;
  width: 55px;
  z-index: 3;
  border: none;
  background: #fff;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .table-data-entry-container .table.table-remove-rows {
    bottom: 17px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .table-data-entry-container .table.table-remove-rows {
    bottom: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-data-entry-container .table.table-remove-rows {
    bottom: 17px;
  }
}
.table-data-entry-container .table.table-remove-rows th {
  background-color: #ececec;
}
.table-data-entry-container .table.table-remove-rows tr td {
  line-height: 48px;
  text-align: center;
  padding: 0;
}
.page-print .table.table-data-entry {
  table-layout: fixed;
  font-size: 0.9em;
  word-break: break-all;
}
.table > thead > tr.danger th,
.table > thead > tr.danger td,
.table > tbody > tr.danger th,
.table > tbody > tr.danger td,
.table > tfoot > tr.danger th,
.table > tfoot > tr.danger td,
.table > thead > tr > th.danger,
.table > thead > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr > td.danger {
  background-color: #ffebeb;
}
.table > thead > tr.danger th .text-danger,
.table > thead > tr.danger td .text-danger,
.table > tbody > tr.danger th .text-danger,
.table > tbody > tr.danger td .text-danger,
.table > tfoot > tr.danger th .text-danger,
.table > tfoot > tr.danger td .text-danger,
.table > thead > tr > th.danger .text-danger,
.table > thead > tr > td.danger .text-danger,
.table > tbody > tr > th.danger .text-danger,
.table > tbody > tr > td.danger .text-danger,
.table > tfoot > tr > th.danger .text-danger,
.table > tfoot > tr > td.danger .text-danger {
  color: #f50000;
}
@media screen and (max-width: 850px) {
  .table-responsive.table-recent-activities,
  .table-responsive.table-notifications,
  .table-responsive.table-su-history {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: 15px;
    overflow-y: hidden;
    width: 100%;
  }
  .table-responsive.table-recent-activities > .table,
  .table-responsive.table-notifications > .table,
  .table-responsive.table-su-history > .table {
    margin-bottom: 0;
    table-layout: auto;
  }
  .table-responsive.table-recent-activities > .table td,
  .table-responsive.table-notifications > .table td,
  .table-responsive.table-su-history > .table td {
    padding-right: 16px;
  }
  .table-responsive.table-recent-activities > .table > thead > tr > th,
  .table-responsive.table-notifications > .table > thead > tr > th,
  .table-responsive.table-su-history > .table > thead > tr > th,
  .table-responsive.table-recent-activities > .table > tbody > tr > th,
  .table-responsive.table-notifications > .table > tbody > tr > th,
  .table-responsive.table-su-history > .table > tbody > tr > th,
  .table-responsive.table-recent-activities > .table > tfoot > tr > th,
  .table-responsive.table-notifications > .table > tfoot > tr > th,
  .table-responsive.table-su-history > .table > tfoot > tr > th,
  .table-responsive.table-recent-activities > .table > thead > tr > td,
  .table-responsive.table-notifications > .table > thead > tr > td,
  .table-responsive.table-su-history > .table > thead > tr > td,
  .table-responsive.table-recent-activities > .table > tbody > tr > td,
  .table-responsive.table-notifications > .table > tbody > tr > td,
  .table-responsive.table-su-history > .table > tbody > tr > td,
  .table-responsive.table-recent-activities > .table > tfoot > tr > td,
  .table-responsive.table-notifications > .table > tfoot > tr > td,
  .table-responsive.table-su-history > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive.table-recent-activities > .table-bordered,
  .table-responsive.table-notifications > .table-bordered,
  .table-responsive.table-su-history > .table-bordered {
    border: 0;
  }
  .table-responsive.table-recent-activities > .table-bordered > thead > tr > th:first-child,
  .table-responsive.table-notifications > .table-bordered > thead > tr > th:first-child,
  .table-responsive.table-su-history > .table-bordered > thead > tr > th:first-child,
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr > th:first-child,
  .table-responsive.table-notifications > .table-bordered > tbody > tr > th:first-child,
  .table-responsive.table-su-history > .table-bordered > tbody > tr > th:first-child,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive.table-recent-activities > .table-bordered > thead > tr > td:first-child,
  .table-responsive.table-notifications > .table-bordered > thead > tr > td:first-child,
  .table-responsive.table-su-history > .table-bordered > thead > tr > td:first-child,
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr > td:first-child,
  .table-responsive.table-notifications > .table-bordered > tbody > tr > td:first-child,
  .table-responsive.table-su-history > .table-bordered > tbody > tr > td:first-child,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr > td:first-child,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr > td:first-child,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive.table-recent-activities > .table-bordered > thead > tr > th:last-child,
  .table-responsive.table-notifications > .table-bordered > thead > tr > th:last-child,
  .table-responsive.table-su-history > .table-bordered > thead > tr > th:last-child,
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr > th:last-child,
  .table-responsive.table-notifications > .table-bordered > tbody > tr > th:last-child,
  .table-responsive.table-su-history > .table-bordered > tbody > tr > th:last-child,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive.table-recent-activities > .table-bordered > thead > tr > td:last-child,
  .table-responsive.table-notifications > .table-bordered > thead > tr > td:last-child,
  .table-responsive.table-su-history > .table-bordered > thead > tr > td:last-child,
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr > td:last-child,
  .table-responsive.table-notifications > .table-bordered > tbody > tr > td:last-child,
  .table-responsive.table-su-history > .table-bordered > tbody > tr > td:last-child,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr > td:last-child,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr > td:last-child,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr:last-child > th,
  .table-responsive.table-notifications > .table-bordered > tbody > tr:last-child > th,
  .table-responsive.table-su-history > .table-bordered > tbody > tr:last-child > th,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive.table-recent-activities > .table-bordered > tbody > tr:last-child > td,
  .table-responsive.table-notifications > .table-bordered > tbody > tr:last-child > td,
  .table-responsive.table-su-history > .table-bordered > tbody > tr:last-child > td,
  .table-responsive.table-recent-activities > .table-bordered > tfoot > tr:last-child > td,
  .table-responsive.table-notifications > .table-bordered > tfoot > tr:last-child > td,
  .table-responsive.table-su-history > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
.page-registry .key .circle {
  display: inline-block;
  height: 20px;
  width: 20px;
  top: 10px;
  position: relative;
}
.page-registry .form-table-registry .table-head {
  background-color: rgba(0, 0, 0, 0.1);
}
.page-registry .form-table-registry .table-head td {
  font-weight: 600;
  padding: 10px;
}
.page-registry .form-table-registry .table-body tr {
  background-color: #fff;
  height: 40px;
  border: 2px solid #ececec;
}
.page-registry .form-table-registry .table-body tr td {
  padding-left: 10px;
  padding-right: 10px;
}
.page-registry .icon-registry-circle {
  font-size: 30px;
  margin: 5px;
  color: #cfe6e4;
}
.page-registry .icon-registry-circle.legend {
  font-size: 20px !important;
  margin: 10px !important;
  margin-right: 0px !important;
}
.page-registry .icon-registry-circle.complete {
  color: #23775d;
}
.page-registry .icon-registry-circle.incomplete {
  color: #ff9b00;
}
.page-registry .icon-registry-multi {
  font-size: 30px;
  margin: 5px;
}
.page-registry .icon-registry-multi.legend {
  font-size: 20px !important;
  margin: 10px !important;
  margin-right: 0px !important;
}
.page-registry .icon-registry-multi.complete {
  color: #23775d;
}
.page-registry .icon-registry-multi.incomplete {
  color: #ff9b00;
}
.page-registry .icon-registry-multi.mixed {
  color: #dc44b3;
}
.page-registry .table.table-registry {
  table-layout: inherit !important;
  width: 500px;
}
.page-registry .table.table-registry thead tr th {
  vertical-align: bottom;
  text-align: left;
  height: 170px;
  border: none !important;
  padding: 8px 16px;
}
.page-registry .table.table-registry thead tr th.stage-header {
  height: auto;
  text-align: center;
  border: none !important;
}
.page-registry .table.table-registry thead tr th.stage-name {
  background-color: #eaf1f0;
  border: 2px solid #fff !important;
  padding: 0;
  max-width: 20px;
  overflow: hidden;
  font-family: FSMeWeb;
  font-weight: 500;
  text-overflow: ellipsis;
}
.page-registry .table.table-registry thead tr th.stage-name div {
  white-space: nowrap;
  padding-top: 10px;
  line-height: 40px;
  transform: rotate(-90deg);
  transform-origin: -25% 0%;
  position: absolute;
}
.page-registry .table.table-registry tbody tr td {
  padding: 0;
  height: 42px;
  background-color: #fff;
  width: 30px;
  text-align: center;
  border: 2px solid #ececec;
}
.page-registry .table.table-registry tbody tr td.marker:last-child {
  border-right: #fff;
}
.page-registry .table.table-registry tbody tr td.form-name {
  background-color: #f9fbfb;
  border: 2px solid #fff;
  width: auto;
  line-height: 20px;
  padding: 7px 7px;
  text-align: left;
}
.page-registry .table.table-registry tbody tr:last-child .marker {
  border-bottom: white !important;
}
.key ul {
  padding-left: 0;
  margin-bottom: 0;
}
.key ul li {
  list-style: none;
  display: inline-block;
}
.key ul li:first-child .icon-registry-circle {
  margin-left: 0 !important;
}
.table-striped .expanded-row th {
  background-color: #ececec !important;
  transition: none;
}
.table-striped .expanded-row td {
  border-top: none;
}
.table-striped .expanded-row td .table-responsive {
  background-color: #ececec;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.table-striped .expanded-row td .table-responsive .table-striped {
  margin-bottom: 0;
}
.table-striped .expanded-row td .label {
  position: initial;
  top: initial;
}
.table-striped .expanded-row .table-body-container {
  max-height: 300px;
  overflow-y: scroll;
  position: relative;
  top: 0;
}
.table-striped .expanded-row .table-body-container table {
  border-collapse: separate !important;
}
.table-striped .icon-cell {
  text-align: center;
  vertical-align: middle;
  width: 60px !important;
  max-width: 60px !important;
  padding: 12px 0 !important;
}
.table-striped .icon-cell.active {
  background-color: #ececec;
}
.table-striped .icon-cell.active .icon-view-history {
  color: #3d4d84 !important;
}
.table-striped .icon-cell a {
  width: 100%;
  height: 100%;
}
.table-striped-row {
  background-color: #fafafa;
}
.top-row {
  border-top: 2px solid #ececec !important;
}
.form-border-left {
  border-left: 2px solid #ececec !important;
}
.nav-tabs > li {
  cursor: pointer;
}
.nav-tabs.management-tabs {
  margin-bottom: 15px;
}
.nav-tabs.management-tabs > li > a {
  font-size: 1rem;
  line-height: 1.267rem;
  padding: 8px;
}
.blank-tabs-container {
  margin-bottom: 15px;
  position: relative;
}
.blank-tabs-container .menu-trigger {
  cursor: pointer;
}
.blank-tabs-container .cp-icon-chevron-up {
  display: inline-block;
  margin-top: 0.8em;
}
.blank-tabs {
  background: rgba(255, 255, 255, 0.96);
  height: 0;
  list-style: none;
  left: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  transition: opacity ease 0.2s;
  z-index: 15;
}
.blank-tabs.open {
  border-bottom: solid 1px #ececec;
  height: auto;
  opacity: 1;
}
.blank-tabs > li {
  border-bottom: solid 1px #ececec;
  margin: 0 auto;
  width: 85%;
}
.blank-tabs > li:last-of-type {
  border-bottom: none;
}
.blank-tabs > li.active > a {
  color: #3d4d84;
}
.blank-tabs > li > a {
  color: #111;
  cursor: pointer;
  display: block;
  padding: 8px 0;
}
.blank-tabs > li > a:hover {
  background-color: #fafafa;
  color: #3d4d84;
  text-decoration: none;
}
.blank-tabs > li > a:focus {
  outline: none;
  text-decoration: none;
}
.cp-icon-chevron-up {
  cursor: pointer;
}
.worklist-tabs > li {
  vertical-align: top;
  background-color: rgba(255, 255, 255, 0.6);
}
.worklist-tabs > li.active {
  background-color: #fff;
}
.worklist-tabs > li .cp-icon-cross {
  cursor: pointer;
  visibility: hidden;
}
.worklist-tabs > li:hover .cp-icon-cross,
.worklist-tabs > li:focus .cp-icon-cross {
  visibility: visible;
}
.worklist-tabs > li.closeable {
  position: relative;
}
.worklist-tabs > li.closeable .cp-icon-cross {
  position: absolute;
  top: 11px;
  right: 4px;
}
.worklist-tabs > li.tab-new-list {
  background-color: transparent;
}
.worklist-tabs > li.tab-new-list:focus,
.worklist-tabs > li.tab-new-list:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.worklist-tabs > li.tab-new-list.active {
  background-color: #fff;
}
.worklist-tabs > li.tab-new-list .icon-pin {
  margin-right: 6px;
}
.worklist-tabs > li.tab-new-list > a {
  padding-left: 8px;
  padding-right: 8px;
  color: #3d4d84 !important;
}
.worklist-tabs > li.tab-new-list > a:hover,
.worklist-tabs > li.tab-new-list > a:focus,
.worklist-tabs > li.tab-new-list > a.active {
  color: #4d61a7 !important;
}
.admin-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
}
.admin-tabs:before,
.admin-tabs:after {
  content: " ";
  display: table;
}
.admin-tabs:after {
  clear: both;
}
.admin-tabs .validation-block {
  margin: 0;
}
.admin-tabs > li {
  float: left;
  margin-right: 20px;
  padding-bottom: 5px;
}
.admin-tabs > li.active a {
  color: #3d4d84;
}
.admin-tabs > li.active a::after {
  background-color: #3d4d84;
  bottom: -6px;
  content: "";
  display: block;
  height: 3px;
  left: 50%;
  margin-left: -10px;
  position: relative;
  width: 20px;
}
.admin-tabs > li a {
  color: #111;
  text-decoration: none;
}
.admin-tabs #chevron-toggle {
  float: right;
  height: 14px;
  margin-top: 1px;
  margin-left: 3px;
}
@media (min-width: 1200px) {
  .admin-tabs > li > a {
    font-size: 1rem;
  }
}
.admin-tabs-wrapper {
  border-bottom: solid 1px #ececec;
  margin-bottom: 15px;
}
.admin-tabs-wrapper.secondary {
  border-bottom-color: #ececec;
}
.admin-tabs-wrapper.secondary .admin-tabs li.active a::after {
  display: none;
}
.nav-tabs-full-width-wrapper {
  padding: 0;
  height: 43px;
  margin-bottom: 15px;
}
.nav-tabs-full-width-wrapper .nav {
  margin: 0 auto;
  max-width: 1320px;
  padding: 0 30px;
  border-bottom-color: #ececec;
}
.nav-tabs-full-width-wrapper .nav.account-tabs {
  padding: 0 !important;
}
.nav-tabs-full-width-wrapper .nav.nav-tabs > li.active > a {
  background-color: #ececec;
  border-color: #ececec;
  border-bottom-color: transparent;
}
@media (min-width: 768px) {
  .blank-tabs-container {
    border-bottom: solid 2px #ececec;
  }
  .blank-tabs-container > .menu-trigger {
    display: none;
    visibility: hidden;
  }
  .blank-tabs {
    background-color: transparent;
    border-bottom: none;
    opacity: 1;
    height: auto;
    position: relative;
  }
  .blank-tabs.open {
    border-bottom: none;
  }
  .blank-tabs > li {
    border: none;
    display: inline-block;
    width: auto;
  }
  .blank-tabs > li > a {
    padding: 8px 10px;
  }
  .blank-tabs > li > a:hover {
    background-color: transparent;
  }
  .blank-tabs.animated {
    margin-bottom: -3px;
    overflow: hidden;
  }
  .blank-tabs.animated > li:after {
    background-color: transparent;
    content: "";
    display: block;
    height: 4px;
    margin: auto;
    transition: transform 0.15s;
    transform: translateY(6px);
  }
  .blank-tabs.animated > li.active:after {
    background-color: #3d4d84;
    transform: translateY(0);
  }
  .su-tabs-container {
    padding: 0;
  }
  .su-tabs-container .su-tabs {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: -2px;
  }
  .su-tabs-container .su-tabs > li {
    margin-right: -4px;
    flex-grow: 1;
  }
  .su-tabs-container .su-tabs > li a {
    padding: 8px 7px;
  }
  .su-tabs-container .su-tabs > li.active a,
  .su-tabs-container .su-tabs > li.active a:focus {
    background-color: #fafafa;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
  .su-tabs-container .su-tabs.animated > li:after {
    display: none;
  }
  .worklist-tabs > li.closeable > a {
    padding-left: 15px;
  }
  .worklist-tabs > li.closeable .cp-icon-cross {
    top: 11px;
    right: -2px;
  }
  .worklist-tabs > li.tab-new-list > a .cp-icon-plus {
    width: auto;
  }
}
@media (min-width: 992px) {
  .su-tabs-container .su-tabs > li {
    width: auto;
  }
  .su-tabs-container .su-tabs.animated {
    margin-bottom: -3px;
  }
  .su-tabs-container .su-tabs.animated > li:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .blank-tabs > li > a {
    font-size: 1.13333333rem;
    padding: 10px 14px;
  }
  .worklist-tabs > li.closeable .cp-icon-cross {
    top: 14px;
  }
}
.full-width .container,
.max-width .container,
.full-width.container,
.max-width.container {
  max-width: none !important;
}
.full-width .nav-tabs-full-width-wrapper .nav,
.max-width .nav-tabs-full-width-wrapper .nav {
  max-width: none !important;
}
.full-width .event-dd .dropdown-section,
.max-width .event-dd .dropdown-section {
  width: calc(100% - 50px);
}
.full-width .event-dd .dropdown-menu,
.max-width .event-dd .dropdown-menu {
  width: calc(100% - 50px);
}
.full-width .btn.back-to-top.fixed,
.max-width .btn.back-to-top.fixed {
  margin-left: -23px;
  z-index: 9999;
}
@media screen and (max-width: 1024px) {
  .full-width .btn.back-to-top.fixed,
  .max-width .btn.back-to-top.fixed {
    margin-left: -5px;
  }
}
.full-width .focus-area,
.max-width .focus-area {
  float: left;
  position: relative;
  width: calc(100% - 400px);
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (max-width: 1024px) {
  .full-width .focus-area,
  .max-width .focus-area {
    width: 100%;
  }
}
.full-width .focus-area.collapsed-sidebar,
.max-width .focus-area.collapsed-sidebar {
  width: calc(100% - 50px);
}
@media screen and (max-width: 1024px) {
  .full-width .focus-area.collapsed-sidebar,
  .max-width .focus-area.collapsed-sidebar {
    width: calc(100% - 65px);
  }
}
.full-width .side-panel,
.max-width .side-panel {
  top: 0;
  right: 0;
  width: 400px;
  position: relative;
  min-height: 1px;
  padding: 0;
  z-index: 1;
}
.full-width .side-panel:after,
.max-width .side-panel:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #fff;
  bottom: 0;
  height: 100vh;
  z-index: -1;
}
.full-width .side-panel.short-content:after,
.max-width .side-panel.short-content:after {
  display: none;
}
.full-width .side-panel .collapse-sidebar-component,
.max-width .side-panel .collapse-sidebar-component {
  background-color: #fff;
  border-top: 2px solid #d7dae6;
  bottom: 0;
  position: fixed;
  width: 100%;
}
.full-width .side-panel .collapse-sidebar-component .text,
.max-width .side-panel .collapse-sidebar-component .text {
  font-family: "Sofia Pro";
  font-size: 1.2em;
  margin-left: 10px;
}
@media screen and (max-width: 1140px) {
  .full-width .side-panel .collapse-sidebar-component,
  .max-width .side-panel .collapse-sidebar-component {
    display: none;
  }
}
.full-width .side-panel .btn.btn-collapsed-sidebar,
.max-width .side-panel .btn.btn-collapsed-sidebar {
  width: 400px;
  height: 50px;
  border-radius: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: none;
  border: none;
  color: #494949;
  display: inline-flex;
  align-items: center;
  padding-left: 15px;
}
.full-width .side-panel .btn.btn-collapsed-sidebar .progress-bar-sidebar,
.max-width .side-panel .btn.btn-collapsed-sidebar .progress-bar-sidebar {
  height: 8px;
  width: 34px;
  margin-bottom: 0;
  border: none;
  position: relative;
  border: 2px solid #494949;
}
.full-width .side-panel .btn.btn-collapsed-sidebar .progress-bar-sidebar .progress-bar,
.max-width .side-panel .btn.btn-collapsed-sidebar .progress-bar-sidebar .progress-bar {
  background-color: #494949;
  top: 0;
  left: 0;
  position: absolute;
}
.full-width .side-panel .btn.btn-collapsed-sidebar h4,
.max-width .side-panel .btn.btn-collapsed-sidebar h4 {
  color: #707070;
  padding-left: 10px;
}
.full-width .side-panel .btn.btn-collapsed-sidebar:hover,
.max-width .side-panel .btn.btn-collapsed-sidebar:hover,
.full-width .side-panel .btn.btn-collapsed-sidebar:focus,
.max-width .side-panel .btn.btn-collapsed-sidebar:focus {
  background-color: #f6f7f8;
  color: #3d4d84;
}
.full-width .side-panel .btn.btn-collapsed-sidebar:hover .progress-bar-sidebar,
.max-width .side-panel .btn.btn-collapsed-sidebar:hover .progress-bar-sidebar,
.full-width .side-panel .btn.btn-collapsed-sidebar:focus .progress-bar-sidebar,
.max-width .side-panel .btn.btn-collapsed-sidebar:focus .progress-bar-sidebar {
  border-color: #3d4d84;
}
.full-width .side-panel .btn.btn-collapsed-sidebar:hover .progress-bar-sidebar .progress-bar,
.max-width .side-panel .btn.btn-collapsed-sidebar:hover .progress-bar-sidebar .progress-bar,
.full-width .side-panel .btn.btn-collapsed-sidebar:focus .progress-bar-sidebar .progress-bar,
.max-width .side-panel .btn.btn-collapsed-sidebar:focus .progress-bar-sidebar .progress-bar {
  background-color: #3d4d84;
}
.full-width .side-panel.collapsed,
.max-width .side-panel.collapsed {
  width: 50px;
  float: right;
}
.full-width .side-panel.collapsed .sidebar-container .fixed-sidebar,
.max-width .side-panel.collapsed .sidebar-container .fixed-sidebar {
  width: 50px !important;
}
.full-width .side-panel.collapsed .sidebar-container .fixed-sidebar.sticky,
.max-width .side-panel.collapsed .sidebar-container .fixed-sidebar.sticky {
  margin-top: 4px;
  z-index: 9999;
}
.full-width .side-panel.collapsed .btn.btn-collapsed-sidebar,
.max-width .side-panel.collapsed .btn.btn-collapsed-sidebar {
  width: 50px;
  justify-content: center;
  padding-left: 0;
}
.full-width .side-panel .fixed-sidebar,
.max-width .side-panel .fixed-sidebar {
  width: 400px;
}
.full-width .hover-menu .hover-menu-content,
.max-width .hover-menu .hover-menu-content {
  position: absolute;
  display: none;
  min-width: 400px;
  right: 50px;
  border: 2px solid #d7dae6;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 99999;
}
.full-width .hover-menu .hover-menu-content h3,
.max-width .hover-menu .hover-menu-content h3 {
  color: #3a3a3a;
  margin-bottom: 10px;
}
.full-width .hover-menu .hover-menu-content .progress-bar-info,
.max-width .hover-menu .hover-menu-content .progress-bar-info {
  width: 100%;
}
.full-width .hover-menu .hover-menu-content .list-item .link-item,
.max-width .hover-menu .hover-menu-content .list-item .link-item {
  margin-left: -15px;
  margin-right: -15px;
}
.full-width .hover-menu .hover-menu-content::after,
.max-width .hover-menu .hover-menu-content::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 18px;
  right: -8px;
}
.full-width .hover-menu .hover-menu-content.hover-menu-event-dropdown,
.max-width .hover-menu .hover-menu-content.hover-menu-event-dropdown,
.full-width .hover-menu .hover-menu-content.hover-menu-state-component,
.max-width .hover-menu .hover-menu-content.hover-menu-state-component {
  padding: 0;
}
.full-width .hover-menu .hover-menu-content.hover-menu-details,
.max-width .hover-menu .hover-menu-content.hover-menu-details {
  margin-top: -52px;
}
.full-width .hover-menu .hover-menu-content.hover-menu-details::after,
.max-width .hover-menu .hover-menu-content.hover-menu-details::after {
  margin-top: 52px;
}
.full-width .hover-menu .hover-menu-content .event-dd,
.max-width .hover-menu .hover-menu-content .event-dd {
  height: 50px;
}
.full-width .hover-menu .hover-menu-content .event-dd .btn,
.max-width .hover-menu .hover-menu-content .event-dd .btn {
  height: 50px;
  margin-bottom: 0 !important;
}
.full-width .hover-menu .hover-menu-content .event-dd .btn.remove-section,
.max-width .hover-menu .hover-menu-content .event-dd .btn.remove-section {
  padding: 10px;
}
.full-width .hover-menu .hover-menu-content .state-component-buttons,
.max-width .hover-menu .hover-menu-content .state-component-buttons {
  margin-bottom: 0 !important;
}
.full-width .hover-menu:nth-child(1) .hover-menu-content,
.max-width .hover-menu:nth-child(1) .hover-menu-content {
  top: -2px;
}
.full-width .hover-menu:nth-child(2) .hover-menu-content,
.max-width .hover-menu:nth-child(2) .hover-menu-content {
  top: 50px;
}
.full-width .hover-menu:nth-child(3) .hover-menu-content,
.max-width .hover-menu:nth-child(3) .hover-menu-content {
  top: 102px;
}
.full-width .hover-menu:nth-child(4) .hover-menu-content,
.max-width .hover-menu:nth-child(4) .hover-menu-content {
  top: 154px;
}
.full-width .hover-menu:nth-child(5) .hover-menu-content,
.max-width .hover-menu:nth-child(5) .hover-menu-content {
  top: 206px;
}
.full-width .hover-menu:nth-child(6) .hover-menu-content,
.max-width .hover-menu:nth-child(6) .hover-menu-content {
  top: 258px;
}
.full-width .hover-menu:hover .btn,
.max-width .hover-menu:hover .btn,
.full-width .hover-menu:focus .btn,
.max-width .hover-menu:focus .btn {
  background-color: #f6f7f8;
  color: #3d4d84;
}
.full-width .hover-menu:hover .hover-menu-content,
.max-width .hover-menu:hover .hover-menu-content,
.full-width .hover-menu:focus .hover-menu-content,
.max-width .hover-menu:focus .hover-menu-content {
  display: block;
}
.full-width .edit-form-row,
.max-width .edit-form-row {
  margin-right: -30px;
}
.full-width .page-header,
.max-width .page-header {
  margin-right: 15px;
}
@media screen and (max-width: 1024px) {
  .full-width,
  .max-width {
    overflow-x: hidden;
  }
  .full-width .page-activities .side-panel,
  .max-width .page-activities .side-panel {
    right: 20px;
  }
}
.scrollable-container {
  overflow: auto;
}
.scrollable-container.scroll-260 {
  height: 260px;
  margin-bottom: 2px;
}
.table-head-container {
  border-bottom: 2px solid #ddd;
  padding-right: 16px;
}
.highcharts-container {
  width: 100% !important;
}
.highcharts-loading {
  width: 100% !important;
}
.admin #wrap {
  padding-bottom: 65px;
}
.page-admin .container {
  padding: 0;
}
.page-admin .admin-permissions label {
  display: block;
}
.page-admin .page-header {
  margin-bottom: 5px;
}
.page-admin .page-header.personal-details-header {
  margin-top: 0;
}
.page-admin .page-toolbar {
  border-bottom: solid 1px #ececec;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.page-admin .details {
  margin-bottom: 5px;
}
.page-admin .tick-box-container .tick-box-input-container input ~ .checkmark:after {
  color: #3d4d84;
}
.page-admin .tick-box-container .tick-box-input-container input:checked ~ .checkmark {
  border-color: #3d4d84;
}
.page-admin .tick-box-container .tick-box-input-container input:focus ~ .checkmark {
  background-color: #ececec;
  border-color: #3d4d84;
}
.page-admin .table td a {
  font-weight: 600;
  font-family: "Sofia Pro";
  cursor: pointer;
}
.page-admin .table-stats .table {
  border-radius: 4px;
  overflow: hidden;
}
.page-admin .table-stats .table th,
.page-admin .table-stats .table td {
  text-align: center;
  border: none;
  background-color: #f9f9f9;
}
.page-admin .table-stats .table td {
  font-weight: 600;
  font-size: 25px;
}
.page-admin .table-stats .table thead .highlight-row th {
  background-color: #ececec;
}
.page-admin .table.table-striped tr th {
  background-color: #707070;
  color: #fff !important;
  border: none;
  transition: background-color 0.2s ease;
}
.page-admin .table.table-striped tr th.sortable {
  padding: 0;
}
.page-admin .table.table-striped tr th.sortable .cp-icon-caret-up,
.page-admin .table.table-striped tr th.sortable .cp-icon-caret-down {
  color: #fff !important;
}
.page-admin .table.table-striped tr th.sortable button {
  border: none;
  background: transparent;
}
.page-admin .table.table-striped tr th:hover {
  background-color: #7d7d7d;
}
.page-admin .table.table-striped tr td {
  border: none;
}
.page-admin .table .input-field input {
  line-height: normal !important;
}
.page-admin .table .tick-box-container {
  line-height: normal !important;
}
.page-admin .table .tick-box-container .tick-box-input-container {
  margin: 0;
}
.page-admin .table .table-header-button {
  padding: 0;
  height: 19px !important;
}
.page-admin .table .table-header-button .caret {
  margin-bottom: 6px;
}
.page-admin .table .table-header-button button {
  background-color: #f4f4f4 !important;
  height: 19px !important;
  line-height: 18px !important;
}
.page-admin .table .table-header-button button:hover {
  background-color: #ececec !important;
}
.page-admin .table .table-header-button button:active {
  background-color: #d3d3d3 !important;
}
.page-admin .list-page-toolbar {
  padding: 15px 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  margin: 0 -15px;
}
.page-admin .list-page-toolbar .dropdown-input .dropdown-content {
  position: relative;
  top: -2px;
}
.page-admin .list-page-toolbar .search-control input {
  margin-top: -2px;
  height: 34px;
}
@media only screen and (min-width: 768px) {
  .page-admin .table {
    table-layout: auto;
  }
}
body.admin .select2-container.select2-container--default .select2-selection--single[aria-expanded="true"] {
  border-color: #3d4d84;
}
body.admin .select2-container.select2-container--default .select2-selection--single:hover:not([aria-disabled="true"]),
body.admin .select2-container.select2-container--default .select2-selection--single:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
body.admin .select2-container.select2-container--default .select2-dropdown.select2-dropdown--below {
  border-color: #3d4d84;
}
body.admin .select2-container.select2-container--default .select2-selection--multiple[aria-expanded="true"] {
  border-color: #3d4d84;
}
body.admin .select2-container.select2-container--default .select2-selection--multiple:hover:not([aria-disabled="true"]),
body.admin .select2-container.select2-container--default .select2-selection--multiple:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
body.admin .form-control:hover:not([aria-disabled="true"]),
body.admin .form-control:focus:not([aria-disabled="true"]) {
  border-color: #3d4d84;
}
#securityTree > ul > li > [class="cp-icon-admin"] {
  color: #3a3a3a;
}
#securityTree > ul > li.node-selected > [class="cp-icon-admin"] {
  color: #fff;
}
.merge-su .panel-merge-su {
  padding-top: 10px;
  padding-bottom: 10px;
}
.merge-su .panel-merge-su .btn-link {
  margin-top: -36px;
}
.merge-su .merge-users-icon {
  height: 48px;
  margin-bottom: 15px;
}
.merge-su .merge-su-single {
  display: block;
  height: 64px;
  margin: 0 auto 10px;
}
.merge-su .merge-su-single.sm {
  height: 48px;
}
.merge-su p.merge-desc {
  margin-bottom: 40px;
}
.merge-su .select2-container--default {
  text-align: left !important;
}
.merge-su .merge-conflict ul {
  list-style: disc;
  margin-top: 5px;
  padding-left: 40px;
}
.merge-su .user-information {
  border-radius: 6px;
  border: solid 1px #ececec;
  margin-top: 1px;
  padding: 10px;
  text-align: left;
}
.merge-su .merged-user-details {
  padding: 25px;
}
.merge-su .merged-user-details label {
  display: block;
  margin-bottom: 15px;
  text-align: center;
}
.panel-broadcast .has-error .form-group {
  margin-bottom: 0px;
}
.admin-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.admin-wrapper .sidebar-menu,
.admin-wrapper .flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
.admin-wrapper .flex-item {
  flex-grow: 1;
  padding: 0 15px;
  width: calc(89% - 20px);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 125px);
  background-color: #fff;
}
.admin-wrapper .flex-item > div:first-child:not(.vue-container) {
  padding: 15px;
}
.admin-wrapper .sidebar-menu {
  background-color: #d7dae6;
  padding: 15px 0 0 20px;
  width: 220px;
}
.admin-wrapper .sidebar-menu h1 {
  margin-bottom: 5px;
}
.admin-wrapper .sidebar-menu ul {
  margin: 0;
}
.admin-wrapper .sidebar-menu ul:before,
.admin-wrapper .sidebar-menu ul:after {
  content: " ";
  display: table;
}
.admin-wrapper .sidebar-menu ul:after {
  clear: both;
}
.admin-wrapper .sidebar-menu ul .validation-block {
  margin: 0;
}
.admin-wrapper .sidebar-menu ul li {
  display: block;
  float: none;
  width: auto;
}
.admin-wrapper .sidebar-menu ul li.sidebar-category a {
  color: #111;
  min-width: 100%;
  display: block;
  text-decoration: none;
}
.admin-wrapper .sidebar-menu ul li.sidebar-category .sidebar-category-title {
  margin-bottom: 5px;
}
.admin-wrapper .sidebar-menu ul li.sidebar-category .sidebar-category-title .imo {
  float: right;
  padding: 4px 20px 0 0;
}
.admin-wrapper .sidebar-menu ul li.sidebar-category ul {
  margin: 10px 0;
}
.admin-wrapper .sidebar-menu ul li.sidebar-menu-item {
  margin-right: 20px;
}
.admin-wrapper .sidebar-menu ul li.sidebar-menu-item a {
  color: #111;
  min-width: 100%;
  display: block;
  margin-bottom: 5px;
  padding: 5px 20px;
  border-radius: 20px;
  text-decoration: none;
}
.admin-wrapper .sidebar-menu ul li.sidebar-menu-item.active a,
.admin-wrapper .sidebar-menu ul li.sidebar-menu-item:hover a {
  background-color: #fff;
  color: #000;
}
.admin-wrapper .audit .form-group {
  padding: 10px;
  background-color: #dddddd;
  border-radius: 5px;
  margin: 0px;
}
.admin-wrapper .audit .form-group .form-group {
  padding: 10px;
  background-color: #ECECEC;
  border-radius: 5px;
}
.admin-wrapper .audit .form-group .form-group .form-group {
  background-color: #ececec;
}
.admin-wrapper .audit h1,
.admin-wrapper .audit h2,
.admin-wrapper .audit h3 {
  color: black;
}
.admin-wrapper .audit input::-webkit-outer-spin-button,
.admin-wrapper .audit input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.admin-wrapper .audit input[type=number] {
  -moz-appearance: textfield;
}
.admin-wrapper .audit .date-time-picker {
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-flow: row wrap;
  flex-grow: 1;
}
.admin-wrapper .audit .date-time-picker .date-picker {
  display: flex;
  flex-direction: row;
}
.admin-wrapper .audit .date-time-picker .date-picker input {
  text-align: center;
}
.admin-wrapper .audit .date-time-picker .date-picker input.datepicker-day,
.admin-wrapper .audit .date-time-picker .date-picker input.datepicker-month {
  width: 7ch;
}
.admin-wrapper .audit .date-time-picker .date-picker input.datepicker-year {
  width: 8ch;
}
.admin-wrapper .audit .date-time-picker .date-picker .separator {
  position: relative;
  top: 55%;
}
.admin-wrapper .audit .date-time-picker .time-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 120px;
}
.admin-wrapper .audit .date-time-picker .time-picker input {
  text-align: center;
  width: 7ch;
}
.admin-wrapper .audit .date-time-picker .time-picker .separator {
  position: relative;
  top: 15%;
}
.admin-wrapper .audit .from-to-filters {
  padding-right: 0;
  width: 650px;
}
.admin-wrapper .audit .where-filters > .form-group {
  display: flex;
}
@media only screen and (min-width: 1366px) {
  .admin-wrapper .audit .where-filters {
    padding-left: 0;
    width: calc(100% - 650px);
  }
}
@media only screen and (max-width: 1366px) {
  .admin-wrapper .audit .where-filters {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .admin-wrapper .audit .where-filters .col-md-4 {
    width: 33.33333333%;
    float: left;
  }
}
.admin-wrapper .audit .input-field {
  min-height: 32px;
}
.admin-wrapper .audit .input-field label,
.admin-wrapper .audit .input-field input {
  font-family: "FsMeWeb" !important;
  font-size: 12px !important;
}
.admin-wrapper .audit .input-field label strong,
.admin-wrapper .audit .input-field input strong {
  font-family: "FsMeWeb" !important;
  font-size: 12px !important;
}
.admin-wrapper .audit .btn-clear {
  background-color: #ECECEC;
  border: unset;
}
.admin-wrapper .audit .form-group [class^="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.admin-wrapper .audit #calculation-request-modal .modal-body {
  max-height: 75vh;
  padding: 15px;
}
.admin-wrapper .audit #calculation-request-modal .modal-xl {
  width: 70vw;
}
.admin-wrapper .audit #calculation-request-modal .table td {
  padding: 8px;
}
.admin-wrapper .audit .calculation-request-modal .calculation-request {
  padding: 15px;
  margin: -15px -15px 0 -15px;
  background-color: #fafafa;
}
.admin-wrapper .audit .calculation-request-modal .calculation-request .text-underlined {
  cursor: pointer;
}
.admin-wrapper .audit .calculation-request-modal .budget-tile {
  margin: 0 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0;
}
.admin-wrapper .audit .calculation-request-modal .budget-tile .budget {
  font-size: 2rem;
  color: #707070;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary {
  border-bottom: none;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li a {
  font-size: 1.4em;
  font-weight: normal;
  transition: background-color linear 0.2s;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a {
  color: #000;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:hover,
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:focus {
  background-color: #fff;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a {
  color: #000;
}
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:hover,
.admin-wrapper .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:focus {
  padding-bottom: 6px;
  border-radius: 4px;
  box-shadow: inset 0 37px 20px rgba(0, 0, 0, 0.016);
}
.admin-wrapper .audit .calculation-request-modal .tab-content {
  max-height: 40vh;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0;
  height: 100%;
  overflow-y: scroll;
}
.admin-wrapper .audit .calculation-request-modal .tab-content h3 {
  line-height: 32px;
}
.admin-wrapper .audit .calculation-request-modal .tab-content h3 .btn {
  display: block;
  text-align: left;
  width: 100%;
}
.admin-wrapper .audit .calculation-request-modal .tab-content h3 .btn .icon {
  float: right;
  width: 32px;
  height: 32px;
  line-height: inherit;
  display: block;
  text-align: center;
  transition: transform linear 0.2s;
}
.admin-wrapper .audit .calculation-request-modal .tab-content h3 .btn:not(.collapsed) .icon {
  transform: rotate(180deg);
}
.admin-wrapper .audit .calculation-request-modal .tab-content .table,
.admin-wrapper .audit .calculation-request-modal .tab-content .table-responsive {
  margin-bottom: 0;
}
.admin-wrapper .audit .calculation-request-modal .list-qa-feedback-items li {
  padding: 4px 8px;
  margin: 0 0 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.category-list {
  border-bottom: solid 1px #ececec;
  margin-bottom: 14px;
  padding-left: 0;
  padding-top: 11px;
  padding-bottom: 5px;
}
.category-list > li {
  display: inline-block;
  margin-right: 15px;
}
.category-list > li.active a {
  color: #3d4d84;
}
.category-list > li a {
  color: #111;
}
.input-field label,
.input-field input {
  font-family: "FSMeWeb", helvetica, arial, sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.input-field label strong,
.input-field input strong {
  font-family: "Sofia Pro", helvetica, arial, sans-serif !important;
  font-size: 14px !important;
}
.strong-field-is-required strong:after {
  content: "*";
  color: #ef4444;
  padding-right: 4px;
}
.password-input-field .text-input-field {
  width: calc(100% - 28px);
  float: left;
}
.password-input-field .text-input-field .text-input {
  width: 252px !important;
}
.password-input-field .icon-knowledge-base {
  cursor: pointer;
  float: right;
  margin-top: 5px;
}
.input-field-inline label strong {
  display: inline-block;
  width: 100%;
}
.input-field-inline .text-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-field-inline .text-input,
.input-field-inline .dropdown-input {
  position: relative;
  margin-top: -21px;
}
.input-field-inline .help-block {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 8px;
  color: #6B6B6B;
}
.input-field-inline .validation-block {
  margin-top: 0;
  width: 100%;
  text-align: left;
  line-height: 1.2em;
}
.checkbox-field-text {
  padding-right: 32px;
}
.settings-list,
.account-modal-content {
  column-rule: 2px dashed #ececec;
}
.settings-list .col-md-6:first-child:not(:only-child),
.account-modal-content .col-md-6:first-child:not(:only-child) {
  border-right: 2px dashed #ececec;
}
.settings-list .validation-block,
.account-modal-content .validation-block {
  margin-top: 2px;
  margin-bottom: 8px;
}
.settings-list .help-block,
.account-modal-content .help-block {
  margin: 0;
  line-height: 16px;
}
.settings-list .input-field,
.account-modal-content .input-field {
  margin-bottom: 12px;
}
.settings-list .dropdown-input-field,
.account-modal-content .dropdown-input-field {
  margin-bottom: 0px;
}
.settings-list .dropdown-input-field:not(.input-field-inline) .dropdown-input,
.account-modal-content .dropdown-input-field:not(.input-field-inline) .dropdown-input {
  margin-top: 2px;
}
.settings-list .dropdown-input-field:not(.input-field-inline) .dropdown-input input,
.account-modal-content .dropdown-input-field:not(.input-field-inline) .dropdown-input input {
  padding-left: 30px;
}
.settings-page-content {
  padding: 0 15px;
}
.settings-page-content .settings-list {
  columns: 2;
  column-gap: 40px;
  max-width: 1100px;
}
.settings-page-content .settings-list.single-column {
  columns: 1;
  max-width: 550px;
}
.settings-page-content .settings-list .input-field-inline .text-input,
.settings-page-content .settings-list .input-field-inline .dropdown-input {
  width: 120px;
}
.settings-page-content .settings-list .input-field-inline .help-block {
  width: calc(100% - 120px);
}
.settings-page-content .settings-list #DateFormat .text-input,
.settings-page-content .settings-list #DateFormat .dropdown-input {
  width: 200px;
}
.settings-page-content .settings-list #DateFormat .help-block {
  width: calc(100% - 200px);
}
.account-modal-content a {
  cursor: pointer;
}
.account-modal-content .remove-line {
  color: #000;
  font-size: 18px;
  text-decoration: none !important;
  right: 32px;
  position: absolute;
  margin-top: -36px;
  z-index: 100;
}
.account-modal-content .remove-line:hover {
  text-shadow: #000 0px 0px 1px;
}
.account-modal-content .table-striped tr td .cp-icon-cross {
  margin-right: 0;
}
.account-modal-content .input-field-inline .text-input,
.account-modal-content .input-field-inline .dropdown-input {
  width: 280px;
}
.account-modal-content .input-field-inline .help-block {
  width: calc(100% - 280px);
}
.account-modal-content .shorter-inline .text-input,
.account-modal-content .shorter-inline .dropdown-input {
  width: 260px;
}
.account-modal-content .shorter-inline .help-block {
  width: calc(100% - 260px);
}
@media only screen and (min-width: 1366px) {
  .account-modal-content .input-field-inline .text-input,
  .account-modal-content .input-field-inline .dropdown-input {
    width: 380px;
  }
  .account-modal-content .input-field-inline .help-block {
    width: calc(100% - 380px);
  }
  .account-modal-content .shorter-inline .text-input,
  .account-modal-content .shorter-inline .dropdown-input {
    width: 360px;
  }
  .account-modal-content .shorter-inline .help-block {
    width: calc(100% - 360px);
  }
  .account-modal-content .password-input-field .input-field-inline .text-input {
    width: 352px !important;
  }
}
.table .input-field input {
  line-height: normal !important;
}
.table .tick-box-container {
  line-height: normal !important;
}
.table .tick-box-container .tick-box-input-container {
  margin: 0;
}
.table .table-header-button {
  padding: 0;
  height: 19px !important;
}
.table .table-header-button .caret {
  margin-bottom: 6px;
}
.table .table-header-button button {
  background-color: #f4f4f4 !important;
  height: 19px !important;
  line-height: 18px !important;
}
.table .table-header-button button:hover {
  background-color: #ececec !important;
}
.table .table-header-button button:active {
  background-color: #d3d3d3 !important;
}
.config-realignment .processing {
  left: 50%;
  transform: translate(-50%, 50%);
  max-width: 55vw;
  position: relative;
  clear: both;
}
.config-realignment .processing .sk-spinner {
  width: 70px;
  height: 70px;
}
.config-realignment .processing .alert .details {
  max-width: 100%;
}
.config-realignment .results {
  height: 80vh;
  overflow-y: scroll;
}
.config-realignment.complete {
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
}
.config-realignment.complete .icon {
  font-size: 60px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  background: #42ce56;
  color: #fff;
  margin-right: 18px;
}
.config-realignment.complete .content {
  flex-grow: 1;
}
.page-activities .formInstanceDescription hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.page-activities .readOnlyInput .panel-heading,
.page-activities .readOnlyInput .panel-body {
  pointer-events: none;
}
.page-activities .readOnlyInput .form-item-checkbox-radio-group {
  background-color: #eee !important;
}
.page-activities .readOnlyInput .js-collapse-section,
.page-activities .readOnlyInput .btn-view-history {
  pointer-events: auto !important;
}
.page-activities .readOnlyInput .note a {
  pointer-events: auto !important;
}
.page-activities .readOnlyInput .guidance-notes {
  pointer-events: auto !important;
}
.page-activities .focus-area {
  margin-bottom: 100% !important;
}
.page-activities .focus-area .service-user-panel {
  flex-wrap: nowrap !important;
}
.page-activities .focus-area .service-user-panel .view-form-item {
  flex-basis: auto !important;
  max-width: 550px !important;
}
.page-activities .focus-area .service-user-panel .view-form-item.centre-of-care-item {
  min-width: 160px;
}
.page-activities .focus-area .service-user-panel .service-user-panel-field {
  display: flex;
  margin-bottom: 0;
}
.page-activities .focus-area .service-user-panel .service-user-panel-field .input-field.dropdown-input-field .dropdown-input-container {
  display: flex;
}
.page-activities .focus-area .service-user-panel .service-user-panel-field .input-field.dropdown-input-field .dropdown-input-container .dropdown-input {
  width: 400px;
  flex-grow: 0;
}
.page-activities .focus-area .service-user-panel .service-user-panel-field input.form-control,
.page-activities .focus-area .service-user-panel .service-user-panel-field .dropdown-button {
  min-height: 35px;
}
.page-activities .focus-area .service-user-panel .service-user-panel-field .btn {
  margin-top: auto;
  margin-left: 5px;
  position: relative;
}
.page-activities .edit-form .panel-body {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group {
  flex-grow: 1;
  flex-basis: 100%;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state {
  flex-basis: calc(50% - 6px);
  max-width: calc(50% - 6px);
  position: relative;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .checkbox-group-buttons-container .buttons-container,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .checkbox-group-buttons-container .buttons-container {
  width: 190px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .checkbox-group-buttons-container .buttons-container.icons-only,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .checkbox-group-buttons-container .buttons-container.icons-only {
  width: 114px;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .checkbox-group-buttons-container .buttons-container > div,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .checkbox-group-buttons-container .buttons-container > div {
  display: flex;
  justify-content: flex-end;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .tri-state-label,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .tri-state-label,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state label,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state label {
  max-width: calc(100% - 190px);
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .form-item,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .form-item {
  max-width: 100%;
  flex-basis: 100%;
  margin: 10px 0;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.bi-state .form-item:last-of-type,
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group.tri-state .form-item:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 1280px) {
  .page-activities .edit-form .panel-body .form-item-checkbox-radio-group {
    flex-basis: 100%;
  }
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group label:focus {
  outline: none;
}
.page-activities .edit-form .panel-body .form-item-checkbox-radio-group:nth-child(odd):last-of-type {
  flex-grow: 0;
}
.page-activities .edit-form .panel-body .form-item-row:not(.form-item-checkbox-radio-group) {
  flex-basis: 100%;
}
.page-activities .edit-form .panel-body .flex-full-width {
  flex-basis: 100%;
}
.page-activities .edit-form .panel-body .flex-full-width .additional-questions-inner {
  margin: 9px 0;
}
.page-activities .edit-form .panel-body .panel-body {
  padding: 0;
}
.page-activities .edit-form .panel-body.service-user-panel {
  flex-direction: column;
}
.page-activities .edit-form .panel-body.service-user-panel .form-item-group {
  margin-bottom: 20px;
}
.page-activities .edit-form .form-item-children {
  margin-top: 15px;
}
.page-activities .flex-item {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-content: center;
}
.page-activities .flex-full-width {
  width: 100%;
}
.page-activities .collapse-enabled-hover-state {
  cursor: pointer;
}
.page-activities .collapse-enabled-hover-state:hover .cp-icon-chevron-up {
  color: #3d4d84;
}
.page-activities .collapse-enabled-hover-state:hover + .collapse-enabled-hover-state .cp-icon-chevron-up {
  color: #3d4d84;
}
.page-activities .panel-heading .collapse-enabled-hover-state {
  cursor: pointer;
}
.page-activities .panel-heading .collapse-enabled-hover-state:hover .cp-icon-chevron-up {
  color: rgba(255, 255, 255, 0.7);
}
.page-activities .panel-heading .collapse-enabled-hover-state:hover + .collapse-enabled-hover-state .cp-icon-chevron-up {
  color: rgba(255, 255, 255, 0.7);
}
.page-activities .autoFillWand {
  color: #fca744;
}
.page-activities .buttons-row {
  padding: 4px 0;
}
.page-activities .triggers-menu {
  width: 245px;
}
.page-activities .triggers-menu > li > a {
  white-space: normal;
}
.page-activities .toggle-answer-container {
  margin-bottom: -9px;
}
.page-activities .toggle-answer-container .toggle-label {
  font-size: 1rem;
  line-height: 1.267rem;
}
.page-activities .toggle-answer-container .btn-link {
  margin-left: -2px;
  padding: 4px;
}
.page-activities .form-title:before,
.page-activities .form-title:after {
  content: " ";
  display: table;
}
.page-activities .form-title:after {
  clear: both;
}
.page-activities .form-title .validation-block {
  margin: 0;
}
.page-activities .form-title .select2-basic {
  display: inline-block;
}
.page-activities .form-title .select2-basic .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 3px;
  top: 13px;
}
.page-activities .modal {
  overflow-y: scroll;
}
.page-activities .modal h3 {
  margin-top: 0;
}
.page-activities .modal #item-history-chart {
  margin-bottom: 20px;
}
.page-activities .modal .answer-history-seperator {
  margin-left: -25px;
  margin-right: -25px;
}
.page-activities .modal .close-form-group {
  margin-bottom: 0;
}
.page-activities .modal .unanswered-toggle {
  margin-bottom: 30px;
}
.page-activities .modal .unanswered-questions {
  margin-bottom: 20px;
}
.page-activities .modal hr {
  border-color: #ececec;
}
.page-activities .modal hr.trigger-divide {
  margin: 25px 0 20px;
}
.page-activities .modal .triggers {
  margin-bottom: 20px;
  margin: 10px 0 10px 15px;
}
.page-activities .modal .triggers .triggers-row {
  border-bottom: solid 1px #ececec;
  display: flex;
}
.page-activities .modal .triggers .triggers-row:before,
.page-activities .modal .triggers .triggers-row:after {
  content: " ";
  display: table;
}
.page-activities .modal .triggers .triggers-row:after {
  clear: both;
}
.page-activities .modal .triggers .triggers-row .validation-block {
  margin: 0;
}
.page-activities .modal .triggers .triggers-row .triggers-name {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  float: left;
  margin: -4px 0 4px;
  width: 75%;
}
.page-activities .modal .triggers .triggers-row .triggers-name label {
  flex-basis: 100%;
}
.page-activities .modal .triggers .triggers-row .triggers-toggle {
  float: left;
  text-align: right;
  width: 25%;
}
.page-activities .modal .triggers .triggers-row:only-child {
  border-bottom: none;
  padding-bottom: 0;
}
.page-activities .modal .answer-toggle {
  margin: 0;
}
.page-activities .modal .cp-icon-chevron-up {
  margin: 3px 0 0 4px;
}
.page-activities .modal .no-data {
  margin-top: 12px;
}
.page-activities .modal .activity-actions {
  margin-top: 25px;
}
.page-activities .modal .modal-footer .hr {
  margin-top: 0;
}
.page-activities .ras-response .alert-info p {
  margin-bottom: 0px !important;
}
.page-activities .ras-response .alert-info b {
  font-weight: bold;
}
.page-activities .ras-response .alert-success p {
  margin-bottom: 0px !important;
}
.page-activities .ras-response .alert-success b {
  color: #00856b;
  font-weight: bold;
}
.page-activities .ras-response .rasTable thead {
  font-weight: bold;
}
.page-activities .ras-response .rasCell {
  border-left: solid 1px #ececec;
}
.page-activities .ras-response .chcMissing {
  background-color: #bbe1bb;
}
.page-activities .ras-response .chcMissing .fa-check {
  color: white !important;
}
.page-activities .ras-response .chcC {
  background-color: #a4d7a4;
}
.page-activities .ras-response .chcC .fa-check {
  color: white !important;
}
.page-activities .ras-response .chcB {
  background-color: #85ca85;
}
.page-activities .ras-response .chcB .fa-check {
  color: white !important;
}
.page-activities .ras-response .chcA {
  background-color: #3a743a;
}
.page-activities .ras-response .chcA .fa-check {
  color: white !important;
}
.page-activities .ras-response .budget-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  margin-bottom: 15px;
}
.page-activities .ras-response .budget-container .estimated-budget {
  padding: 10px 20px;
  border: 2px solid #ececec;
  border-radius: 4px;
  background-color: #fafafa;
  flex-grow: 1;
  align-self: normal;
  min-width: 49%;
}
.page-activities .ras-response .budget-container .estimated-budget b.budget {
  color: #3d4d84;
  display: block;
  font-size: 35px;
  padding: 5px;
}
.page-activities .ras-response .budget-container .formulate-request-info {
  flex-grow: 1;
}
.page-activities .ras-response .budget-container .recalculate {
  flex-grow: 0;
}
.page-activities .ras-response .actual-budget-container .variation-reason {
  display: inline-block;
  margin-bottom: 20px;
}
.page-activities .ras-response .actual-budget-container .submit-budget {
  margin-top: 23px;
}
.page-activities .ras-response .tab-panel .nav.nav-tabs {
  margin-bottom: 15px;
}
.page-activities .ras-response .tab-panel .nav.nav-tabs li {
  cursor: pointer;
}
.page-activities .ras-response .tab-panel .allocation-list .key {
  background-color: #f9f9f9;
  width: 40%;
}
.page-activities .ras-response .tab-panel .allocation-list .value {
  width: 60%;
}
.page-activities .ras-response .tab-panel .tab-content {
  margin: 15px 0 0 0;
}
.page-activities .ras-response .tab-panel .tab-content p {
  margin-bottom: 10px;
}
.page-activities .text-success {
  color: #2db040;
}
.page-activities .text-danger {
  color: #bd1010;
}
.page-activities #item-history-chart,
.page-activities .highcharts-container {
  width: 100% !important;
}
.page-activities .edit-form label,
.page-activities .view-form label {
  display: inline-block;
}
.page-activities .edit-form .form-item-group .answer-row:before,
.page-activities .view-form .form-item-group .answer-row:before,
.page-activities .edit-form .form-item-group .answer-row:after,
.page-activities .view-form .form-item-group .answer-row:after {
  content: " ";
  display: table;
}
.page-activities .edit-form .form-item-group .answer-row:after,
.page-activities .view-form .form-item-group .answer-row:after {
  clear: both;
}
.page-activities .edit-form .form-item-group .answer-row .validation-block,
.page-activities .view-form .form-item-group .answer-row .validation-block {
  margin: 0;
}
.page-activities .edit-form .form-item-group .note,
.page-activities .view-form .form-item-group .note {
  margin: 6px 0 0;
}
.page-activities .edit-form .form-item-group p,
.page-activities .view-form .form-item-group p {
  margin-bottom: 0;
}
.page-activities .edit-form .form-item-group .filedrag .drag-instructions,
.page-activities .view-form .form-item-group .filedrag .drag-instructions {
  width: 100%;
}
.page-activities .edit-form .form-item-group .filedrag .imageholder,
.page-activities .view-form .form-item-group .filedrag .imageholder {
  margin-right: 18px;
}
.page-activities .edit-form .form-item-group + .panel-body,
.page-activities .view-form .form-item-group + .panel-body {
  margin-top: 20px;
}
.page-activities .edit-form .form-item-group + .panel-body,
.page-activities .view-form .form-item-group + .panel-body {
  margin-top: 20px;
}
.page-activities .edit-form .form-header,
.page-activities .view-form .form-header {
  margin: 0 0 10px 0;
  padding: 0;
}
.page-activities .edit-form .form-header:before,
.page-activities .view-form .form-header:before,
.page-activities .edit-form .form-header:after,
.page-activities .view-form .form-header:after {
  content: " ";
  display: table;
}
.page-activities .edit-form .form-header:after,
.page-activities .view-form .form-header:after {
  clear: both;
}
.page-activities .edit-form .form-header .validation-block,
.page-activities .view-form .form-header .validation-block {
  margin: 0;
}
.page-activities .edit-form .form-item:first-of-type .form-header,
.page-activities .view-form .form-item:first-of-type .form-header {
  padding-top: 0;
}
.page-activities .edit-form .form-item-children .form-item:first-of-type .form-header,
.page-activities .view-form .form-item-children .form-item:first-of-type .form-header {
  padding-top: 15px;
}
.page-activities .edit-form .checkbox-group-buttons-container .btn-key-item:last-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-key-item:last-child,
.page-activities .edit-form .buttons-container .btn-key-item:last-child,
.page-activities .view-form .buttons-container .btn-key-item:last-child,
.page-activities .edit-form .checkbox-group-buttons-container .btn-alert:last-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-alert:last-child,
.page-activities .edit-form .buttons-container .btn-alert:last-child,
.page-activities .view-form .buttons-container .btn-alert:last-child,
.page-activities .edit-form .checkbox-group-buttons-container .btn-view-history:last-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-view-history:last-child,
.page-activities .edit-form .buttons-container .btn-view-history:last-child,
.page-activities .view-form .buttons-container .btn-view-history:last-child {
  margin-right: 0;
}
.page-activities .edit-form .checkbox-group-buttons-container .btn-key-item:first-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-key-item:first-child,
.page-activities .edit-form .buttons-container .btn-key-item:first-child,
.page-activities .view-form .buttons-container .btn-key-item:first-child,
.page-activities .edit-form .checkbox-group-buttons-container .btn-alert:first-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-alert:first-child,
.page-activities .edit-form .buttons-container .btn-alert:first-child,
.page-activities .view-form .buttons-container .btn-alert:first-child,
.page-activities .edit-form .checkbox-group-buttons-container .btn-view-history:first-child,
.page-activities .view-form .checkbox-group-buttons-container .btn-view-history:first-child,
.page-activities .edit-form .buttons-container .btn-view-history:first-child,
.page-activities .view-form .buttons-container .btn-view-history:first-child {
  margin-left: 2px;
}
.page-activities .edit-form .checkbox-group-buttons-container .btn-icon-small .btn-key-item,
.page-activities .view-form .checkbox-group-buttons-container .btn-icon-small .btn-key-item,
.page-activities .edit-form .buttons-container .btn-icon-small .btn-key-item,
.page-activities .view-form .buttons-container .btn-icon-small .btn-key-item,
.page-activities .edit-form .checkbox-group-buttons-container .btn-icon-small .btn-alert,
.page-activities .view-form .checkbox-group-buttons-container .btn-icon-small .btn-alert,
.page-activities .edit-form .buttons-container .btn-icon-small .btn-alert,
.page-activities .view-form .buttons-container .btn-icon-small .btn-alert,
.page-activities .edit-form .checkbox-group-buttons-container .btn-icon-small .btn-view-history,
.page-activities .view-form .checkbox-group-buttons-container .btn-icon-small .btn-view-history,
.page-activities .edit-form .buttons-container .btn-icon-small .btn-view-history,
.page-activities .view-form .buttons-container .btn-icon-small .btn-view-history {
  font-size: 14px;
  width: 30px;
  height: 32px;
}
.page-activities .edit-form .checkbox-group-buttons-container .btn-text-long .btn-key-item,
.page-activities .view-form .checkbox-group-buttons-container .btn-text-long .btn-key-item,
.page-activities .edit-form .buttons-container .btn-text-long .btn-key-item,
.page-activities .view-form .buttons-container .btn-text-long .btn-key-item,
.page-activities .edit-form .checkbox-group-buttons-container .btn-text-long .btn-alert,
.page-activities .view-form .checkbox-group-buttons-container .btn-text-long .btn-alert,
.page-activities .edit-form .buttons-container .btn-text-long .btn-alert,
.page-activities .view-form .buttons-container .btn-text-long .btn-alert,
.page-activities .edit-form .checkbox-group-buttons-container .btn-text-long .btn-view-history,
.page-activities .view-form .checkbox-group-buttons-container .btn-text-long .btn-view-history,
.page-activities .edit-form .buttons-container .btn-text-long .btn-view-history,
.page-activities .view-form .buttons-container .btn-text-long .btn-view-history {
  width: auto;
  font-size: 12px;
}
.page-activities .edit-form .checkbox-group-buttons-container.bi-state,
.page-activities .view-form .checkbox-group-buttons-container.bi-state {
  padding: 7px;
}
.page-activities .edit-form .form-item {
  margin-bottom: 20px;
}
.page-activities .edit-form .form-item:nth-child(1) > .header .activity-subtitle {
  margin-top: 0px;
}
.page-activities .edit-form .form-item .header .activity-subtitle {
  margin-top: 20px;
}
.page-activities .edit-form .form-item .form-item-group {
  padding: 0;
  transition: background linear 0.3s;
}
.page-activities .edit-form .form-item:only-child,
.page-activities .edit-form .form-item:last-child {
  margin-bottom: 0;
}
.page-activities .edit-form .form-item:first-child .form-item-group {
  padding-top: 0;
}
.page-activities .edit-form .form-item .checkbox-question {
  margin-left: 28px;
  display: inline-block;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.page-activities .edit-form .form-item .checkbox-answers-container input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  border: 2px solid #fafafa;
  transition: background-color linear 0.1s;
  transition: border linear 0.1s;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container input ~ .checkmark:after {
  display: block;
  position: absolute;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container input:checked ~ .checkmark:after {
  display: block;
  content: "";
  position: absolute;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container .checkmark {
  background-color: #ececec;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container input:checked ~ .checkmark {
  border-color: #3d4d84;
  background-color: transparent;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container input ~ .checkmark:after {
  color: #3d4d84;
  font-family: ImosphereIcons;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container input:checked ~ .checkmark:after {
  content: "\e865";
  font-size: 16px;
}
.page-activities .edit-form .form-item .checkbox-answers-container .checkbox-container .checkmark:hover {
  background-color: #ececec;
  border-color: #ececec;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group {
  background-color: #fbfbfb;
  position: relative;
  margin: 3px;
  padding: 7px 10px 10px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state {
  padding: 7px;
  background-color: #fbfbfb;
  display: block;
  border-radius: 4px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label {
  padding: 0;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:hover,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:focus-within {
  background-color: transparent;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:hover .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:focus-within .checkmark {
  border-color: #c4d9d7;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:hover .checkmark:after,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:focus-within .checkmark:after {
  background-color: #c4d9d7;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:hover input:checked ~ .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:focus-within input:checked ~ .checkmark {
  border-color: #444;
  background-color: #fff;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:hover input:checked ~ .checkmark:after,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state label:focus-within input:checked ~ .checkmark:after {
  background-color: #444;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.tri-state + .form-item-row:not(tri-state) {
  margin-top: 20px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label {
  padding: 0;
  background-color: #fbfbfb;
  display: block;
  border-radius: 4px;
  transition: background-color linear 0.2s;
  cursor: pointer;
  line-height: 22px;
  margin: 0;
  border: 4px solid #fbfbfb;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):hover,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):focus-within {
  outline: none;
  background-color: #ececec;
  border-color: #ececec;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):hover .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):focus-within .checkmark {
  background-color: #fff;
  border-color: #444;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):hover input:checked ~ .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):focus-within input:checked ~ .checkmark {
  border-color: #444;
  background-color: #fff;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):hover input:checked ~ .checkmark:after,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group label:not(.radio-button-label):focus-within input:checked ~ .checkmark:after {
  color: #444;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .tri-state-label,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .question-label {
  font-weight: 600;
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
  color: #444;
  padding: 4px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .form-item-checkbox-radio-group {
  padding: 0px !important;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .form-item-checkbox-radio-group .checkbox-group-buttons-container {
  margin: 4px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.clear-style {
  padding: 0;
  background-color: transparent;
  margin: 0;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group.clear-style .radio-button-answers-container {
  margin-top: 0;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .form-item-group {
  padding: 0;
  display: flex;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .form-item-group .question-item {
  flex-grow: 1;
  flex-basis: calc(100% - 266px);
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-question-container {
  height: auto;
  line-height: 22px;
  margin: 2px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container {
  margin-top: 6px;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label {
  position: relative;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
  font-size: 1em;
  margin-bottom: 6px;
  margin-right: 2px;
  line-height: 22px;
  padding: 0 15px 0 30px;
  display: inline-flex;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label .guidance {
  display: none;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label input:checked ~ .checkmark {
  border-color: #3d4d84;
  background-color: transparent;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label input:checked ~ .checkmark:after {
  background-color: #3d4d84;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label .checkmark {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fafafa;
  border: 2px solid #d3d3d3;
  margin: 0 7px 0 0;
  transition: background-color linear 0.1s;
  transition: border-color linear 0.1s;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  background-color: #d3d3d3;
  transition: background-color linear 0.1s;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:hover .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:focus-within .checkmark {
  border-color: #a0a0a0;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:hover .checkmark:after,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:focus-within .checkmark:after {
  background-color: #a0a0a0;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:hover input,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:focus-within input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:hover input:checked ~ .checkmark,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .radio-button-label:focus-within input:checked ~ .checkmark {
  background-color: #3d4d8494;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text {
  display: flex;
  flex-wrap: wrap;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text .radio-button-label {
  flex: 0 0 24%;
  display: initial;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text .radio-button-label .answer-option,
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text .radio-button-label .guidance {
  display: block;
}
@media only screen and (max-width: 1600px) {
  .page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text .radio-button-label {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 1400px) {
  .page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container.with-help-text .radio-button-label {
    flex: 0 0 49%;
  }
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .radio-button-answers-container .form-guidance {
  display: block;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .checkbox-group-buttons {
  position: absolute;
  bottom: 4px;
  right: 6px;
}
.page-activities .edit-form .auto-fill-enabled .field-group-required::before {
  content: "*";
  color: #ef4444;
}
.page-activities .edit-form .auto-fill-enabled .panel-body-mandatory {
  border-radius: 0;
  border-bottom: #fafafa;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section {
  padding: 6px 15px;
  background-color: #fbfbfb;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section .form-item-checkbox-radio-group {
  background-color: #fff;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section .padding-adjustment {
  color: #111;
  font-size: 0.9em;
  line-height: 21px;
  margin-left: 4px;
  margin-top: 1px;
  transition: color linear 0.1s;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section .heading {
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section:hover .padding-adjustment {
  color: #3d4d84;
}
.page-activities .edit-form .auto-fill-enabled .additional-questions-section.non-relevant-section {
  opacity: 0.7;
  font-style: italic;
}
.page-activities .edit-form .flex-container-input {
  display: flex;
  /* or inline-flex */
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: flex-end;
  width: 100%;
  height: 32px;
}
.page-activities .edit-form .flex-container-input.flex-item-input-short {
  width: 50%;
}
.page-activities .edit-form .form-item.form-item-checkbox-radio-group .form-item-group .flex-container-input.flex-item-input-short {
  width: 100%;
}
.page-activities .edit-form .flex-item-input {
  flex-basis: 100%;
  flex-grow: 4;
  flex-shrink: 9;
}
.page-activities .edit-form .flex-item-buttons {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
}
.page-activities .edit-form .repeating-section .form-item {
  margin-bottom: 0;
  line-height: 32px;
}
.page-activities .edit-form .repeating-section .collapse .form-item,
.page-activities .edit-form .repeating-section .collapsing .form-item {
  margin-bottom: 20px;
}
.page-activities .buttons-container .btn.btn-text {
  background-color: #fafafa;
  border: 2px solid #ececec;
  border-radius: 16px;
  color: #111;
  font-size: 12px;
  float: left;
  height: 32px;
  line-height: 30px;
  margin: 0 3px;
  padding: 0 9px;
  transition: all linear 0.1s;
}
.page-activities .buttons-container .btn.btn-text:not([disabled]):hover,
.page-activities .buttons-container .btn.btn-text:not([disabled]):focus {
  border-color: #3d4d84;
  background-color: #fafafa;
}
.page-activities .buttons-container .btn.btn-text.active {
  border-color: #3d4d84;
  background-color: #3d4d84;
  box-shadow: none;
  color: #fff;
}
.page-activities .buttons-container .btn.btn-text.active:hover {
  border-color: #2d3961;
  background-color: #2d3961;
}
.page-activities .buttons-container .btn.btn-text.active:focus {
  border-color: #2d3961;
  background-color: #2d3961;
}
.page-activities .buttons-container .btn:last-child {
  margin-right: 0;
}
.page-activities .buttons-container .btn:first-child {
  margin-left: 6px;
}
.page-activities .buttons-container .btn.history:after {
  content: "History";
}
.page-activities .buttons-container .btn.addnote:after {
  content: "Add Note";
}
.page-activities .buttons-container .btn.hasnote:after {
  content: "Edit Note";
}
.page-activities .buttons-container .btn.keyitem:after {
  content: "Key Item";
}
.page-activities .buttons-container .btn.alert:after {
  content: "Alert";
}
.page-activities .buttons-container.icons-only .btn.btn-text {
  font-size: 1.2em;
  padding: 0;
  text-align: center;
  width: 32px;
  position: relative;
  font-family: FontAwesome;
}
.page-activities .buttons-container.icons-only .btn.history:after {
  content: "\f017";
}
.page-activities .buttons-container.icons-only .btn.addnote:after {
  content: "\f040";
}
.page-activities .buttons-container.icons-only .btn.hasnote:after {
  content: "\f040";
}
.page-activities .buttons-container.icons-only .btn.keyitem:after {
  content: "\f084";
}
.page-activities .buttons-container.icons-only .btn.alert:after {
  content: "\f12a";
}
.page-activities .goal > li {
  border-bottom: solid 1px #fafafa;
  padding: 1.25em 1.5em;
}
.page-activities .goal > li .cp-icon-checkbox {
  cursor: pointer;
  margin-left: -1.25em;
  margin-top: 1px;
}
.page-activities .goal > li .cp-icon-checkbox.selected {
  color: #3d4d84;
}
.page-activities .goal > li .cp-icon-checkbox.selected:before {
  content: "\e900";
}
.page-activities .goal > li .cp-icon-checkbox.selected:focus,
.page-activities .goal > li .cp-icon-checkbox.selected:hover {
  color: #303d68;
}
.page-activities .goal > li .cp-icon-check {
  color: #3d4d84;
}
.page-activities .goal > li .goal-name {
  cursor: pointer;
  margin-bottom: 0;
}
.page-activities .goal > li:first-of-type {
  padding-top: 0;
}
.page-activities .goal > li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}
.page-activities .strengths-needs {
  font-size: 1rem;
  line-height: 1.267rem;
  padding-top: 0.5em;
}
.page-activities .strengths-needs .heading {
  display: block;
}
.page-activities .strengths-needs .value + .value {
  margin-top: 0.375em;
}
.page-activities .view-form .panel-body,
.page-activities .view-form .form-item-children-container {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
}
.page-activities .view-form .panel-body .form-item,
.page-activities .view-form .form-item-children-container .form-item,
.page-activities .view-form .panel-body .form-item-children,
.page-activities .view-form .form-item-children-container .form-item-children {
  flex-basis: 100%;
  max-width: 100%;
}
.page-activities .view-form .panel-body .checkbox-item,
.page-activities .view-form .form-item-children-container .checkbox-item {
  flex-basis: calc(50% - 6px);
}
.page-activities .view-form .panel-body .checkbox-item .form-item,
.page-activities .view-form .form-item-children-container .checkbox-item .form-item,
.page-activities .view-form .panel-body .checkbox-item .form-item-children-checkbox,
.page-activities .view-form .form-item-children-container .checkbox-item .form-item-children-checkbox {
  flex-basis: 100%;
  padding: 0;
  margin: 0;
}
.page-activities .view-form .panel-body .checkbox-item .form-item-children-checkbox,
.page-activities .view-form .form-item-children-container .checkbox-item .form-item-children-checkbox {
  margin: 10px 0;
}
.page-activities .view-form .panel-body .checkbox-item .form-item-children-checkbox:last-of-type,
.page-activities .view-form .form-item-children-container .checkbox-item .form-item-children-checkbox:last-of-type {
  margin-bottom: 0;
}
.page-activities .view-form .panel-body .form-item-children-checkbox,
.page-activities .view-form .form-item-children-container .form-item-children-checkbox {
  flex-basis: 50%;
}
.page-activities .view-form .goal > li {
  padding-left: 0;
}
.page-activities .view-form .buttons-col {
  padding-left: 0;
}
.page-activities .view-form .buttons-container {
  height: 32px;
  clear: both !important;
  font-style: normal !important;
}
.page-activities .view-form .buttons-container .btn-key-item,
.page-activities .view-form .buttons-container .btn-alert,
.page-activities .view-form .buttons-container .btn-view-history {
  font-size: 16px;
  width: 34px;
  height: 36px;
}
.page-activities .view-form .form-item-children-container .form-item-children-checkbox:last-child .checkbox-item {
  margin-bottom: 13px;
}
.page-activities .view-form .checkbox-item {
  padding: 7px 10px 10px;
  background-color: #fbfbfb;
  margin: 3px;
}
.page-activities .view-form .checkbox-item + .form-item:not(.checkbox-item) .question-col,
.page-activities .view-form .checkbox-item + .form-item:not(.checkbox-item) .buttons-col {
  padding-top: 10px;
}
.page-activities .view-form .checkbox-item .row {
  margin-left: 0;
  margin-right: 0;
}
.page-activities .view-form .checkbox-item .row .question-col,
.page-activities .view-form .checkbox-item .row .buttons-col {
  padding-right: 0;
  position: relative;
  min-height: 45px;
  float: left;
}
.page-activities .view-form .checkbox-item .row.icons-only .buttons-col {
  width: 115px;
}
.page-activities .view-form .checkbox-item .row.icons-only .question-col {
  width: calc(100% - 115px);
  padding: 0;
}
.page-activities .view-form .checkbox-item .form-item-group {
  padding: 0 !important;
}
.page-activities .view-form .checkbox-item .tick-box-container .checkmark {
  display: block;
  position: absolute;
  top: unset;
  left: unset;
  width: 18px;
  height: 18px;
  border: 2px solid #3a3a3a;
}
.page-activities .view-form .checkbox-item .tick-box-container .checkmark:after {
  display: block;
  position: absolute;
  font-family: ImosphereIcons;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: #3a3a3a;
}
.page-activities .view-form .checkbox-item .tick-box-container .checkmark.checked:after {
  content: "\e865";
  font-size: 16px;
}
.page-activities .view-form .checkbox-item .tick-box-container .checkbox-question {
  position: relative;
  display: block;
  width: calc(100% - 25px);
  left: 25px;
}
.page-activities .view-form .table-data-entry-container {
  margin-bottom: 24px;
}
.page-activities .show-all-questions .form-item-group,
.page-activities .show-all-questions .form-item-children .form-item:last-of-type .form-item-group,
.page-activities .show-all-questions .form-item-children:last-of-type .form-item .form-item-group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-activities .show-all-questions .form-item:last-of-type .form-item-group,
.page-activities .show-all-questions .form-item:last-of-type .form-item-children:last-of-type .form-item:last-of-type .form-item-group {
  border-bottom: none;
  padding-bottom: 10px;
}
.page-activities .show-all-questions .form-header + .form-item-children .form-item-group {
  padding-top: 2px !important;
}
.page-activities .show-all-questions .form-item:not(:last-of-type) .form-item-children:last-of-type .form-item:last-of-type .form-item-group {
  padding-bottom: 10px;
}
.page-activities .show-all-questions .form-item-group.unanswered {
  color: #707070;
  font-style: italic;
}
.page-activities .show-all-questions .form-item-group.unanswered label {
  color: #707070;
}
.page-activities .show-only-answered .form-item-group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-activities .show-only-answered .answered-item.first .answered {
  padding-top: 0;
}
.page-activities .show-only-answered .answered-item.first .form-item-children .answered {
  padding-top: 20px;
}
.page-activities .show-only-answered .first-header {
  padding-top: 0;
}
.page-activities .show-only-answered .answered-item.last .answered {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.page-activities .show-only-answered .form-header + .answered-child .answered {
  padding-top: 0;
}
.page-activities #guprogress-target .guprogress-bar {
  background-color: #42ce56;
  z-index: 100;
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
}
.page-activities #guprogress-target.progress-danger .guprogress-bar {
  background-color: #eb1515;
}
.page-activities .collapse-enabled-hover-state {
  cursor: pointer;
}
.page-activities .collapse-enabled-hover-state:hover + .collapse-enabled-hover-state .cp-icon-chevron-up {
  color: #fff;
}
.page-activities .form-instance-tags-add-btn {
  margin: 4px;
  padding: 4px;
  color: #3d4d84;
  font-size: 13px;
}
.page-activities .form-instance-tags-add-btn.text-muted {
  color: #616161;
}
.page-activities .form-instance-tags-container {
  margin: 8px 0 0;
}
.page-activities .form-instance-tag {
  background-color: #fafafa;
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
  margin: 4px 2px;
  padding: 0 10px 0 10px;
  position: relative;
  transition: background-color linear 0.1s;
}
.page-activities .form-instance-tag.tag-edit {
  padding: 0 10px 0 10px;
}
.show-more-toggle {
  color: #3d4d84;
  cursor: pointer;
}
.state-component {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  align-content: center;
  max-height: 160px;
}
.state-component .component {
  padding-top: 7px;
  height: 34px;
  margin-left: 21%;
}
.state-component .component .component-tracker {
  display: flex;
  margin: 0;
  counter-reset: item;
  list-style-type: none;
  padding: 0;
}
.state-component .component .component-tracker .step {
  flex: 1;
}
.state-component .component .component-tracker .step:not(.active) .step-name {
  background-color: #3d4d84;
  transition: background-color ease-in-out 0.5s;
}
.state-component .component .component-tracker .step.after .step-name {
  background-color: #ececec;
}
.state-component .component .component-tracker .step .circle {
  border-radius: 20px;
  content: "";
  font-size: 18px;
  line-height: 1.8em;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 10;
  background-color: #fff;
  transition: background-color 0.7s ease;
}
.state-component .component .component-tracker .step .circle:not(.active) {
  background-color: #3d4d84;
}
.state-component .component .component-tracker .step .circle:not(.active):before {
  color: #fff;
  content: "\E865";
  display: block;
  font-family: ImosphereIcons;
}
.state-component .component .component-tracker .step .circle.active {
  background-color: #fff;
  border: 6px solid #3d4d84;
}
.state-component .component .component-tracker .step .circle.after {
  width: 22px;
  height: 22px;
  margin-top: 2px;
  border: 4px #ececec solid;
  background-color: #fff;
}
.state-component .component .component-tracker .step .circle.after:before {
  color: #fff;
}
.state-component .component .component-tracker .step.active-pending:before {
  background: #8f9ccb;
}
.state-component .component .component-tracker .step .step-name {
  display: inline-flex;
  margin: 0 0 0 15px;
  position: relative;
  background: #ececec;
  height: 4px;
  width: 100%;
  align-items: center;
  z-index: 1;
  padding: 0 30px;
}
.state-component .current-state {
  font-size: 11px;
  color: grey;
  display: block;
  text-align: center;
}
.state-component .current-state-update {
  font-size: 16px;
  text-align: center;
  display: block;
  color: #3d4d84;
  font-weight: 600;
  font-family: sofia pro;
}
.state-component .component-buttons {
  height: 40px;
}
.state-component .component-buttons .btn {
  background-color: #3d4d84;
  color: #fff;
  display: inline-block;
  border-radius: 0;
  padding: 10px 18px;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.state-component .component-buttons .main-text {
  font-size: 14px;
}
.state-component-buttons {
  background-color: #f9f9f9;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 10px;
  height: 34px;
  display: flex;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .state-component-buttons {
    display: block;
  }
}
.state-component-buttons:after {
  content: "";
  clear: both;
  display: table;
}
.state-component-buttons span,
.state-component-buttons a {
  background-color: #f9f9f9;
  color: grey;
  padding: 7px 0;
  flex-grow: 1;
  max-width: 50%;
  font-size: 14px;
  text-align: center;
}
.state-component-buttons span:first-child,
.state-component-buttons a:first-child {
  border-right: 2px solid white;
}
.state-component-buttons span.pull-left,
.state-component-buttons a.pull-left {
  align-self: flex-start;
}
.state-component-buttons span.pull-right,
.state-component-buttons a.pull-right {
  align-self: flex-end;
  margin-left: auto;
}
.state-component-buttons a {
  cursor: pointer;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .state-component-buttons a {
    width: 50%;
  }
}
.state-component-buttons a:hover,
.state-component-buttons a:focus {
  background-color: #e0e0e0;
  color: grey;
  text-decoration: none;
}
.centre-of-care-event {
  border-radius: 0px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  height: 47px;
  background-color: #fff;
  border: none;
  margin-bottom: 10px !important;
  width: 100%;
}
.centre-of-care-event .imo {
  font-size: 22px;
  color: #3d4d84;
  padding-top: 7px;
}
.centre-of-care-event .centre-of-care-event-name {
  font-size: 15px;
  padding-top: 8px;
}
.form-tracker-container {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -20px;
  padding: 0 20px 20px;
  max-height: 550px;
}
body.highlight-mandatory .not-nullable {
  margin: -5px;
  background: rgba(255, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px !important;
}
.tooltip-estimated-budget {
  font-size: 3em;
  font-family: "Sofia Pro", sans-serif;
  color: #3d4d84;
}
.additional-items {
  background: transparent;
  border: none;
}
.form-heading-actions-container {
  display: flex;
  flex-direction: row;
}
.form-heading-actions-container .form-heading {
  flex-grow: 1;
  line-height: 50px;
  flex-basis: max-content;
}
.form-heading-actions-container .form-heading.normal-line-height {
  line-height: 1.2em;
}
.form-heading-actions-container .form-actions {
  flex-grow: 1;
}
.form-instance-meta-data {
  background-color: #fafafa;
  padding: 8px 15px;
}
.form-instance-meta-data ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.form-instance-meta-data ul li {
  min-width: 25%;
  margin-bottom: 8px;
}
.form-instance-meta-data ul li .key {
  display: block;
  font-weight: 600;
  font-family: "Sofia Pro";
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  .page-activities .panel.panel-border-top:last-of-type,
  .page-activities .panel-summary-personal-details:last-of-type,
  .page-activities .panel-summary-calendar:last-of-type,
  .page-activities .panel-summary-diagnoses:last-of-type,
  .page-activities .panel-summary-alerts:last-of-type,
  .page-activities .panel-summary-reminders:last-of-type,
  .page-activities .panel-summary-activities:last-of-type,
  .page-activities .summary-panel:last-of-type {
    padding-bottom: 0 !important;
  }
}
@media only screen and (max-width: 1200px) {
  .page-activities .triggers-menu {
    width: auto;
  }
}
.page-print .form-guidance {
  font-style: italic;
  font-weight: 400;
  padding: 0;
}
.page-print .status-label {
  margin: 0 4px !important;
}
.page-print .status-label:last-of-type {
  margin-right: 0 !important;
}
.page-print .form-item-children:last-of-type table {
  margin-bottom: 5px !important;
}
.page-print #footer {
  display: none;
  visibility: hidden;
}
@media print {
  .page-print .btn.btn-print {
    display: none !important;
    visibility: hidden !important;
  }
}
@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}
.theme-default .input-group-btn .btn.btn-default.coding-system-type[disabled] {
  background-color: #ececec;
  opacity: 1;
}
.calendar-padding {
  padding: 30px;
  padding-top: 10px;
}
.page-calendar .agenda-view {
  -webkit-display: flex;
  display: flex;
}
.page-calendar .agenda-view .agenda-time {
  padding-top: 13px;
  width: 104px;
}
.page-calendar .agenda-view .agenda-event {
  -webkit-flex: 1;
  flex: 1;
}
.page-calendar .agenda-view .time-overlap {
  display: block;
}
.page-calendar .date {
  margin: 30px 0 10px;
}
.page-calendar .date:first-of-type {
  margin-top: -20px;
}
.page-calendar .cp-icon-cake {
  margin-right: 0.25em;
}
.page-calendar .modal .event-title {
  margin-bottom: 10px;
}
.page-calendar .modal .event-date {
  display: block;
}
.page-calendar .modal dl.vertical {
  margin-bottom: 0;
}
.page-calendar .modal dl.event-details {
  margin: 15px 0;
}
.page-calendar .modal p {
  margin-bottom: 10px;
}
.page-calendar .modal p.scheduled-date {
  margin-bottom: 0;
}
.page-calendar .modal p.scheduled-date .btn {
  border: 0;
  margin: -3px 0 0 5px;
  padding: 0;
}
.page-calendar .modal .validation-block {
  margin-bottom: 0;
}
.page-calendar .modal .note {
  margin-bottom: 0;
}
.page-calendar .modal .form-attendance {
  border-top: solid 1px #ececec;
  margin-top: 25px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 18px 25px 0;
}
.page-calendar .modal .attendance-label {
  color: #3d4d84;
  font-weight: 400;
}
.page-calendar .modal .event-actions,
.page-calendar .modal .delete-event {
  margin-top: 25px;
}
.page-calendar .modal .event-actions:before,
.page-calendar .modal .delete-event:before,
.page-calendar .modal .event-actions:after,
.page-calendar .modal .delete-event:after {
  content: " ";
  display: table;
}
.page-calendar .modal .event-actions:after,
.page-calendar .modal .delete-event:after {
  clear: both;
}
.page-calendar .modal .event-actions .validation-block,
.page-calendar .modal .delete-event .validation-block {
  margin: 0;
}
.page-calendar .modal .reason-for-deleting {
  border-top: solid 1px #ececec;
  margin-top: 25px;
  padding-top: 20px;
}
.page-calendar .panel.event {
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.25s ease;
}
.page-calendar .panel.event .note {
  border: none;
  padding: 0;
}
.page-calendar .panel.event:hover {
  background-color: #fafafa;
}
.page-calendar .panel.event > .panel-body {
  padding: 10px;
}
.page-calendar .panel.event.panel-border-left {
  border: none;
  border-left: 4px solid #3d4d84;
}
.page-calendar .panel.event.event-past {
  background-color: #fafafa;
  border-left-color: #707070;
  transition: background-color 0.25s ease;
}
.page-calendar .panel.event.event-past [class^="cp-icon-"] {
  color: #707070;
}
.page-calendar .panel.event.event-past:hover {
  background-color: #f0f0f0;
}
.page-calendar .panel.event.last-in-series [class^="cp-icon-"],
.page-calendar .panel.event.last-in-series [class*=" cp-icon-"] {
  color: #fca744;
  font-weight: 600;
}
.page-calendar .panel.event.event-attendance-full {
  border-left-color: #1ad44e;
}
.page-calendar .panel.event.event-attendance-partial {
  border-left-color: #ffba3a;
}
.page-calendar .panel.event.event-attendance-none {
  border-left-color: #ed3a3a;
}
.page-calendar .calendar-title {
  padding: 10px 0;
}
.page-calendar .calendar-title .calendar-times {
  padding-top: 5px;
}
@media print {
  .theme-default theme-calendar .calendar-title {
    margin: 10px 0 !important;
  }
  .theme-default theme-calendar .calendar-title .calendar-times {
    padding: 2px 0 0 !important;
  }
  .theme-default theme-calendar .date,
  .theme-default theme-calendar .date:first-of-type {
    font-size: 10pt !important;
    margin: 15px 0 10px !important;
  }
  .theme-default theme-calendar .agenda-view {
    page-break-inside: avoid;
    padding: 0 0 6px !important;
  }
  .theme-default theme-calendar .panel {
    border-bottom: dotted 1px #777 !important;
    padding-bottom: 5px !important;
  }
  .theme-default theme-calendar .panel.event > .panel-body {
    padding-left: 2px !important;
  }
  .theme-default theme-calendar .panel.event.event-past {
    background: white !important;
  }
}
/* Care Plans LESS */
.page-care-plans {
  margin-bottom: 30px;
  padding-right: 15px;
}
.page-care-plans .focus-area {
  margin-bottom: 100px;
}
.page-care-plans .centre-of-care {
  color: #111;
  font-weight: 400;
}
.page-care-plans .cp-icon-checkbox {
  margin-right: 0.25em;
}
.page-care-plans .label.label-review {
  background-color: #4a5da0;
}
.page-care-plans .panel.global-plan-sidebar {
  margin-top: 2em;
  transition: margin-top 0.2s ease;
}
.page-care-plans .panel.global-plan-sidebar.sticky {
  margin-top: 0;
}
.page-care-plans .panel .need.selected .need-name {
  color: #3d4d84;
  cursor: pointer;
}
.page-care-plans .panel .need.selected .need-name:hover {
  color: #3d4d84;
}
.page-care-plans .panel .panel-body-modal:first-child .section .section-title-highlight {
  background: #3d4d84;
  color: #fafafa;
}
.page-care-plans .panel .text-emphasis {
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
}
.page-care-plans .fixed-sidebar .tracker-content {
  padding-top: 8px;
  clear: both;
}
.page-care-plans .required:after {
  content: "*";
  color: #ef4444;
  font-size: 1.5rem;
  line-height: 1px;
  padding-right: calc(5px - 2);
  position: relative;
  top: calc(5px + 2);
}
.page-care-plans .need {
  border-color: #ececec;
}
.page-care-plans .status-current {
  background: #fafafa;
  color: #3d4d84;
}
.page-care-plans .need-name {
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
  line-height: 1em;
}
.page-care-plans .need-name:hover {
  color: #3d4d84;
  cursor: pointer;
}
.page-care-plans .need-name:hover .checkbox {
  background-color: #ececec;
  border-color: #ececec;
}
.page-care-plans .need-name .checkbox {
  background-color: #fafafa;
  border-radius: 4px;
  border: 2px solid #fafafa;
  height: 22px;
  left: 0;
  margin: 0 8px 0 0;
  top: 0;
  transition: background-color linear 0.1s;
  transition: border linear 0.1s;
  width: 22px;
}
.page-care-plans .need-name .text {
  display: inline-block;
  line-height: 22px;
}
.page-care-plans .need-name.selected .checkbox:after {
  font-family: "ImosphereIcons";
  content: "\e865";
  font-size: 16px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-left: 1px;
}
.page-care-plans .need-name.selected .checkbox {
  border-color: #3d4d84;
  color: #3d4d84;
  background-color: transparent;
}
.page-care-plans .need-name.selected:focus .checkbox,
.page-care-plans .need-name.selected:hover .checkbox {
  color: #2d3961;
  border-color: #2d3961;
}
.page-care-plans .guidance {
  margin-left: 30px;
}
.page-care-plans .need-hidden {
  margin: 0 30px;
}
.page-care-plans .section {
  margin: 0 0 20px;
  padding: 0;
}
.page-care-plans .section.last-section,
.page-care-plans .section:only-child {
  padding-bottom: 0;
}
.page-care-plans .section .section-title {
  color: #111;
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.2em;
}
.page-care-plans .section .section-title.required:after {
  content: "*";
  color: #ed3a3a;
  font-size: 1.5rem;
  line-height: 1px;
  padding-right: calc(5px - 2);
  position: relative;
  top: calc(5px + 2);
  top: 4px;
}
.page-care-plans .section .needs-list {
  margin-top: -5px;
}
.page-care-plans .section .needs-list .list-item:before,
.page-care-plans .section .needs-list .list-item:after {
  content: " ";
  display: table;
}
.page-care-plans .section .needs-list .list-item:after {
  clear: both;
}
.page-care-plans .section .needs-list .list-item .validation-block {
  margin: 0;
}
.page-care-plans .section .needs-list .list-item:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}
.page-care-plans .section .needs-list .list-item:last-of-type .additional-information {
  padding-bottom: 0;
}
.page-care-plans .section .needs-edit .list-item .need-name.isReadOnly {
  pointer-events: none !important;
}
.page-care-plans .section .needs-edit .list-item .need-name .guidance-notes {
  pointer-events: auto !important;
}
.page-care-plans .section .needs-edit .list-item .cp-icon-checkbox,
.page-care-plans .section .needs-edit .list-item .cp-icon-check {
  margin-left: -1.25em;
  margin-top: 1px;
}
.page-care-plans .section .needs-edit .list-item .cp-icon-checkbox.isReadOnly,
.page-care-plans .section .needs-edit .list-item .cp-icon-check.isReadOnly {
  background-color: #eee;
  pointer-events: none;
}
.page-care-plans .section .needs-view .cp-icon-chevron-up {
  top: 7px;
}
.page-care-plans .section .intervention-list {
  margin-top: -5px;
  margin-bottom: 15px;
}
.page-care-plans .section .intervention-list.list-collapsable > .list-item .cp-icon-chevron-up {
  top: 21px;
}
.page-care-plans .section .intervention-list .btn-xs {
  margin-top: 5px;
}
.page-care-plans .section .intervention-list-view {
  margin-top: -5px;
}
.page-care-plans .section .intervention-list-view .list-item:last-of-type {
  border-bottom: none;
}
.page-care-plans .section .intervention-list-view .list-item:last-of-type .additional-information {
  padding-bottom: 0;
}
.page-care-plans .section .intervention-list-view.list-collapsable > .list-item .cp-icon-chevron-up {
  top: 20px;
}
.page-care-plans .section .view-more-collapse:after {
  content: "";
  content: "less";
}
.page-care-plans .section .view-more-collapse.collapsed:after {
  content: "";
  content: "more";
}
.page-care-plans .section .view-more {
  color: #3d4d84;
}
.page-care-plans .section label {
  font-family: "Sofia Pro", helvetica, arial, sans-serif;
  font-size: 1.067em;
}
.page-care-plans .role-name::before,
.page-care-plans .relationship-name::before {
  content: ",";
  display: inline-block;
  margin-left: -2px;
}
.page-care-plans .relationship-name::before {
  content: "Relation: ";
  margin: 0;
}
.page-care-plans .care-plan-panel {
  border-radius: 3px;
  border: solid 1px #fff;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}
.page-care-plans .care-plan-panel > .care-plan-name {
  padding: 10px;
}
.page-care-plans .care-plan-panel > .care-plan-detail {
  font-size: 1rem;
  line-height: 1.267rem;
  border-top: solid 1px #ececec;
  padding: 10px;
}
.page-care-plans .care-plan-panel > .care-plan-detail .status {
  color: #111;
  padding: 0;
}
.page-care-plans .cp-label {
  color: #616161;
}
.page-care-plans .cp-label.cp-coc {
  color: #111;
  font-weight: 500;
}
.page-care-plans .cp-review-date {
  margin-bottom: 20px;
}
.page-care-plans #guprogress-target .guprogress-bar {
  background-color: #42ce56;
  z-index: 100;
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
}
.page-care-plans #guprogress-target.progress-danger .guprogress-bar {
  background-color: #eb1515;
}
@media (min-width: 992px) {
  .page-care-plans {
    margin-bottom: 0;
  }
  .page-care-plans .cp-review-date {
    margin-bottom: 0;
  }
}
@media all and (min-width: 1000px) {
  .page-care-plans .care-plan-panel {
    border-left: solid 3px #7a8ac1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
    flex-flow: row;
    margin-bottom: 10px;
  }
  .page-care-plans .care-plan-panel.current {
    border-left-color: #3d4d84;
  }
  .page-care-plans .care-plan-panel > .care-plan-detail {
    -webkit-align-self: stretch;
    align-self: stretch;
    border-left: 1px solid #f5f5f5;
    border-top: none;
    padding: 10px 15px;
  }
  .page-care-plans .care-plan-panel > .care-plan-detail.date {
    max-width: 186px;
    width: 18%;
  }
  .page-care-plans .care-plan-panel > .care-plan-detail.created-by {
    max-width: 200px;
    width: 20%;
  }
  .page-care-plans .care-plan-panel > .care-plan-name {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding-left: 20px;
  }
  .page-care-plans .care-plan-panel > .care-plan-detail {
    display: -webkit-flex;
    display: flex;
    text-align: center;
  }
  .page-care-plans .care-plan-involvement,
  .page-care-plans .contact-record,
  .page-care-plans .status,
  .page-care-plans .date-created,
  .page-care-plans .staff-member {
    -webkit-align-self: center;
    align-self: center;
    margin: 0 auto;
  }
  .page-care-plans .care-plan-involvement {
    margin: 0;
  }
  .page-care-plans .cp-label {
    display: block;
  }
}
.login-wrapper {
  background-image: url(Content/Images/generic-background-transparent.png);
  background-color: #3d4d84;
  background-size: cover;
  min-height: 100%;
}
.modal-content .page-login .col-md-4 {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.cp-icon-user {
  margin-top: 2px;
}
.login-btn-input {
  height: 45px !important;
  margin-top: 0;
  margin-bottom: 15px !important;
}
.login-btn-input .login-button {
  margin-top: 0;
}
.page-login.login-wrapper {
  padding-top: 110px;
}
.page-login .jumbotron {
  width: 550px;
  height: 550px;
  background-color: #fff;
  font-family: "Sofia Pro";
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  padding-bottom: 85px;
  padding-top: 15px;
}
.page-login .jumbotron h2 {
  color: #000;
  font-weight: 600;
  font-size: 1.429em;
  font-family: "Sofia Pro";
}
.page-login .jumbotron.circle-panel {
  border-radius: 100%;
  padding-top: 71px;
  padding-left: 60px;
  padding-right: 60px;
}
.page-login .jumbotron.circle-panel .panel-body {
  padding-left: 25px;
  padding-right: 25px;
}
.page-login .jumbotron.circle-panel p {
  font-size: 1em;
  line-height: 1.3em;
  margin-top: 5px;
}
.page-login .jumbotron.circle-panel .name {
  color: #707070;
}
.page-login .jumbotron.circle-panel .error-container {
  height: 60px;
  padding-bottom: 5px;
}
.page-login .jumbotron.circle-panel .error-container .alert-danger {
  color: #921212;
  background-color: #f2dede;
  border-color: #ebccd1;
  text-align: center;
  font: 400 0.9em "FSMeWeb", Helvetica, sans-serif;
  border-radius: 4px;
}
.page-login .jumbotron.circle-panel .input-group {
  margin: 15px auto 25px auto;
  position: relative;
}
.page-login .jumbotron.circle-panel .input-group label {
  position: absolute;
  left: 0;
  top: -20px;
  transition: all linear 0.2s;
  opacity: 0;
  color: #707070;
  font-size: 0.9em;
}
.page-login .jumbotron.circle-panel .input-group label.visible {
  opacity: 1;
}
.page-login .jumbotron.circle-panel .input-group .login-text {
  border: none;
  border-bottom: 3px solid #3d4d84;
  background-color: #fff;
  color: #707070;
  font-weight: bold;
  width: 300px;
  padding: 10px 5px;
  border-radius: 0;
  box-shadow: none;
  transition: all linear 0.2s;
}
.page-login .jumbotron.circle-panel .input-group .login-text:focus {
  border-bottom: 3px solid #ff54c0;
}
.page-login .jumbotron.circle-panel .btn {
  margin-top: 25px;
  width: 300px;
  font-weight: bold;
  border-radius: 16px;
  background-color: #3d4d84;
  border-color: #3d4d84;
}
.page-login .jumbotron.circle-panel .btn:hover,
.page-login .jumbotron.circle-panel .btn:focus {
  background-color: #2d3961;
}
.page-login .jumbotron.circle-panel a {
  font-size: 14px;
  font-weight: bold;
}
.page-login .jumbotron.circle-panel a:hover,
.page-login .jumbotron.circle-panel a:focus {
  color: #2d3961;
  outline: none;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel {
  color: #707070;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .error-container {
  height: auto;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .error-container .alert {
  margin-bottom: 0px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .type-selection {
  margin: 10px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .type-selection label {
  color: #000;
  display: inline-block;
  font-size: 1em;
  margin-left: 10px;
  opacity: 1;
  position: static;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .phone-message {
  background-color: #ececec;
  border-radius: 4px;
  font-size: 0.9em;
  margin-left: 30px;
  margin-left: 10px;
  padding: 4px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .phone-message p {
  margin-bottom: 5px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .login-two-factor-auth .form-group {
  margin-bottom: 30px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .remember-me {
  width: 100%;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .remember-me input {
  display: block;
  float: left;
  height: 20px;
  margin: 0px 8px 0px 2px;
  width: 20px;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .remember-me label {
  color: #707070;
  font-size: 1em;
}
.page-login .jumbotron.circle-panel .two-factor-auth-panel .test-mode {
  color: #eb1515;
  margin-top: 0px;
}
.page-login .jumbotron.eula {
  width: 60%;
  height: 70%;
  min-height: 525px;
  min-width: 525px;
}
.page-login .login-organisation-name {
  color: #fff;
  font-family: "Sofia Pro";
  font-size: 1.2em;
  padding-bottom: 17px;
}
.page-login .panel {
  border-color: transparent;
}
.page-login .panel.panel-login {
  padding: 2em 1.5em;
}
.page-login .panel.panel-login .login-logo {
  display: block;
  margin: 0 auto 1em;
  max-width: 250px;
}
.page-login .panel.panel-shadow {
  box-shadow: none;
}
.page-login .panel .col-md-4 {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.page-login .panel .panel-body {
  padding: 0;
}
.page-login .panel .panel-body .login-label {
  display: block;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}
.page-login .panel .panel-body .alert.alert-info {
  margin-bottom: 30px;
}
.page-login .staff-select {
  margin-bottom: 15px;
}
.page-login .staff-selection-container {
  border-radius: 6px;
  border: solid 1px #ececec;
  cursor: pointer;
  padding: 10px;
}
.page-login .staff-selection-container:focus,
.page-login .staff-selection-container:hover {
  border-color: #40518b;
}
.page-login .staff-selection-container.active,
.page-login .staff-selection-container.active .cp-icon-user {
  border-color: #40518b;
  color: #3d4d84;
}
.page-login .staff-selection-container .staff-name {
  margin-bottom: 0;
}
.page-login .has-error .staff-selection-container {
  border-color: #bf201f;
}
.page-login .has-error .btn {
  box-shadow: none;
}
.page-login .alert.broadcast-message {
  margin-bottom: 3em;
}
.page-login .password-reset-link {
  padding-top: 0.7em;
  text-align: center;
}
.page-login .password-reset-link p:first-of-type {
  margin-bottom: 1em;
}
.page-login .password-reset-link p,
.page-login .password-reset-link p:only-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 480px) {
  .page-login.login-wrapper {
    overflow-y: auto !important;
    position: fixed;
    bottom: 0;
  }
  .page-login .broadcast-message {
    padding-right: 20px;
  }
  .page-login .login-container {
    margin: auto;
    width: 92%;
    height: 100%;
  }
  .page-login .panel.panel-shadow {
    box-shadow: 1px 1px 1px #bec8d4;
  }
  .page-login .panel .panel-body {
    padding: 0.5em;
  }
}
@media only screen and (min-width: 768px) {
  .page-login .login-container {
    max-width: 680px;
  }
  .page-login .broadcast-message .message {
    margin: 0 auto;
    max-width: 680px;
  }
  .page-login .panel .panel-body {
    padding: 1.5em;
  }
  .page-login .panel .col-md-4 {
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-login .staff-select {
    margin-left: -10px;
    margin-right: -10px;
  }
  .page-login .staff-select.center-users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .page-login .cp-icon-user {
    color: #ececec;
  }
}
@media only screen and (min-width: 992px) {
  .page-login .login-container {
    max-width: 100%;
  }
  .page-login .broadcast-message {
    margin-bottom: 3em;
  }
  .page-login .broadcast-message .message {
    margin: 0 auto;
    max-width: 740px;
  }
}
.sidebar-layout .sidebar {
  background-color: #3d4d84;
  color: #fff;
  margin-top: 60px;
  padding: 0 20px 20px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
}
.sidebar-layout .sidebar .logo {
  display: block;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.sidebar-layout .sidebar .nav-sidebar {
  margin: 0 -20px 20px;
}
.sidebar-layout .sidebar .nav-sidebar li a {
  color: #96a3ce;
  padding: 15px 20px;
}
.sidebar-layout .sidebar .nav-sidebar li a:hover,
.sidebar-layout .sidebar .nav-sidebar li a:active,
.sidebar-layout .sidebar .nav-sidebar li a:focus {
  background-color: #33416f;
  color: #fff;
  outline: none;
}
.sidebar-layout .sidebar .nav-sidebar li.active a {
  background-color: #2d3961;
  color: #fff;
}
.sidebar-layout .sidebar .nav-sidebar li.active a:hover,
.sidebar-layout .sidebar .nav-sidebar li.active a:active,
.sidebar-layout .sidebar .nav-sidebar li.active a:focus {
  background-color: #2a355a;
}
.sidebar-layout .content {
  padding: 20px;
}
.sidebar-layout .content .page-header .header-link-sm {
  display: block;
  font-size: 12px;
  margin-top: 10px;
}
.page-management .navbar-brand {
  padding: 20px 0 0 20px;
}
.page-management .nav.nav-tabs {
  margin-bottom: 15px;
}
.page-management .nav.nav-tabs > li > a {
  font-size: 1rem;
  line-height: 1.267rem;
  padding: 8px;
}
@media only screen and (min-width: 992px) {
  .page-management .nav.nav-tabs > li > a {
    padding: 10px 12px;
  }
}
@media only screen and (min-width: 1200px) {
  .page-management .nav.nav-tabs > li > a {
    font-size: 1rem;
  }
}
.page-management .nav.nav-tabs ~ .alert {
  margin: 0 0 20px 0;
}
.page-management table {
  table-layout: auto;
}
.page-management table.table-fixed-layout {
  table-layout: fixed;
}
.page-management table thead tr th.sortable:hover {
  color: #3d4d84;
  cursor: pointer;
}
.page-management .custom-brand-photo-upload .brand-image-container {
  padding-bottom: 12px;
  max-width: 280px;
}
.page-management .custom-brand-photo-upload img {
  width: 100%;
}
.page-management .connected-application {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 17px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 34px;
  padding: 0 9px;
}
.page-management .connected-application.core {
  display: block !important;
  background-color: #ddeefb;
  border-color: #ddeefb;
  color: #3d4d84;
}
.page-management .connected-application.incompatible {
  background-color: #fff;
  border-color: #eb1515;
  color: #eb1515;
}
.page-management .connected-application.compatible {
  background-color: #fff;
  border-color: #0db887;
  color: #0db887;
}
.page-management .incompatible-version {
  background: #fae3e3;
  padding: 4px 8px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.page-management .incompatible-version h3 {
  font-size: 1.1em;
  color: #ba0a3c;
}
.page-management .compatible-version {
  background: #d2f5e1;
  padding: 4px 8px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.page-management .compatible-version h3 {
  font-size: 1.1em;
  color: #3d4d84;
}
/** Styling for new management page **/
.management-page .page-container {
  padding: 15px;
}
.management-page .v-enter-active,
.management-page .v-leave-active {
  transition: opacity 0.5s ease;
}
.management-page .v-enter-from,
.management-page .v-leave-to {
  opacity: 0;
}
.management-page .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.management-page .slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.management-page .slide-fade-enter-from,
.management-page .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.management-page .fade-enter-active,
.management-page .fade-leave-active {
  transition: opacity 0.5s ease;
}
.management-page .fade-enter-from,
.management-page .fade-leave-to {
  opacity: 0;
}
.management-page h1,
.management-page h2,
.management-page h3 {
  color: black;
}
.management-page .list-page-toolbar {
  padding: 15px;
}
.management-page .list-page-toolbar .dropdown-input .dropdown-content {
  position: relative;
  top: -2px;
}
.management-page .list-page-toolbar .search-control input {
  margin-top: -2px;
  height: 34px;
}
.management-page .tabbed-interface {
  padding: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
}
.management-page .tabbed-interface .section.title-container {
  width: 80%;
}
.management-page .tabbed-interface .section.title-container h2 {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.management-page .router-tabs {
  font-size: medium;
  font-weight: 500;
  margin-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  border-bottom: 5px solid #777;
}
.management-page .router-tabs .tab {
  padding: 20px 20px 11px;
  color: #767676;
  text-decoration: none;
  position: relative;
}
.management-page .router-tabs .tab.router-link-active {
  color: #111;
  border-bottom: 5px solid #111;
  padding-bottom: 6px;
}
.management-page .router-tabs .tab .indicator {
  position: absolute;
  width: 6px;
  height: 6px;
  display: block;
  top: 20px;
  right: 14px;
  border-radius: 3px;
}
.management-page .router-tabs .tab .indicator:after {
  display: none;
}
.management-page .router-tabs .tab .indicator.error {
  background-color: #eb1515;
}
.management-page .router-tabs .tab .indicator.warning {
  background-color: #ff5519;
}
.management-page .management-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  /** Table Styling **/
}
.management-page .management-wrapper .sidebar-menu,
.management-page .management-wrapper .flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
.management-page .management-wrapper .flex-item {
  flex-grow: 1;
  width: calc(89% - 20px);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 125px);
  background-color: #fff;
}
.management-page .management-wrapper .sidebar-menu {
  background-color: #343c46;
  padding: 15px 0 0 20px;
  width: 220px;
}
.management-page .management-wrapper .sidebar-menu h1 {
  margin-bottom: 5px;
}
.management-page .management-wrapper .sidebar-menu h2 {
  color: #fff;
}
.management-page .management-wrapper .sidebar-menu ul {
  margin: 0;
}
.management-page .management-wrapper .sidebar-menu ul:before,
.management-page .management-wrapper .sidebar-menu ul:after {
  content: " ";
  display: table;
}
.management-page .management-wrapper .sidebar-menu ul:after {
  clear: both;
}
.management-page .management-wrapper .sidebar-menu ul .validation-block {
  margin: 0;
}
.management-page .management-wrapper .sidebar-menu ul li {
  display: block;
  float: none;
  width: auto;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-category a {
  color: #fff;
  min-width: 100%;
  display: block;
  text-decoration: none;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-category .sidebar-category-title {
  margin-bottom: 5px;
  color: #fff;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-category .sidebar-category-title .imo {
  float: right;
  padding: 4px 20px 0 0;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-category .sidebar-category-title strong {
  color: #fff;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-category ul {
  margin: 10px 0;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-menu-item {
  margin-right: 20px;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-menu-item a {
  color: #fff;
  min-width: 100%;
  display: block;
  margin-bottom: 5px;
  padding: 5px 20px;
  border-radius: 20px;
  text-decoration: none;
}
.management-page .management-wrapper .sidebar-menu ul li.sidebar-menu-item a.router-link-active,
.management-page .management-wrapper .sidebar-menu ul li.sidebar-menu-item a:hover {
  background-color: #fff;
  color: #000;
}
.management-page .management-wrapper .table td a {
  font-weight: 600;
  font-family: "Sofia Pro";
  cursor: pointer;
}
.management-page .management-wrapper .table th.sortable {
  cursor: pointer;
}
.management-page .management-wrapper .table.table-striped tr th {
  background-color: #707070;
  color: #fff !important;
  border: none;
  padding: 4px;
  transition: background-color 0.2s ease;
}
.management-page .management-wrapper .table.table-striped tr th.sortable {
  padding: 0;
}
.management-page .management-wrapper .table.table-striped tr th.sortable .cp-icon-caret-up,
.management-page .management-wrapper .table.table-striped tr th.sortable .cp-icon-caret-down {
  color: #fff !important;
}
.management-page .management-wrapper .table.table-striped tr th.sortable button {
  border: none;
  background: transparent;
  width: 100%;
  text-align: left;
  padding: 4px;
}
.management-page .management-wrapper .table.table-striped tr th.sortable:hover,
.management-page .management-wrapper .table.table-striped tr th.sortable:focus {
  background-color: #7d7d7d;
  outline: none;
}
.management-page .management-wrapper .table.table-striped tr td {
  border: none;
  padding: 4px;
  vertical-align: middle;
}
.management-page .management-wrapper .table.table-striped tr td .btn.btn-link {
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 24px;
}
.management-page .management-wrapper .table .input-field input {
  line-height: normal !important;
}
.management-page .management-wrapper .table .tick-box-container {
  line-height: normal !important;
}
.management-page .management-wrapper .table .tick-box-container .tick-box-input-container {
  margin: 0;
}
.management-page .management-wrapper .table .table-header-button {
  padding: 0;
  height: 19px !important;
}
.management-page .management-wrapper .table .table-header-button .caret {
  margin-bottom: 6px;
}
.management-page .management-wrapper .table .table-header-button button {
  background-color: #f4f4f4 !important;
  height: 19px !important;
  line-height: 18px !important;
}
.management-page .management-wrapper .table .table-header-button button:hover {
  background-color: #ececec !important;
}
.management-page .management-wrapper .table .table-header-button button:active {
  background-color: #d3d3d3 !important;
}
.management-page .management-wrapper .form-group {
  padding: 10px;
  background-color: #dddddd;
  border-radius: 5px;
  margin: 0px;
}
.management-page .management-wrapper .form-group .form-group {
  padding: 10px;
  background-color: #fafafa;
  border-radius: 5px;
}
.management-page .management-wrapper .form-group .form-group .form-group {
  background-color: #ececec;
}
.management-page .organisation-list .suspend-modal-buttons {
  margin-left: 10px;
}
.management-page .organisation-list .suspend-modal-buttons button {
  margin-left: 5px;
}
.management-page .organisation-list .table td.options {
  text-align: right;
}
.management-page .organisation-list .table td.options.no-change-status {
  padding-right: 33px;
}
.management-page .organisation-list .table td.options button {
  background-color: transparent;
  border: none;
}
.management-page .tabbed-interface-footer {
  max-width: 55vw;
  display: flex;
}
.management-page .tabbed-interface-footer .item {
  flex-grow: 0;
}
.management-page .tabbed-interface-footer .item.grow {
  flex-grow: 1;
}
.management-page .organisation-edit {
  max-width: 55vw;
}
.management-page .organisation-edit .help-block {
  color: #000;
  margin-bottom: 5px;
  margin-top: 0px;
}
.management-page .organisation-edit .validation-block {
  font-size: 1em;
}
.management-page .organisation-edit .form-control {
  background-color: #fff;
  border: solid 2px #AEAEAE;
}
.management-page .organisation-edit .has-error .form-control {
  background-color: #fbb3b3;
}
.management-page .organisation-edit .btn-toggle {
  margin-top: 10px;
}
.management-page .organisation-edit .input-group-addon {
  background-color: #aeaeae;
  color: #111;
}
.management-page .organisation-edit .readonly-text-field > span {
  border: solid 2px #AEAEAE;
  background-color: #ececec;
  line-height: 16px;
}
.management-page .organisation-edit .section-header {
  font-family: "Sofia Pro";
  font-size: 14px;
  font-weight: bold;
}
.management-page .organisation-details .service-areas {
  padding-top: 20px;
}
.management-page .organisation-details .service-areas .add-service-area {
  display: flex;
  flex-direction: row;
}
.management-page .organisation-details .service-areas .add-service-area .input-column {
  width: 40%;
  padding-right: 15px;
}
.management-page .organisation-details .service-areas .add-service-area .add-button {
  margin-top: 23px;
}
.management-page .organisation-details .service-areas .urn {
  color: #737373;
}
.management-page .organisation-details .service-areas .urn mark {
  background-color: transparent;
  color: inherit;
}
.management-page .organisation-details .service-areas .urn mark:last-child {
  color: #111;
}
.management-page .organisation-details input:read-only {
  background-color: #dddddd;
}
.management-page .organisation-settings .form-group a {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}
.management-page .organisation-settings .form-group a:hover {
  text-decoration: none;
}
.management-page .organisation-settings .form-group.short-input .help-block {
  float: left;
  margin-top: 0px;
}
.management-page .organisation-settings .form-group.short-input .input-group {
  bottom: 20px;
}
.management-page .organisation-settings .form-group.short-input .input-group .form-control {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  float: right;
  width: 80px;
}
.management-page .organisation-settings .form-group.short-input .validation-block {
  bottom: 15px;
  clear: left;
  float: left;
  position: relative;
}
.management-page .organisation-settings .form-group.short-input .small {
  bottom: 15px;
  float: right;
  position: relative;
}
.management-page .organisation-settings textarea {
  width: 100%;
}
.management-page .organisation-settings .regex-link {
  margin-top: 10px;
}
.management-page .organisation-settings .regex-link a[target="_blank"] {
  color: #3d4d84;
}
.management-page .organisation-settings .regex-link a[target="_blank"]:after {
  content: "\f08e";
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-left: 4px;
}
.management-page .organisation-settings .regex-tests {
  margin-top: 16px;
}
.management-page .organisation-settings .regex-tests .regex-test-results div {
  background-color: #fff;
  border: solid 2px #AEAEAE;
  border-radius: 4px;
  height: 30px;
  margin-bottom: 4px;
  padding: 2px;
}
.management-page .organisation-settings .regex-tests .regex-test-results div span.imo {
  border-radius: 5px;
  color: #fff;
  float: left;
  font-size: 22px;
  margin-right: 6px;
}
.management-page .organisation-settings .regex-tests .regex-test-results div span.imo.success {
  background-color: #42ce56;
}
.management-page .organisation-settings .regex-tests .regex-test-results div span.imo.fail {
  background-color: #eb1515;
}
.management-page .organisation-settings .regex-tests button {
  margin-top: 10px;
}
.management-page .organisation-terms .terms-table .row {
  background-color: #ececec;
  padding-top: 10px;
}
.management-page .organisation-terms .terms-table .row.alt {
  background-color: #fafafa;
}
.management-page .organisation-terms .terms-table .row.standalone {
  border-radius: 5px;
  margin-top: 10px;
}
.management-page .organisation-terms .terms-table .row.last {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.management-page .organisation-terms .terms-table .row:first-child {
  background-color: #707070;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-family: "Sofia Pro";
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}
.management-page .organisation-terms .terms-table .term-name {
  font-family: "Sofia Pro";
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
}
.management-page .organisation-terms .terms-table .help-block {
  margin-top: 0px;
}
.management-page .organisation-users {
  max-width: 55vw;
}
.management-page .organisation-users fieldset {
  border: none;
}
.management-page .organisation-users legend {
  background-color: #707070;
  border-radius: 5px;
}
.management-page .organisation-users .table-responsive {
  border-radius: 5px;
}
.management-page .organisation-users .table-responsive thead th {
  padding: 10px;
}
.management-page .organisation-users .list-page-toolbar {
  padding: 0;
}
.management-page .organisation-users .input-field-inline .text-input,
.management-page .organisation-users .input-field-inline .dropdown-input {
  width: 60%;
}
.management-page .organisation-users .input-field-inline .help-block {
  width: calc(100% - 280px);
}
.management-page .organisation-users .input-field-inline label strong {
  display: inline-block;
  width: 100%;
}
.management-page .organisation-users .input-field-inline .text-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.management-page .organisation-users .input-field-inline .text-input,
.management-page .organisation-users .input-field-inline .dropdown-input {
  position: relative;
  margin-top: -21px;
}
.management-page .organisation-users .input-field-inline .help-block {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 8px;
  color: #6B6B6B;
}
.management-page .organisation-users .input-field-inline .validation-block {
  margin-top: 5px;
  width: 100%;
  text-align: left;
  line-height: 1.2em;
}
.management-page .organisation-users .two-factor-auth {
  display: flex;
}
.management-page .organisation-users .two-factor-auth label {
  flex-basis: 40%;
}
.management-page .organisation-users .two-factor-auth .value-container {
  flex-basis: 60%;
}
.management-page .organisation-users .two-factor-auth .value-container > span {
  display: inline-block;
  margin-right: 5px;
}
.management-page .organisation-users .user-service-areas .dropdown-content ul {
  max-height: 160px;
}
.management-page .organisation-associations h2 {
  display: inline-block;
  margin: 0px 10px 16px 0px;
}
.management-page .organisation-associations h2 + button {
  margin-bottom: 8px;
}
.management-page .organisation-associations .view-id {
  border: solid 1px #777;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
  padding: 0px 4px;
  font-size: 0.875em;
  line-height: 1.5em;
  background-color: #ececec;
}
.management-page .organisation-associations .associations-list > li {
  margin-bottom: 10px;
  border: solid 2px #ececec;
  overflow: hidden;
  border-radius: 4px;
}
.management-page .organisation-associations .associations-list > li.has-error {
  border: 2px solid #eb1515;
  border-radius: 4px;
}
.management-page .organisation-associations .associations-list .item {
  flex-grow: 0;
}
.management-page .organisation-associations .associations-list .item.item-grow {
  flex-grow: 1;
}
.management-page .organisation-associations .associations-list .item.form-name {
  font-size: 1.1em;
}
.management-page .organisation-associations .associations-list .item .btn-toggle .btn-toggle-text {
  font-family: 'FSMeWeb';
  font-weight: normal;
  font-size: 13px;
}
.management-page .organisation-associations .associations-list .header,
.management-page .organisation-associations .associations-list .body,
.management-page .organisation-associations .associations-list .footer {
  padding: 8px 10px;
}
.management-page .organisation-associations .associations-list .header button,
.management-page .organisation-associations .associations-list .body button,
.management-page .organisation-associations .associations-list .footer button {
  background-color: transparent;
}
.management-page .organisation-associations .associations-list .header {
  padding-top: 4px;
  padding-bottom: 4px;
}
.management-page .organisation-associations .associations-list .header,
.management-page .organisation-associations .associations-list .footer {
  background-color: #fafafa;
  display: flex;
  align-items: center;
}
.management-page .organisation-associations .associations-list .body,
.management-page .organisation-associations .associations-list .footer {
  background-color: #ececec;
}
.management-page .organisation-associations .associations-list .body .list .imo {
  background: #fff;
  padding: 4px;
  color: #3d4d84;
  border-radius: 4px;
}
.management-page .organisation-associations .associations-list .footer {
  margin-top: 1px;
}
.management-page .organisation-associations .associations-list .btn-link {
  color: #000;
  font-size: 13px;
  text-decoration: underline;
}
.management-page .organisation-associations .associations-list .btn-link:hover {
  text-decoration: none;
}
.management-page .organisation-associations .edit-buttons {
  text-align: right;
}
.management-page .organisation-associations .form-definitions-list {
  border: solid 1px #AEAEAE;
  border-radius: 4px;
  max-height: 160px;
  overflow-y: scroll;
  padding-top: 8px;
}
.management-page .organisation-associations .form-definitions-list.has-error {
  background-color: #fbb3b3 !important;
}
.management-page .organisation-associations .selected-service-areas div {
  background-color: #3d4d84;
  border: solid 2px #3d4d84;
  border-radius: 4px;
  display: inline-block;
  margin: 2px;
  padding: 2px 2px 2px 5px;
  color: #fff;
}
.management-page .organisation-associations .selected-service-areas div button {
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
}
.management-page .organisation-associations .selected-service-areas div button:hover {
  color: #fff;
}
.management-page .organisation-associations .selected-service-areas div:hover {
  background-color: #455795;
  cursor: pointer;
}
.management-page .organisation-associations .radio-buttons label {
  margin-right: 10px;
}
.management-page .organisation-associations label.tick-box-container-label {
  font-weight: normal;
}
.management-page .organisation-associations label.tick-box-container-label .checkbox-text {
  display: inline-block;
  margin-left: 14px;
}
.management-page .organisation-associations .export-template-name {
  display: flex;
  flex-direction: row;
}
.management-page .organisation-associations .export-template-name .text-input-column {
  width: 92%;
  padding-right: 15px;
}
.management-page .organisation-associations .export-template-name .button-input-column {
  margin-top: 23px;
}
.management-page .organisation-associations .table-responsive {
  margin-bottom: 0;
  border-radius: 5px;
  border: solid 1px #707070;
}
.management-page .organisation-associations .table-responsive .table {
  margin-bottom: 0;
}
.management-page .organisation-associations .table-responsive .table thead th {
  padding: 5px;
}
.management-page .file-import-options {
  float: right;
  position: relative;
  right: 12px;
  top: 4px;
}
.management-page .file-import-options button,
.management-page .file-import-options a {
  display: inline-block;
  font-size: 13px;
  margin-left: 5px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.management-page .file-import-options button.btn-link:hover,
.management-page .file-import-options a.btn-link:hover,
.management-page .file-import-options button.btn-link:focus,
.management-page .file-import-options a.btn-link:focus {
  text-decoration: none;
}
.management-page .file-import-options button.active,
.management-page .file-import-options a.active {
  color: #111;
  cursor: default;
  text-decoration: none;
}
.management-page .file-import-options button.active:hover,
.management-page .file-import-options a.active:hover {
  text-decoration: none;
}
.management-page .file-import-options button.inactive,
.management-page .file-import-options a.inactive {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}
.management-page .file-import-options button.inactive:hover,
.management-page .file-import-options a.inactive:hover {
  text-decoration: none;
}
.management-page .file-import-message {
  align-items: center;
  background-color: #fff;
  border-left-width: 24px;
  border-radius: 4px;
  border-style: solid;
  display: flex;
  padding: 5px;
  width: 100%;
}
.management-page .file-import-message p {
  margin-bottom: 0px;
}
.management-page .file-import-message .left {
  flex-grow: 1;
}
.management-page .file-import-message .left .imo,
.management-page .file-import-message .left .fa {
  color: #fff;
  position: relative;
  left: -10px;
  margin-left: -14px;
  width: 12px;
  text-align: center;
}
.management-page .file-import-message .right {
  flex-grow: 0;
}
.management-page .file-import-message .right .imo {
  color: #000;
}
.management-page .file-import-message .right a {
  cursor: pointer;
}
.management-page .file-import-message.info {
  border-color: #3d4d84;
}
.management-page .file-import-message.success {
  border-color: #42ce56;
}
.management-page .file-import-message.error {
  border-color: #eb1515;
}
.management-page .file-import-message.error .left .imo {
  left: -6px;
  margin-left: -19px;
  width: 16px;
}
.management-page .file-import-message .imo {
  font-size: 16px;
}
.management-page .file-import-container {
  display: flex;
  height: 32px;
}
.management-page .file-import-container .file-import-text {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right-width: 0px;
  flex-basis: 85%;
}
.management-page .file-import-container .file-browse-label {
  background-color: #707070;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  cursor: pointer;
  flex-basis: 15%;
  height: 32px;
  padding: 5px 0px 0px 0px;
  text-align: center;
}
.management-page .file-import-container .file-browse-label:hover {
  background-color: #444;
}
.management-page .form-definitions-list-page .options {
  text-align: right;
}
.management-page .form-definitions-list-page .modal-body {
  overflow-y: visible !important;
}
.management-page .form-definitions-list-page .import-table .table-responsive {
  overflow: visible;
}
.management-page .form-definitions-list-page .import-table .status-name {
  width: 80%;
}
.management-page .form-definitions-list-page .import-table .status-container {
  display: inline;
}
.management-page .form-definitions-list-page .import-table .status-container .status-text-container {
  position: relative;
}
.management-page .form-definitions-list-page .import-table .status-container .status-text {
  background-color: #fff;
  border: solid 1px #AEAEAE;
  border-radius: 4px;
  display: none;
  font-size: 12px;
  left: 10px;
  padding: 5px;
  position: absolute;
  text-align: left;
  bottom: -5px;
  width: 300px;
  z-index: 10;
}
.management-page .form-definitions-list-page .import-table .status-container .status-text span {
  display: block;
}
.management-page .form-definitions-list-page .import-table .status-container button:hover .status-text {
  display: block;
}
.management-page .organisation-wizard .title {
  width: 100%;
  padding: 30px;
}
.management-page .organisation-wizard .wizard-content {
  display: flex;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  background-color: #777777;
  flex: 0 1 auto;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps > li {
  display: flex;
  flex-direction: row;
  align-content: center;
  position: relative;
  font-size: 1.2em;
  line-height: 32px;
  font-family: 'Sofia Pro';
  color: #bbbbbb;
  padding: 0 10px 0 15px;
  background-color: #777777;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps > li.complete,
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps > li.active {
  color: white;
  background-color: #999999;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps > li:first-of-type {
  padding-left: 15px;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .steps > li:first-of-type {
  padding-left: 15px;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .step-content {
  padding: 15px;
  height: calc(100vh - 329px);
  overflow-y: scroll;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .step-content.organisation-finalised {
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .step-content.organisation-finalised .icon {
  font-size: 60px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  background: #42ce56;
  color: #fff;
  margin-right: 18px;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .step-content.organisation-finalised .content {
  flex-grow: 1;
}
.management-page .organisation-wizard .wizard-content .wizard-step-container .navigation {
  padding: 15px 25px;
  background-color: #707070;
  flex: 0 1 40px;
}
.management-page .organisation-wizard .wizard-content .summary-container {
  width: 50%;
  height: calc(100vh - 224px);
  float: left;
  padding: 20px;
  background-color: #fafafa;
  overflow-y: scroll;
}
.management-page .organisation-wizard .wizard-content .summary-container ::placeholder {
  font-style: italic;
  color: #999;
}
.management-page .organisation-wizard .wizard-content .summary-container .readonly-text-field span {
  background-color: #eeeeee;
  height: 32px;
}
.management-page .organisation-wizard .wizard-content .summary-container .readonly-text-field.placeholder span {
  font-style: italic;
  color: #999;
}
.management-page .organisation-wizard .wizard-content .summary-container dl dt {
  flex-basis: 33.33333333%;
  font-weight: 600;
  font-family: 'Sofia Pro';
  font-size: 1.067em;
  margin-right: 10px;
}
.management-page .organisation-wizard .wizard-content .summary-container dl dd {
  flex-basis: calc(66.66666667% - 15px);
  padding: 15px;
  border-radius: 4px;
  background: #eeeeee;
  margin: 0 0 10px 0;
}
.management-page .organisation-wizard .wizard-content .summary-container dl dd .view-id {
  border: solid 1px #777;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
  padding: 0px 4px;
  font-size: 0.875em;
  line-height: 1.5em;
  background-color: #ececec;
}
.management-page .organisation-wizard h3,
.management-page .organisation-wizard h4 {
  line-height: 32px;
}
.management-page .organisation-wizard h3 .btn,
.management-page .organisation-wizard h4 .btn {
  display: block;
  text-align: left;
  width: 100%;
  padding: 0;
  border: 0;
}
.management-page .organisation-wizard h3 .btn .icon,
.management-page .organisation-wizard h4 .btn .icon {
  float: right;
  width: 32px;
  height: 32px;
  line-height: inherit;
  display: block;
  text-align: center;
  transition: transform linear 0.2s;
}
.management-page .organisation-wizard h3 .btn:not(.collapsed) .icon,
.management-page .organisation-wizard h4 .btn:not(.collapsed) .icon {
  transform: rotate(180deg);
}
.management-page .organisation-wizard h3 .btn {
  font-size: 18px;
}
.management-page .edit-form-definition .tab:hover {
  cursor: pointer;
}
.management-page .edit-form-definition .page-container {
  width: 50%;
}
.management-page .edit-form-definition .form-control {
  background-color: #fff;
  border: solid 2px #AEAEAE;
}
.management-page .edit-form-definition .versions-table,
.management-page .edit-form-definition .template-overrides-table {
  border: solid 1px #AEAEAE;
  border-radius: 4px;
}
.management-page .edit-form-definition .versions-table .table-responsive,
.management-page .edit-form-definition .template-overrides-table .table-responsive {
  margin-bottom: 0px;
}
.management-page .edit-form-definition .versions-table .table-responsive table,
.management-page .edit-form-definition .template-overrides-table .table-responsive table {
  margin-bottom: 0px;
}
.management-page .edit-form-definition .overview dt {
  font-weight: bold;
}
.management-page .edit-form-definition .overview dd {
  margin-bottom: 12px;
}
.management-page .edit-form-definition .overview .heading-with-button {
  display: flex;
  margin-bottom: 16px;
}
.management-page .edit-form-definition .overview .heading-with-button h2 {
  flex-basis: 80%;
}
.management-page .edit-form-definition .overview .heading-with-button .btn {
  flex-basis: 20%;
}
.management-page .edit-form-definition .overview .versions-table .table-responsive {
  overflow-x: visible;
}
.management-page .edit-form-definition .overview .versions-table .version {
  padding-right: 30px;
  text-align: right;
  width: 10%;
}
.management-page .edit-form-definition .overview .versions-table .status-name {
  width: 80%;
}
.management-page .edit-form-definition .overview .versions-table .icons {
  text-align: right;
  width: 10%;
}
.management-page .edit-form-definition .overview .versions-table .status-container {
  display: inline;
}
.management-page .edit-form-definition .overview .versions-table .status-container .status-text-container {
  position: relative;
}
.management-page .edit-form-definition .overview .versions-table .status-container .status-text {
  background-color: #fff;
  border: solid 1px #AEAEAE;
  border-radius: 4px;
  display: none;
  font-size: 12px;
  left: 10px;
  padding: 5px;
  position: absolute;
  text-align: left;
  bottom: -5px;
  width: 400px;
  z-index: 10;
}
.management-page .edit-form-definition .overview .versions-table .status-container .status-text span {
  display: block;
}
.management-page .edit-form-definition .overview .versions-table .status-container button:hover .status-text {
  display: block;
}
.management-page .edit-form-definition .overview .upload-form-definitions-modal .buttons-container {
  margin-top: 12px;
  text-align: right;
}
.management-page .edit-form-definition .overview .upload-form-definitions-modal .buttons-container .btn {
  margin-left: 10px;
}
.management-page .edit-form-definition .export-templates .select-template.selected .dropdown-input-container {
  width: 95%;
}
.management-page .edit-form-definition .export-templates .inline-download-icon {
  bottom: 32px;
  color: #000;
  float: right;
  position: relative;
  right: 6px;
}
.management-page .edit-form-definition .export-templates .template-overrides-table .organisation {
  padding-left: 12px;
  width: 40%;
}
.management-page .edit-form-definition .export-templates .template-overrides-table .template-name {
  width: 50%;
}
.management-page .edit-form-definition .export-templates .template-overrides-table .icons {
  text-align: right;
  width: 10%;
}
.management-page .edit-form-definition .export-templates .template-overrides-table .icons .btn {
  margin-right: 12px;
}
.management-page .audit input::-webkit-outer-spin-button,
.management-page .logs input::-webkit-outer-spin-button,
.management-page .audit input::-webkit-inner-spin-button,
.management-page .logs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.management-page .audit input[type=number],
.management-page .logs input[type=number] {
  -moz-appearance: textfield;
}
.management-page .audit .date-time-picker,
.management-page .logs .date-time-picker {
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-flow: row wrap;
  flex-grow: 1;
}
.management-page .audit .date-time-picker .date-picker,
.management-page .logs .date-time-picker .date-picker {
  display: flex;
  flex-direction: row;
}
.management-page .audit .date-time-picker .date-picker input,
.management-page .logs .date-time-picker .date-picker input {
  text-align: center;
}
.management-page .audit .date-time-picker .date-picker input.datepicker-day,
.management-page .logs .date-time-picker .date-picker input.datepicker-day,
.management-page .audit .date-time-picker .date-picker input.datepicker-month,
.management-page .logs .date-time-picker .date-picker input.datepicker-month {
  width: 7ch;
}
.management-page .audit .date-time-picker .date-picker input.datepicker-year,
.management-page .logs .date-time-picker .date-picker input.datepicker-year {
  width: 8ch;
}
.management-page .audit .date-time-picker .date-picker .separator,
.management-page .logs .date-time-picker .date-picker .separator {
  position: relative;
  top: 55%;
}
.management-page .audit .date-time-picker .time-picker,
.management-page .logs .date-time-picker .time-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 120px;
}
.management-page .audit .date-time-picker .time-picker input,
.management-page .logs .date-time-picker .time-picker input {
  text-align: center;
  width: 7ch;
}
.management-page .audit .date-time-picker .time-picker .separator,
.management-page .logs .date-time-picker .time-picker .separator {
  position: relative;
  top: 15%;
}
.management-page .audit .from-to-filters,
.management-page .logs .from-to-filters {
  padding-right: 0;
  width: 650px;
}
.management-page .audit .where-filters > .form-group,
.management-page .logs .where-filters > .form-group {
  display: flex;
}
@media only screen and (min-width: 1366px) {
  .management-page .audit .where-filters,
  .management-page .logs .where-filters {
    padding-left: 0;
    width: calc(100% - 650px);
  }
}
@media only screen and (max-width: 1366px) {
  .management-page .audit .where-filters,
  .management-page .logs .where-filters {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .management-page .audit .where-filters .col-md-4,
  .management-page .logs .where-filters .col-md-4 {
    width: 33.33333333%;
    float: left;
  }
}
.management-page .audit .input-field,
.management-page .logs .input-field {
  min-height: 32px;
}
.management-page .audit .input-field label,
.management-page .logs .input-field label,
.management-page .audit .input-field input,
.management-page .logs .input-field input {
  font-family: "FsMeWeb" !important;
  font-size: 12px !important;
}
.management-page .audit .input-field label strong,
.management-page .logs .input-field label strong,
.management-page .audit .input-field input strong,
.management-page .logs .input-field input strong {
  font-family: "FsMeWeb" !important;
  font-size: 12px !important;
}
.management-page .audit .btn-clear,
.management-page .logs .btn-clear {
  background-color: #ECECEC;
  border: unset;
}
.management-page .audit .form-group [class^="col-"],
.management-page .logs .form-group [class^="col-"] {
  padding-right: 1px;
  padding-left: 1px;
}
.management-page .audit #calculation-request-modal .modal-body,
.management-page .logs #calculation-request-modal .modal-body {
  max-height: 75vh;
  padding: 15px;
}
.management-page .audit #calculation-request-modal .modal-xl,
.management-page .logs #calculation-request-modal .modal-xl {
  width: 70vw;
}
.management-page .audit #calculation-request-modal .table td,
.management-page .logs #calculation-request-modal .table td {
  padding: 8px;
}
.management-page .audit .calculation-request-modal .calculation-request,
.management-page .logs .calculation-request-modal .calculation-request {
  padding: 15px;
  margin: -15px -15px 0 -15px;
  background-color: #fafafa;
}
.management-page .audit .calculation-request-modal .calculation-request .text-underlined,
.management-page .logs .calculation-request-modal .calculation-request .text-underlined {
  cursor: pointer;
}
.management-page .audit .calculation-request-modal .budget-tile,
.management-page .logs .calculation-request-modal .budget-tile {
  margin: 0 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0;
}
.management-page .audit .calculation-request-modal .budget-tile .budget,
.management-page .logs .calculation-request-modal .budget-tile .budget {
  font-size: 2rem;
  color: #707070;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary {
  border-bottom: none;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li a,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li a {
  font-size: 1.4em;
  font-weight: normal;
  transition: background-color linear 0.2s;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a {
  color: #000;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:hover,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:hover,
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:focus,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li.active a:focus {
  background-color: #fff;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a {
  color: #000;
}
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:hover,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:hover,
.management-page .audit .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:focus,
.management-page .logs .calculation-request-modal .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:focus {
  padding-bottom: 6px;
  border-radius: 4px;
  box-shadow: inset 0 37px 20px rgba(0, 0, 0, 0.016);
}
.management-page .audit .calculation-request-modal .tab-content,
.management-page .logs .calculation-request-modal .tab-content {
  max-height: 40vh;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0;
  height: 100%;
  overflow-y: scroll;
}
.management-page .audit .calculation-request-modal .tab-content h3,
.management-page .logs .calculation-request-modal .tab-content h3 {
  line-height: 32px;
}
.management-page .audit .calculation-request-modal .tab-content h3 .btn,
.management-page .logs .calculation-request-modal .tab-content h3 .btn {
  display: block;
  text-align: left;
  width: 100%;
}
.management-page .audit .calculation-request-modal .tab-content h3 .btn .icon,
.management-page .logs .calculation-request-modal .tab-content h3 .btn .icon {
  float: right;
  width: 32px;
  height: 32px;
  line-height: inherit;
  display: block;
  text-align: center;
  transition: transform linear 0.2s;
}
.management-page .audit .calculation-request-modal .tab-content h3 .btn:not(.collapsed) .icon,
.management-page .logs .calculation-request-modal .tab-content h3 .btn:not(.collapsed) .icon {
  transform: rotate(180deg);
}
.management-page .audit .calculation-request-modal .tab-content .table,
.management-page .logs .calculation-request-modal .tab-content .table,
.management-page .audit .calculation-request-modal .tab-content .table-responsive,
.management-page .logs .calculation-request-modal .tab-content .table-responsive {
  margin-bottom: 0;
}
.management-page .audit .calculation-request-modal .list-qa-feedback-items li,
.management-page .logs .calculation-request-modal .list-qa-feedback-items li {
  padding: 4px 8px;
  margin: 0 0 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.management-page .modal.modal-logs pre {
  max-height: 300px;
  overflow: auto;
  white-space: pre-wrap;
}
@media only screen and (min-width: 768px) {
  .management-page .table {
    table-layout: auto;
  }
}
.flex-half {
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-half .data {
  width: 110px;
}
.flex-half .chart {
  width: calc(100% - 150px);
  margin: 0;
}
@media (max-width: 991px) {
  .flex-half p {
    flex-grow: 1;
    margin: 0;
  }
}
@media (max-width: 1280px) {
  .flex-half .data {
    width: 100%;
    margin-top: 15px;
  }
  .flex-half .data p {
    display: block;
  }
  .flex-half .chart {
    width: 100%;
    margin: 0;
  }
}
.btn.btn-key-item:disabled:hover,
.btn.btn-alert:disabled:hover {
  background-color: transparent;
  border-color: transparent;
  color: #111;
}
.btn.btn-key-item.active:disabled:hover,
.btn.btn-alert.active:disabled:hover {
  background-color: #3d4d84;
  color: #fff;
}
.event-actions,
.delete-event {
  margin-top: 25px;
}
.event-actions:before,
.delete-event:before,
.event-actions:after,
.delete-event:after {
  content: " ";
  display: table;
}
.event-actions:after,
.delete-event:after {
  clear: both;
}
.event-actions .validation-block,
.delete-event .validation-block {
  margin: 0;
}
.progress-bar-vertical {
  width: 10px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  float: left;
}
.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.calendarHolder article {
  background-color: #fff;
}
.calendarHolder article h1 {
  text-align: center;
}
.calendarHolder article h2 {
  cursor: pointer;
}
.calendarHolder article h2.primary {
  color: #3d4d84;
  font-size: 17px;
}
.calendarHolder article h2.secondary {
  color: #acc2c2;
  font-size: 15px;
}
.calendarHolder table {
  width: 100%;
  margin: 4px;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 1px;
}
.calendarHolder th {
  position: relative;
  width: 20px;
  height: 8px;
  padding: 0 0 3px;
  text-align: center;
}
.calendarHolder th::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 5px;
  background-color: #acc2c2;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.calendarHolder td {
  position: relative;
  width: 20px;
  height: 16px;
  padding: 1px;
  vertical-align: top;
  background-color: #dfe7e7;
  line-height: 0px;
  cursor: pointer;
}
.calendarHolder td:empty {
  background-color: transparent;
}
.calendarHolder .has-events-quarter {
  background-color: #bccece;
}
.calendarHolder .has-events-half {
  background-color: #99b5b5;
}
.calendarHolder .has-events-threequarter {
  background-color: #769c9c;
}
.calendarHolder .has-events-full {
  background-color: #548383;
}
.calendarHolder .selected-date {
  border: solid 1.5px #3d4d84;
}
.calendarHolder .selected-date .day {
  margin-top: 5.5px !important;
}
.calendarHolder .day {
  color: #548383;
  text-align: center;
  margin-top: 7.5px;
  font-size: 12px;
  font-weight: normal;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.calendarHolder .day.has-events-quarter,
.calendarHolder .day.has-events-half,
.calendarHolder .day.has-events-threequarter,
.calendarHolder .day.has-events-full {
  color: #fff;
}
.calendarHolder .split {
  position: absolute;
  bottom: 1px;
  right: 1px;
}
.calendarHolder .inactive {
  pointer-events: none;
}
.individual-contact {
  margin-bottom: 15px;
}
.individual-contact-container {
  border-radius: 0px;
  border: solid 1px #ececec;
  background-color: #fff;
  cursor: pointer;
  -webkit-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
  box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
  margin: 8px;
  padding: 6px;
}
@media (min-width: 1299px) {
  .individual-contact-container {
    width: 45%;
  }
}
@media (max-width: 1299px) {
  .individual-contact-container {
    width: 90%;
  }
}
.individual-contact-container:focus,
.individual-contact-container:hover {
  border-color: #40518b;
}
.individual-contact-container.active,
.individual-contact-container.active .cp-icon-user {
  border-color: #40518b;
  color: #3d4d84;
}
.individual-contact-container .staff-name {
  margin-bottom: 0;
}
.individual-contact-container:last-child:nth-child(odd) {
  width: 93%;
}
.page-caselist .form-filter {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px 0;
}
.page-caselist .table-responsive {
  padding: 0;
  margin: 0;
}
.page-caselist .table-responsive .table.table-striped tr th,
.page-caselist .table-responsive .table.table-striped tr td {
  padding: 12px;
}
.page-caselist .table-responsive .table.table-striped tr th {
  background-color: #ececec;
  border-bottom-width: 1px;
}
.page-caselist .table-responsive .table.table-striped tr td {
  border: none;
}
.page-caselist .table-responsive .table.table-striped tr .label-open {
  background-color: #111 !important;
}
.page-caselist .table-responsive .table.table-striped tr .cp-icon-plus,
.page-caselist .table-responsive .table.table-striped tr .icon-view-history {
  color: inherit;
}
.page-caselist .table-responsive .table.table-striped tr .cp-icon-plus:active,
.page-caselist .table-responsive .table.table-striped tr .icon-view-history:active,
.page-caselist .table-responsive .table.table-striped tr .cp-icon-plus:focus,
.page-caselist .table-responsive .table.table-striped tr .icon-view-history:focus,
.page-caselist .table-responsive .table.table-striped tr .cp-icon-plus:hover,
.page-caselist .table-responsive .table.table-striped tr .icon-view-history:hover {
  background-color: transparent;
  color: inherit;
}
.page-caselist .table-responsive .table.table-striped tr .btn.btn-link {
  color: #3d4d84;
}
.page-caselist .table-responsive .table.table-striped tr .btn.btn-link:active,
.page-caselist .table-responsive .table.table-striped tr .btn.btn-link:focus,
.page-caselist .table-responsive .table.table-striped tr .btn.btn-link:hover {
  background-color: transparent;
  color: #111;
}
.page-caselist .table-responsive .table.table-striped tr .btn.btn-control:active,
.page-caselist .table-responsive .table.table-striped tr .btn.btn-control:focus,
.page-caselist .table-responsive .table.table-striped tr .btn.btn-control:hover {
  text-decoration: none;
}
.page-caselist .list-page-toolbar .form-control-search {
  border-radius: 17px;
}
.page-caselist .list-page-toolbar .form-control-search:not(:hover) {
  border-color: #444;
}
.page-caselist .list-page-toolbar .form-control-search .cp-icon-search {
  color: #444;
}
.page-caselist .list-page-toolbar .form-control-search + .cp-icon-cross {
  padding: 0;
  border: none;
  background-color: transparent;
}
.page-caselist .list-page-toolbar .btn-default {
  line-height: 32px !important;
  border: solid 2px #444 !important;
}
.page-caselist .list-page-toolbar .btn-default:hover {
  border-color: #3d4d84 !important;
}
.page-caselist .list-page-toolbar .pagination {
  border: 2px solid #444;
  height: 34px;
  margin-top: -2px;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 17px;
}
#caselist-modal .coc-form-group .dropdown-input {
  width: 440px;
}
#caselist-modal .role-form-group .dropdown-input {
  width: 500px;
}
#caselist-modal .staff-form-group .dropdown-input {
  width: 445px;
}
.table-case-list .table {
  margin-bottom: 0;
}
.table-case-list .table.table-striped tr:nth-of-type(odd) {
  background-color: transparent;
}
.table-case-list .table.table-striped tr.alternate-row {
  background-color: #f9f9f9;
}
.dropdown-left {
  min-width: 150px;
  text-align: left;
}
.personal-details-container {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
}
.personal-details-history tr.highlight-current td .current,
.other-personal-details-history tr.highlight-current td .current {
  display: none;
}
.personal-details-history tr.highlight-current:first-child td:first-child .current,
.other-personal-details-history tr.highlight-current:first-child td:first-child .current {
  display: inline-block;
  margin-left: 24px;
}
.personal-details-history tr.highlight-current:first-child td:first-child .current::after,
.other-personal-details-history tr.highlight-current:first-child td:first-child .current::after {
  content: "current";
}
.summary-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.summary-panel {
  border-top: none;
}
.summary-panel .summary-panel-content {
  overflow-y: auto;
}
.summary-panel p {
  margin-bottom: 0;
}
.summary-panel.staff-dashboard .panel-heading {
  padding: 0 20px;
}
.summary-panel.staff-dashboard .panel-heading .panel-title {
  margin-top: 8px;
}
.summary-panel.staff-dashboard .panel-heading .panel-title .badge {
  vertical-align: baseline;
  padding: 2px 6px 4px;
  height: 18px;
  border-radius: 9px;
  background-color: #3d4d84;
}
.summary-panel.staff-dashboard .header-right .btn {
  margin-top: 8px;
  line-height: 34px;
}
.summary-panel.staff-dashboard .header-right .btn[data-toggle="dropdown"] {
  line-height: 1em;
  margin-top: 4px;
  font-size: 1em;
}
@media (max-width: 1024px) {
  .summary-panel .panel-heading,
  .summary-panel .panel-body {
    padding: 0 15px;
  }
  .summary-panel .panel-heading .header-right,
  .summary-panel .panel-body .header-right {
    right: 15px;
  }
  .summary-panel .panel-body {
    padding: 0 15px 15px;
  }
}
.opd-summary .opd-content {
  height: 315px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ras-panel {
  min-height: 290px;
}
.ras-panel h3 {
  line-height: 1em;
}
.ras-panel h3 .label {
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
  vertical-align: bottom;
}
.ras-summary-panel .open-activity-link {
  color: #3a3a3a;
}
.ras-summary-spacing {
  margin-bottom: 20px;
}
.ras-summary-spacing:first-of-type {
  margin-top: 10px;
}
.open-activity-link {
  margin: 0 5px 10px 0;
}
.single-ras-panel .ras-panel {
  min-height: 150px;
}
.single-ras-panel .ras-panel .ras-summary-spacing {
  margin-top: 10px;
  float: left;
  width: 50%;
}
.diagnosis-summary-panel,
.alerts-summary-panel,
.personal-summary-panel {
  width: 34%;
}
.contact-summary-panel,
.involvement-summary-panel,
.care-plan-summary-panel {
  width: 50%;
}
.contacts-summary-align {
  display: flex;
  display: -webkit-flex;
  background-color: #ececec;
  padding: 8px;
  flex-direction: row;
  flex-grow: 1;
  flex-flow: wrap;
  justify-content: center;
}
.padding-bottom-15:not(:last-child) {
  padding-bottom: 15px;
}
.summary-button-create {
  display: inline-block;
}
table .highcharts-button {
  display: none;
}
.no-export .highcharts-button {
  display: none;
}
.tile_count {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
.tile_count .tile_stats_count {
  border-bottom: 1px solid #d9dee4;
  padding: 0 10px 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}
.tile_count .tile_stats_count:hover .count {
  color: #3d4d84;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .tile_count .tile_stats_count {
    margin-bottom: 10px;
    border-bottom: 0;
    padding-bottom: 10px;
  }
}
.tile_count .tile_stats_count:before {
  content: "";
  position: absolute;
  left: 0;
  height: 65px;
  border-left: 2px solid #adb2b5;
  margin-top: 10px;
}
@media (min-width: 992px) {
  .tile_count .tile_stats_count:first-child:before {
    border-left: 0;
  }
}
.tile_count .tile_stats_count .count {
  font-size: 30px;
  line-height: 47px;
  font-weight: 600;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .tile_count .tile_stats_count .count {
    font-size: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .tile_count .tile_stats_count .count {
    font-size: 30px;
  }
}
.tile_count .tile_stats_count span {
  font-size: 12px;
}
@media (min-width: 768px) {
  .tile_count .tile_stats_count span {
    font-size: 13px;
  }
}
.tile_count .tile_stats_count .count_bottom i {
  width: 12px;
}
.green {
  color: #1abb9c;
}
.red {
  color: red;
}
.Rag_Red {
  color: #e02130;
}
.Rag_Amber {
  color: #fab243;
}
.Rag_Green {
  color: #429867;
}
.Rag_Grey {
  color: #3a3a3a;
}
.summaryHolder {
  padding-top: 10px;
  padding-bottom: 10px;
}
.summaryPadding {
  float: left;
  margin-left: 15px;
  padding-top: 5px;
}
.summaryClearfix {
  clear: both;
}
.buttons-container .btn.btn-text {
  background-color: #fafafa;
  border-radius: 16px;
  color: #111;
  font-size: 12px;
  float: left;
  height: 32px;
  line-height: 32px;
  margin: 0 3px;
  padding: 0 9px;
  transition: background-color linear 0.1s;
}
.buttons-container .btn.btn-text:not([disabled]):hover,
.buttons-container .btn.btn-text:not([disabled]):focus {
  background-color: #ececec;
}
.buttons-container .btn.btn-text.active {
  background-color: #3d4d84;
  box-shadow: none;
  color: #fff;
}
.buttons-container .btn.btn-text.active:hover {
  background-color: #2d3961;
}
.buttons-container .btn.btn-text.active:focus {
  background-color: #3d4d84;
}
.buttons-container .btn:last-child {
  margin-right: 0;
}
.buttons-container .btn:first-child {
  margin-left: 6px;
}
.buttons-container.icons-only .btn.btn-text {
  font-size: 1.2em;
  padding: 0;
  text-align: center;
  width: 32px;
}
.pathway {
  height: 110px;
}
.pathway .pathway-header .pathway-title h3 {
  line-height: 1em;
}
.pathway .pathway-header .pathway-title .label {
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
  vertical-align: bottom;
}
.pathway .pathway-header .pathway-title .label.label-open {
  background-color: #3d4d84;
}
.pathway .pathway-header .pathway-title .label.label-closed {
  background-color: #505151;
}
.pathway .pathway-header .pathway-title .label.label-abandoned {
  background-color: #eb1515;
}
.pathway .pathway-header .pathway-title .label.label-paused {
  background-color: #707070;
}
.pathway .pathway-tracker {
  display: flex;
  flex-flow: row;
  list-style: none;
  padding: 0 40px;
  margin: 20px 0;
}
.pathway .pathway-tracker li {
  flex-grow: 1;
  position: relative;
}
.pathway .pathway-tracker li .marker {
  width: 24px;
  height: 24px;
  background-color: #ececec;
  border-radius: 50%;
  display: block;
}
.pathway .pathway-tracker li .marker:before {
  font-family: "care-partner-font";
  width: 24px;
  height: 24px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  display: block;
}
.pathway .pathway-tracker li .step-name {
  position: absolute;
  font-size: 0.875em;
  left: -48%;
  text-align: center;
  width: 100%;
  padding: 4px;
}
.pathway .pathway-tracker li:before,
.pathway .pathway-tracker li:after {
  width: calc(100% - 28px);
  height: 6px;
  display: block;
  background-color: #ececec;
  content: "";
  border-radius: 3px;
  top: 9px;
  position: absolute;
  left: 26px;
}
.pathway .pathway-tracker li.current .marker,
.pathway .pathway-tracker li.current:after {
  background-color: #3d4d84;
}
.pathway .pathway-tracker li.current:after {
  width: calc(50% - 24px);
}
.pathway .pathway-tracker li.current .marker:before {
  content: "\e94d";
}
.pathway .pathway-tracker li.complete .marker,
.pathway .pathway-tracker li.complete:after {
  background-color: #42ce56;
}
.pathway .pathway-tracker li.complete .marker:before {
  content: "\e900";
}
.pathway .pathway-tracker li.paused .marker,
.pathway .pathway-tracker li.paused:after {
  background-color: #707070;
}
.pathway .pathway-tracker li.paused .marker:before {
  content: "\e945";
}
.pathway .pathway-tracker li.abandoned .marker,
.pathway .pathway-tracker li.abandoned:after {
  background-color: #eb1515;
}
.pathway .pathway-tracker li.abandoned .marker:before {
  content: "\e945";
}
.pathway .pathway-tracker li:first-child .step-name {
  left: 0;
  text-align: left;
}
.pathway .pathway-tracker li:last-child:not(:first-child) {
  flex-grow: 0;
}
.pathway .pathway-tracker li:last-child:not(:first-child) .step-name {
  left: -376px;
  text-align: right;
  width: 400px;
}
.pathway .pathway-tracker li:last-child:not(:first-child):before,
.pathway .pathway-tracker li:last-child:not(:first-child):after {
  display: none;
}
.additional-pathways {
  background-color: #fafafa;
  padding: 0 15px;
  margin: 0 -15px;
}
.additional-pathways .pathways {
  padding: 0 0 15px 0;
}
.additional-pathways .pathways .pathway {
  padding: 5px 10px 10px;
  margin-top: 15px;
  background: #fff;
}
.additional-pathways .btn-additional-pathways {
  transition: color linear 0.1s;
}
.additional-pathways .btn-additional-pathways:hover,
.additional-pathways .btn-additional-pathways:focus {
  background-color: transparent;
  color: #111;
}
.additional-pathways .btn-additional-pathways.collapsed .imo {
  transform: rotate(-180deg);
}
.container-summary {
  padding-left: 15px;
  padding-right: 15px;
}
.fluid-grid .item {
  flex-grow: 1;
  min-width: calc(33% - 20px);
  margin: 10px;
  flex-basis: calc(33% - 20px);
}
.fluid-grid .item.shadow {
  background-color: #fff;
  box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
}
.fluid-grid .item.no-grow {
  flex-grow: 0;
}
.fluid-grid .item.fixed-185 {
  min-width: 185px;
}
.fluid-grid .item .panel {
  margin-bottom: 0;
  background-color: transparent;
}
.fluid-grid .flex {
  display: flex;
}
.fluid-grid .flex.item-container-row {
  flex-direction: row;
  width: 100%;
}
.fluid-grid .flex.item-container-row.wrap {
  flex-wrap: wrap;
}
.fluid-grid .flex.item-container-row.wrap .last-item {
  flex-grow: 0;
  flex-basis: calc(33% - 15px);
}
.fluid-grid .summary-ras-budget {
  font-weight: bold;
  font-size: 40px;
  line-height: 1em;
}
.fluid-grid .item-container {
  float: left;
  width: 100%;
}
.fluid-grid .item-container.not-flex {
  width: 33.5%;
}
.fluid-grid .item-container.not-flex + .item-container {
  width: 66.5%;
}
.fluid-grid .item-container.not-flex + .item-container .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 0px;
}
.fluid-grid .item-container.not-flex + .item-container .flex.item-container-row {
  flex-direction: row;
  width: 100%;
}
.fluid-grid .item-container.not-flex + .item-container .flex .item {
  flex-grow: 0;
  flex-basis: calc(50% - 20px);
  margin-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .fluid-grid .item.fixed-145-1024 {
    min-width: 145px;
  }
}
.page-data-explorer .nav.nav-tabs.nav-tabs-primary li a {
  font-size: 1.4em;
}
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary {
  border-bottom: none;
}
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li a {
  transition: background-color linear 0.2s;
}
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li.active a {
  color: #000;
}
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li.active a:hover,
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li.active a:focus {
  background-color: #fff;
}
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:hover,
.page-data-explorer .nav.nav-tabs.nav-tabs-secondary li:not(.active) a:focus {
  padding-bottom: 6px;
  border-radius: 4px;
  background-color: #f8f8f8;
  box-shadow: inset 0 37px 20px rgba(0, 0, 0, 0.016);
}
.page-data-explorer .tab-content {
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.page-data-explorer .tab-content > .tab-pane .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  border-top-left-radius: 0;
}
.page-data-explorer .tab-content > .tab-pane .tab-content h3 {
  line-height: 32px;
}
.page-data-explorer .tab-content > .tab-pane .tab-content h3 .btn {
  display: block;
  text-align: left;
  width: 100%;
}
.page-data-explorer .tab-content > .tab-pane .tab-content h3 .btn .icon {
  float: right;
  width: 32px;
  height: 32px;
  line-height: inherit;
  display: block;
  text-align: center;
  transition: transform linear 0.2s;
}
.page-data-explorer .tab-content > .tab-pane .tab-content h3 .btn:not(.collapsed) .icon {
  transform: rotate(180deg);
}
.page-data-explorer .tab-content .radio .description {
  font-size: 0.9em;
  margin-left: 20px;
}
.page-data-explorer .list.list-formulate-logs li {
  padding: 0;
}
.page-data-explorer .list.list-formulate-logs li a {
  display: block;
  padding: 8px;
  border-radius: 4px;
}
.page-data-explorer .list.list-formulate-logs li a .date-time {
  display: flex;
  font-size: 1.1em;
}
.page-data-explorer .list.list-formulate-logs li a .date-time .date,
.page-data-explorer .list.list-formulate-logs li a .date-time .time {
  width: 50%;
}
.page-data-explorer .list.list-formulate-logs li a .date-time .time {
  text-align: right;
  color: #767676;
}
.page-data-explorer .list.list-formulate-logs li a .service-user-identifier {
  display: block;
}
.page-data-explorer .list.list-formulate-logs li a:hover,
.page-data-explorer .list.list-formulate-logs li a:focus {
  background-color: #fafafa;
  text-decoration: none;
}
.page-data-explorer .list.list-formulate-logs li.active a {
  background-color: #3d4d84;
  color: #fff;
}
.page-data-explorer .list.list-formulate-logs li.active a .date-time .time {
  color: rgba(255, 255, 255, 0.85);
}
.page-data-explorer .list.list-formulate-logs li.active a:hover,
.page-data-explorer .list.list-formulate-logs li.active a:focus {
  background-color: #3d4d84;
  color: #fff;
  text-decoration: none;
}
.page-data-explorer .list.list-qa-feedback-items li {
  padding: 4px 8px;
  margin: 0 0 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.page-data-explorer .list.list-key-value-pair li {
  display: flex;
}
.page-data-explorer .list.list-key-value-pair li .key {
  color: #767676;
}
.page-data-explorer .list.list-key-value-pair li .key,
.page-data-explorer .list.list-key-value-pair li .value {
  flex-basis: 50%;
  flex-grow: 0;
}
.page-data-explorer .list.list-flex {
  display: flex;
  justify-content: center;
}
.page-data-explorer .list.list-flex:last-of-type {
  margin-bottom: 7.5px;
}
.page-data-explorer .list.list-flex.list-charts {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
}
.page-data-explorer .list.list-flex li {
  flex-grow: 1;
  flex-basis: 25%;
  text-align: center;
  margin: 7.5px;
  border-radius: 4px;
}
.page-data-explorer .list.list-flex li:first-child {
  margin-left: 0;
}
.page-data-explorer .list.list-flex li:last-child {
  margin-right: 0;
}
.page-data-explorer .list.list-flex li.stat {
  padding: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
}
.page-data-explorer .list.list-flex li.stat .imo {
  background-color: #d7dae6;
  display: block;
  padding: 16px;
  border-radius: 50%;
  color: #3d4d84;
  flex-grow: 0;
  width: 90px;
  height: 90px;
  margin-right: 16px;
}
.page-data-explorer .list.list-flex li.stat .details {
  text-align: left;
}
.page-data-explorer .list.list-flex li.stat .details.no-data {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
.page-data-explorer .list.list-flex li.stat .details .number {
  display: inline-block;
  font-size: 3em;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 600;
  margin: 16px 0 16px 0;
  color: #3d4d84;
  position: relative;
}
.page-data-explorer .list.list-flex li.stat .details .description {
  display: block;
  margin: 0 auto;
  max-width: 280px;
  line-height: 1.3em;
}
.page-data-explorer .list.list-flex li.stat.error {
  background-color: #eb1515;
  color: #fff;
}
.page-data-explorer .list.list-flex li.chart {
  padding-bottom: 20px;
  overflow: hidden;
}
.page-data-explorer .list.list-flex li.chart h3 {
  display: block;
  /*background: rgb(236,236,236);
                        background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(250,250,250,1) 100%);*/
  line-height: 48px;
}
.page-data-explorer .list.list-flex li.chart .chart {
  padding: 10px;
}
.page-data-explorer .tile {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  margin: 7.5px;
  border-radius: 4px;
}
.page-data-explorer .tile .tile-header {
  background-color: #3d4d84;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  height: 50px;
}
.page-data-explorer .tile .tile-header.loading {
  background: linear-gradient(-45deg, #3d4d84, #8b94b5, #3d4d84, #8b94b5);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}
.page-data-explorer .tile .tile-header .title {
  flex-grow: 1;
  text-align: left;
}
.page-data-explorer .tile .tile-header .title h3 {
  color: #fff;
  display: inline;
}
.page-data-explorer .tile .tile-header .title .insight-help {
  color: white;
}
.page-data-explorer .tile .tile-header .controls {
  flex-grow: 0;
}
.page-data-explorer .tile .tile-body {
  height: 370px;
}
.page-data-explorer .tile .tile-body.table-view {
  height: 405px;
}
.page-data-explorer .tile .tile-body.education-bands {
  height: 420px;
}
.page-data-explorer .tile .tile-body .average-key {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fafafa;
  position: relative;
  width: 18px;
  height: 12px;
  top: 1px;
  border-radius: 2px;
}
.page-data-explorer .tile .tile-body .average-key:before,
.page-data-explorer .tile .tile-body .average-key:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 0px;
  width: 8px;
  border-top: 2px solid #111;
}
.page-data-explorer .tile .tile-body .average-key:after {
  left: 12px;
  width: 4px;
}
.page-data-explorer .tile .tile-footer {
  background-color: #f8f8f8;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: flex-end;
}
.page-data-explorer .tile .tile-footer .component {
  flex-grow: 0;
}
.page-data-explorer .tile .tile-footer .component.legend {
  width: calc(100% - 240px);
}
.page-data-explorer .tile .tile-footer .component.copy-to-clipboard {
  flex-grow: 1;
}
.page-data-explorer .tile.tile-thin {
  height: 110px;
  align-items: center;
  display: flex;
}
.page-data-explorer .tile.tile-thin .statistic {
  padding: 10px;
}
.page-data-explorer .tile .statistic {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
.page-data-explorer .tile .statistic .imo {
  background-color: #d7dae6;
  display: block;
  border-radius: 50%;
  color: #3d4d84;
  flex-grow: 0;
  width: 90px;
  height: 90px;
  margin-right: 16px;
  line-height: 90px;
}
.page-data-explorer .tile .statistic .details {
  text-align: left;
}
.page-data-explorer .tile .statistic .details.no-data {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
.page-data-explorer .tile .statistic .details .number {
  display: inline-block;
  font-size: 3em;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 600;
  margin: 16px 0 16px 0;
  color: #3d4d84;
  position: relative;
}
.page-data-explorer .tile .statistic .details .description {
  display: block;
  margin: 0 auto;
  max-width: 280px;
  line-height: 1.3em;
}
.page-data-explorer .tile .chart-container {
  display: flex;
  flex-direction: column;
}
.page-data-explorer .tile .chart-container .controls {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  padding: 8px 15px;
}
.page-data-explorer .tile .chart-container .controls .sort {
  margin-right: auto;
}
.page-data-explorer .tile .chart-container .controls .traverse button {
  background-color: #000;
  border-radius: 3px;
  color: #fff;
  padding: 0px 4px;
  border: none;
  width: 20px;
  height: 20px;
  line-height: 20px;
  transition: background-color linear 0.15s;
}
.page-data-explorer .tile .chart-container .controls .traverse button:disabled {
  background-color: #707070;
  border-color: #707070;
  box-shadow: none;
  pointer-events: none;
}
.page-data-explorer .tile .chart-container .controls .traverse button:first-of-type {
  padding-left: 2px;
}
.page-data-explorer .tile .chart-container .controls .traverse button:last-of-type {
  padding-right: 2px;
}
.page-data-explorer .tile .chart-container .controls .traverse button:hover,
.page-data-explorer .tile .chart-container .controls .traverse button:focus {
  background-color: #3d4d84;
}
.page-data-explorer .tile .chart-container .controls .btn {
  font-size: 13px;
  line-height: normal;
  height: 21px;
  padding-bottom: 1px;
}
.page-data-explorer .tile .chart-container .chart {
  overflow: hidden;
}
.page-data-explorer .tile .chart-container .chart h3 {
  display: block;
  /*background: rgb(236,236,236);
                    background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(250,250,250,1) 100%);*/
  line-height: 48px;
}
.page-data-explorer .tile .chart-container-education-bands {
  display: flex;
  margin-top: 5px;
}
.page-data-explorer .tile .chart-container-education-bands .chart {
  width: 33%;
}
.page-data-explorer .tile .chart-container-education-bands .chart .highcharts-title,
.page-data-explorer .tile .chart-container-education-bands .chart .highcharts-axis-title {
  font-weight: bold;
}
.page-data-explorer .tile .chart-table-wrapper {
  height: 370px;
}
.page-data-explorer .tile .chart-table-wrapper .chart-table {
  max-height: 370px;
  overflow-y: auto;
}
.page-data-explorer .tile .chart-table-wrapper .chart-table .chart-table-row {
  display: flex;
}
.page-data-explorer .tile .chart-table-wrapper .chart-table .chart-table-row.alt div {
  background-color: #ececec;
}
.page-data-explorer .tile .chart-table-wrapper .chart-table .header {
  background-color: #ececec;
  cursor: pointer;
  font-weight: bold;
  padding: 4px 8px;
  text-align: left;
}
.page-data-explorer .tile .chart-table-wrapper .basic-chart-table .name-column {
  flex: 1 1 80%;
  overflow: hidden;
  padding: 4px 0px 4px 8px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-data-explorer .tile .chart-table-wrapper .basic-chart-table .value-column {
  flex: 1 1 20%;
  padding: 4px 8px 4px 0px;
  text-align: right;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row > div {
  padding: 4px 8px;
  text-align: left;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .identifier-column {
  flex: 1 1 25%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .opened-column {
  flex: 1 1 15%;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .assessor-column {
  flex: 1 1 20%;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .calculations-column {
  flex: 1 1 10%;
  text-align: right;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .status-column {
  flex: 1 1 10%;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .estimated-column {
  flex: 1 1 20%;
  text-align: right;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .estimated-column:not(.header) {
  display: flex;
  flex-flow: row;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .estimated-column .estimated-value {
  flex: 1 1 90%;
  padding-right: 5px;
  text-align: right;
}
.page-data-explorer .tile .chart-table-wrapper .insight-form-calculations-chart-table .chart-table-row .estimated-column .estimated-icon {
  color: #707070;
  flex: 1 1 10%;
  padding-left: 0px;
  padding-top: 4px;
  text-align: left;
}
.page-data-explorer .tile .chart-table-wrapper .education-bands-chart-table .chart-table-row > div {
  padding: 4px 8px;
  text-align: left;
}
.page-data-explorer .tile .chart-table-wrapper .education-bands-chart-table .chart-table-row .name-column {
  flex: 1 1 25%;
}
.page-data-explorer .tile .chart-table-wrapper .education-bands-chart-table .chart-table-row .band-column {
  flex: 1 1 7%;
}
.page-data-explorer .tile .chart-table-wrapper .education-bands-chart-table .chart-table-row .mean-column {
  flex: 1 1 7%;
}
.page-data-explorer .tile .timeframe-readonly {
  font-size: 0.9em;
  margin: 8px 8px 8px 0px;
  text-align: right;
}
.page-data-explorer .tile .timeframe-readonly .timeframe {
  font-weight: bold;
}
.page-data-explorer .tile .copy-to-clipboard {
  text-align: left;
}
.page-data-explorer .tile .copy-to-clipboard .btn {
  margin: 0px 0px 0px 8px;
  transition: color linear 0.2s;
}
.page-data-explorer .tile .copy-to-clipboard.copying .btn {
  color: #097cbf;
}
.page-data-explorer .tile .copy-to-clipboard.copied .btn {
  color: #0db887;
}
.page-data-explorer .tile .copy-to-clipboard.copied .btn:after {
  content: '\e865';
  display: inline-block;
  color: inherit;
  font-family: "ImosphereIcons";
}
.page-data-explorer .tile .no-data-message {
  display: block;
  height: 398px;
  padding-top: 100px;
}
.page-data-explorer .tile .no-data-message .imo {
  margin-left: auto;
  margin-right: auto;
}
.page-data-explorer .tile .loading-container {
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}
.page-data-explorer .text-facecode {
  color: #767676;
  font-size: 0.875em;
  display: block;
}
.page-data-explorer .legend-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.page-data-explorer .legend-wrapper .legend {
  font-size: 0.875em;
  height: 65px;
  margin-left: 5px;
  overflow-y: hidden;
  padding: 3px;
  flex-grow: 1;
}
.page-data-explorer .legend-wrapper .legend .legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-data-explorer .legend-wrapper .legend .legend-item .legend-color {
  border-color: transparent;
  border-radius: 2px;
  font-size: 0.8em;
  height: 12px;
  margin: 0px 3px 0px 0px;
  width: 16px;
}
.page-data-explorer .legend-wrapper .legend .legend-item .legend-name {
  flex: 2 1 90%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-data-explorer .legend-wrapper .legend-scroll {
  width: 30px;
}
.page-data-explorer .legend-wrapper .legend-scroll button {
  background-color: #000;
  border-radius: 2px;
  color: #fff;
  padding: 0;
  border: none;
  width: 20px;
  height: 20px;
  line-height: 20px;
  transition: background-color linear 0.15s;
  text-align: center;
}
.page-data-explorer .legend-wrapper .legend-scroll button:hover,
.page-data-explorer .legend-wrapper .legend-scroll button:focus {
  background-color: #3d4d84;
}
.page-data-explorer .legend-wrapper .legend-scroll button.up {
  margin-top: 12px;
}
.page-data-explorer .legend-wrapper .legend-scroll button.down {
  margin-top: 3px;
  padding-top: 1px;
}
.page-data-explorer .legend-wrapper .legend-scroll button:disabled {
  background-color: #707070;
  box-shadow: none;
  pointer-events: none;
}
.page-data-explorer .date-period-picker {
  border: 1px solid #ddd;
  background-color: #F8F8F8;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16), inset 0 27px 8px rgba(255, 255, 255, 0.5);
  float: right;
  margin: 8px 8px 8px 17px;
  padding: 6px;
  display: flex;
  transition: all linear 0.2s;
}
.page-data-explorer .date-period-picker:hover {
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16), inset 0 27px 8px rgba(0, 0, 0, 0.02);
}
.page-data-explorer .date-period-picker .readonly-left {
  text-align: left;
}
.page-data-explorer .date-period-picker .readonly-left b {
  font-family: "Sofia Pro", sans-serif;
  font-weight: bold;
  display: block;
}
.page-data-explorer .date-period-picker .readonly-right {
  padding: 0 0 0 12px;
}
.page-data-explorer .date-period-picker .readonly-right .btn {
  font-size: 20px;
  line-height: 42px;
  height: 42px;
}
.page-data-explorer .date-period-picker .date-picker-container {
  float: left;
  padding: 0 8px;
  width: 280px;
}
.page-data-explorer .date-period-picker .date-picker-container .from,
.page-data-explorer .date-period-picker .date-picker-container .to {
  float: left;
  margin: 2px 2px 6px 2px;
}
.page-data-explorer .date-period-picker .date-picker-container .validation-message {
  float: left;
  margin-right: 5px;
}
.page-data-explorer .date-period-picker .date-picker-container label {
  text-align: left;
}
.page-data-explorer .date-period-picker .dropdown-menu {
  left: -73px;
}
.page-data-explorer .date-period-picker .dropdown-menu.dropdown-menu-with-arrow:before,
.page-data-explorer .date-period-picker .dropdown-menu.dropdown-menu-with-arrow:after {
  border-bottom-width: 9px;
  left: 258px;
}
.page-data-explorer .insight-help {
  color: #3d4d84;
  font-size: 1.3em;
  margin-left: 4px;
}
.page-data-explorer .insight-help button {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
}
.page-data-explorer .insight-help button:hover,
.page-data-explorer .insight-help button:focus {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
}
.page-data-explorer .insight-help .dropdown-menu {
  color: #000;
  width: 450px;
  left: -10px;
}
.page-data-explorer .insight-help .dropdown-menu .help-text {
  padding: 10px;
}
.page-data-explorer .insight-help .dropdown-menu .help-text h4 {
  margin-bottom: 5px;
  color: unset;
}
.page-data-explorer .insight-help .dropdown-menu .help-text p {
  font-weight: normal;
}
.page-data-explorer .insight-help .dropdown-menu.dropdown-menu-right {
  left: auto;
  right: -10px;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.page-print {
  color: black;
  font-size: 0.933rem;
  margin: 10px 20px 0;
  padding-bottom: 0;
}
.page-print .print-logo-icon {
  font-size: 25px;
  color: #111 !important;
}
.page-print .print-logo-icon .name {
  font-family: "Sofia Pro";
  color: #111 !important;
}
.page-print footer {
  background-color: transparent;
}
.page-print .page-activities .strengths-needs .heading {
  margin-bottom: 3.75pt;
}
.page-print .print-container {
  position: relative;
}
.page-print .btn-print {
  margin-top: 3px;
}
.page-print .btn-close-preview {
  margin-top: 2px;
  outline: none;
}
.page-print .btn-close-preview [class^="cp-icon-"] {
  color: #707070;
  font-size: 1.75rem;
}
.page-print .btn-close-preview [class^="cp-icon-"]:hover {
  color: #070707;
}
.page-print .btn-close-preview:hover .cp-icon-cross:before {
  color: #707070;
  content: "\e924";
}
.page-print .btn-close-preview:active {
  box-shadow: none;
}
.page-print .print-controls {
  border-radius: 6px;
  border: solid 1px #ececec;
  padding: 20px;
  background-color: #fff;
}
.page-print .print-controls .btn-primary {
  font-size: 1.13333333rem;
}
.page-print .print-controls .control-label {
  font-size: 13px !important;
  display: block;
  font-weight: 400;
  margin: 0 5px 0 0;
}
.page-print .print-controls .form-control {
  padding-left: 10px;
  padding-right: 12px;
}
.page-print .print-preview-title {
  font-size: 17px;
  color: #707070;
  margin-bottom: 10px;
}
.page-print .banner,
.page-print .banner-container,
.page-print .navbar,
.page-print .blank-tabs-container,
.page-print .cp-icon-star,
.page-print .fixed-banner,
.page-print.fixed-banner.visibile {
  display: none;
  visibility: hidden;
}
.page-print .container {
  max-width: 100%;
}
.page-print .print-preview {
  border: solid 1px #ececec;
  box-shadow: 0 0 8px 3px #ececec;
  padding: 20px;
  background-color: #fff;
}
.page-print .print-preview .form-title {
  margin-bottom: -2px;
  padding: 20px 0 0;
}
.page-print .print-preview .date-assessed {
  margin-bottom: -2px;
  padding: 10px 0 0;
}
.page-print .print-preview .sk-spinner {
  display: none;
}
.page-print .print-preview .loading-message {
  background-color: transparent;
}
.page-print .print-preview.loading .table-print {
  opacity: 0.5;
}
.page-print .print-preview.loading .sk-spinner {
  display: block;
  opacity: 1;
  position: absolute;
  top: 240px;
  left: 50%;
  margin-left: -20px;
  z-index: 10;
}
.page-print [class^="cp-icon-"],
.page-print [class*="cp-icon-"] {
  display: none;
}
.page-print .cp-icon-cross {
  display: block;
}
.page-print .cp-icon-care-plan-associations {
  display: inline-block;
  margin: 2px 0 0 4px;
}
.page-print .cp-icon-care-plan-associations:hover,
.page-print .cp-icon-care-plan-associations:focus {
  color: #000;
}
.page-print .cp-icon-function {
  color: #111;
  display: inline-block;
}
.page-print .cp-icon-function:focus,
.page-print .cp-icon-function:hover {
  color: #111;
}
.page-print h1,
.page-print h2,
.page-print h3,
.page-print h4 {
  background: none !important;
  color: black;
}
.page-print h1 {
  font-size: 28px;
}
.page-print h2 {
  font-size: 21px;
  line-height: 26px;
}
.page-print h3 {
  font-size: 16px;
  line-height: 20px;
}
.page-print .text-sm {
  font-size: 13px !important;
}
.page-print .note {
  border: none !important;
}
.page-print .text-emphasis {
  color: black;
}
.page-print .guidance-note {
  font-size: 7.5pt !important;
  font-style: italic;
  font-weight: 400;
  line-height: 1.267rem;
  white-space: pre-wrap;
  clear: both;
  margin: 0;
  padding: 0 0 8px;
}
.page-print #footer {
  display: none;
  visibility: hidden;
}
.table-print {
  border: none !important;
  border-collapse: separate !important;
  height: 99%;
  width: 100%;
}
.table-print thead {
  display: table-header-group;
}
.table-print tbody {
  display: table-row-group;
}
.table-print tfoot {
  display: table-footer-group;
}
.table-print tfoot .print-information th,
.table-print tfoot .print-information td {
  padding: 5pt 0 0 !important;
}
.table-print th,
.table-print td {
  border: solid 1px #cccccc !important;
  border-spacing: 0;
  font-weight: 400 !important;
  margin: 0;
  /* following is for Opera and IE compatibility */
  padding: 3pt 5pt !important;
  vertical-align: top;
  empty-cells: show;
  white-space: normal;
}
.table-print th.cell-emphasis,
.table-print td.cell-emphasis,
.table-print th.cell-emphasis p,
.table-print td.cell-emphasis p,
.table-print th.cell-emphasis span,
.table-print td.cell-emphasis span {
  background: #e3e3e3 !important;
}
.table-print th.text-emphasis,
.table-print td.text-emphasis {
  font-weight: 600 !important;
}
.table-print th.section-title,
.table-print td.section-title {
  border-bottom: none !important;
}
.table-print th.form-item-cell,
.table-print td.form-item-cell {
  border: none !important;
  padding: 3pt 0 0 !important;
}
.table-print th.xs,
.table-print td.xs {
  width: 20%;
}
.table-print th.sm,
.table-print td.sm {
  width: 35%;
}
.table-print th.md,
.table-print td.md {
  width: 50%;
}
.table-print th.lg,
.table-print td.lg {
  width: 75%;
}
.table-print .cell-borderless {
  border: none !important;
}
.table-print .cell-borderless th,
.table-print .cell-borderless td {
  border: none !important;
  padding: 0 !important;
}
.table-print .cell-borderless .table-print.table-bordered th,
.table-print .cell-borderless .table-print.table-bordered td {
  border: solid 1px #cccccc !important;
  padding: 3pt 5pt !important;
}
.table-print .cell-borderless .table-print.table-bordered .cell-borderless {
  border: none !important;
}
.table-print .cell-borderless .table-print.table-bordered .cell-borderless th,
.table-print .cell-borderless .table-print.table-bordered .cell-borderless td {
  border: none !important;
  padding: 0 !important;
}
.table-print .print-information th,
.table-print .su-banner th,
.table-print .page-title th,
.table-print .page-content th,
.table-print .table-print-borderless th,
.table-print .print-information td,
.table-print .su-banner td,
.table-print .page-title td,
.table-print .page-content td,
.table-print .table-print-borderless td {
  border: none !important;
  padding: 0 0 5pt !important;
}
.table-print .print-information table,
.table-print .su-banner table,
.table-print .page-title table,
.table-print .page-content table,
.table-print .table-print-borderless table {
  width: 100% !important;
}
.table-print .print-information .print-copy,
.table-print .su-banner .print-copy,
.table-print .page-title .print-copy,
.table-print .page-content .print-copy,
.table-print .table-print-borderless .print-copy {
  display: inline-block !important;
  margin-right: 8pt !important;
}
.table-print .print-information .print-status,
.table-print .su-banner .print-status,
.table-print .page-title .print-status,
.table-print .page-content .print-status,
.table-print .table-print-borderless .print-status {
  margin-top: 10px;
}
.table-print .su-banner th,
.table-print .su-banner table tr th,
.table-print .su-banner table tr td {
  border: none !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.table-print .su-banner table {
  border-bottom: solid 1pt #000 !important;
  margin-bottom: 10pt !important;
}
.table-print .su-banner table th,
.table-print .su-banner table td {
  border: solid 1px #cccccc !important;
  padding: 3pt 5pt !important;
}
.table-print .su-banner .print-su-identifier {
  display: inline-block !important;
  padding-right: 10pt !important;
}
.table-print .page-title th,
.table-print .page-title td {
  border: none !important;
  padding: 2pt 0 !important;
}
.table-print .page-title th h1,
.table-print .page-title td h1 {
  font-size: 15pt !important;
}
.table-print .page-title h2 {
  margin: 10pt 0 0 !important;
}
.table-print .page-title .coc {
  display: inline-block !important;
  margin-right: 10pt !important;
}
.table-print .sub-title {
  margin: 10pt 0 -3pt !important;
}
.table-print .section-subtitle {
  color: black;
  display: inline-block;
  font-family: "Sofia Pro";
  font-size: 1.2em;
  font-weight: 600;
}
.table-print .form {
  position: relative;
  top: -3pt !important;
}
.table-print .form-section-title {
  margin: 10pt 0 0 !important;
}
.table-print .care-plan-h2 {
  margin-top: 10pt !important;
}
.table-print .care-plan-h3 {
  margin: 10pt 0 3pt !important;
}
.table-print .center-of-care {
  margin: 2pt 0 5pt !important;
}
.table-print .spacer td {
  border: none !important;
  height: 20pt !important;
}
.table-print .table-interventions table {
  margin-bottom: 10pt;
}
.table-print .table-interventions:only-child table {
  margin-bottom: 0;
}
.table-print .answer-history-h3 {
  margin: 10pt 0 3pt !important;
}
.table-print .activity-filter {
  padding-right: 10pt;
}
.table-print .list-tags .tags-list-commas:after {
  content: ", ";
}
.table-print .list-tags .tags-list-commas:last-child:after {
  content: " ";
}
.table-custom-print.table-bordered {
  border-color: #cccccc !important;
}
.table-custom-print.table-bordered tr:first-child td.print-information {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
.table-custom-print.table-bordered tr:first-child td.print-information .print-copy {
  display: inline-block !important;
  margin-right: 8pt !important;
}
.table-custom-print.table-bordered thead > tr > td,
.table-custom-print.table-bordered thead tr:first-child td {
  border: solid 2px #cccccc !important;
}
.table-custom-print.table-bordered thead > tr td.blank-cell {
  border-color: transparent !important;
}
.table-custom-print.table-bordered thead > tr td.blank-cell.trailing-blank {
  border-bottom: none !important;
}
.table-custom-print.table-bordered td,
.table-custom-print.table-bordered tr:first-child td {
  border: solid 1px #cccccc !important;
  empty-cells: show;
  line-height: 18pt !important;
  padding: 0 4pt !important;
  vertical-align: top;
  white-space: normal;
}
.table-custom-print.table-bordered td.blank-cell,
.table-custom-print.table-bordered tr:first-child td.blank-cell {
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  height: 12pt !important;
  padding: 4pt !important;
}
.table-custom-print.table-bordered .sub-header {
  display: inline-block !important;
  margin-right: -4px !important;
  padding: 3pt !important;
}
.table-custom-print.table-bordered .sub-header {
  float: none !important;
}
.table-custom-print.table-bordered .sub-header h4 {
  font-weight: 400 !important;
}
.table-custom-print.table-bordered .form-section-title {
  padding-top: 2pt !important;
  padding-bottom: 2pt !important;
}
.table-custom-print.table-bordered .question {
  border-top: solid 1px #cccccc !important;
  margin-left: -4pt !important;
  margin-right: -4pt !important;
  padding-top: 4pt !important;
  padding-left: 4pt !important;
  padding-right: 4pt !important;
}
.table-custom-print.table-bordered .question .marked-as {
  padding-left: 6pt !important;
}
.table-custom-print.table-bordered .answer {
  color: #333 !important;
  margin-left: -4pt !important;
  margin-top: -4pt !important;
  margin-right: -4pt !important;
  padding-left: 4pt !important;
  padding-right: 4pt !important;
  padding-bottom: 4pt !important;
}
.table-custom-print.table-bordered .answer p {
  white-space: pre-wrap;
}
.table-custom-print.table-bordered .has-note {
  border: solid 1px #cccccc !important;
  padding: 5pt !important;
  margin: 2pt 2pt 6pt !important;
}
.table-custom-print.table-bordered .children {
  padding-left: 20pt !important;
}
.table-custom-print.table-bordered .children .question,
.table-custom-print.table-bordered .children .answer {
  padding-left: 0 !important;
}
.table-custom-print.table-bordered .children .has-note {
  margin-left: 0 !important;
}
.table-custom-print.table-bordered .pathways-h3 {
  margin: 2pt 0 !important;
}
.table-custom-print.table-bordered .care-plan-h3 {
  margin: 6pt -4pt 1pt !important;
}
.table-custom-print.table-bordered .center-of-care {
  margin: 5pt 0 !important;
}
.table-custom-print.table-bordered .cell-emphasis-light {
  background-color: #f3f3f3 !important;
}
.table-custom-print.table-bordered .cell-emphasis-medium {
  background-color: #e4e4e4 !important;
}
.table-custom-print.table-bordered tbody:nth-of-type(n + 2) > tr:last-of-type > .blank-cell {
  border-color: transparent !important;
}
.question {
  margin-left: -4pt !important;
  margin-right: -4pt !important;
  padding-top: 4pt !important;
  padding-left: 4pt !important;
  padding-right: 4pt !important;
}
.question .marked-as {
  padding-left: 6pt !important;
}
.answer {
  color: #333 !important;
  margin-left: -4pt !important;
  margin-top: -4pt !important;
  margin-right: -4pt !important;
  padding-left: 4pt !important;
  padding-right: 4pt !important;
  padding-bottom: 8pt !important;
}
.answer p {
  white-space: pre-wrap;
}
@page {
  margin: 1cm;
}
@media print {
  html,
  body {
    height: 99%;
  }
  a[href]:after {
    content: none !important;
  }
  .page-print h1 {
    font-size: 18pt !important;
  }
  .page-print h2 {
    font-size: 14pt !important;
    line-height: 28px;
  }
  .page-print h3 {
    font-size: 11pt !important;
    line-height: 24px;
  }
  .page-print h4 {
    font-size: 10pt !important;
    line-height: 21px;
  }
  .page-print .text-sm {
    font-size: 7.4pt !important;
  }
  .print-container {
    position: relative;
    top: 0 !important;
  }
  .print-bottom-border-override {
    border-bottom: solid 1px #cccccc !important;
  }
  .print-shift-notes h1,
  .print-shift-notes h1 span {
    background: transparent !important;
    font-size: 26px !important;
    padding: 2px !important;
  }
  .print-shift-notes table td {
    padding: 5px 8px !important;
  }
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11 {
    width: 100%;
  }
  /* GU Truncate */
  .gutruncate-ellipsis {
    display: none !important;
  }
  .gutruncate-more {
    display: inline !important;
  }
  .gutruncate-more-link {
    display: none;
  }
  .nav-tabs.tabs-borderless li > a {
    display: none;
    font-weight: 600;
    padding: 10px 0;
  }
}
tr {
  page-break-inside: auto !important;
}
/* Semi-Bold*/
@font-face {
  font-family: "Sofia Pro";
  src: url("Content/Fonts/sofiapro-semibold-webfont.woff?v3");
  font-weight: 600;
  font-style: normal;
}
/* Bold */
@font-face {
  font-family: "Sofia Pro";
  src: url("Content/Fonts/sofiapro-bold-webfont.woff?v3");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "FSMeWeb";
  src: url("Content/Fonts/FSMeWeb-Regular.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FSMeWeb";
  src: url("Content/Fonts/FSMeWeb-Bold.woff");
  font-weight: bold;
  font-style: normal;
}
html,
body {
  color: #111;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  text-rendering: optimizeLegibility;
  transition: background linear 0.3s;
  min-height: 100%;
  background-image: url(/content/images/rectangle-graphic.svg);
  background-size: 800px;
  background-repeat: no-repeat;
  background-position: right -200px top -150px;
  background-color: #d7dae6;
}
.text-color {
  color: #111;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.13333333rem;
}
.text-sm {
  font-size: 1rem;
  line-height: 1.267rem;
}
.text-emphasis {
  color: #1b1b1b;
  font-weight: 600;
  font-family: "Sofia Pro";
}
.text-italic {
  font-style: italic;
}
.text-muted {
  color: #616161;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-action {
  color: #fca744;
}
.text-success {
  color: #0db887;
}
.text-has-error {
  color: #bf201f;
}
.text-bold {
  font-weight: 600;
}
.text-underlined {
  text-decoration: underline !important;
}
.bold-font-small {
  font-family: "Sofia Pro";
  font-size: 15px;
  line-height: 1.3em;
}
.line-height-sm {
  line-height: 1.357rem;
}
b,
strong {
  color: #1b1b1b;
  font-weight: 600;
}
a,
a.text-emphasis {
  color: #3d4d84;
}
a:hover,
a.text-emphasis:hover {
  color: #33416f;
}
.worklist-user-name {
  color: #3d4d84 !important;
  font-family: "Sofia Pro";
  font-size: 15px;
  line-height: 1.3em;
}
.worklist-user-name:hover {
  border-color: #3d4d84 !important;
}
h1,
h2,
h3,
h4,
.as-h1 {
  color: #3d4d84;
  margin: 0;
}
h1,
.as-h1 {
  font: 600 25px / 38px "Sofia Pro", helvetica, arial, sans-serif;
}
h2,
.as-h2 {
  font: 600 21px / 32px "Sofia Pro", helvetica, arial, sans-serif;
}
h2 .text-muted,
.as-h2 .text-muted {
  font-weight: 400;
}
h2.contact-role-name,
.as-h2.contact-role-name {
  max-width: 65%;
}
h2.black,
.as-h2.black {
  color: #111;
}
h3 {
  font: 600 17px / 26px "Sofia Pro", helvetica, arial, sans-serif;
}
h4 {
  font: 600 15px / 21px "Sofia Pro", helvetica, arial, sans-serif;
}
h4.about-org-title {
  margin-top: 40px;
}
p.about-cp-desc {
  margin-bottom: 60px;
}
.identifier,
.list-link-text {
  font-family: "Sofia Pro";
  font-size: 15px;
  line-height: 1.3em;
}
.panel-title {
  font-size: 16px !important;
}
.no-data {
  color: #707070;
  padding: 30px;
  text-align: center;
  background-color: #fff;
  margin-bottom: 15px;
}
.no-data h2,
.no-data h3 {
  color: #707070 !important;
}
.no-data-summary {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-align: left;
}
.no-data-summary h2,
.no-data-summary h3 {
  color: #707070 !important;
}
.no-data-page {
  text-align: center;
  color: #111;
}
.diagnosis-code {
  color: #616161;
  font-size: 1rem;
  line-height: 1.267rem;
  display: block;
}
@media (min-width: 1299px) {
  .number-counts {
    font-size: 40px;
    color: #3a3a3a;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .number-counts.small {
    font-size: 15px;
  }
  .count-text {
    font-size: 1rem;
    line-height: 1.267rem;
    margin-bottom: 0px;
  }
}
@media (max-width: 1299px) {
  .number-counts {
    font-size: 40px;
    color: #3a3a3a;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0px;
  }
  .count-text {
    display: inline-block;
    margin-bottom: 0px;
  }
}
.summary-view-more {
  font-weight: normal;
  font-size: 15px;
}
.activity-goals-selected-count {
  font-weight: normal;
  font-size: 15px;
  color: #fff !important;
}
@media print {
  * {
    background: white !important;
    border: none !important;
    border-radius: 0 !important;
    color: #000 !important;
    font-size: 9pt !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 13pt !important;
  }
  h1,
  h1 span {
    font-size: 14pt !important;
    line-height: 20pt !important;
  }
  .banner h1,
  .banner h1 span {
    font-size: 16pt !important;
    line-height: 22pt !important;
  }
  h2 {
    font-size: 11pt !important;
    line-height: 18pt !important;
  }
  h3 {
    font-size: 10pt !important;
    line-height: 15pt !important;
  }
  h4 {
    font-size: 9pt !important;
    line-height: 14pt !important;
  }
}
.markdown p {
  max-width: 650px;
  margin-bottom: 10px !important;
}
.markdown p:last-of-type {
  margin-bottom: 0;
}
.markdown h1 {
  font-size: 18px;
  color: #111;
}
.markdown h2 {
  font-size: 16px;
  color: #111;
}
.markdown h3 {
  font-size: 14px;
  color: #111;
}
.markdown h4,
.markdown h5,
.markdown h6 {
  font-size: 13px;
  color: #111;
}
.markdown p,
.markdown table,
.markdown ul,
.markdown ol,
.markdown blockquote {
  font-size: 12px;
  color: #111;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
}
.markdown blockquote {
  border-left-color: #ddd;
  margin: 15px 0 !important;
}
.markdown blockquote:last-of-type {
  margin-bottom: 0;
}
.markdown blockquote p:last-of-type {
  margin-bottom: 0 !important;
}
.markdown ul,
.markdown ol {
  max-width: 650px;
  padding-left: 20px;
  margin: 10px 0 !important;
}
.markdown ul li,
.markdown ol li {
  margin-bottom: 10px !important;
}
.markdown ul li:last-of-type,
.markdown ol li:last-of-type {
  margin-bottom: 0;
}
.markdown ul li p,
.markdown ol li p {
  margin-bottom: 10px !important;
}
.markdown ul li p:last-of-type,
.markdown ol li p:last-of-type {
  margin-bottom: 0;
}
.markdown ul {
  list-style-type: disc;
}
.markdown ol {
  list-style-type: decimal;
}
.markdown a {
  text-decoration: underline !important;
  font-family: "FSMeWeb", helvetica, arial, sans-serif;
}
.markdown hr {
  border-top-color: #ddd;
}
