﻿@import (reference) "../colorPalette.less";
// Fixed Sidebar LESS
.fixed-sidebar.affix {
    position: initial;
    width: 100%;
}

// Form Save Options - changes at 760 viewport
.sidebar-container {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 20;

    @media screen and (max-width: 900px) {
        position: inherit;
    }

    .panel {
        background: transparent;
        .border-radius(0);
        border: none;
        margin-bottom: 0;

        .autosave-label {
            display: none;
        }

        .btn-primary {
            float: right;
        }

        .panel-body {
            padding: 15px;

            .tracker-content {
                padding-top: 0;
            }

            .alert {
                float: left;
                max-width: 540px;
            }
        }

        .tab-content {
            background-color: @color-white;
        }

        .nav {
            &.nav-tabs {
                border-bottom: none;
                display: flex;

                > li {
                    flex-grow: 1;

                    > a {
                        margin-right: 0;
                        border: none;
                        border-radius: 0;
                        font-family: @heading-font-family;
                        font-size: 16px;
                        padding: 7px 15px 8px;
                        line-height: 21px;

                        @media (max-width: 1130px) {
                            font-size: 0.9em;
                        }
                    }

                    &.active {
                        a {
                            pointer-events: none;
                            background-color: @color-blue;
                            color: @color-white;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: @screen-md-min) {
        position: relative;
        z-index: auto;

        .panel {
            .border-radius-sm;
            margin-bottom: 0;

            .autosave-label {
                display: block;
            }

            .btn-primary {
                float: none;
            }

            .panel-body {
                padding: 20px;

                .alert {
                    float: none;
                    max-width: none;
                }
            }
        }

        .answer-options .dropdown-menu {
            bottom: auto;
            left: auto;
            right: 0;
            top: 96%;
        }
    }

    @media only screen and (min-width: 1350px) {
        .answer-options .dropdown-menu {
            left: 0;
            right: auto;
        }
    }

    @media only screen and (min-width: 900px) {
        position: relative;
        z-index: auto;
    }
}
