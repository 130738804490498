@import "../colorPalette.less";

.page-data-explorer {
    .nav.nav-tabs.nav-tabs-primary li a {
        font-size: 1.4em;
    }

    .nav.nav-tabs.nav-tabs-secondary {
        border-bottom: none;

        li {
            a {
                transition: background-color linear 0.2s;
            }

            &.active {
                a {
                    color: @color-black;

                    &:hover, &:focus {
                        background-color: @color-white;
                    }
                }
            }

            &:not(.active) {
                a {
                    &:hover, &:focus {
                        padding-bottom: 6px;
                        border-radius: 4px;
                        background-color: #f8f8f8;
                        box-shadow: inset 0 37px 20px rgba(0,0,0,0.016);
                    }
                }
            }
        }
    }

    .tab-content {
        background-color: #fff;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        > .tab-pane {
            .tab-content {
                background-color: #fff;
                border: 1px solid #ddd;
                padding: 16px;
                margin-bottom: 20px;
                border-radius: 4px;
                box-shadow: 0px 6px 6px rgba(0,0,0,0.16);
                border-top-left-radius: 0;

                h3 {
                    line-height: 32px;

                    .btn {
                        display: block;
                        text-align: left;
                        width: 100%;

                        .icon {
                            float: right;
                            width: 32px;
                            height: 32px;
                            line-height: inherit;
                            display: block;
                            text-align: center;
                            transition: transform linear 0.2s;
                        }

                        &:not(.collapsed) {
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .radio {
            .description {
                font-size: 0.9em;
                margin-left: 20px;
            }
        }
    }

    .list {
        &.list-formulate-logs {
            li {
                padding: 0;

                a {
                    display: block;
                    padding: 8px;
                    border-radius: 4px;

                    .date-time {
                        display: flex;
                        font-size: 1.1em;

                        .date,
                        .time {
                            width: 50%;
                        }

                        .time {
                            text-align: right;
                            color: #767676;
                        }
                    }

                    .service-user-identifier {
                        display: block;
                    }

                    &:hover,
                    &:focus {
                        background-color: #fafafa;
                        text-decoration: none;
                    }
                }

                &.active {
                    a {
                        background-color: @color-blue;
                        color: @color-white;

                        .date-time {
                            .time {
                                color: rgba(255, 255, 255, 0.85);
                            }
                        }

                        &:hover,
                        &:focus {
                            background-color: @color-blue;
                            color: @color-white;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &.list-qa-feedback-items {
            li {
                padding: 4px 8px;
                margin: 0 0 8px 0;
                border: 1px solid #ddd;
                border-radius: 4px;
            }
        }

        &.list-key-value-pair {
            li {
                display: flex;

                .key {
                    color: #767676;
                }

                .key,
                .value {
                    flex-basis: 50%;
                    flex-grow: 0;
                }
            }
        }

        &.list-flex {
            display: flex;
            justify-content: center;

            &:last-of-type {
                margin-bottom: 7.5px;
            }

            &.list-charts {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: stretch;
                overflow: hidden;
            }

            li {
                flex-grow: 1;
                flex-basis: 25%;
                text-align: center;
                margin: 7.5px;
                border-radius: 4px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.stat {
                    padding: 16px;
                    margin-bottom: 32px;
                    display: flex;
                    flex-direction: row;

                    .imo {
                        background-color: #d7dae6;
                        display: block;
                        padding: 16px;
                        border-radius: 50%;
                        color: @color-blue;
                        flex-grow: 0;
                        width: 90px;
                        height: 90px;
                        margin-right: 16px;
                    }

                    .details {
                        text-align: left;

                        &.no-data {
                            display: flex;
                            justify-content: center;
                            padding-left: 0px;
                        }

                        .number {
                            display: inline-block;
                            font-size: 3em;
                            font-family: "Sofia Pro", sans-serif;
                            font-weight: 600;
                            margin: 16px 0 16px 0;
                            color: @color-blue;
                            position: relative;
                        }

                        .description {
                            display: block;
                            margin: 0 auto;
                            max-width: 280px;
                            line-height: 1.3em;
                        }
                    }

                    &.error {
                        background-color: @color-red;
                        color: @color-white;
                    }
                }

                &.chart {
                    padding-bottom: 20px;
                    overflow: hidden;

                    h3 {
                        display: block;
                        /*background: rgb(236,236,236);
                        background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(250,250,250,1) 100%);*/
                        line-height: 48px;
                        //color: @color-white;
                    }

                    .chart {
                        padding: 10px;
                    }
                }
            }
        }
    }

    .tile {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        text-align: center;
        margin: 7.5px;
        border-radius: 4px;

        .tile-header {
            background-color: rgb(61, 77, 132);
            border-bottom: none;
            border-radius: 4px 4px 0px 0px;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            height: 50px;


            &.loading {
                background: linear-gradient(-45deg, #3d4d84, #8b94b5, #3d4d84, #8b94b5);
                background-size: 400% 400%;
                animation: gradient 10s ease infinite;
            }

            .title {
                flex-grow: 1;
                text-align: left;

                h3 {
                    color: @color-white;
                    display: inline;
                }

                .insight-help {
                    color: white;
                }
            }

            .controls {
                flex-grow: 0;
            }
        }

        .tile-body {
            height: 370px;

            &.table-view {
                height: 405px;
            }

            &.education-bands {
                height: 420px;
            }

            .average-key {
                display: inline-block;
                border: 1px solid #ddd;
                background-color: #fafafa;
                position: relative;
                width: 18px;
                height: 12px;
                top: 1px;
                border-radius: 2px;

                &:before, &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 4px;
                    left: 0px;
                    width: 8px;
                    border-top: 2px solid #111;
                }

                &:after{
                    left: 12px;
                    width: 4px;
                }
            }
        }

        .tile-footer {
            background-color: rgb(248, 248, 248);
            margin-left: 0px;
            margin-right: 0px;
            display: flex;
            flex-direction: row;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            justify-content: flex-end;

            .component {
                flex-grow: 0;

                &.legend {
                    width: ~"calc(100% - 240px)";
                }

                &.copy-to-clipboard {
                    flex-grow: 1;
                }
            }
        }

        &.tile-thin {
            height: 110px;
            align-items: center;
            display: flex;

            .statistic {
                padding: 10px;
            }
        }

        .statistic {
            padding: 16px;
            display: flex;
            flex-direction: row;

            .imo {
                background-color: #d7dae6;
                display: block;
                border-radius: 50%;
                color: @color-blue;
                flex-grow: 0;
                width: 90px;
                height: 90px;
                margin-right: 16px;
                line-height: 90px;
            }

            .details {
                text-align: left;

                &.no-data {
                    display: flex;
                    justify-content: center;
                    padding-left: 0px;
                }

                .number {
                    display: inline-block;
                    font-size: 3em;
                    font-family: "Sofia Pro", sans-serif;
                    font-weight: 600;
                    margin: 16px 0 16px 0;
                    color: @color-blue;
                    position: relative;
                }

                .description {
                    display: block;
                    margin: 0 auto;
                    max-width: 280px;
                    line-height: 1.3em;
                }
            }
        }

        .chart-container {
            display: flex;
            flex-direction: column;

            .controls {
                display: flex;
                flex-direction: row;
                background-color: #f8f8f8;
                padding: 8px 15px;

                .sort {
                    margin-right: auto;
                }

                .traverse {
                    button {
                        background-color: @color-black;
                        border-radius: 3px;
                        color: @color-white;
                        padding: 0px 4px;
                        border: none;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        transition: background-color linear 0.15s;

                        &:disabled {
                            background-color: @color-medium-grey;
                            border-color: @color-medium-grey;
                            box-shadow: none;
                            pointer-events: none;
                        }

                        &:first-of-type {
                            padding-left: 2px;
                        }

                        &:last-of-type {
                            padding-right: 2px;
                        }

                        &:hover, &:focus {
                            background-color: @color-blue;
                        }
                    }
                }

                .btn {
                    font-size: 13px;
                    line-height: normal;
                    height: 21px;
                    padding-bottom: 1px;
                }
            }

            .chart {
                overflow: hidden;

                h3 {
                    display: block;
                    /*background: rgb(236,236,236);
                    background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(250,250,250,1) 100%);*/
                    line-height: 48px;
                    //color: @color-white;
                }
            }
        }

        .chart-container-education-bands {
            display: flex;
            margin-top: 5px;

            .chart {
                width: 33%;

                .highcharts-title, .highcharts-axis-title {
                    font-weight: bold;
                }
            }
        }

        .chart-table-wrapper {
            height: 370px;

            .chart-table {
                max-height: 370px;
                overflow-y: auto;

                .chart-table-row {
                    display: flex;

                    &.alt div {
                        background-color: @color-light-grey;
                    }
                }

                .header {
                    background-color: @color-light-grey;
                    cursor: pointer;
                    font-weight: bold;
                    padding: 4px 8px;
                    text-align: left;
                }
            }

            .basic-chart-table {
                .name-column {
                    flex: 1 1 80%;
                    overflow: hidden;
                    padding: 4px 0px 4px 8px;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .value-column {
                    flex: 1 1 20%;
                    padding: 4px 8px 4px 0px;
                    text-align: right;
                }
            }

            .insight-form-calculations-chart-table {
                .chart-table-row {
                    > div {
                        padding: 4px 8px;
                        text-align: left;
                    }

                    .identifier-column {
                        flex: 1 1 25%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .opened-column {
                        flex: 1 1 15%;
                    }

                    .assessor-column {
                        flex: 1 1 20%;
                    }

                    .calculations-column {
                        flex: 1 1 10%;
                        text-align: right;
                    }

                    .status-column {
                        flex: 1 1 10%;
                    }

                    .estimated-column {
                        &:not(.header) {
                            display: flex;
                            flex-flow: row;
                        }

                        flex: 1 1 20%;
                        text-align: right;

                        .estimated-value {
                            flex: 1 1 90%;
                            padding-right: 5px;
                            text-align: right;
                        }

                        .estimated-icon {
                            color: @color-medium-grey;
                            flex: 1 1 10%;
                            padding-left: 0px;
                            padding-top: 4px;
                            text-align: left;
                        }
                    }
                }
            }

            .education-bands-chart-table {
                .chart-table-row {
                    > div {
                        padding: 4px 8px;
                        text-align: left;
                    }

                    .name-column {
                        flex: 1 1 25%;
                    }

                    .band-column {
                        flex: 1 1 7%;
                    }

                    .mean-column {
                        flex: 1 1 7%;
                    }
                }
            }
        }

        .timeframe-readonly {
            font-size: 0.9em;
            margin: 8px 8px 8px 0px;
            text-align: right;

            .timeframe {
                font-weight: bold;
            }
        }

        .copy-to-clipboard {
            text-align: left;

            .btn {
                margin: 0px 0px 0px 8px;
                transition: color linear 0.2s;
            }

            &.copying {
                .btn {
                    color: @color-alert-info;
                }
            }

            &.copied {
                .btn {
                    color: @color-success;

                    &:after {
                        content: '\e865';
                        display: inline-block;
                        color: inherit;
                        font-family: "ImosphereIcons";
                    }
                }
            }
        }

        .no-data-message {
            display: block;
            height: 398px;
            padding-top: 100px;

            .imo {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .loading-container {
            transform: translateY(-50%);
            position: relative;
            top: 50%;
        }
    }

    .text-facecode {
        color: #767676;
        font-size: 0.875em;
        display: block;
    }

    .legend-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .legend {
            font-size: 0.875em;
            height: 65px;
            margin-left: 5px;
            overflow-y: hidden;
            padding: 3px;
            flex-grow: 1;

            .legend-item {
                display: flex;
                align-items: center;
                margin-right: 10px;

                .legend-color {
                    border-color: transparent;
                    border-radius: 2px;
                    font-size: 0.8em;
                    height: 12px;
                    margin: 0px 3px 0px 0px;
                    width: 16px;
                }

                .legend-name {
                    flex: 2 1 90%;
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .legend-scroll {
            width: 30px;

            button {
                background-color: @color-black;
                border-radius: 2px;
                color: @color-white;
                padding: 0;
                border: none;
                width: 20px;
                height: 20px;
                line-height: 20px;
                transition: background-color linear 0.15s;
                text-align: center;

                &:hover, &:focus {
                    background-color: @color-blue;
                }

                &.up {
                    margin-top: 12px;
                }

                &.down {
                    margin-top: 3px;
                    padding-top: 1px;
                }

                &:disabled {
                    background-color: @color-medium-grey;
                    box-shadow: none;
                    pointer-events: none;
                }
            }
        }
    }

    .date-period-picker {
        border: 1px solid #ddd;
        background-color: #F8F8F8;
        border-radius: 4px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16), inset 0 27px 8px rgba(255,255,255,0.5);
        float: right;
        margin: 8px 8px 8px 17px;
        padding: 6px;
        display: flex;
        transition: all linear 0.2s;

        &:hover {
            background-color: #fff;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16), inset 0 27px 8px rgba(0, 0,0, 0.02);
        }

        .readonly-left {
            text-align: left;

            b {
                font-family: "Sofia Pro", sans-serif;
                font-weight: bold;
                display: block;
            }
        }

        .readonly-right {
            padding: 0 0 0 12px;

            .btn {
                font-size: 20px;
                line-height: 42px;
                height: 42px;
            }
        }

        .date-picker-container {
            float: left;
            padding: 0 8px;
            width: 280px;

            .from, .to {
                float: left;
                margin: 2px 2px 6px 2px;
            }

            .validation-message {
                float: left;
                margin-right: 5px;
            }

            label {
                text-align: left;
            }
        }

        .dropdown-menu {
            left: -73px;

            &.dropdown-menu-with-arrow {
                &:before, &:after {
                    border-bottom-width: 9px;
                    left: 258px;
                }
            }
        }
    }

    .insight-help {
        color: @color-blue;
        font-size: 1.3em;
        margin-left: 4px;

        button {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            width: 16px;
            height: 16px;
            text-align: center;
            border-radius: 50%;

            &:hover, &:focus {
                box-shadow: 0px 0px 9px rgba(0,0,0,0.5);
            }
        }

        .dropdown-menu {
            color: @color-black;
            width: 450px;
            left: -10px;

            .help-text {
                padding: 10px;

                h4 {
                    margin-bottom: 5px;
                    color: unset;
                }

                p{
                    font-weight: normal;
                }
            }

            &.dropdown-menu-right{
                left: auto;
                right: -10px;
            }
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
