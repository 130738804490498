﻿// Lists LESS
.list {
    list-style: none;
    margin: 0;
    padding: 0;

    > .list-item {
        padding: @unit 0;

        p {
            margin: 0;
        }
    }

    &.list-unstyled {
        .list-item {
            padding: 0;

            button:not(.btn-primary) {
                display: block;
                width: 100%;
                text-align: left;
                border: none;
                background: none;
                padding: 6px 12px;

                &:hover, &:focus {
                    background-color: #ececec;
                    color: #555555;
                    outline: none;
                }
            }

            > div{
                padding: 6px 12px;
            }
        }
    }

    .divider {
        background-color: @color-light-grey;
        height: 1px;
        margin: 10px -10px;
        overflow: hidden;
    }

    .selected-item {
        .border-radius(50%);
        background: @color-blue;
        float: left;
        margin: 4px 0 0 -18px;
        position: relative;
        width: 10px;
        height: 10px;
        // adds border between items
        &.list-border {
            > .list-item {
                border-bottom: solid 1px @color-light-grey;
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }
    // lists that have links
    &.list-link {
        > .list-item {
            padding: 0;

            > .link-item,
            .link-item {
                color: @color-medium-grey;
                cursor: pointer;
                display: block;
                margin-left: -20px;
                margin-right: -20px;
                overflow: hidden;
                padding: @unit @unit*4;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:focus {
                    background: @color-white;
                }

                &:hover {
                    background: lighten(@color-extra-light-grey, 3%);
                }
            }

            &.active > .link-item {
                background: @color-extra-light-grey;
                color: @color-body;
                cursor: auto;
            }
        }
    }
    // small lists
    &.list-sm {
        .text-sm;

        > .list-item {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        &.list-border > .list-item {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        &.list-link {
            > .list-item {
                padding: 0;

                > .link-item {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }

                & .current {
                    font-weight: 700;
                }
            }
        }
    }
    // large lists
    &.list-lg {
        > .list-item {
            padding-top: @unit*2;
            padding-bottom: @unit*2;
        }

        &.list-collapsable {
            > .list-item [class^="cp-icon-chevron"] {
                top: 12px;
            }
        }
    }
    // lists that can be collapsed/expanded
    &.list-collapsable {
        > .list-item {
            padding-right: @unit*5;
            position: relative;

            [class^="cp-icon-chevron"] {
                position: absolute;
                right: 0;
                top: @unit - 2px;
            }
        }
    }
    // lists which are key-value pairs
    &.list-key-value-pair {
        li {
            display: flex;

            .key {
                color: #767676;
            }

            .key,
            .value {
                flex-basis: 50%;
                flex-grow: 0;
            }
        }

        &.stacked {
            li {
                display: block;
                margin-bottom: 4px;

                .key,
                .value {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    // additional informaition about the list item
    .additional-information {
        .text-sm;
        margin: @unit*2 0;
    }
    // lists with border
    &.list-border {
        > .list-item {
            border-bottom: solid 1px @color-light-grey;
        }
    }

    &.involvement-numbers {
        > li {
            border-bottom: solid 1px @color-light-grey;
            padding: 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: none;
                padding-bottom: 0;
            }

            &.activities {
                .label.label-open {
                    background-color: @color-blue;
                }
            }

            &.care-plans {
                .label.label-current {
                    background-color: @color-blue;
                }
            }

            &.contacts {
                .label.label-current {
                    background-color: @color-blue;
                }
            }

            &.diagnoses {
                .label.label-current {
                    background-color: @color-blue;
                }
            }

            .no-data {
                padding-top: 2em;
                padding-bottom: 3em;
            }
        }

        .involvement-details {
            -webkit-align-items: center;
            align-items: center;
            display: -webkit-flex;
            display: flex;
            cursor: pointer;
            padding: 0.75em 1em 0.75em 0.25em;

            &:hover {
                background-color: @color-extra-light-grey;
            }
        }

        .badge {
            border-radius: 38px;
            display: inline-block;
            font-size: 1rem;
            min-height: 38px;
            margin-right: 8px;
            margin-left: 4px;
            padding: 11px;
            text-align: center;
            min-width: 38px;
        }

        .details {
            -webkit-align-self: auto;
            -webkit-flex: 1 1 auto;
            align-self: auto;
            flex: 1 1 auto;
            order: 0;
        }

        .text-emphasis {
            margin-right: 0.375em;
        }

        .cp-icon-chevron-up {
            transition: all 0.2s linear;
        }

        .collapsed {
            .cp-icon-chevron-up {
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
        }

        .table.table-condensed {
            margin: 0.25em auto 0.5em;
            width: 99%;
        }

        .pagination {
            margin-bottom: 0.75em;
        }
    }
    // Form Tracker
    &.form-tracker {
        margin: 1.5em 0 0;
        padding: 0;
    }
    // Answer History
    &.answer-history {
        padding: 0;

        .list-item {
            border: none;
            padding: 0 @unit*4 @unit*7;

            .note {
                margin: @unit*2 0;
            }
        }

        .date-qualified {
            border-bottom: solid 1px @color-light-grey;
            margin: 0 -@unit*4 @unit*2;
            padding: 0 0 3px;

            .more-link {
                cursor: pointer;

                &:before {
                    content: "less";
                }

                &.collapsed:before {
                    content: "more...";
                }
            }

            > .list-item {
                padding-top: @unit*3;
                padding-bottom: @unit*3;

                .date-qualified {
                    margin-bottom: @unit;
                }

                .cp-icon-chevron-up {
                    top: 25px;

                    &::before {
                        content: "hide";
                    }
                }
            }
        }

        .contact-record-title {
            margin-left: -20px;
            margin-bottom: 5px;
        }

        .form-info {
            .text-sm;
            margin: -5px 0 5px;
        }
    }
    // Group Cal
    &.groupcal {
        .list-item {
            padding: 0 0 0 10px;
            position: relative;

            &:last-of-type {
                padding-bottom: 0.5em;
            }
        }

        .shift-note-container {
            .text-sm;
            border-left: solid 1px @color-light-grey;
            padding-left: 1em;
            padding-right: 0.5em;
        }

        .shift-note-date {
            .text-muted;

            &:before {
                background: @color-blue;
                border-radius: 50%;
                content: "";
                display: block;
                height: 12px;
                width: 12px;
                position: absolute;
                left: 5px;
            }
        }

        .service-user {
            font-size: @font-size-h3;
            margin-left: -10px;
        }

        .shift-note {
            margin-top: 0.5em;
            margin-bottom: 2em;
        }
    }
    // Shift Notes
    &.shift-notes {
        .list-item {
            padding: 0 0 0 10px;
            position: relative;

            &:last-of-type {
                padding-bottom: 0.5em;
            }

            a {
                color: @color-blue;
            }
        }

        .shift-note-container {
            .text-sm;
            border-left: solid 1px @color-light-grey;
            padding-left: 1em;
            padding-right: 0.5em;
        }

        .shift-note-date {
            .text-muted;

            &:before {
                background: @color-blue;
                border-radius: 50%;
                content: "";
                display: block;
                height: 12px;
                width: 12px;
                position: absolute;
                left: 5px;
            }
        }

        .service-user {
            font-size: @font-size-h3;
            margin-left: -10px;
        }

        .shift-note {
            margin-top: 0.5em;
            margin-bottom: 2em;
        }
    }

    &.contact-record-sidebar {
        .sidebar-heading {
            background-color: @color-extra-light-grey;
            margin: 0 -20px;
            padding: 0.375em 20px 0.3125em;
        }

        .list-item {
            padding-top: 0.75em;
            padding-bottom: 0.75em;

            &:last-of-type {
                padding-bottom: 1.5em;
            }
        }

        .list-item-btn {
            padding-top: 0;
        }

        &:first-of-type {
            .sidebar-heading {
                margin-top: 20px;
            }
        }
    }
}

.sidebar-additional-data {
    margin-bottom: 10px;

    .sidebar-heading {
        background-color: @color-extra-light-grey;
        margin: 0 -15px;
        padding: 0.375em 10px 0.3125em;
        font-size: 1em;
    }

    .list-additional-data-cards {
        padding: 0 10px 10px;
        background-color: @color-extra-light-grey;
        margin: 0 -15px;
        max-height: 132px;
        overflow-y: auto;

        > li {
            padding: 10px;
            background-color: @color-white;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    padding: 0;
                }
            }
        }
    }
}
