﻿@import (reference) "../colorPalette.less";

.admin {
    #wrap {
        padding-bottom: 65px;
    }
}

.page-admin {
    .container {
        padding: 0;
    }

    .admin-permissions {
        label {
            display: block;
        }
    }

    .page-header {
        margin-bottom: 5px;

        &.personal-details-header {
            margin-top: 0;
        }
    }

    .page-toolbar {
        border-bottom: solid 1px @color-light-grey;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    .details {
        margin-bottom: 5px;
    }

    .tick-box-container {
        .tick-box-input-container {
            input ~ .checkmark:after {
                color: @color-blue;
            }

            input:checked ~ .checkmark {
                border-color: @color-blue;
            }

            input:focus ~ .checkmark {
                background-color: @color-data-entry-button-border;
                border-color: @color-blue;
            }
        }
    }

    .table {
        td a {
            font-weight: 600;
            font-family: "Sofia Pro";
            cursor: pointer;
        }
    }

    .table-stats {
        .table {
            border-radius: 4px;
            overflow: hidden;

            th,
            td {
                text-align: center;
                border: none;
                background-color: #f9f9f9;
            }

            td {
                font-weight: 600;
                font-size: @font-size-h1;
            }

            thead {
                .highlight-row {
                    th {
                        background-color: @color-light-grey;
                    }
                }
            }
        }
    }

    .table {
        &.table-striped {
            tr {
                th {
                    background-color: @color-medium-grey;
                    color: @color-white !important;
                    border: none;
                    transition: background-color 0.2s ease;

                    &.sortable {
                        padding: 0;

                        .cp-icon-caret-up,
                        .cp-icon-caret-down {
                            color: @color-white !important;
                        }

                        button{
                            border: none;
                            background: transparent;
                        }
                    }

                    &:hover {
                        background-color: lighten(@color-medium-grey, 5%);
                    }
                }

                td {
                    border: none;
                }
            }
        }

        .input-field input {
            line-height: normal !important;
        }

        .tick-box-container {
            line-height: normal !important;

            .tick-box-input-container {
                margin: 0;
            }
        }

        .table-header-button {
            padding: 0;
            height: 19px !important;

            .caret {
                margin-bottom: 6px;
            }

            button {
                background-color: lighten(
                    @color-data-entry-button-border,
                    3
                ) !important;
                height: 19px !important;
                line-height: 18px !important;

                &:hover {
                    background-color: @color-data-entry-button-border !important;
                }

                &:active {
                    background-color: darken(
                        @color-data-entry-button-border,
                        10
                    ) !important;
                }
            }
        }
    }

    .list-page-toolbar {
        padding: 15px 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        margin: 0 -15px;

        .dropdown-input .dropdown-content {
            position: relative;
            top: -2px;
        }

        .search-control input {
            margin-top: -2px;
            height: 34px;
        }
    }

    @media only screen and (min-width: 768px) {
        .table {
            table-layout: auto;
        }
    }
}

body.admin {
    // Select2 Overrides
    .select2-container {
        &.select2-container--default {
            .select2-selection--single {
                &[aria-expanded="true"] {
                    border-color: @color-blue;
                }

                &:hover:not([aria-disabled="true"]),
                &:focus:not([aria-disabled="true"]) {
                    border-color: @color-blue;
                }
            }

            .select2-dropdown.select2-dropdown--below {
                border-color: @color-blue;
            }

            .select2-selection--multiple {
                &[aria-expanded="true"] {
                    border-color: @color-blue;
                }

                &:hover:not([aria-disabled="true"]),
                &:focus:not([aria-disabled="true"]) {
                    border-color: @color-blue;
                }
            }
        }
    }
    // Form element hover and focus states
    .form-control {
        &:hover:not([aria-disabled="true"]),
        &:focus:not([aria-disabled="true"]) {
            border-color: @color-blue;
        }
    }
}

#securityTree {
    > ul {
        > li {
            > [class="cp-icon-admin"] {
                color: #3a3a3a;
            }

            &.node-selected {
                > [class="cp-icon-admin"] {
                    color: @color-white;
                }
            }
        }
    }
}

.merge-su {
    .panel-merge-su {
        padding-top: 10px;
        padding-bottom: 10px;

        .btn-link {
            margin-top: -36px;
        }
    }

    .merge-users-icon {
        height: 48px;
        margin-bottom: 15px;
    }

    .merge-su-single {
        display: block;
        height: 64px;
        margin: 0 auto 10px;

        &.sm {
            height: 48px;
        }
    }

    p.merge-desc {
        margin-bottom: 40px;
    }

    .select2-container--default {
        text-align: left !important;
    }

    .merge-conflict {
        ul {
            list-style: disc;
            margin-top: 5px;
            padding-left: 40px;
        }
    }

    .user-information {
        .border-radius-sm;
        border: solid 1px @color-light-grey;
        margin-top: 1px;
        padding: 10px;
        text-align: left;
    }

    .merged-user-details {
        padding: 25px;

        label {
            display: block;
            margin-bottom: 15px;
            text-align: center;
        }
    }
}

.panel-broadcast .has-error .form-group {
    margin-bottom: 0px;
}

.admin-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    .sidebar-menu,
    .flex-item {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
    }

    .flex-item {
        flex-grow: 1;
        padding: 0 15px;
        width: calc(~"89% - 20px");
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        min-height: calc(100vh - 125px);
        background-color: @color-white;

        > div:first-child:not(.vue-container) {
            padding: 15px;
        }
    }

    .sidebar-menu {
        background-color: @color-main-background;
        padding: 15px 0 0 20px;
        width: 220px;

        h1 {
            margin-bottom: 5px;
        }

        ul {
            .clearfix;
            margin: 0;

            li {
                display: block;
                float: none;
                width: auto;

                &.sidebar-category {
                    a {
                        color: @color-dark-grey;
                        min-width: 100%;
                        display: block;
                        text-decoration: none;
                    }

                    .sidebar-category-title {
                        margin-bottom: 5px;

                        .imo {
                            float: right;
                            padding: 4px 20px 0 0;
                        }
                    }

                    ul {
                        margin: 10px 0;
                    }
                }

                &.sidebar-menu-item {
                    margin-right: 20px;

                    a {
                        color: @color-dark-grey;
                        min-width: 100%;
                        display: block;
                        margin-bottom: 5px;
                        padding: 5px 20px;
                        border-radius: 20px;
                        text-decoration: none;
                    }

                    &.active,
                    &:hover {
                        a {
                            background-color: @color-white;
                            color: @color-black;
                        }
                    }
                }
            }
        }
    }

    .audit {

        .form-group {
            padding: 10px;
            background-color: #dddddd;
            border-radius: 5px;
            margin: 0px;

            .form-group {
                padding: 10px;
                background-color: #ECECEC;
                border-radius: 5px;

                .form-group {
                    background-color: @color-light-grey;
                }
            }
        }

        h1,
        h2,
        h3 {
            color: black;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .date-time-picker {
            display: flex;
            flex-direction: row;
            padding: 0;
            flex-flow: row wrap;
            flex-grow: 1;

            .date-picker {
                display: flex;
                flex-direction: row;

                input {
                    text-align: center;

                    &.datepicker-day, &.datepicker-month {
                        width: 7ch;
                    }

                    &.datepicker-year {
                        width: 8ch;
                    }
                }

                .separator {
                    position: relative;
                    top: 55%;
                }
            }

            .time-picker {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 120px;

                input {
                    text-align: center;
                    width: 7ch;
                }

                .separator {
                    position: relative;
                    top: 15%;
                }
            }
        }

        .from-to-filters {
            padding-right: 0;
            width: 650px;
        }

        .where-filters {

            > .form-group {
                display: flex;
            }

            @media only screen and (min-width: @screen-xl-min) {
                padding-left: 0;
                width: calc(100% - 650px);
            }

            @media only screen and (max-width: @screen-xl-min) {
                width: 100%;
            }

            @media only screen and (max-width: @screen-md-min) {
                .col-md-4 {
                    width: 33.33333333%;
                    float: left;
                }
            }
        }

        .input-field {
            min-height: 32px;

            label,
            input {
                font-family: "FsMeWeb" !important;
                font-size: 12px !important;

                strong {
                    font-family: "FsMeWeb" !important;
                    font-size: 12px !important;
                }
            }
        }

        .btn-clear {
            background-color: #ECECEC;
            border: unset;
        }

        .form-group {
            [class^="col-"] {
                padding-right: 5px;
                padding-left: 5px;
            }
        }

        #calculation-request-modal .modal-body {
            max-height: 75vh;
            padding: 15px;
        }

        #calculation-request-modal .modal-xl {
            width: 70vw;
        }

        #calculation-request-modal .table td {
            padding: 8px;
        }

        .calculation-request-modal {
            .calculation-request {
                padding: 15px;
                margin: -15px -15px 0 -15px;
                background-color: #fafafa;

                .text-underlined {
                    cursor: pointer;
                }
            }

            .budget-tile {
                margin: 0 15px;
                padding: 15px;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: 0px 0px 6px rgba(0,0,0,0.16);
                border-top-left-radius: 0;

                .budget {
                    font-size: 2rem;
                    color: rgb(112, 112, 112);
                }
            }

            .nav.nav-tabs.nav-tabs-secondary {
                border-bottom: none;

                li {
                    a {
                        font-size: 1.4em;
                        font-weight: normal;
                        transition: background-color linear 0.2s;
                    }

                    &.active {
                        a {
                            color: @color-black;

                            &:hover, &:focus {
                                background-color: @color-white;
                            }
                        }
                    }

                    &:not(.active) {
                        a {
                            color: @color-black;

                            &:hover, &:focus {
                                padding-bottom: 6px;
                                border-radius: 4px;
                                box-shadow: inset 0 37px 20px rgba(0,0,0,0.016);
                            }
                        }
                    }
                }
            }

            .tab-content {
                max-height: 40vh;
                padding: 15px;
                border: 1px solid #ddd;
                margin-bottom: 0;
                border-radius: 4px;
                box-shadow: 0px 0px 6px rgba(0,0,0,0.16);
                border-top-left-radius: 0;
                height: 100%;
                overflow-y: scroll;


                h3 {
                    line-height: 32px;

                    .btn {
                        display: block;
                        text-align: left;
                        width: 100%;

                        .icon {
                            float: right;
                            width: 32px;
                            height: 32px;
                            line-height: inherit;
                            display: block;
                            text-align: center;
                            transition: transform linear 0.2s;
                        }

                        &:not(.collapsed) {
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .table, .table-responsive {
                    margin-bottom: 0;
                }
            }

            .list-qa-feedback-items {
                li {
                    padding: 4px 8px;
                    margin: 0 0 8px 0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
            }
        }
    }
}

.category-list {
    border-bottom: solid 1px @color-light-grey;
    margin-bottom: 14px;
    padding-left: 0;
    padding-top: 11px;
    padding-bottom: 5px;

    > li {
        display: inline-block;
        margin-right: 15px;

        &.active a {
            color: @color-default;
        }
    }

    > li a {
        color: @color-body;
    }
}

.input-field {
    label,
    input {
        font-family: @base-font-family !important;
        font-size: @base-font-size !important;
        font-weight: @base-font-weight !important;
        line-height: @base-line-height !important;

        strong {
            font-family: @heading-font-family !important;
            font-size: 14px !important;
        }
    }
}

.strong-field-is-required {
    strong {
        &:after {
            content: "*";
            color: lighten(@color-red, 10%);
            padding-right: 4px;
        }
    }
}

.password-input-field {
    .text-input-field {
        width: calc(100% - 28px);
        float: left;

        .text-input {
            width: 252px !important;
        }
    }

    .icon-knowledge-base {
        cursor: pointer;
        float: right;
        margin-top: 5px;
    }
}

.input-field-inline {
    label strong {
        display: inline-block;
        width: 100%;
    }

    .text-input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-input,
    .dropdown-input {
        position: relative;
        margin-top: -21px;
    }

    .help-block {
        display: inline-block;
        margin-bottom: 0;
        padding-right: 8px;
        color: #6B6B6B;
    }

    .validation-block {
        margin-top: 0;
        width: 100%;
        text-align: left;
        line-height: 1.2em;
    }
}

.checkbox-field-text {
    padding-right: 32px;
}

.settings-list,
.account-modal-content {
    column-rule: 2px dashed @color-light-grey;

    .col-md-6:first-child:not(:only-child) {
        border-right: 2px dashed @color-light-grey;
    }

    .validation-block {
        margin-top: 2px;
        margin-bottom: 8px;
    }

    .help-block {
        margin: 0;
        line-height: 16px;
    }

    .input-field {
        margin-bottom: 12px;
    }

    .dropdown-input-field {
        margin-bottom: 0px;
    }

    .dropdown-input-field:not(.input-field-inline) {
        .dropdown-input {
            margin-top: 2px;

            input {
                padding-left: 30px;
            }
        }
    }
}

.settings-page-content {
    padding: 0 15px;

    .settings-list {
        columns: 2;
        column-gap: 40px;
        max-width: 1100px;

        &.single-column {
            columns: 1;
            max-width: 550px;
        }

        .input-field-inline {
            .text-input,
            .dropdown-input {
                width: 120px;
            }

            .help-block {
                width: calc(100% - 120px);
            }
        }

        #DateFormat {
            .text-input,
            .dropdown-input {
                width: 200px;
            }

            .help-block {
                width: calc(100% - 200px);
            }
        }
    }
}

.account-modal-content {
    a {
        cursor: pointer;
    }

    .remove-line {
        color: @color-black;
        font-size: 18px;
        text-decoration: none !important;
        right: 32px;
        position: absolute;
        margin-top: -36px;
        z-index: 100;

        &:hover {
            text-shadow: @color-black 0px 0px 1px;
        }
    }

    .table-striped tr td {
        .cp-icon-cross {
            margin-right: 0;
        }
    }

    .input-field-inline {
        .text-input,
        .dropdown-input {
            width: 280px;
        }

        .help-block {
            width: calc(100% - 280px);
        }
    }

    .shorter-inline {
        .text-input,
        .dropdown-input {
            width: 260px;
        }

        .help-block {
            width: calc(100% - 260px);
        }
    }

    @media only screen and (min-width: @screen-xl-min) {
        .input-field-inline {
            .text-input,
            .dropdown-input {
                width: 380px;
            }

            .help-block {
                width: calc(100% - 380px);
            }
        }

        .shorter-inline {
            .text-input,
            .dropdown-input {
                width: 360px;
            }

            .help-block {
                width: calc(100% - 360px);
            }
        }

        .password-input-field .input-field-inline .text-input {
            width: 352px !important;
        }
    }
}

.table {
    .input-field input {
        line-height: normal !important;
    }

    .tick-box-container {
        line-height: normal !important;

        .tick-box-input-container {
            margin: 0;
        }
    }

    .table-header-button {
        padding: 0;
        height: 19px !important;

        .caret {
            margin-bottom: 6px;
        }

        button {
            background-color: lighten(
                @color-data-entry-button-border,
                3
            ) !important;
            height: 19px !important;
            line-height: 18px !important;

            &:hover {
                background-color: @color-data-entry-button-border !important;
            }

            &:active {
                background-color: darken(
                    @color-data-entry-button-border,
                    10
                ) !important;
            }
        }
    }
}

.config-realignment {
    .processing {
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 55vw;
        position: relative;
        clear: both;

        .sk-spinner {
            width: 70px;
            height: 70px;
        }

        .alert {
            .details {
                max-width: 100%;
            }
        }
    }

    .results {
        height: 80vh;
        overflow-y: scroll;
    }

    &.complete {
        height: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: row;

        .icon {
            font-size: 60px;
            width: 100px;
            height: 100px;
            text-align: center;
            line-height: 100px;
            border-radius: 50%;
            background: @color-green;
            color: @color-white;
            margin-right: 18px;
        }

        .content {
            flex-grow: 1;
        }
    }
}
