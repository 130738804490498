﻿.short-text-group {
    width: 100%;

    .form-control:only-child {
        border-radius: 4px;
    }

    &.shortDataInputNumeric {
        width: 50% !important;
    }
}

.btn-group {
    height: 34px;
    background-color: @color-white;
    padding: 2px;
    border-radius: 17px;

    &.no-background {
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        height: auto;
    }

    &.no-padding {
        padding: 0;

        .btn {
            height: 34px;
            line-height: 34px;
            border-radius: 17px;

            &.btn-default {
                &.btn-icon {
                    border-width: 2px;
                    border-style: solid;
                    line-height: 30px;

                    &:hover {
                        background-color: @color-white;
                    }
                }
            }
        }
    }

    .btn {
        height: 30px;
        border: none;
        line-height: 30px;
        border-radius: 15px;

        &.btn-default {
            &:hover {
                background-color: @color-data-entry-button-border;
            }

            &.active,
            &:active,
            &:focus {
                box-shadow: none;
                background-color: @color-data-entry-button-border;
                color: @color-body;
            }

            &:first-child:last-child {
                &:hover,
                &:focus {
                    background-color: @color-white;
                }
            }
        }

        &:not(:first-child):not(:last-child):not(.dropdown-toggle),
        &:last-child:not(:first-child),
        &:first-child:not(:last-child):not(.dropdown-toggle) {
            border-radius: inherit;
        }

        & + .btn {
            margin-left: 2px;
        }

        &.as-h1 {
            height: auto;
            background-color: transparent;
            line-height: initial;
            padding: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }

    &.alternate {
        background-color: @color-data-entry-button-border;

        .btn {
            &.btn-default {
                background-color: @color-data-entry-button-border;

                &:hover,
                &:focus {
                    background-color: rgba(0, 0, 0, 0.05);
                    color: @color-body;
                }

                &:active,
                &.active {
                    background-color: @color-white;
                    color: @color-body;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        color: @color-body;
                    }

                    &:focus {
                        background-color: @color-white;
                        color: @color-body;
                    }
                }
            }
        }
    }

    &.linked {
        .btn {
            &:first-child:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &.primary {
    }
}
