﻿.scrollable-container {
    overflow: auto;

    &.scroll-260 {
        height: 260px;
        margin-bottom: 2px;
    }
}
.table-head-container {
    border-bottom: 2px solid #ddd;
    padding-right: 16px;
}

.highcharts-container {
    width: 100% !important;
}

.highcharts-loading {
    width: 100% !important;
}
