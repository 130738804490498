﻿// Page Header
.page-header {
    border-bottom: none;
    margin: 0;
    padding: 0 0 @unit*2;

    &.page-header-border {
        border-bottom: solid 1px @color-light-grey;
        margin-bottom: 15px;
    }

    h1,
    h2 {
        margin: @unit 0 0;
    }

    h2 {
        line-height: 34px;

        @media only screen and (max-width: 900px) {
            font-size: 1.55em;
        }
    }

    .title-controls {
        margin: 7px @unit*2 0;
    }

    .activity-info {
        margin-right: -4px;
        width: 100%;

        @media only screen and (min-width: @screen-md-min) {
            margin-right: 0;
            width: auto;
        }
    }

    .notification-pin {
        margin-top: 12px;
        margin-left: 8px;
    }

    &.admin {
        padding-top: 5px;

        h1 {
            margin: 0;
        }
    }

    &.personal-details-history-header {
        margin-top: -50px;
    }
}
