﻿@import (reference) "../colorPalette.less";

.dropdown-menu {
    z-index: 100000;

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .fixed-height-container {
        > ul {
            list-style: none;
            margin: 0;
            padding: 0;

            > li > a {
                cursor: pointer;
                display: block;
                padding: 6px 20px;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333333;
                white-space: nowrap;

                &.active {
                    color: @color-white;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    > li > a {
        cursor: pointer;
    }

    li.disabled > a:hover {
        background: inherit;
        color: @color-medium-grey;
    }

    .dropdown-list-item {
        border-bottom: solid 1px @color-light-grey;
        clear: both;
        color: @color-body;
        cursor: pointer;
        display: block;
        padding: 12px 20px;
        white-space: normal;

        &:hover {
            background-color: @color-extra-light-grey;
        }

        &:last-child,
        &:only-child {
            border-bottom: none;
        }

        &.disabled {
            color: @color-medium-grey;

            &:hover {
                background: transparent;
                cursor: auto;
            }
        }
    }

    .divider {
        margin: 0.35em 0;
    }

    > li > a.color-tint-danger,
    > li > a.color-tint-danger:focus,
    > li > a.color-tint-danger:hover {
        .color-tint-danger;
        outline: none;
    }
    // Forces long contact names to truncate (viewContacts)
    .edit-contact-name {
        max-width: 350px;
    }

    &.dropdown-menu-with-arrow {
        margin-top: 10px;

        &:before {
            border-bottom: 8px solid rgba(0, 0, 0, 0.15);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            content: "";
            display: block;
            left: 8px;
            height: 0;
            position: absolute;
            top: -9px;
            width: 0;
        }

        &:after {
            border-bottom: 8px solid @color-white;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            content: "";
            display: block;
            height: 0;
            left: 8px;
            top: -8px;
            position: absolute;
            width: 0;
        }

        &.scroll {
            max-height: 490px;
            overflow-y: scroll;
        }
    }

    &.dropdown-menu-right {
        &.dropdown-menu-with-arrow {
            &:before,
            &:after {
                left: auto;
                right: 8px;
            }
        }
    }
}

.dropdown .caret {
    margin-left: 2px;
}

.dropdown-input-field {
    &.has-success {
        .dropdown-button,
        input.form-control {
            background-color: @alert-green;
            border: 2px solid @alert-green;
        }
    }

    .option-id {
        border: solid 1px @color-black;
        border-radius: 4px;
        margin-right: 5px;
        padding: 2px 1px 1px 2px;
        font-size: 0.875em;
    }
}

.dropdown-input {
    width: 100%;

    .form-control {
        text-align: left;
        position: relative;

        .cp-icon-check {
            position: absolute;
            right: -2px;
            top: -1px;
            margin: 8px;
        }

        .label {
            display: inline-block;
            cursor: default;
            margin-right: 7px;
            position: relative;
            padding: 4px 6px;
            cursor: pointer;
            border: none;

            .remove-option {
                margin-left: 2px;
            }

            &:hover, &:focus {
                background-color: darken(#3d4d84, 10%);
            }
        }
    }

    .dropdown-content {
        z-index: 99999;
        background-color: @color-extra-light-grey;
        min-width: 100px;
        border: 2px solid @color-blue;
        margin-top: -2px;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
        position: fixed;

        .no-data-message {
            padding: 6px 12px;
            color: #666666;
        }

        ul {
            max-height: 200px;
            overflow-y: auto;
            margin: 0;

            li {
                cursor: pointer;
                font-size: 1rem;
                padding: 6px 12px;
                color: #666666;
                transition: background-color linear 0.1s;

                &:hover,
                &.focus {
                    background-color: @color-data-entry-button-border;
                    color: #555555;
                }

                &.active {
                    background-color: lighten( @color-data-entry-button-border, 1 );
                    color: #555555;

                    &:hover,
                    &.focus {
                        background-color: darken( @color-data-entry-button-border, 5 );
                        color: @color-medium-dark-grey;
                    }
                }

                &.addOption {
                    background-color: @color-data-entry-button-border;
                    border-top: 1px solid @color-medium-dark-grey;
                }
            }
        }
    }

    .dropdown-button {
        height: auto;
        min-height: 32px;
        padding-top: 4px;

        .selected-item {
            white-space: nowrap;
            overflow-x: hidden;
            width: calc(100% - 25px);
            display: inline-block;
        }

        .multiselect-labels {
            white-space: nowrap;
            overflow-x: hidden;
            width: calc(100% - 25px);
        }

        &:not(.multi-select) {
            height: 32px;
            line-height: 21px;
        }
    }

    .chevron {
        margin-top: 3px;
    }

    .clear-all {
        right: 40px;
        position: absolute;
        font-size: 18px;
        line-height: 22px;
        margin-top: -1px;

        &:hover {
            text-shadow: @color-black 0px 0px 1px;
        }
    }

    .selected-options {
        width: calc(100% - 24px);
    }

    .search-input-wrapper,
    .filter-input-wrapper {
        position: relative;

        .form-control {
            position: initial;
        }

        label[class^="cp-icon-"], label[class*=" cp-icon-"] {
            font-family: "care-partner-font" !important;
            font-size: 16px;
        }

        .cp-icon-search,
        .icon-filter,
        .cp-icon-check {
            position: absolute;
            margin: 8px;
            cursor: text;

            &:focus,
            &:hover {
                color: @color-medium-grey;
            }
        }

        .cp-icon-check {
            top: 1px;
            right: 0;
        }

        input {
            padding-left: 2em;
        }
    }

    .filter-input-wrapper {
        .cp-icon-search {
            margin-left: 12px;
        }

        .icon-filter {
            margin-left: 15px;
            margin-top: 10px;
        }

        input {
            background-color: @color-extra-light-grey;
            margin: 6px;
            width: calc(100% - 12px);
            padding-left: 2.3em;
        }
    }

    .dropdown-content {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }

    &.expanded {
        .form-control {
            border-color: @color-blue;
        }

        .search-input-wrapper input,
        .dropdown-button {
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .dropdown-menu {
            > li > a:focus,
            > li > a:hover {
                background-color: lighten(@color-extra-light-grey, 2%);
                color: @theme-color;

                b,
                strong {
                    color: @theme-color;
                }
            }

            > li.selected > a,
            > li > a.selected,
            > li.active > a,
            > li > a.active {
                background-color: @theme-color;
                color: @color-white;
            }

            .fixed-height-container {
                > ul {
                    > li > a {
                        &.active {
                            background-color: @theme-color;
                        }

                        &:hover,
                        &:focus {
                            background-color: lighten( @color-extra-light-grey, 2% );
                            color: @theme-color;
                        }
                    }
                }
            }
        }
    }
}
