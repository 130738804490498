﻿// Description List LESS
dl {
    margin: 0;

    dt,
    dd {
        line-height: inherit;
        margin: 0;
    }

    // Horizontal
    &.horizontal {
        display: inline-block;
        padding-right: 15px;

        &:last-child {
            padding-right: 0;
        }
    }

    dt.horizontal,
    dt.horizontal + dd {
        display: inline-block;
    }

    // Vertical
    &.vertical {
        margin: 0 0 10px;

        &.vertical-sm {
            margin: 0 0 0.375em;

            &.small {
                margin: 0 0 0.25em;
            }
        }

        &.vertical-lg {
            margin: 0 0 15px;
        }

        &:last-child {
            margin: 0;
        }

        .horizontal {
            display: inherit;
            float: left;
            margin-right: 4px;
        }

        dt.horizontal + dd {
            display: inherit;
        }
    }

    dt.horizontal + dd {
        display: inherit;
    }
}

// Small
&.sm {
    dt,
    dd {
        .text-sm;
    }
}

dl {
    dt,
    dd {
        font-weight: normal;
    }
}
