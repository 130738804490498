﻿.badge {
    font-weight: 400;
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .badge {
            background-color: @theme-color;
            font-weight: 400;
        }
    }

    .badge.badge-@{name} {
        background-color: @theme-color!important;
    }
}
