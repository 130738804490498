﻿@import (reference) "../colorPalette.less";

.summary-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.summary-panel {
    &:extend(.panel.panel-border-top all);
    border-top: none;

    .summary-panel-content {
        overflow-y: auto;
    }

    p {
        margin-bottom: 0;
    }

    &.staff-dashboard {
        .panel-heading {
            padding: 0 20px;

            .panel-title {
                margin-top: 8px;

                .badge {
                    vertical-align: baseline;
                    padding: 2px 6px 4px;
                    height: 18px;
                    border-radius: 9px;
                    background-color: @color-blue;
                }
            }
        }

        .header-right {
            .btn {
                margin-top: 8px;
                line-height: 34px;

                &[data-toggle="dropdown"] {
                    line-height: 1em;
                    margin-top: 4px;
                    font-size: 1em;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .panel-heading,
        .panel-body {
            padding: 0 15px;

            .header-right {
                right: 15px;
            }
        }

        .panel-body {
            padding: 0 15px 15px;
        }
    }
}

.opd-summary {
    .opd-content {
        height: 315px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.ras-panel {
    min-height: 290px;

    h3 {
        line-height: 1em;

        .label {
            font-family: @base-font-family;
            vertical-align: bottom;
        }
    }
}

.ras-summary-panel {
    .open-activity-link {
        color: #3a3a3a;
    }
}

.ras-summary-spacing {
    margin-bottom: 20px;

    &:first-of-type {
        margin-top: 10px;
    }
}

.open-activity-link {
    margin: 0 5px 10px 0;
}

.single-ras-panel {
    .ras-panel {
        min-height: 150px;

        .ras-summary-spacing {
            margin-top: 10px;
            float: left;
            width: 50%;
        }
    }
}

.diagnosis-summary-panel,
.alerts-summary-panel,
.personal-summary-panel {
    width: 34%;
}

.contact-summary-panel,
.involvement-summary-panel,
.care-plan-summary-panel {
    width: 50%;
}

.contacts-summary-align {
    display: flex;
    display: -webkit-flex;
    background-color: @color-data-entry-button-border;
    padding: 8px;
    flex-direction: row;
    flex-grow: 1;
    flex-flow: wrap;
    justify-content: center;
}

&.padding-bottom-15:not(:last-child) {
    padding-bottom: 15px;
}

.summary-button-create {
    display: inline-block;
}

table .highcharts-button {
    display: none;
}

.no-export .highcharts-button {
    display: none;
}

.tile_count {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.tile_count .tile_stats_count {
    border-bottom: 1px solid #d9dee4;
    padding: 0 10px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    &:hover {
        .count {
            color: @color-link;
            text-decoration: underline;
        }
    }
}

@media (min-width: 992px) {
    .tile_count .tile_stats_count {
        margin-bottom: 10px;
        border-bottom: 0;
        padding-bottom: 10px;
    }
}

.tile_count .tile_stats_count:before {
    content: "";
    position: absolute;
    left: 0;
    height: 65px;
    border-left: 2px solid #adb2b5;
    margin-top: 10px;
}

@media (min-width: 992px) {
    .tile_count .tile_stats_count:first-child:before {
        border-left: 0;
    }
}

.tile_count .tile_stats_count .count {
    font-size: 30px;
    line-height: 47px;
    font-weight: 600;
    margin-top: 4px;
}

@media (min-width: 768px) {
    .tile_count .tile_stats_count .count {
        font-size: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1100px) {
    .tile_count .tile_stats_count .count {
        font-size: 30px;
    }
}

.tile_count .tile_stats_count span {
    font-size: 12px;
}

@media (min-width: 768px) {
    .tile_count .tile_stats_count span {
        font-size: 13px;
    }
}

.tile_count .tile_stats_count .count_bottom i {
    width: 12px;
}

.green {
    color: #1abb9c;
}

.red {
    color: red;
}

.Rag_Red {
    color: #e02130;
}

.Rag_Amber {
    color: #fab243;
}

.Rag_Green {
    color: #429867;
}

.Rag_Grey {
    color: #3a3a3a;
}

.summaryHolder {
    padding-top: 10px;
    padding-bottom: 10px;
}

.summaryPadding {
    float: left;
    margin-left: 15px;
    padding-top: 5px;
}

.summaryClearfix {
    clear: both;
}

.buttons-container {
    .btn {
        &.btn-text {
            background-color: @color-data-entry-button-background;
            border-radius: 16px;
            color: @color-dark-grey;
            font-size: 12px;
            float: left;
            height: 32px;
            line-height: 32px;
            margin: 0 3px;
            padding: 0 9px;
            transition: background-color linear 0.1s;

            &:not([disabled]) {
                &:hover,
                &:focus {
                    background-color: @color-data-entry-button-border;
                }
            }

            &.active {
                background-color: @color-blue;
                box-shadow: none;
                color: @color-white;

                &:hover {
                    background-color: darken(@color-blue, 10%);
                }

                &:focus {
                    background-color: @color-blue;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 6px;
        }
    }

    &.icons-only {
        .btn {
            &.btn-text {
                font-size: 1.2em;
                padding: 0;
                text-align: center;
                width: 32px;
            }
        }
    }
}

.pathway {
    height: 110px;

    .pathway-header {
        .pathway-title {
            h3 {
                line-height: 1em;
            }

            .label {
                font-family: @base-font-family;
                vertical-align: bottom;

                &.label-open {
                    background-color: @color-blue;
                }

                &.label-closed {
                    background-color: #505151;
                }

                &.label-abandoned {
                    background-color: #eb1515;
                }

                &.label-paused {
                    background-color: @color-medium-grey;
                }
            }
        }
    }

    .pathway-tracker {
        display: flex;
        flex-flow: row;
        list-style: none;
        padding: 0 40px;
        margin: 20px 0;

        li {
            flex-grow: 1;
            position: relative;

            .marker {
                width: 24px;
                height: 24px;
                background-color: @color-light-grey;
                border-radius: 50%;
                display: block;

                &:before {
                    font-family: "care-partner-font";
                    width: 24px;
                    height: 24px;
                    line-height: 25px;
                    color: @color-white;
                    text-align: center;
                    display: block;
                }
            }

            .step-name {
                position: absolute;
                font-size: 0.875em;
                left: -48%;
                text-align: center;
                width: 100%;
                padding: 4px;
            }

            &:before,
            &:after {
                width: ~"calc(100% - 28px)";
                height: 6px;
                display: block;
                background-color: @color-light-grey;
                content: "";
                border-radius: 3px;
                top: 9px;
                position: absolute;
                left: 26px;
            }

            &.current {
                .marker,
                &:after {
                    background-color: @color-blue;
                }

                &:after {
                    width: ~"calc(50% - 24px)";
                }

                .marker {
                    &:before {
                        content: "\e94d";
                    }
                }
            }

            &.complete {
                .marker,
                &:after {
                    background-color: @color-green;
                }

                .marker {
                    &:before {
                        content: "\e900";
                    }
                }
            }

            &.paused {
                .marker,
                &:after {
                    background-color: @color-status-paused;
                }

                .marker {
                    &:before {
                        content: "\e945";
                    }
                }
            }

            &.abandoned {
                .marker,
                &:after {
                    background-color: @color-status-abandoned;
                }

                .marker {
                    &:before {
                        content: "\e945";
                    }
                }
            }

            &:first-child {
                .step-name {
                    left: 0;
                    text-align: left;
                }
            }

            &:last-child:not(:first-child) {
                flex-grow: 0;

                .step-name {
                    left: -376px;
                    text-align: right;
                    width: 400px;
                }

                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }
}

.additional-pathways {
    background-color: @color-extra-light-grey;
    padding: 0 15px;
    margin: 0 -15px;

    .pathways {
        padding: 0 0 15px 0;

        .pathway {
            padding: 5px 10px 10px;
            margin-top: 15px;
            background: @color-white;
        }
    }

    .btn-additional-pathways {
        transition: color linear 0.1s;

        &:hover,
        &:focus {
            background-color: transparent;
            color: @color-body;
        }

        &.collapsed {
            .imo {
                transform: rotate(-180deg);
            }
        }
    }
}
// Fluid Flex Grid
.container-summary {
    padding-left: 15px;
    padding-right: 15px;
}

.fluid-grid {
    .item {
        flex-grow: 1;
        min-width: ~"calc(33% - 20px)";
        margin: 10px;
        flex-basis: calc(33% - 20px);

        &.shadow {
            background-color: @color-white;
            box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
        }

        &.no-grow {
            flex-grow: 0;
        }

        &.fixed-185 {
            min-width: 185px;
        }

        .panel {
            margin-bottom: 0;
            background-color: transparent;
        }
    }

    .flex {
        display: flex;

        &.item-container-row {
            flex-direction: row;
            width: 100%;

            &.wrap {
                flex-wrap: wrap;

                .last-item {
                    flex-grow: 0;
                    flex-basis: calc(33% - 15px);
                }
            }
        }
    }

    .summary-ras-budget {
        font-weight: bold;
        font-size: 40px;
        line-height: 1em;
    }

    .item-container {
        float: left;
        width: 100%;

        &.not-flex {
            width: 33.5%;

            & + .item-container {
                width: 66.5%;

                .flex {
                    display: flex;

                    &.item-container-row {
                        flex-direction: row;
                        width: 100%;
                    }

                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-left: 0px;

                    .item {
                        flex-grow: 0;
                        flex-basis: ~"calc(50% - 20px)";
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .fluid-grid {
        .item {
            &.fixed-145-1024 {
                min-width: 145px;
            }
        }
    }
}
