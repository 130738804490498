﻿.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    > div {
        height: fit-content;
    }

    > input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:focus {
            + label {
                &:after {
                    display: block;
                    content: '';
                    height: 4px;
                    border-radius: 2px;
                    background: #e4b892;
                    position: relative;
                    bottom: -10px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }

    > label {
        position: relative;
        width: 1.1em;
        font-size: 70px;
        height: 80px;
        font-weight: lighter;
        color: #e4b892;
        cursor: pointer;
        text-align: center;
        line-height: 70px;
        margin-bottom: 20px;

        &:before {
            content: "\2605";
            position: absolute;
            opacity: 0;
        }

        &:hover:before,
        &:hover ~ label:before {
            opacity: 1 !important;
        }
    }



    > input:checked ~ label:before {
        opacity: 1;
    }

    &:hover > input:checked ~ label:before {
        opacity: 0.4;
    }

    .feedback-text > textarea {
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
        height: 104px;
    }
}
