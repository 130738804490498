﻿.banner {
    display: flex;
    flex-direction: column;
    text-align: center;

    .name {
        overflow: hidden;
        flex-grow: 1;
    }

    h1 {
        color: @color-body;
        display: inline-block;
        overflow: hidden;
        margin: auto;
        max-width: calc(100% - 65px);
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 1024px) {
            font-size: 1.8em;
        }
    }

    .cp-icon-star-su {
        font-size: 22px;
        position: relative;
        top: 10px;
        vertical-align: top;

        &.active {
            color: #ffb035;
        }
    }

    .cp-icon-cake {
        color: lighten(@color-blue, 4%);
        font-size: 26px;
        position: relative;
        top: -16px;
    }

    dl.horizontal {
        line-height: 1.75rem;
    }

    .preferred-name {
        margin-top: -8px;
    }

    @media only screen and (min-width: @screen-md-min) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        min-height: 58px; // if changed change page layout class + remember padding-top
        padding-top: 12px;
        text-align: left;

        .avatar {
            float: left;
            margin-right: 0.25em;
        }

        .preferred-name {
            margin-left: 40px;
        }

        .identifier {
            font-size: 0.9333rem;
            margin-top: 12px;
            padding-left: 2.5em;
            text-align: right;
            white-space: nowrap;
        }
    }
}

.banner-container {
    border-bottom: solid 1px transparent;
    padding-top: 30px;
}

.banner-container.deceased {
    background-color: rgba(0, 0, 0, 0.9) !important;

    .banner {
        color: @color-white;

        h1,
        .text-emphasis {
            color: @color-white;
        }

        .cp-icon-star-su {
            font-size: 24px;
        }

        .text-muted {
            color: fade(@color-white, 80%) !important;
        }

        .cp-icon-user {
            color: @color-medium-grey;
        }
    }

    &.fixed-banner {
        background-color: rgba(0, 0, 0, 0.9) !important;
        border-color: rgba(0, 0, 0, 0.9);

        .identifier-deceased {
            display: none;
        }
    }
}

.page-container {
    transition: padding-bottom 0.4s ease;
    display: block;
    /*padding-bottom:80px;*/

    &.pathways-dropdown-open {
        padding-bottom: 320px;
    }
}

.nav-banner {
    background-color: @color-white;
    border-bottom: solid 1px @color-light-grey;
    margin-bottom: 15px;

    @media only screen and (min-width: @screen-sm-min) {
        border-bottom: none;
        margin-bottom: 0;
    }
}
.banner-container-addon {
    display: none;
    background-color: rgba(247, 247, 247, 0.54);
    padding: 0 15px;
    -webkit-box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);
    -moz-box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);
    box-shadow: 0px 10px 12px -5px rgba(212, 212, 212, 0.43);

    .toggle-answer-container {
        top: -3px;
        display: inline-block;
        float: right;
        position: relative;

        .toggle-answers {
            height: 28px;

            .dropdown-toggle {
                height: 24px;
                .current-toggle-view {
                    top: -3px;
                    position: relative;
                }
            }
        }
    }
}

// 992 and above - point banner is fixed
@media only screen and (min-width: @screen-md-min) {
    .form-banner-container.fixed-banner {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;
        .blurred-background(90%);

        .banner-container-addon {
            display: block;
        }
    }
    .banner-container.fixed-banner {
        .blurred-background(90%);
        border-color: @color-light-grey;
        padding-top: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;

        .preferred-name {
            display: none;
        }
    }

    .banner-container.deceased {
        .identifier-deceased {
            float: left;
            margin-top: -0.375em;
        }
    }

    .nav-banner {
        &.fixed-banner-container {
            padding-top: 88px;
        }
    }

    .cp-icon-star-su {
        font-size: 22px;
        transition: none;
    }

    .patient-identifier {
        margin-top: 10px;
    }
}

// 1200 and above
@media only screen and (min-width: @screen-lg-min) {
    .banner {
        .identifier {
            font-size: 1rem;
        }
    }
}

// Print
@media print {
    .banner {
        border-bottom: solid 1px @color-black !important;
        padding-bottom: 10px !important;
        margin-bottom: 10px !important;

        .patient-identifier dl.horizontal {
            padding-right: 10px !important;
        }
    }
}
