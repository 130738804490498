﻿// Login LESS
.login-wrapper {
    background-image: url(Content/Images/generic-background-transparent.png);
    background-color: @color-blue;
    background-size: cover;
    min-height: 100%;
}
.modal-content {
    .page-login {
        .col-md-4 {
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.cp-icon-user {
    margin-top: 2px;
}

.login-btn-input {
    height: 45px !important;
    margin-top: 0;
    margin-bottom: 15px !important;

    .login-button {
        margin-top: 0;
    }
}

.page-login {
    &.login-wrapper {
        padding-top: 110px;
    }

    .jumbotron {
        width: 550px;
        height: 550px;
        background-color: @color-white;
        font-family: "Sofia Pro";
        margin-left: auto;
        margin-right: auto;
        font-weight: 700;
        padding-bottom: 85px;
        padding-top: 15px;

        h2 {
            color: @color-black;
            font-weight: 600;
            font-size: 1.429em;
            font-family: "Sofia Pro";
        }

        &.circle-panel {
            border-radius: 100%;
            padding-top: 71px;
            padding-left: 60px;
            padding-right: 60px;

            .panel-body {
                padding-left: 25px;
                padding-right: 25px;
            }

            p {
                font-size: 1em;
                line-height: 1.3em;
                margin-top: 5px;
            }

            .name {
                color: @color-medium-grey;
            }

            .error-container {
                height: 60px;
                padding-bottom: 5px;

                .alert-danger {
                    color: #921212;
                    background-color: #f2dede;
                    border-color: #ebccd1;
                    text-align: center;
                    font: 400 0.9em "FSMeWeb", Helvetica, sans-serif;
                    border-radius: 4px;
                }
            }

            .input-group {
                margin: 15px auto 25px auto;
                position: relative;

                label {
                    position: absolute;
                    left: 0;
                    top: -20px;
                    transition: all linear 0.2s;
                    opacity: 0;
                    color: @color-medium-grey;
                    font-size: 0.9em;

                    &.visible {
                        opacity: 1;
                    }
                }

                .login-text {
                    border: none;
                    border-bottom: 3px solid @color-blue;
                    background-color: @color-white;
                    color: @color-medium-grey;
                    font-weight: bold;
                    width: 300px;
                    padding: 10px 5px;
                    border-radius: 0;
                    box-shadow: none;
                    transition: all linear 0.2s;

                    &:focus {
                        border-bottom: 3px solid #ff54c0;
                    }
                }
            }

            .btn {
                margin-top: 25px;
                width: 300px;
                font-weight: bold;
                border-radius: 16px;
                background-color: @color-blue;
                border-color: @color-blue;

                &:hover,
                &:focus {
                    background-color: darken(@color-blue, 10%);
                }
            }

            a {
                font-size: 14px;
                font-weight: bold;

                &:hover,
                &:focus {
                    color: darken(@color-blue, 10%);
                    outline: none;
                }
            }

            .two-factor-auth-panel {
                color: @color-medium-grey;

                .error-container {
                    height: auto;

                    .alert {
                        margin-bottom: 0px;
                    }
                }

                .type-selection {
                    margin: 10px;

                    label {
                        color: @color-black;
                        display: inline-block;
                        font-size: 1em;
                        margin-left: 10px;
                        opacity: 1;
                        position: static;
                    }
                }

                .phone-message {
                    background-color: @color-light-grey;
                    border-radius: 4px;
                    font-size: 0.9em;
                    margin-left: 30px;
                    margin-left: 10px;
                    padding: 4px;

                    p {
                        margin-bottom: 5px;
                    }
                }

                .login-two-factor-auth {
                    .form-group {
                        margin-bottom: 30px;
                    }
                }

                .remember-me {
                    width: 100%;

                    input {
                        display: block;
                        float: left;
                        height: 20px;
                        margin: 0px 8px 0px 2px;
                        width: 20px;
                    }

                    label {
                        color: @color-medium-grey;
                        font-size: 1em;
                    }
                }

                .test-mode {
                    color: @color-red;
                    margin-top: 0px;
                }
            }
        }

        &.eula {
            width: 60%;
            height: 70%;
            min-height: 525px;
            min-width: 525px;
        }
    }

    .login-organisation-name {
        color: @color-white;
        font-family: "Sofia Pro";
        font-size: 1.2em;
        padding-bottom: 17px;
    }

    .panel {
        border-color: transparent;

        &.panel-login {
            padding: 2em 1.5em;

            .login-logo {
                display: block;
                margin: 0 auto 1em;
                max-width: 250px;
            }
        }

        &.panel-shadow {
            box-shadow: none;
        }

        .col-md-4 {
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .panel-body {
            padding: 0;

            .login-label {
                display: block;
                font-weight: 600;
                margin-bottom: 15px;
                text-align: center;
            }

            .alert {
                &.alert-info {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .staff-select {
        margin-bottom: 15px;
    }

    .staff-selection-container {
        .border-radius-sm;
        border: solid 1px @color-light-grey;
        cursor: pointer;
        padding: 10px;

        &:focus,
        &:hover {
            border-color: lighten(@color-blue, 2%);
        }

        &.active,
        &.active .cp-icon-user {
            border-color: lighten(@color-blue, 2%);
            color: @color-blue;
        }

        .staff-name {
            margin-bottom: 0;
        }
    }

    .has-error {
        .staff-selection-container {
            border-color: @color-has-error;
        }

        .btn {
            box-shadow: none;
        }
    }

    .alert.broadcast-message {
        margin-bottom: 3em;
    }

    .password-reset-link {
        padding-top: 0.7em;
        text-align: center;

        p:first-of-type {
            margin-bottom: 1em;
        }

        p,
        p:only-child {
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: 480px) {
    .page-login {
        &.login-wrapper {
            overflow-y: auto !important;
            position: fixed;
            bottom: 0;
        }

        .broadcast-message {
            padding-right: 20px;
        }

        .login-container {
            margin: auto;
            width: 92%;
            height: 100%;
        }

        .panel {
            &.panel-shadow {
                box-shadow: 1px 1px 1px #bec8d4;
            }

            .panel-body {
                padding: 0.5em;
            }
        }
    }
}

@media only screen and (min-width: @screen-sm-min) {
    .page-login {
        .login-container {
            max-width: 680px;
        }

        .broadcast-message {
            .message {
                margin: 0 auto;
                max-width: 680px;
            }
        }

        .panel {
            .panel-body {
                padding: 1.5em;
            }

            .col-md-4 {
                margin-bottom: 15px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .staff-select {
            margin-left: -10px;
            margin-right: -10px;

            &.center-users {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .cp-icon-user {
            color: @color-light-grey;
        }
    }
}

@media only screen and (min-width: @screen-md-min) {
    .page-login {
        .login-container {
            max-width: 100%;
        }

        .broadcast-message {
            margin-bottom: 3em;

            .message {
                margin: 0 auto;
                max-width: 740px;
            }
        }
    }
}