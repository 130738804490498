﻿.icon {
    &.icon-file-drag-drop {
        background: url(Content/SVG/file.svg) no-repeat;
        background-size: contain;
        display: inline-block;
        text-indent: -999999px;
        vertical-align: top;
        width: 35px;
        height: 32px;
    }

    &.browser-icon {
        background: url(Content/Images/browser-logos.png?1.1) no-repeat;
        display: inline-block;
        width: 128px;
        height: 128px;

        &.browser-firefox {
            background-position: 0 0;
        }

        &.browser-iOS {
            background-position: -512px 0;
        }

        &.browser-ie11 {
            background-position: -124px 0;
        }

        &.browser-chrome {
            background-position: -256px 0;
        }

        &.browser-unsupported {
            background-position: -640px 0;
        }

        &.browser-safari {
            background-position: -384px 0;
        }

        &.browser-edge {
            background-position: -768px 0;
        }
    }

    &.notification-icon {
        color: @color-blue;
        font-size: 30px;
        padding-top: 5px;
        padding-left: 5px;

        &.warning {
            color: #f0ad4e !important;
        }
    }
}

@font-face {
    font-family: "care-partner-font";
    src: url("Content/Fonts/care-partner-font.woff?v1.29") format("woff");
    font-weight: normal;
    font-style: normal;
}

[class^="cp-icon-"],
[class*=" cp-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "care-partner-font" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    speak: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.cp-x2 {
        font-size: 24px;
    }

    &.cp-x3 {
        font-size: 32px;
    }

    &.cp-x4 {
        font-size: 48px;
    }

    &.cp-x5 {
        font-size: 64px;
    }

    &.cp-fw {
        display: inline-block;
        text-align: center;
        width: 20px;
    }

    &.cp-fw {
        display: inline-block;
        text-align: center;
        width: 28px;
    }
}
.cp-icon-care-plan-associations:before {
    content: "\e941";
}

.cp-icon-audit:before {
    content: "\e94e";
}
.cp-icon-pencil:before {
    content: "\e94f";
}
.cp-icon-toggle:before {
    content: "\e950";
}
.cp-icon-pathways:before {
    content: "\e951";
}
.cp-icon-people:before {
    content: "\e952";
}
.cp-icon-person:before {
    content: "\e953";
}
.cp-icon-more:before {
    content: "\e94d";
}
.cp-icon-function:before {
    content: "\e940";
}

.cp-icon-comment:before {
    content: "\e93f";
}

.cp-icon-calculator-s:before {
    content: "\e93d";
}

.cp-icon-calculator:before {
    content: "\e93e";
}

.cp-icon-bin-s:before {
    content: "\e93b";
}

.cp-icon-bin:before {
    content: "\e93c";
}

.cp-icon-logs:before {
    content: "\e935";
}

.cp-icon-comment-s:before {
    content: "\e936";
}

.cp-icon-eye:before {
    content: "\e937";
}

.cp-icon-database:before {
    content: "\e938";
}

.cp-icon-code:before {
    content: "\e939";
}

.cp-icon-building-s:before {
    content: "\e93a";
}

.cp-icon-chevron-left:before {
    content: "\e934";
}

.cp-icon-caret-up:before {
    content: "\e933";
}

.cp-icon-caret-down:before {
    content: "\e932";
}

.cp-icon-caret-right:before {
    content: "\e930";
}

.cp-icon-caret-left:before {
    content: "\e931";
}

.cp-icon-search:before {
    content: "\e92f";
}

.cp-icon-chevron-up:before {
    content: "\e92e";
}

.cp-icon-chevron-down:before {
    content: "\e92d";
}

.cp-icon-refresh:before {
    content: "\e92c";
}

.cp-icon-paperclip:before {
    content: "\e92b";
}

.cp-icon-cake:before {
    content: "\e92a";
}

.cp-icon-share:before {
    content: "\e919";
}

.cp-icon-share-s:before {
    content: "\e91a";
}

.cp-icon-archive:before {
    content: "\e91b";
}

.cp-icon-archive-s:before {
    content: "\e91c";
}

.cp-icon-care-plans:before {
    content: "\e90f";
}

.cp-icon-care-plans-s:before {
    content: "\e91d";
}

.cp-icon-plus:before {
    content: "\e913";
}

.cp-icon-plus-s:before {
    content: "\e918";
}

.cp-icon-star:before {
    content: "\e904";
}

.cp-icon-star-s:before {
    content: "\e914";
}

.cp-icon-bell:before {
    content: "\e907";
}

.cp-icon-bell-s:before {
    content: "\e91e";
}

.cp-icon-bell-slash:before {
    content: "\e91f";
}

.cp-icon-admin:before {
    content: "\e90b";
}

.cp-icon-admin-s:before {
    content: "\e915";
}

.cp-icon-pin:before {
    content: "\e917";
}

.cp-icon-pin-s:before {
    content: "\e920";
}

.cp-icon-copy-forward:before {
    content: "\e916";
}

.cp-icon-copy-forward-s:before {
    content: "\e921";
}

.cp-icon-dial:before {
    content: "\e912";
}

.cp-icon-dial-s:before {
    content: "\e922";
}

.cp-icon-bug:before {
    content: "\e910";
}

.cp-icon-bug-s:before {
    content: "\e923";
}

.cp-icon-cross:before {
    content: "\e9133";
}

.cp-icon-cross-s:before {
    content: "\e924";
}

.cp-icon-user:before {
    content: "\e903";
}

.cp-icon-guidance-notes:before {
    content: "\e90c";
}

.cp-icon-guidance-notes-s:before {
    content: "\e90d";
}

.cp-icon-shift-notes:before {
    content: "\e905";
}

.cp-icon-shift-notes-s:before {
    content: "\e925";
}

.cp-icon-calendar:before {
    content: "\e90a";
}

.cp-icon-calendar-s:before {
    content: "\e926";
}

.cp-icon-pause:before {
    content: "\e945";
}

.cp-icon-print:before {
    content: "\e906";
}

.cp-icon-print-s:before {
    content: "\e927";
}

.cp-icon-activities {
    &.no-data-page {
        align-items: center;
        font-size: 75px;
    }

    &:before {
        content: "\e90e";
    }
}

.cp-icon-activities-s-16:before {
    content: "\e928";
}

.cp-icon-piggy:before {
    content: "\e908";
}

.cp-icon-piggy-s:before {
    content: "\e929";
}

.cp-icon-answer-history:before {
    content: "\e911";
}

.cp-icon-check:before {
    content: "\e900";
}

.cp-icon-checkbox:before {
    content: "\e901";
}

.cp-icon-trigger:before {
    content: "\e902";
}

.cp-icon-bars:before {
    content: "\e909";
}

.cp-icon-buildings-group-s:before {
    content: "\e942";
}

.cp-icon-buildings-group:before {
    content: "\e943";
}

.cp-icon-building:before {
    content: "\e944";
}

.cp-icon-chevron-down,
.cp-icon-chevron-up,
.cp-icon-chevron-right,
.cp-icon-chevron-left {
    color: darken(@color-medium-grey, 6%);
}

.cp-icon-bell-slash {
    color: @color-medium-grey;
}

.cp-icon-guidance-notes {
    color: @color-medium-grey;
    cursor: pointer;
}

.cp-icon-guidance-notes:before {
    content: "\e90c";
    cursor: pointer;
}

.cp-icon-guidance-notes-s:before,
.cp-icon-guidance-notes.gutruncate_open:before {
    content: "\e90d";
}

.cp-icon-search,
.cp-icon-plus,
.cp-icon-cross,
.cp-icon-checkbox,
.cp-icon-paperclip,
.cp-icon-pin {
    color: @color-medium-grey;
}

.cp-icon-star {
    color: @color-medium-grey;
    cursor: pointer;

    &.cp-icon-star-su {
        display: inline-block;
        transition: color 0.15s ease-in-out;

        &:focus,
        &:hover {
            color: #ffa500;
        }

        &.active {
            animation: fade 0.2s ease-out;
            color: #ffa500;
            transition: all 0.1s ease;

            &:before {
                content: "\e914";
            }

            &:focus,
            &:hover {
                transform: scale(0.8);
            }
        }
    }
}

.profile-photo {
    border-radius: 50%;
    height: 36px;
    width: 36px;

    &.active {
        cursor: pointer;
    }
}

.about-cp-logo {
    display: block;
    margin: 70px auto 20px;
    width: 100%;
    height: 100%;
    max-width: 280px;
}

.about-cp-icon-container {
    .border-radius(24px);
    background: @color-white;
    border: solid 1px @color-light-grey;
    color: @color-blue;
    margin: @unit*2 auto;
    padding: 0 10px;
    width: 146px;
    height: 146px;

    .build {
        font-size: 2.5rem;
        padding: 44px 0 5px;
    }

    .browser-icon {
        margin: 8px auto 0;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        [class^="cp-icon-"],
        [class*="cp-icon-"] {
            &:focus,
            &:hover,
            &.active,
            &.cp-icon-guidance-notes.gutruncate_open:before {
                color: @theme-color;
                outline: none;
            }

            &.cp-icon-guidance-notes {
                &:not(.collapsed) {
                    color: @theme-color;

                    &:hover,
                    &:focus {
                        color: darken(@theme-color, 10%);
                        &:before {
                            color: inherit;
                        }
                    }
                }
            }
        }

        .cp-icon-chevron-down,
        .cp-icon-chevron-up {
            cursor: pointer;
        }

        .cp-icon-cake {
            color: @theme-color;
        }

        .cp-icon-paperclip {
            &:focus,
            &:hover {
                color: @color-medium-grey;
            }
        }

        .cp-icon-plus:not(.static-icon) {
            &:focus,
            &:hover {
                &:before {
                    content: "\e918";
                }
            }
        }

        .cp-icon-cross {
            &:focus,
            &:hover {
                color: @color-has-error;

                &:before {
                    content: "\e924";
                }
            }
        }

        .cp-icon-bell,
        .cp-icon-bell-s {
            color: @theme-color;

            &:focus,
            &:hover {
                color: @theme-color;
            }
        }

        .cp-icon-bell-slash:focus,
        .cp-icon-bell-slash:hover,
        .cp-icon-refresh:focus,
        .cp-icon-refresh:hover {
            color: @color-medium-grey;
        }

        .cp-icon-function,
        .cp-icon-function:focus,
        .cp-icon-function:hover {
            color: @color-medium-grey;
        }
    }
}

@icon-nav-home: "\E8EE";
