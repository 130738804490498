﻿@import (reference) "../colorPalette.less";
// Panels LESS
.panel {
    .border-radius-sm;
    box-shadow: none;

    &.panel-default {
        border-color: @color-white;
        border-radius: 0 0 0px 0px;
    }

    .panel-heading {
        background: none;
        border-bottom: none;
        padding: 20px 20px 10px;

        .panel-title {
            color: @color-body;
            font-size: @font-size-h4;
            font-weight: 600;
            line-height: @line-height-h4;
        }

        &.small .panel-title {
            .text-sm;
            color: @color-medium-grey !important;
            font-weight: 400;
            line-height: 25px;
            margin-top: -5px;
            text-transform: uppercase;
        }
    }

    &.new-form-panel {
        border: 0px;
        width: 100%;
        padding: 0;

        .new-form-footer {
            font-family: "Sofia Pro";
            font-size: 1.2em;
        }

        .panel-heading {
            padding: 15px;
            height: 100px;
            display: block;
            width: 100%;
        }

        &:hover, &:focus {
            .panel-heading {
                background-color: #fafafa;
            }

            .new-form-footer {
                background-color: darken(@color-default, 10);
            }
        }
    }

    .back-to-top {
        margin: 0.375em 0 1em;
        z-index: 10;
    }

    .panel-body {
        padding: 20px;

        .table {
            margin-bottom: 0;
        }

        .panel {
            .panel-heading,
            .panel-body {
                border: none;
            }
        }

        &.new-form-footer {
            background-color: @color-default;
            color: @color-white;
            padding-top: 20px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            transition: background-color linear 0.2s;
            border: none;
            width: 100%;
            display: block;
            text-align: left;
        }
    }

    .panel-body-modal {
        padding-top: 0;
    }

    .panel-heading + .panel-body {
        padding-top: 15px;
    }

    .panel-heading.small + .panel-body {
        padding-top: 5px;
    }

    .panel-seperator {
        .panel-section {
            border-top: 2px solid @color-light-grey;
        }

        .panel-section:first-child {
            border-top: none; /* limits the scope of the previous rule */
        }
    }

    .panel-footer {
        background: @color-white;
        border-radius: 0 0 0px 0px;
        border-top: none;
        padding: 0 20px 20px;
    }

    .panel-highlight {
        background: @color-extra-light-grey;
    }
    // Adds a thicker top border
    &.panel-border-top {
        border: none;
        border-top: solid 5px @color-light-grey;

        .panel-heading,
        .panel-body,
        .panel-footer {
            border: solid 1px @color-white;
        }

        .panel-heading {
            border-top: none;
            border-bottom: none;
            border-radius: 0;
        }

        .panel-body {
            border-radius: 0 0 0px 0px;
            border-top: none;
        }

        .panel-footer {
            border-top: none;
            margin-top: -6px;
            padding: 15px 20px;
        }
    }
    // Sets the solid header style panel
    &.panel-solid-header {
        border: none;
        border-top: none;
        margin-bottom: 10px;

        .panel-heading,
        .panel-body,
        .panel-footer {
            border: none;
        }

        .panel-heading {
            border-top: none;
            border-bottom: none;
            border-radius: 0;
            padding: 0;
            position: relative;

            .panel-title {
                background-color: @color-light-grey;
                padding: 7px 40px 8px 15px;
                color: @color-white;
                display: block;
            }

            .form-guidance {
                font-size: 0.933em;
                color: @color-dark-grey;
                margin: 5px;
            }
        }

        .panel-body {
            border-radius: 0 0 0px 0px;
            border-top: none;
            padding: 15px;
            padding-bottom: 25px;

            .form-item {
                .form-item-group {
                    label {
                        font-family: @heading-font-family;
                        font-size: 1.067em;
                        margin-bottom: 3px;

                        .help-block {
                            margin: 0;
                        }
                    }
                }
            }

            .repeating-sections-container {
                margin: 0 -15px;

                .repeating-section {
                    padding: 15px;

                    &:nth-child(even) {
                        background-color: @color-extra-light-grey;
                    }
                }
            }

            .repeating-sections-add-another-container {
                margin: -15px -15px 20px -15px;
                padding: 15px;
                position: relative;
                display: block;
                text-align: center;

                p {
                    line-height: 34px;
                    margin: 0;
                }

                .repeating-section-counter {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    line-height: 34px;
                }

                & + .form-item {
                    margin-top: 15px;
                    border-top: 1px solid #ddd;
                }
            }

            .table-data-entry-container

            + .repeating-sections-add-another-container {
                margin-top: 0;
            }
        }

        &.has-more-pathways {
            .panel-body {
                padding-bottom: 0;
            }
        }

        .panel-footer {
            border-top: none;
            margin-top: -6px;
            padding: 15px 20px;
        }

        h5 {
            font-size: 1.1em;
            font-weight: 600;
            font-family: Sofia Pro;
            margin: 0;
            line-height: 1.4em;

            & + span {
                display: block;
            }
        }
    }
    // Adds a thicker left border
    &.panel-border-left {
        border-left: solid 5px @color-light-grey;

        .panel-footer {
            margin-top: -6px;
            padding: 15px 20px;
        }
    }
    // Adds shadow to panel
    &.panel-shadow {
        -webkit-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
        box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
    }
    // Autosave sidebar
    &.autosave-sidebar {
        .panel-body {
            padding-top: 22px;
        }

        .gu-progress {
            border-radius: 10px 10px 0 0;
            height: 4px;
            margin: 0 0 -6px; /* Negative bottom margin is required when gu-progress doesn't exist */
            overflow: hidden;
            position: relative;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
        }

        .autosave-label {
            .text-muted;
            .text-sm;
            line-height: 1rem;
            margin-top: -16px;
            margin-right: -12px;
            position: relative;
        }

        .btn {
            margin-bottom: 2px;
        }

        .autosave-content {
            @media (max-width: @screen-md-min) {
                display: inline-block;
            }
        }
    }

    .activity-section-title,
    .activity-subtitle {
        color: @color-body;
        display: inline-block;
        font-family: Sofia Pro;
        font-size: 1.2em;
        font-weight: 600;
    }

    .header-right {
        line-height: 38px;
        position: absolute;
        right: 8px;
        top: 0;

        .rotate {
            border-radius: 50%;
            color: @color-white;
            padding: 4px;
            position: relative;
            top: 1px;
        }

        .btn {
            border: none;
        }

        > a {
            line-height: 42px;
        }

        ul.dropdown-menu {
            li {
                line-height: 1.2em;

                a {
                    line-height: inherit;
                }
            }
        }
    }
    // Notifications
    .notification-details {
        .additional-information {
            > .hr-small {
                margin-bottom: 20px;
            }
        }
    }
    // Involvements
    &.panel-involvement {
        .involvement-heading {
            border-bottom: solid 1px @color-light-grey;
            display: -webkit-flex;
            display: flex;
            padding: 1em;
        }

        .status,
        .date {
            -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
            border: none;
            margin: 0;
            padding: 0;
        }

        .date {
            padding: 0.125em 0 0 0.25em;
        }
    }
    // Contacts
    &.panel-contact {
        .panel-heading {
            &.contact-header {
                margin-left: @unit*4;
                margin-right: @unit*4;
                padding-left: 0;
                padding-right: 0;

                .panel-title {
                    margin-right: 5px;
                    margin-bottom: 2px;
                    width: 90%;
                }
            }

            .go-to-summary {
                .text-ellipsis;
                max-width: 74%;
            }
        }

        .profession-name {
            border-top: solid 1px @color-light-grey;
            margin-top: @unit*2;
            padding-top: @unit*2;
        }

        .contact-notes {
            overflow-y: auto;
        }
    }
    // Register Service User
    &.panel-exisiting-service-user {
        margin: 4px 0;

        &:hover {
            background: @color-extra-light-grey;

            a {
                text-decoration: none;
            }
        }

        dl {
            color: @color-body;
        }

        .panel-body {
            padding: 8px 12px;
        }
    }

    &.panel-collapse-interaction {
        height: 40px;
        padding-right: 12px;
        background-color: transparent;
        border-radius: 0;
        margin-bottom: 0;

        p {
            margin: 10px 0 10px;
        }

        .more-less-text:before {
            content: "Show";
        }

        &:not(.collapsed) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .more-less-text:before {
                content: "Hide";
            }
        }

        &:hover {
            span {
                color: @color-blue;
            }
        }
    }
    // Diagnoses
    &.panel-primary-diagnosis,
    &.panel-diagnosis {
        .panel-body {
            padding: 15px 0;

            .selected-diagnosis {
                color: @color-body;
                cursor: pointer;
                display: block;
                padding: 5px 20px;

                &:hover,
                &:hover .text-muted {
                    background: @color-extra-light-grey;
                    color: @color-blue;
                    text-decoration: none;
                }
            }
        }
    }
    // Summary Panels
    &.panel-summary-alerts {
        .alert-bullet {
            border-radius: 50%;
            border: solid 1px transparent;
            margin-top: 4px;
            margin-left: -15px;
            width: 10px;
            height: 10px;

            &.pinned {
                border-color: @color-blue;
            }
        }

        .summary-link {
            padding-left: 34px;
        }
    }
    // Pathways
    &.panel-pathway {
        margin-bottom: 0;

        + .panel-pathway {
            margin-top: 5px;
        }

        &:last-of-type {
            margin-bottom: 20px;
        }

        .panel-body {
            padding: 15px 20px;
        }

        .pathway-detail {
            @media (min-width: @screen-sm-min) {
                float: right;
            }
        }

        &.panel-border-left {
            &.abandoned {
                border-left-color: @color-status-abandoned;
            }

            &.paused {
                border-left-color: @color-status-paused;
            }

            &.closed {
                border-left-color: @color-status-closed;
            }

            &.open {
                border-left-color: @color-blue;
            }
        }

        &[class*="pathway-"] {
            .panel-heading {
                align-items: center;
                display: flex;
                padding: 0 20px 0 0;
                word-break: break-word;

                &:hover {
                    background: @color-extra-light-grey;
                    cursor: pointer;
                }

                .heading-content {
                    flex-grow: 1;
                }
            }

            .stage-title {
                display: inline-block;
                margin-right: 1.25em;
            }

            .stage-duration {
                width: 100px;
                padding-right: 0;
            }

            .title-icon {
                margin-right: 4px;

                [class*="cp-icon-"],
                .panel-title {
                    display: inline-block;
                    margin-right: 0.25em;
                }

                [class*="cp-icon-"] {
                    background-color: @color-blue;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    transition: background-color linear 0.1s;
                    width: 70px;

                    &:before {
                        color: @color-white;
                        font-size: 30px;
                    }
                }
            }

            .panel-title {
                color: @color-body;
                cursor: pointer;
            }
        }

        .summary-link {
            padding-left: 35px;
        }

        &.pathway-current:not(.paused) {
            .title-icon {
                [class*="cp-icon-"] {
                    &:before {
                        content: "\e94d";
                    }
                }
            }
        }

        &.pathway-complete:not(.paused) {
            .title-icon {
                [class*="cp-icon-"] {
                    background-color: @color-green;
                }
            }
        }

        &.pathway-failed:not(.paused) {
            .title-icon {
                [class*="cp-icon-"] {
                    background-color: @color-red;

                    &:before {
                        content: "\e9133";
                        margin-left: 2px;
                    }
                }
            }
        }

        &.paused {
            .title-icon {
                [class*="cp-icon-"] {
                    background-color: @color-medium-grey;

                    &:before {
                        content: "\e945";
                    }
                }
            }
        }

        &.pathway-not-started {
            background-color: @color-extra-light-grey;
            pointer-events: none;

            .title-icon {
                [class*="cp-icon-"] {
                    background-color: @color-medium-grey;

                    &:before {
                        display: none;
                    }
                }
            }

            [class*="cp-icon-chevron"] {
                display: none;
            }
        }

        .task-title {
            align-items: center;
            background-color: @color-extra-light-grey;
            color: @color-body;
            cursor: pointer;
            display: flex;
            font-size: 1rem;
            font-weight: 400;
            margin-top: 10px;
            padding: 5px 20px;
            position: relative;
            word-break: break-word;

            &:hover {
                background-color: darken(@color-extra-light-grey, 4%);
            }

            .title-content {
                flex-grow: 1;
            }

            .task-duration {
                width: 100px;
                padding-right: 0;
            }

            [class*="label-"] {
                left: 5px;
                margin-right: 1.5em;
                position: relative;
                top: -1px;
            }
        }

        .label-open {
            background-color: @color-blue;
        }

        .label-complete {
            background-color: @color-green;
        }

        .label-failed {
            background-color: @color-red;
        }

        .label-abandoned {
            background-color: @color-status-abandoned;
        }

        .label-paused {
            background-color: @color-status-paused;
        }

        [class*="label-"] {
            left: 5px;
            margin-right: 1.5em;
            position: relative;
            top: -1px;
        }

        .task-current {
            .task-body,
            .task-title {
                border-left: 6px solid @color-blue;
            }
        }

        .task-complete {
            .task-body,
            .task-title {
                border-left: 6px solid @color-green;
            }
        }

        .task-failed {
            .task-body,
            .task-title {
                border-left: 6px solid @color-red;
            }
        }

        .pathway-restart-btn-group {
            margin-top: 15px;
        }

        .task-body,
        .task-title {
            margin-bottom: 0;
            margin-left: -20px;
            margin-right: -20px;
        }

        .task-detail {
            padding-bottom: 15px;
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 15px;

            .associate-activity {
                margin-bottom: 8px;
            }

            .hr-small.last {
                margin-bottom: 0;
            }
        }
    }

    .sidebar-pathway {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;

        .flex-half {
            flex-grow: 1;
            flex-wrap: wrap;
            align-items: center;
            display: flex;
            justify-content: center;

            @media (max-width: 991px) {
                p {
                    flex-grow: 1;
                    margin: 0;
                }
            }

            &.chart {
                @media (max-width: 991px) {
                    display: none;
                }

                .chart-text {
                    color: #009680;
                    flex-basis: 100%;
                }

                .overdue-chart-text {
                    color: #cf5d5c;
                    flex-basis: 100%;
                }
            }

            .sidebar-mobile-duration {
                display: block;
            }

            .duration-information-block {
                p:last-of-type {
                    margin-bottom: 0;
                }

                @media (max-width: 991px) {
                    display: flex;
                    width: 100%;
                }
            }

            @media (min-width: @screen-md-min) {
                .sidebar-mobile-duration {
                    display: none;
                }
            }
        }

        .sidebar-header {
            .title {
                display: none;
            }

            @media (min-width: @screen-md-min) {
                align-items: center;
                display: flex;
                flex-basis: 100%;
                margin-bottom: 5px;

                .title {
                    display: block;
                    flex-grow: 1;
                    margin: 0;
                }
            }
        }
    }

    &.panel-summary-reminders {
        .summary-link {
            padding-left: 42px;
        }

        [class^="cp-icon-"],
        [class*=" cp-icon-"] {
            float: left;
            margin-top: 2px;
            margin-left: -22px;
        }
    }

    &.panel-summary-personal-details {
        .form-group {
            margin-bottom: 1em;
        }

        .label {
            &.label-open {
                background-color: #00c089;
                margin-top: 3px;
                margin-left: 5px;
            }
        }
    }

    .summary-link {
        color: @color-body;
        display: block;
        margin: 0 -15px;
        padding: 0.5em 15px;

        &:focus,
        &:hover {
            background-color: @color-extra-light-grey;
            text-decoration: none;
        }
    }

    .summary-list-no-link {
        color: @color-body;
        display: block;
        margin: 0 -20px;
        padding: 0.5em 20px;
    }
}

// Summary
.panel-summary-personal-details,
.panel-summary-calendar,
.panel-summary-diagnoses,
.panel-summary-alerts,
.panel-summary-reminders,
.panel-summary-activities {
    &:extend(.panel.panel-border-top all);

    p {
        margin-bottom: 0;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .activity-subtitle {
            &.as-header {
                color: @color-white;
                background-color: @theme-color;
                padding: 7px 40px 8px 15px;
                display: block;
                margin: -15px -15px 0;

                .guidance-toggle {
                    color: @color-white;
                    transition: color linear 0.2s;

                    &:hover,
                    &:focus {
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }

            & + .form-guidance-container {
                margin-top: 15px;

                p {
                    padding: 0;
                }
            }
        }

        .panel {
            .panel-heading {
                .panel-title {
                    color: @theme-color;
                }
            }

            &.panel-border-top {
                border-top-color: @theme-color;
                border-radius: 0 0 0px 0px;
            }

            &.panel-solid-header {
                .panel-heading {
                    .panel-title {
                        color: @color-white;
                        background-color: @theme-color;

                        &:hover,
                        &:focus {
                            color: @color-white;
                        }

                        .cp-icon-guidance-notes,
                        .guidance-toggle {
                            color: @color-white;
                            transition: color linear 0.1s;

                            &:hover,
                            &:focus {
                                color: rgba(255, 255, 255, 0.7);
                            }

                            &[aria-expanded="true"] {
                                color: rgba(255, 255, 255, 0.7);

                                &:hover,
                                &:focus {
                                    color: @color-white;
                                }
                            }
                        }
                    }
                }

                border-radius: 0 0 0px 0px;
            }

            &.panel-border-left {
                border-left-color: @theme-color;
                border-radius: 0 0 0px 0px;
            }
        }

        @media (min-width: @screen-md-min) {
            .panel {
                .task-detail {
                    .label {
                        &.label-open {
                            background-color: @theme-color;
                        }
                    }
                }
            }
        }
    }
}

.summary-background-colour {
    background-color: @color-data-entry-button-border;
}
