﻿@import (reference) "../colorPalette.less";

.alert {
    .border-radius-sm;
    border: none;
    color: @color-white;
    font-weight: 400;
    padding: 8px 15px;
    display: flex;

    &.alert-icon-detail {
        padding: 0;
        overflow: hidden;

        .icon {
            flex-grow: 0;
            background-color: #777;
            color: #fff;
            padding: 8px 12px;
        }

        .details {
            flex-grow: 1;
            max-width: 650px;
            padding: 8px 12px;
            color: #111;

            p:last-of-type{
                margin: 0;
            }
        }

        &.alert-danger, &.alert-info, &.alert-success, &.alert-warning {
            background-color: #ececec;

            b{
                color: #111;
                font-weight: 600;
            }
        }
        // Danger
        &.alert-danger {
            .icon {
                background-color: @color-alert-danger;
            }
        }
        // Info
        &.alert-info {
            .icon {
                background-color: @color-alert-info;
            }
        }
        // Success
        &.alert-success {
            .icon {
                background-color: @color-alert-success;
            }
        }
        // Warning
        &.alert-warning {
            .icon {
                background-color: @color-alert-warning;
            }
        }
    }


    &.alert-danger,
    &.alert-info,
    &.alert-success,
    &.alert-warning,
    &.alert-message {
        a {
            text-decoration: underline;
        }

        a,
        .text-emphasis,
        b,
        strong {
            color: @color-white;
        }

        .sk-spinner {
            &.sk-spinner-double-bounce .sk-double-bounce1,
            &.sk-spinner-double-bounce .sk-double-bounce2 {
                background-color: @color-white;
            }
        }

        &.alert-auto-request-banner {
            display: inline-block;
            font-size: 1.1em;
            margin: 10px 0 0;

            .content {
                display: flex;
                flex-direction: row;

                .icon {
                    font-size: 1.1em;
                    line-height: 1.25em;
                    margin-right: 8px;
                    flex-grow: 0;
                }

                .info {
                    line-height: 1.4em;
                    letter-spacing: 0.008em;

                    b {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    // If positioned below content
    &:not(:first-child) {
        margin: 20px 0 0;
    }
    // Danger
    &.alert-danger {
        background-color: @color-alert-danger;
    }
    // Info
    &.alert-info {
        background-color: @color-alert-info;

        &.alert-info-muted {
            background-color: lighten(@color-alert-info, 40%);
            border: 2px solid @color-alert-info;
            color: @color-body;
        }

        .form-name {
            color: @color-body;
            font-weight: 600;
            font-style: italic;
        }
    }
    // Success
    &.alert-success {
        background-color: @color-alert-success;
        color: #00856b;
    }
    // Warning
    &.alert-warning {
        background-color: @color-alert-warning;
        color: @color-body;

        a,
        .text-emphasis,
        b,
        strong {
            color: @color-body;
        }
    }
    // Message
    &.alert-message {
        background-color: @color-blue;
        color: @color-white;
        border-radius: 0px;
        margin-top: 10px;
        font-size: 1.1em;
    }

    &.alert-default {
        background-color: @color-extra-light-grey;
        color: @color-body;

        .text-emphasis,
        b,
        strong {
            color: @color-body;
        }

        &.alert-default-muted {
            background-color: @color-extra-light-grey;
            border: 2px solid @color-light-grey;
            color: @color-body;
        }
    }
    // Read Only
    &.alert-readonly {
        background-color: @color-alert-warning;
        color: @color-body;
        margin-bottom: 0px;

        a,
        .text-emphasis,
        b,
        strong {
            color: @color-body;
        }
    }
    // Lists
    ul {
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;

        &.list-bullet {
            margin-left: 16px;

            li {
                position: relative;

                &:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    display: block;
                    left: -16px;
                    position: absolute;
                    background-color: @color-body;
                    top: 4px;
                    border-radius: 50%;
                }
            }
        }
    }
    // Spinner
    .sk-spinner {
        &.sk-spinner-double-bounce.sk-spinner {
            width: 20px;
            height: 20px;
        }
    }
    // Browser Message
    &.login-message {
        border-radius: 0;
        border: none;
        display: block;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        right: 0;
        margin-top: 10px;
    }

    &.login-message-in-broadcast {
        border-radius: 0;
        border: none;
        display: block;
        text-align: center;
        margin-right: 20px;
        margin-left: -0.75em;
    }
    // Unsupported Browser
    &.alert-unsupported-browser {
        .border-radius(2px);
        padding: 0.75em 1em;
    }
    // Broadcast Message
    &.broadcast-message {
        .border-radius(0);
        .text-sm;
        background: @color-white;
        color: @color-body;
        padding-top: 10px;
        padding-bottom: 10px;

        .cp-icon-comment {
            float: left;
            color: darken(@color-light-grey, 4%);
            margin-left: -24px;
            margin-top: 0.15em;
        }

        p {
            margin-bottom: 0;
            white-space: pre-line;

            @media only screen and (min-width: @screen-md-min) {
                font-size: @base-font-size;
                line-height: @base-line-height;
            }
        }

        a {
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.broadcast-message-prev {
        background-color: @color-light-grey;
        color: @color-body;

        a {
            color: @color-body;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.alert-no-top-margin {
        margin-top: 0;
    }
}

.browser-alert {
    &:extend(.alert);
    background-color: @color-alert-danger;
    margin-top: @unit*6;
}
