﻿.sidebar-layout {
    .sidebar {
        background-color: @color-blue;
        color: @color-white;
        margin-top: 60px;
        padding: 0 20px 20px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 50;

        .logo {
            display: block;
            font-size: 1.5em;
            margin-bottom: 20px;
        }

        .nav-sidebar {
            margin: 0 -20px 20px;

            li a {
                color: lighten(@color-blue, 32%);
                padding: 15px 20px;

                &:hover,
                &:active,
                &:focus {
                    background-color: darken(@color-blue, 6%);
                    color: @color-white;
                    outline: none;
                }
            }

            li.active {
                a {
                    background-color: darken(@color-blue, 10%);
                    color: @color-white;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: darken(@color-blue, 12%);
                    }
                }
            }
        }
    }

    .content {
        padding: 20px;

        .page-header {
            .header-link-sm {
                display: block;
                font-size: 12px;
                margin-top: 10px;
            }
        }
    }
}

.page-management {
    // Navbar
    .navbar-brand {
        padding: 20px 0 0 20px;
    }
    // Tabs
    .nav.nav-tabs {
        margin-bottom: 15px;

        > li > a {
            .text-sm;
            padding: 8px;

            @media only screen and (min-width: @screen-md-min) {
                padding: 10px 12px;
            }

            @media only screen and (min-width: @screen-lg-min) {
                font-size: 1rem;
            }
        }
        // Alert spacing fix #33356
        ~ .alert {
            margin: 0 0 20px 0;
        }
    }
    // Table
    table {
        table-layout: auto;

        &.table-fixed-layout {
            table-layout: fixed;
        }

        thead tr th.sortable {
            &:hover {
                color: @color-blue;
                cursor: pointer;
            }
        }
    }

    .custom-brand-photo-upload {
        .brand-image-container {
            padding-bottom: 12px;
            max-width: 280px;
        }

        img {
            width: 100%;
        }
    }

    .connected-application {
        display: inline-block;
        border: 1px solid transparent;
        border-radius: 17px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 34px;
        padding: 0 9px;

        &.core {
            display: block !important;
            background-color: @color-status-draft;
            border-color: @color-status-draft;
            color: @color-blue;
        }

        &.incompatible {
            background-color: @color-white;
            border-color: @color-red;
            color: @color-red;
        }

        &.compatible {
            background-color: @color-white;
            border-color: @color-success;
            color: @color-success;
        }
    }

    .incompatible-version {
        background: lighten(@color-has-error, 50%);
        padding: 4px 8px;
        border-radius: 6px;
        margin-top: 4px;
        margin-bottom: 8px;

        h3 {
            font-size: 1.1em;
            color: @color-danger;
        }
    }

    .compatible-version {
        background: lighten(#25a35b, 50%);
        padding: 4px 8px;
        border-radius: 6px;
        margin-top: 4px;
        margin-bottom: 8px;

        h3 {
            font-size: 1.1em;
            color: @color-blue;
        }
    }
}

/** Styling for new management page **/
.management-page {
    .page-container {
        padding: 15px;
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    h1,
    h2,
    h3 {
        color: black;
    }

    .list-page-toolbar {
        padding: 15px;

        .dropdown-input .dropdown-content {
            position: relative;
            top: -2px;
        }

        .search-control input {
            margin-top: -2px;
            height: 34px;
        }
    }

    .tabbed-interface {
        padding: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-between;

        .section {
            &.title-container {
                width: 80%;

                h2 {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .router-tabs {
        font-size: medium;
        font-weight: 500;
        margin-top: 10px;
        padding-bottom: 5px;
        padding-left: 15px;
        border-bottom: 5px solid #777;

        .tab {
            padding: 20px 20px 11px;
            color: #767676;
            text-decoration: none;
            position: relative;

            &.router-link-active {
                color: #111;
                border-bottom: 5px solid #111;
                padding-bottom: 6px;
            }

            .indicator {
                position: absolute;
                width: 6px;
                height: 6px;
                display: block;
                top: 20px;
                right: 14px;
                border-radius: 3px;

                &:after {
                    display: none;
                }

                &.error {
                    background-color: @color-red;
                }

                &.warning {
                    background-color: @color-orange;
                }
            }
        }
    }

    .management-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;

        .sidebar-menu,
        .flex-item {
            order: 0;
            flex: 0 1 auto;
            align-self: auto;
        }

        .flex-item {
            flex-grow: 1;
            // padding: 0 15px;
            width: calc(~"89% - 20px");
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            min-height: calc(100vh - 125px);
            background-color: @color-white;

            > div:first-child:not(.vue-container) {
                // padding: 15px;
            }
        }

        .sidebar-menu {
            background-color: #343c46;
            padding: 15px 0 0 20px;
            width: 220px;

            h1 {
                margin-bottom: 5px;
            }

            h2 {
                color: @color-white;
            }

            ul {
                .clearfix;
                margin: 0;

                li {
                    display: block;
                    float: none;
                    width: auto;

                    &.sidebar-category {
                        a {
                            color: @color-white;
                            min-width: 100%;
                            display: block;
                            text-decoration: none;
                        }

                        .sidebar-category-title {
                            margin-bottom: 5px;
                            color: @color-white;

                            .imo {
                                float: right;
                                padding: 4px 20px 0 0;
                            }

                            strong {
                                color: @color-white;
                            }
                        }

                        ul {
                            margin: 10px 0;
                        }
                    }

                    &.sidebar-menu-item {
                        margin-right: 20px;

                        a {
                            color: @color-white;
                            min-width: 100%;
                            display: block;
                            margin-bottom: 5px;
                            padding: 5px 20px;
                            border-radius: 20px;
                            text-decoration: none;

                            &.router-link-active,
                            &:hover {
                                background-color: @color-white;
                                color: @color-black;
                            }
                        }
                    }
                }
            }
        }
        /** Table Styling **/
        .table {
            td a {
                font-weight: 600;
                font-family: "Sofia Pro";
                cursor: pointer;
            }

            th.sortable {
                cursor: pointer;
            }

            &.table-striped {
                tr {
                    th {
                        background-color: @color-medium-grey;
                        color: @color-white !important;
                        border: none;
                        padding: 4px;
                        transition: background-color 0.2s ease;

                        &.sortable {
                            padding: 0;

                            .cp-icon-caret-up,
                            .cp-icon-caret-down {
                                color: @color-white !important;
                            }

                            button {
                                border: none;
                                background: transparent;
                                width: 100%;
                                text-align: left;
                                padding: 4px;
                            }

                            &:hover, &:focus {
                                background-color: lighten(@color-medium-grey, 5%);
                                outline: none;
                            }
                        }
                    }

                    td {
                        border: none;
                        padding: 4px;
                        vertical-align: middle;

                        .btn {
                            &.btn-link {
                                height: 24px;
                                width: 24px;
                                text-align: center;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }

            .input-field input {
                line-height: normal !important;
            }

            .tick-box-container {
                line-height: normal !important;

                .tick-box-input-container {
                    margin: 0;
                }
            }

            .table-header-button {
                padding: 0;
                height: 19px !important;

                .caret {
                    margin-bottom: 6px;
                }

                button {
                    background-color: lighten( @color-data-entry-button-border, 3 ) !important;
                    height: 19px !important;
                    line-height: 18px !important;

                    &:hover {
                        background-color: @color-data-entry-button-border !important;
                    }

                    &:active {
                        background-color: darken( @color-data-entry-button-border, 10 ) !important;
                    }
                }
            }
        }

        .form-group {
            padding: 10px;
            background-color: #dddddd;
            border-radius: 5px;
            margin: 0px;

            .form-group {
                padding: 10px;
                background-color: rgb(250, 250, 250);
                border-radius: 5px;

                .form-group {
                    background-color: @color-light-grey;
                }
            }
        }
    }

    .organisation-list {
        .suspend-modal-buttons {
            margin-left: 10px;

            button {
                margin-left: 5px;
            }
        }

        .table {
            td.options {
                text-align: right;

                &.no-change-status {
                    padding-right: 33px;
                }

                button {
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }

    .tabbed-interface-footer {
        max-width: 55vw;
        display: flex;

        .item {
            flex-grow: 0;

            &.grow {
                flex-grow: 1;
            }
        }
    }

    .organisation-edit {
        max-width: 55vw;

        .help-block {
            color: @color-black;
            margin-bottom: 5px;
            margin-top: 0px;
        }

        .validation-block {
            font-size: 1em;
        }

        .form-control {
            background-color: @color-white;
            border: solid 2px #AEAEAE;
        }

        .has-error {
            .form-control {
                background-color: @color-has-error-bg;
            }
        }

        .btn-toggle {
            margin-top: 10px;
        }

        .input-group-addon {
            background-color: #aeaeae;
            color: #111;
        }

        .readonly-text-field > span {
            border: solid 2px #AEAEAE;
            background-color: #ececec;
            line-height: 16px;
        }

        .section-header {
            font-family: "Sofia Pro";
            font-size: 14px;
            font-weight: bold;
        }
    }

    .organisation-details {
        .service-areas {
            padding-top: 20px;

            .add-service-area {
                display: flex;
                flex-direction: row;

                .input-column {
                    width: 40%;
                    padding-right: 15px;
                }

                .add-button {
                    margin-top: 23px;
                }
            }

            .urn {
                color: #737373;

                mark {
                    background-color: transparent;
                    color: inherit;

                    &:last-child {
                        color: #111;
                    }
                }
            }
        }

        input:read-only {
            background-color: rgb(221, 221, 221);
        }
    }

    .organisation-settings {
        .form-group {
            a {
                color: @color-black;
                cursor: pointer;
                text-decoration: underline;
            }

            a:hover {
                text-decoration: none;
            }
        }

        .form-group.short-input {
            .help-block {
                float: left;
                margin-top: 0px;
            }

            .input-group {
                bottom: 20px;

                .form-control {
                    border-bottom-left-radius: 4px;
                    border-top-left-radius: 4px;
                    float: right;
                    width: 80px;
                }
            }

            .validation-block {
                bottom: 15px;
                clear: left;
                float: left;
                position: relative;
            }

            .small {
                bottom: 15px;
                float: right;
                position: relative;
            }
        }

        textarea {
            width: 100%;
        }

        .regex-link {
            margin-top: 10px;

            a[target="_blank"] {
                color: @color-blue;

                &:after {
                    display: inline-block;
                    content: "\f08e";
                    display: inline-block;
                    font: normal normal normal 14px / 1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    margin-left: 4px;
                }
            }
        }

        .regex-tests {
            margin-top: 16px;

            .regex-test-results div {
                background-color: @color-white;
                border: solid 2px #AEAEAE;
                border-radius: 4px;
                height: 30px;
                margin-bottom: 4px;
                padding: 2px;

                span.imo {
                    border-radius: 5px;
                    color: @color-white;
                    float: left;
                    font-size: 22px;
                    margin-right: 6px;

                    &.success {
                        background-color: @color-green;
                    }

                    &.fail {
                        background-color: @color-red;
                    }
                }
            }

            button {
                margin-top: 10px;
            }
        }
    }

    .organisation-terms {
        .terms-table {
            .row {
                background-color: @color-light-grey;
                padding-top: 10px;

                &.alt {
                    background-color: @color-extra-light-grey;
                }

                &.standalone {
                    border-radius: 5px;
                    margin-top: 10px;
                }

                &.last {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
            // Header
            .row:first-child {
                background-color: @color-medium-grey;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: @color-white;
                font-family: "Sofia Pro";
                font-size: 14px;
                font-weight: bold;
                padding-bottom: 10px;
            }

            .term-name {
                font-family: "Sofia Pro";
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 0px;
            }

            .help-block {
                margin-top: 0px;
            }
        }
    }

    .organisation-users {

        fieldset {
            border: none;
        }

        legend {
            background-color: #707070;
            border-radius: 5px;
        }

        .table-responsive {
            border-radius: 5px;

            thead th {
                padding: 10px;
            }
        }

        max-width: 55vw;

        .list-page-toolbar {
            padding: 0;
        }

        .input-field-inline {
            .text-input,
            .dropdown-input {
                width: 60%;
            }

            .help-block {
                width: calc(100% - 280px);
            }
        }

        .input-field-inline {
            label strong {
                display: inline-block;
                width: 100%;
            }

            .text-input {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .text-input,
            .dropdown-input {
                position: relative;
                margin-top: -21px;
            }

            .help-block {
                display: inline-block;
                margin-bottom: 0;
                padding-right: 8px;
                color: #6B6B6B;
            }

            .validation-block {
                margin-top: 5px;
                width: 100%;
                text-align: left;
                line-height: 1.2em;
            }
        }

        .two-factor-auth {
            display: flex;

            label {
                flex-basis: 40%;
            }

            .value-container {
                flex-basis: 60%;

                > span {
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }

        .user-service-areas .dropdown-content ul {
            max-height: 160px;
        }
    }

    .organisation-associations {
        h2 {
            display: inline-block;
            margin: 0px 10px 16px 0px;

            + button {
                margin-bottom: 8px;
            }
        }

        .view-id {
            border: solid 1px #777;
            border-radius: 4px;
            display: inline-block;
            margin-right: 5px;
            padding: 0px 4px;
            font-size: 0.875em;
            line-height: 1.5em;
            background-color: #ececec;
        }

        .associations-list {

            > li {
                margin-bottom: 10px;
                border: solid 2px @color-light-grey;
                overflow: hidden;
                border-radius: 4px;

                &.has-error {
                    border: 2px solid @color-red;
                    border-radius: 4px;
                }
            }

            .item {
                flex-grow: 0;

                &.item-grow {
                    flex-grow: 1;
                }

                &.form-name {
                    font-size: 1.1em;
                }

                .btn-toggle {
                    .btn-toggle-text {
                        font-family: 'FSMeWeb';
                        font-weight: normal;
                        font-size: 13px;
                    }
                }
            }

            .header, .body, .footer {
                padding: 8px 10px;

                button {
                    background-color: transparent;
                }
            }

            .header {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .header, .footer {
                background-color: @color-extra-light-grey;
                display: flex;
                align-items: center;
            }

            .body, .footer {
                background-color: @color-light-grey;
            }

            .body {
                .list {
                    .imo {
                        background: #fff;
                        padding: 4px;
                        color: #3d4d84;
                        border-radius: 4px;
                    }
                }
            }

            .footer {
                margin-top: 1px;
            }

            .btn-link {
                color: @color-black;
                font-size: 13px;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .edit-buttons {
            text-align: right;
        }

        .form-definitions-list {
            border: solid 1px #AEAEAE;
            border-radius: 4px;
            max-height: 160px;
            overflow-y: scroll;
            padding-top: 8px;

            &.has-error {
                background-color: #fbb3b3 !important;
            }
        }

        .selected-service-areas {
            div {
                background-color: @color-blue;
                border: solid 2px @color-blue;
                border-radius: 4px;
                display: inline-block;
                margin: 2px;
                padding: 2px 2px 2px 5px;
                color: @color-white;

                button {
                    background-color: transparent;
                    border: none;
                    color: rgba(255,255,255,0.8);

                    &:hover {
                        color: @color-white;
                    }
                }

                &:hover {
                    background-color: lighten(@color-blue, 5%);
                    cursor: pointer;
                }
            }
        }

        .radio-buttons {
            label {
                margin-right: 10px;
            }
        }

        label.tick-box-container-label {
            font-weight: normal;

            .checkbox-text {
                display: inline-block;
                margin-left: 14px;
            }
        }

        .export-template-name {
            display: flex;
            flex-direction: row;

            .text-input-column {
                width: 92%;
                padding-right: 15px;
            }

            .button-input-column {
                margin-top: 23px;
            }
        }

        .table-responsive {
            margin-bottom: 0;
            border-radius: 5px;
            border: solid 1px #707070;

            .table {
                margin-bottom: 0;

                thead th {
                    padding: 5px;
                }
            }
        }
    }

    .file-import-options {
        float: right;
        position: relative;
        right: 12px;
        top: 4px;

        button, a {
            display: inline-block;
            font-size: 13px;
            margin-left: 5px;
            color: @color-black;
            cursor: pointer;
            text-decoration: none;

            &.btn-link {
                &:hover, &:focus {
                    text-decoration: none;
                }
            }

            &.active {
                color: @color-dark-grey;
                cursor: default;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            &.inactive {
                color: @color-black;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .file-import-message {
        align-items: center;
        background-color: @color-white;
        border-left-width: 24px;
        border-radius: 4px;
        border-style: solid;
        display: flex;
        padding: 5px;
        width: 100%;

        p {
            margin-bottom: 0px;
        }

        .left {
            flex-grow: 1;

            .imo, .fa {
                color: @color-white;
                position: relative;
                left: -10px;
                margin-left: -14px;
                width: 12px;
                text-align: center;
            }
        }

        .right {
            flex-grow: 0;

            .imo {
                color: @color-black;
            }

            a {
                cursor: pointer;
            }
        }

        &.info {
            border-color: @color-blue;
        }

        &.success {
            border-color: @color-green;
        }

        &.error {
            border-color: @color-red;

            .left .imo {
                left: -6px;
                margin-left: -19px;
                width: 16px;
            }
        }

        .imo {
            font-size: 16px;
        }
    }

    .file-import-container {
        display: flex;
        height: 32px;

        .file-import-text {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border-right-width: 0px;
            flex-basis: 85%;
        }

        .file-browse-label {
            background-color: @color-medium-grey;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            color: @color-white;
            cursor: pointer;
            flex-basis: 15%;
            height: 32px;
            padding: 5px 0px 0px 0px;
            text-align: center;

            &:hover {
                background-color: @color-medium-dark-grey;
            }
        }
    }

    .form-definitions-list-page {
        .options {
            text-align: right;
        }

        .modal-body {
            overflow-y: visible !important;
        }

        .import-table {

            .table-responsive {
                overflow: visible;
            }

            .status-name {
                width: 80%;
            }

            .status-container {
                display: inline;

                .status-text-container {
                    position: relative;
                }

                .status-text {
                    background-color: @color-white;
                    border: solid 1px #AEAEAE;
                    border-radius: 4px;
                    display: none;
                    font-size: 12px;
                    left: 10px;
                    padding: 5px;
                    position: absolute;
                    text-align: left;
                    bottom: -5px;
                    width: 300px;
                    z-index: 10;

                    span {
                        display: block;
                    }
                }

                button:hover {
                    .status-text {
                        display: block;
                    }
                }
            }
        }
    }

    .organisation-wizard {
        .title {
            width: 100%;
            padding: 30px;
        }

        .wizard-content {
            display: flex;

            .wizard-step-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 50%;

                .steps {
                    list-style: none;
                    padding-left: 0;
                    display: flex;
                    flex-direction: row;
                    background-color: #777777;
                    flex: 0 1 auto;

                    > li {
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        position: relative;
                        font-size: 1.2em;
                        line-height: 32px;
                        font-family: 'Sofia Pro';
                        color: #bbbbbb;
                        padding: 0 10px 0 15px;
                        background-color: #777777;

                        &.complete,
                        &.active {
                            color: white;
                            background-color: #999999;
                        }
                    }

                    > li:first-of-type {
                        padding-left: 15px;
                    }

                    > li:first-of-type {
                        padding-left: 15px;
                    }
                }

                .step-content {
                    padding: 15px;
                    height: calc(~"100vh - 329px");
                    overflow-y: scroll;

                    &.organisation-finalised {
                        height: auto;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: row;

                        .icon {
                            font-size: 60px;
                            width: 100px;
                            height: 100px;
                            text-align: center;
                            line-height: 100px;
                            border-radius: 50%;
                            background: @color-green;
                            color: @color-white;
                            margin-right: 18px;
                        }

                        .content {
                            flex-grow: 1;
                        }
                    }
                }

                .navigation {
                    padding: 15px 25px;
                    background-color: #707070;
                    flex: 0 1 40px;
                }
            }

            .summary-container {
                width: 50%;
                height: calc(~"100vh - 224px");
                float: left;
                padding: 20px;
                background-color: #fafafa;
                overflow-y: scroll;

                ::placeholder {
                    font-style: italic;
                    color: #999;
                }

                .readonly-text-field {
                    span {
                        background-color: #eeeeee;
                        height: 32px;
                    }

                    &.placeholder {
                        span {
                            font-style: italic;
                            color: #999;
                        }
                    }
                }

                dl {
                    dt {
                        flex-basis: 33.33333333%;
                        font-weight: 600;
                        font-family: 'Sofia Pro';
                        font-size: 1.067em;
                        margin-right: 10px;
                    }

                    dd {
                        flex-basis: ~"calc(66.66666667% - 15px)";
                        padding: 15px;
                        border-radius: 4px;
                        background: #eeeeee;
                        margin: 0 0 10px 0;

                        .view-id {
                            border: solid 1px #777;
                            border-radius: 4px;
                            display: inline-block;
                            margin-right: 5px;
                            padding: 0px 4px;
                            font-size: 0.875em;
                            line-height: 1.5em;
                            background-color: #ececec;
                        }
                    }
                }
            }
        }

        h3,
        h4 {
            line-height: 32px;

            .btn {
                display: block;
                text-align: left;
                width: 100%;
                padding: 0;
                border: 0;

                .icon {
                    float: right;
                    width: 32px;
                    height: 32px;
                    line-height: inherit;
                    display: block;
                    text-align: center;
                    transition: transform linear 0.2s;
                }

                &:not(.collapsed) {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        h3 {
            .btn {
                font-size: 18px;
            }
        }
    }

    .edit-form-definition {
        .tab:hover {
            cursor: pointer;
        }

        .page-container {
            width: 50%;
        }

        .form-control {
            background-color: @color-white;
            border: solid 2px #AEAEAE;
        }

        .versions-table, .template-overrides-table {
            border: solid 1px #AEAEAE;
            border-radius: 4px;

            .table-responsive {
                margin-bottom: 0px;

                table {
                    margin-bottom: 0px;
                }
            }
        }

        .overview {
            dt {
                font-weight: bold;
            }

            dd {
                margin-bottom: 12px;
            }

            .heading-with-button {
                display: flex;
                margin-bottom: 16px;

                h2 {
                    flex-basis: 80%;
                }

                .btn {
                    flex-basis: 20%;
                }
            }

            .versions-table {
                .table-responsive {
                    overflow-x: visible;
                }

                .version {
                    padding-right: 30px;
                    text-align: right;
                    width: 10%;
                }

                .status-name {
                    width: 80%;
                }

                .icons {
                    text-align: right;
                    width: 10%;
                }

                .status-container {
                    display: inline;

                    .status-text-container {
                        position: relative;
                    }

                    .status-text {
                        background-color: @color-white;
                        border: solid 1px #AEAEAE;
                        border-radius: 4px;
                        display: none;
                        font-size: 12px;
                        left: 10px;
                        padding: 5px;
                        position: absolute;
                        text-align: left;
                        bottom: -5px;
                        width: 400px;
                        z-index: 10;

                        span {
                            display: block;
                        }
                    }

                    button:hover {
                        .status-text {
                            display: block;
                        }
                    }
                }
            }

            .upload-form-definitions-modal {
                .buttons-container {
                    margin-top: 12px;
                    text-align: right;

                    .btn {
                        margin-left: 10px;
                    }
                }
            }
        }

        .export-templates {
            .select-template {
                &.selected {
                    .dropdown-input-container {
                        width: 95%;
                    }
                }
            }

            .inline-download-icon {
                bottom: 32px;
                color: @color-black;
                float: right;
                position: relative;
                right: 6px;
            }

            .template-overrides-table {
                .organisation {
                    padding-left: 12px;
                    width: 40%;
                }

                .template-name {
                    width: 50%;
                }

                .icons {
                    text-align: right;
                    width: 10%;

                    .btn {
                        margin-right: 12px;
                    }
                }
            }
        }
    }

    .audit,
    .logs {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .date-time-picker {
            display: flex;
            flex-direction: row;
            padding: 0;
            flex-flow: row wrap;
            flex-grow: 1;

            .date-picker {
                display: flex;
                flex-direction: row;

                input {
                    text-align: center;

                    &.datepicker-day, &.datepicker-month {
                        width: 7ch;
                    }

                    &.datepicker-year {
                        width: 8ch;
                    }
                }

                .separator {
                    position: relative;
                    top: 55%;
                }
            }

            .time-picker {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 120px;

                input {
                    text-align: center;
                    width: 7ch;
                }

                .separator {
                    position: relative;
                    top: 15%;
                }
            }
        }

        .from-to-filters {
            padding-right: 0;
            width: 650px;
        }

        .where-filters {

            > .form-group {
                display: flex;
            }

            @media only screen and (min-width: @screen-xl-min) {
                padding-left: 0;
                width: calc(100% - 650px);
            }

            @media only screen and (max-width: @screen-xl-min) {
                width: 100%;
            }

            @media only screen and (max-width: @screen-md-min) {
                .col-md-4 {
                    width: 33.33333333%;
                    float: left;
                }
            }
        }

        .input-field {
            min-height: 32px;

            label,
            input {
                font-family: "FsMeWeb" !important;
                font-size: 12px !important;

                strong {
                    font-family: "FsMeWeb" !important;
                    font-size: 12px !important;
                }
            }
        }

        .btn-clear {
            background-color: #ECECEC;
            border: unset;
        }

        .form-group {
            [class^="col-"] {
                padding-right: 1px;
                padding-left: 1px;
            }
        }

        #calculation-request-modal .modal-body {
            max-height: 75vh;
            padding: 15px;
        }

        #calculation-request-modal .modal-xl {
            width: 70vw;
        }

        #calculation-request-modal .table td {
            padding: 8px;
        }

        .calculation-request-modal {
            .calculation-request {
                padding: 15px;
                margin: -15px -15px 0 -15px;
                background-color: #fafafa;

                .text-underlined {
                    cursor: pointer;
                }
            }

            .budget-tile {
                margin: 0 15px;
                padding: 15px;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: 0px 0px 6px rgba(0,0,0,0.16);
                border-top-left-radius: 0;

                .budget {
                    font-size: 2rem;
                    color: rgb(112, 112, 112);
                }
            }

            .nav.nav-tabs.nav-tabs-secondary {
                border-bottom: none;

                li {
                    a {
                        font-size: 1.4em;
                        font-weight: normal;
                        transition: background-color linear 0.2s;
                    }

                    &.active {
                        a {
                            color: @color-black;

                            &:hover, &:focus {
                                background-color: @color-white;
                            }
                        }
                    }

                    &:not(.active) {
                        a {
                            color: @color-black;

                            &:hover, &:focus {
                                padding-bottom: 6px;
                                border-radius: 4px;
                                box-shadow: inset 0 37px 20px rgba(0,0,0,0.016);
                            }
                        }
                    }
                }
            }

            .tab-content {
                max-height: 40vh;
                padding: 15px;
                border: 1px solid #ddd;
                margin-bottom: 0;
                border-radius: 4px;
                box-shadow: 0px 0px 6px rgba(0,0,0,0.16);
                border-top-left-radius: 0;
                height: 100%;
                overflow-y: scroll;


                h3 {
                    line-height: 32px;

                    .btn {
                        display: block;
                        text-align: left;
                        width: 100%;

                        .icon {
                            float: right;
                            width: 32px;
                            height: 32px;
                            line-height: inherit;
                            display: block;
                            text-align: center;
                            transition: transform linear 0.2s;
                        }

                        &:not(.collapsed) {
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .table, .table-responsive {
                    margin-bottom: 0;
                }
            }

            .list-qa-feedback-items {
                li {
                    padding: 4px 8px;
                    margin: 0 0 8px 0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
            }
        }
    }

    .modal {
        &.modal-logs {
            pre {
                max-height: 300px;
                overflow: auto;
                white-space: pre-wrap;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .table {
            table-layout: auto;
        }
    }
}