﻿.datepicker table {
    tr td {
        .day.active,
        .month.active,
        .year.active {
            background: @color-blue;

            &:hover {
                background: darken(@color-blue, 4%);
            }
        }

        .active,
        tr td {
            background-image: none;
            border: none;
            text-shadow: none;
        }

        &.day {
            border-radius: 50%;
            background-image: none;
            border: none;
            line-height: 30px;
            padding: 0;
            text-shadow: none;
            width: 30px;
            height: 30px;

            &:hover,
            &:focus {
                background-color: @color-extra-light-grey;
                outline: none;
            }
        }

        &.day.active,
        &.day.current.active {
            &:hover {
                color: @color-white;
                text-shadow: none;
            }
        }

        &.day.current,
        .month.current,
        .year.current {
            font-weight: 600;
            text-shadow: none;

            &:hover {
                background: darken(@color-light-grey, 2%);
                text-shadow: none;
            }
        }

        &.current.active {
            font-weight: 400;
            color: @color-white !important;
            text-shadow: none;
        }

        .day.current.active,
        .month.current.active,
        .year.current.active,
        .day.current.active:hover,
        .month.current.active:hover,
        .year.current.active:hover,
        .day.active:hover,
        .month.active:hover,
        .year.active:hover {
            color: @color-white !important;
            text-shadow: none;
        }

        .month,
        .year {
            border-radius: 50%;
            line-height: 40px;
            width: 40px;
            height: 40px;
        }
    }
}

.date-time-picker {
    [class^="col-"]:first-of-type {
        padding-bottom: 15px;
    }

    @media only screen and (min-width: @screen-sm-min) {
        [class^="col-"]:first-of-type {
            padding-bottom: initial;
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .datepicker {
            table {
                .switch:hover {
                    color: @theme-color;
                    text-decoration: underline;
                }

                .prev:hover,
                .next:hover,
                .month.current,
                .day:hover,
                .month:hover,
                .year:hover,
                .day.current,
                .month.current,
                .year.current {
                    color: @theme-color;
                }

                .day.active,
                .month.active,
                .year.active {
                    background: @theme-color;

                    &:hover {
                        background: darken(@theme-color, 4%);
                    }
                }

                .day.current,
                .month.current,
                .year.current {
                    color: @theme-color;
                }
            }
        }
    }
}
