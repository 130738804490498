﻿// Navigation
@import "../colorPalette.less";

.nav {
    &.modal-nav {
        list-style: none;
        border-bottom: 2px @color-light-grey solid;
        margin-bottom: 10px;

        .nav-item {
            padding: 0;
            display: inline-block;
            color: @color-nav-active;
            cursor: pointer;
            margin: 0 0 -2px 0;
            min-width: 40px;

            a {
                padding: 5px 12px;
            }

            &.active {
                border-bottom: 2px solid @atmo-blue;
            }

            &:hover {
                border-bottom: 2px solid @atmo-pink;
            }
        }
    }

    &.nav-tabs {
        &.calculation-response-tabs {
            margin: 0 -15px;
            padding: 0 15px;
            border-bottom: 3px solid @color-light-grey;

            li {
                margin: 0 0 -3px 0;

                a {
                    border: none;
                    font-family: "Sofia Pro";
                    font-size: 1.1em;

                    &:hover {
                        background-color: transparent;
                        border-bottom: 3px solid @atmo-pink;
                    }
                }

                &.active {
                    a {
                        border-bottom: 3px solid @color-blue;
                        color: #111;
                    }
                }
            }
        }

        &.nav-tabs-primary {
            background-color: @color-white;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            border-bottom: 3px solid @color-light-grey;

            li {
                margin: 0 0 -3px 0;

                a {
                    border: none;
                    font-family: "Sofia Pro";
                    font-size: 1.3em;

                    &:hover {
                        background-color: transparent;
                        border-bottom: 3px solid @atmo-pink;
                    }
                }

                &.active {
                    a {
                        border-bottom: 3px solid @color-blue;
                        color: #111;
                    }
                }
            }
        }

        &.nav-tabs-secondary {
            margin-top: 0;

            li {
                a {
                    font-family: "Sofia Pro";
                    font-size: 2em;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background-color: transparent;
                    }

                    span.imo {
                        font-size: 2em;
                        margin-left: 8px;
                    }
                }
            }

            & + .tab-content {
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
                margin-bottom: 16px;
            }
        }

        &.nav-tabs-tertiary {
        }
    }
}

.navbar {
    user-select: none;
    background: @color-nav;
    border: none;
    border-radius: 0;
    font-size: 1.15em;
    height: 60px;
    min-height: 60px;
    margin: 0;
    font-family: "FsMeWeb", Arial, sans-serif;

    &.hide-nav-bar {
        opacity: 0;
    }

    /* New Management Nav Bar */
    .management-navbar-header {
        margin: 7px 8px;
        color: @color-white;
        display: inline-block;

        .header-item {
            display: block;
            float: left;

            &.logo {
                font-size: 40px;
                margin: 4px 8px 0 0;
            }

            &.product-details {
                display: flex;
                flex-direction: column;

                .app-name {
                    font-weight: 600;
                    font-size: 1.8em;
                    font-family: sofia pro;
                    line-height: 0.9em;
                    margin-bottom: 2px;
                    text-transform: lowercase;
                }

                .version {
                    font-size: 0.8em;
                }
            }
        }
    }

    &.nav-management {
        .navbar-brand {
            color: @color-white;
        }

        .dropdown-menu {
            a {
                color: @color-white !important;
            }
        }

        &.navbar.navbar-default,
        &.navbar.navbar-default,
        &.navbar-nav,
        .open,
        .dropdown-menu {
            background-color: @color-blue !important;
        }

        &.navbar.navbar-default &.navbar-nav li > a:hover,
        &.navbar.navbar-default .navbar-nav > li:hover > a,
        &.navbar.navbar-default .navbar-nav li.dropdown > a:hover,
        &.navbar.navbar-default .navbar-nav li.open .dropdown-menu,
        &.navbar.navbar-default
            .navbar-nav
            li.open
            .dropdown-menu
            > li
            > a:focus,
        &.navbar.navbar-default .navbar-nav li > a:focus,
        &.navbar.navbar-default .navbar-nav li.dropdown > a:focus,
        &.navbar.navbar-default .navbar-nav > .open > a,
        &.navbar.navbar-default .navbar-nav > .open > a:hover,
        &.navbar.navbar-default .navbar-nav > .open > a:focus {
            background-color: darken(@color-blue, 4%);
        }

        &.navbar.navbar-default .navbar-nav .open .dropdown-menu li > a:hover,
        &.navbar.navbar-default
            .navbar-nav
            li.open
            .dropdown-menu
            li
            > a:focus:hover {
            background-color: darken(@color-blue, 8%);
        }
    }

    .nav {
        &.navbar-right {
            margin-right: 0px;
        }

        > li {
            &.nav-environment {
                background-color: @color-blue;

                > span {
                    color: rgba(255, 255, 255, 0.5);
                    line-height: 57px;
                    padding: 0 20px 0 20px;
                    height: 60px;
                    font-family: "Sofia Pro", Arial, sans-serif;
                    font-weight: 600;

                    &.environment-badge {
                        color: @color-white;
                        line-height: 60px;
                    }
                }
            }

            > a {
                color: @color-nav-text;
                line-height: 57px;
                padding: 0 20px 0 20px;
                height: 60px;
                font-family: "Sofia Pro", Arial, sans-serif;
                font-weight: 600;
                font-style: normal;
                font-size: 16.1px;
                text-rendering: optimizeLegibility;

                .imo {
                    font-size: 22px;
                    position: relative;
                    top: 2px;
                }

                &:hover,
                &:focus {
                    color: @color-white;
                    background: @color-nav-item-active;

                    .argon-icon {
                        opacity: 1;
                    }
                }

                .fa {
                    font-size: 22px;
                }
            }

            > a#home {
                .imo {
                    top: 4px;
                    margin-right: 0;

                    &:before {
                        content: @icon-nav-home;
                    }
                }
            }

            &.active {
                > a,
                > a:hover,
                > a:focus {
                    font-weight: normal;
                    color: rgba(255, 255, 255, 1);
                    background-color: @color-nav-active;

                    .imo {
                        color: @color-nav-icon-active;
                    }
                }

                &::after {
                    & when (@header-use-caret = true) {
                        width: 0;
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 7px solid @color-main-background;
                        display: block;
                        content: "";
                        position: relative;
                        bottom: 7px;
                        margin: 0 auto;
                        left: 1px;
                    }
                }
            }

            &.home-location {
                a {
                    .ai {
                        font-size: 16px;
                    }
                }
            }

            .notification-bullet {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: transparent;
                display: block;
                position: absolute;
                right: 12px;
                top: 16px;

                &.visible {
                    background-color: @color-notification-primary;
                    transition: background-color linear 0.15s;
                }
            }

            &.admin-menu-item {
                a {
                    border-left: 1px solid @color-nav-text;
                }
            }

            &.open {
                > a,
                > a:hover,
                > a:focus {
                    background: @color-nav-active;
                    color: rgba(2555, 255, 255, 1);

                    .argon-icon {
                        opacity: 1;
                    }

                    &::after {
                        & when (@header-use-caret = true) {
                            width: 0;
                            height: 0;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-bottom: 8px solid @color-white;
                            display: block;
                            position: relative;
                            bottom: 9px;
                            margin: 0 auto;
                            z-index: 9999;
                            content: "";
                        }
                    }

                    &.text-menu-item {
                        &::after {
                            top: -12px;
                        }
                    }
                }

                > .dropdown-menu {
                    background: @color-white;
                    border-color: rgba(0, 0, 0, 0.15);
                    border-top-color: @color-white;
                    border-radius: 4px;
                    padding: 0;
                    top: 60px;
                    min-width: 170px;

                    li {
                        a {
                            color: @color-medium-dark-grey;
                            font-size: 1em;
                            line-height: 1.2em;
                            padding: 8px 16px;
                            white-space: normal;
                            word-wrap: break-word;

                            &:hover,
                            &:focus {
                                background: @color-light-grey;
                                color: @color-medium-dark-grey;
                            }
                        }

                        &.active {
                            a {
                                background: @color-blue;
                                color: @color-white;
                            }
                        }

                        .dropdown-menu {
                            min-width: 185px;

                            li {
                                a {
                                    padding-left: 12px;
                                }
                            }
                        }
                    }

                    .notification-item {
                        &.read {
                            > a {
                                background: @color-light-grey;
                                opacity: 0.65;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        > a {
                            b {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .initial-circle {
                    border-radius: 100%;
                    padding: 4px 8px 9px 6px;
                    height: 60px;
                    width: 60px;
                    text-align: center !important;
                    overflow: hidden;

                    > a.initials {
                        padding: 0 !important;
                        text-decoration: none;
                        font-size: 20px;
                        line-height: 50px;
                        margin: 0;
                        margin-top: 8px;
                        color: @color-white !important;
                        font-family: Sofia Pro;

                        &:hover {
                            background-color: transparent !important;
                            color: darken(@color-white, 15%) !important;
                        }
                    }
                }
            }

            &.notification-menu-item {
                width: 69px;
                height: 60px;
                padding-left: 5px;

                .notification-bullet {
                    right: 16px;
                }

                &.open {
                    > a {
                        &:after {
                            bottom: 4px;
                        }
                    }
                }
            }

            &.nav-user {
                a {
                    line-height: 62px;
                }

                &.open {
                    ul.dropdown-menu {
                        width: 280px;

                        a:focus {
                            outline: none;
                        }

                        .nav-user-info {
                            a {
                                &:focus {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }

        .home-arrow {
            padding-left: 10px;
            padding-right: 10px;
        }

        .home-picker {
            border-radius: 0 0 4px 4px !important;
        }
    }

    &.nav-management {
        .nav > li.open > a::after,
        .navbar .nav > li.open > a:hover::after,
        .navbar .nav > li.open > a:focus::after,
        .navbar .nav > li.open > a:active:after {
            bottom: 4px;
            border-bottom-color: #366186;
        }

        .nav > li.open .dropdown-menu {
            min-width: 200px;
        }
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    &.white-arrow {
        .nav {
            > li.active {
                &::after {
                    border-bottom-color: @color-white;
                    transition: border-bottom-color linear 0.15s;
                }
            }
        }
    }
}

nav.navbar {
    .nav-icon {
        margin-right: 5px;

        &.big {
            font-size: 24px !important;
        }

        &.n-icon {
            color: @color-nav-text;
            top: 4px;
            position: relative;
        }

        &.pl-n-icon {
            /*animation: dissapear 0.7s;
            color: rgba(0, 0, 0, 0);*/
            font-size: 24px;
            left: 25px;
            position: absolute;
            top: 19px;
        }

        .icon-notification {
            top: -20px;

            &.animate {
                animation: ring 2s 1s ease-in-out 2;
                color: #f90;
                transform-origin: top;
                transition: color 1s linear;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .nav-below-1024 {
            display: none;
        }
    }
}

&.modal-open {
    .navbar.navbar-fixed-top {
        .container-fluid {
            padding-right: 17px;
        }
    }

    .navbar {
        margin-right: -17px;
        padding-right: 17px;
    }
}
//Utility class to remove the object above 768 - Also affects margin-left
.nav-below-768 {
    display: none;
}
// Dropdowns
.dropdown-menu {
    .content-heading {
        border-top: 1px solid #b6c3cc;
        background-color: #b6c3cc;
        color: @color-black;
        font-size: 1em;
        line-height: 2;
        cursor: default;
        padding-left: 20px;
        font-weight: 600;
        clear: both;
    }

    .nav-user-info {
        padding: 20px 5px 20px 5px;
        font-size: 15px !important;
        border-bottom: 1px solid #b6c3cc;
        cursor: default;
        line-height: 1;
        background-color: @color-white;

        p {
            padding-bottom: 2px;
            margin: 0;
            word-break: break-word;
            text-wrap: inherit;
        }

        .username {
            font-size: 17px;
            padding-top: 2px;
            font-family: sofia pro;
            padding-bottom: 4px;
        }

        a {
            color: #1A59A2 !important;
            margin: 0;
            padding: 0 !important;

            &:hover {
                background-color: @color-white !important;
            }
        }
    }

    .dropdown-content {
        padding: 8px 16px 12px;
        white-space: nowrap;

        &.dropdown-content-condensed {
            padding: 8px 16px;
        }
    }

    .radio,
    .checkbox {
        margin-top: 12px;
        margin-bottom: 12px;
        min-height: 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .dropdown-menu-scroll {
        max-height: 270px;
        overflow-y: scroll;
    }
}

.dropdown-menu > li > a {
    padding: 6px 20px;

    &.active {
        background: #1e6b84;
        color: @color-white;

        &:focus {
            background: darken(#1e6b84, 15%);
        }

        &:hover {
            cursor: default;
        }
    }
}
// Media Query Attached - 1280- Affects max-width
.dropdown-content {
    min-width: 50%;
}

.dropdown-menu-report-list {
    min-width: 300px;
}
// Multi Level Menu
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: -1px;
    right: 100%;
    margin-top: 0px;
    margin-left: 0px;
    border-top-right-radius: 0;
    padding: 0;
    max-height: auto;
    transition: max-height linear 0.3s;

    li.divider {
        margin: 0;
    }
    // Small Screen Submenus
    @media screen and (max-height: 910px) {
        max-height: 360px;
        overflow-y: auto;
    }

    @media screen and (max-height: 748px) {
        max-height: 300px;
        overflow-y: auto;
    }
}

.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu a:focus + .dropdown-menu {
    display: block;
}

.dropdown-submenu > .dropdown-submenu-header {
    padding: 6px 20px;
    clear: both;
    display: block;
    font-weight: normal;
    line-height: 1.42857;
    white-space: nowrap;
    cursor: pointer;
}

.dropdown-submenu > .dropdown-submenu-header:before {
    content: "\E825";
    font-family: ImosphereIcons;
    margin: 0px;
    left: 0px;
    position: absolute;
}

.dropdown-submenu:hover > .dropdown-submenu-header {
    background-color: @color-light-grey;
    color: #333;
}

.dropdown-submenu:hover > .dropdown-submenu-header:after {
    border-left-color: #333;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.mixin-user-colour(@cssClass,@color) {
    .initial-circle {
        &.user-colour-@{cssClass} {
            background-color: @color;
        }
    }
}

.mixin-user-colour(0, @color-user-profile-orange);
.mixin-user-colour(1, @color-user-profile-navy);
.mixin-user-colour(2, @color-user-profile-cherry);
.mixin-user-colour(3, @color-user-profile-magenta);
.mixin-user-colour(4, @color-user-profile-green);
.mixin-user-colour(5, @color-user-profile-sky-blue);
.mixin-user-colour(6, @color-user-profile-yellow);
.mixin-user-colour(7, @color-user-profile-pink);
.mixin-user-colour(8, @color-user-profile-light-blue);
.mixin-user-colour(9, @color-user-profile-grey);

.notification-list {
    max-height: 320px;
    overflow-y: auto;
    width: 300px;

    li {
        a {
            display: block;
            padding: 8px 12px;
            white-space: normal;
            overflow: hidden;

            div {
                display: inline-block;
                vertical-align: top;
                float: left;
                width: 205px;

                &.icon-container {
                    padding-right: 6px;
                    padding-top: 4px;
                    width: 40px;
                    font-size: 1.8em;
                    color: darken(@color-blue, 10%);

                    .imo {
                        color: inherit;
                    }
                }
            }
        }

        &.notification-banner {
            background: @color-white;
            border-bottom: 1px solid #ddd;
            padding: 7px;

            b {
                font-weight: 600;
            }
        }
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

@media (max-width: 767px) {
    .nav-below-1024 {
        margin-left: 15px;
    }

    .nav-below-768 {
        display: inline-block;
        margin-left: 15px;
    }

    .notification-below-768 {
        display: none !important;
    }

    .navbar-collapse {
        background: rgb(70, 70, 70);
    }

    .navbar-collapse {
        ul.nav {
            li.active {
                &:after {
                    display: none !important;
                }

                & > a {
                    background: #3b3b3b !important;
                }
            }
        }
    }
}

.list-page-toolbar {
    .form-control-search {
        margin: -2px;
        height: 34px;
        width: 210px;
        padding-bottom: 4px;
    }

    &.flex {
        display: flex;

        .left-content {
            flex-grow: 1;
        }
    }
}
