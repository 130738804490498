﻿@import (reference) "../colorPalette.less";

.pagination {
    background-color: @color-white;
    line-height: 30px;
    height: 30px;
    border-radius: 15px;
    .no-select;
    margin: 0;

    > li {
        & > a {
            background-color: transparent;
            border: none;
            border-radius: 15px;
            color: @color-body;
            height: 30px;
            line-height: 30px;
            outline: none;
            padding: 0 9px;
            text-align: center;
            z-index: 10;
        }

        &:first-child,
        &:last-child {
            & > a {
                width: 30px;
                border-radius: 15px;

                &:hover,
                &:focus,
                &:active {
                    background-color: @color-data-entry-button-border;
                }
            }
        }

        &:first-child {
            & > a {
                padding: 0;
            }
        }

        & > .pagination-detail {
            background-color: transparent;
            border: none;
            color: @color-body;
            cursor: default;
            display: inline-block;
            float: left;
            outline: none;
            line-height: 10px;
            padding: 1px;
            position: relative;
            text-align: center;
            z-index: 10;
            min-width: 100px;

            label {
                font-family: FSMeWeb;
                font-weight: 500;
            }

            .page-number {
                font-size: 12px;
            }

            .total-items {
                font-size: 10px;
            }
        }

        .page-number-input {
            width: 32px;
            height: 18px;
            text-align: center;
            border: 0px;
            border-radius: 4px;
            background-color: @color-data-entry-button-background;
        }

        .page-number-input:not(:disabled):hover {
            border: 1px solid black;
        }

        .sk-spinner {
            display: inline-block;
            float: right;

            &.sk-spinner-double-bounce.sk-spinner {
                width: 30px;
                height: 30px;
                float: none;
            }

            &.sk-spinner-double-bounce .sk-double-bounce1,
            &.sk-spinner-double-bounce .sk-double-bounce2 {
                background-color: transparent;
                border: solid 1px darken(@color-medium-grey, 4%);
            }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }

        &.disabled > a,
        &.disabled > a:focus,
        &.disabled > a:hover,
        &.disabled > .pagination-detail,
        &.disabled > .pagination-detail:focus,
        &.disabled > .pagination-detail:hover,
        &.disabled:hover > a,
        &.disabled:hover > a:focus,
        &.disabled:hover > a:hover,
        &.disabled:hover > span,
        &.disabled:hover > span:focus,
        &.disabled:hover > span:hover {
            background-color: transparent;
            border-color: @color-medium-grey;
            color: @color-body;
            outline: none;
            pointer-events: none;
        }

        &.disabled > a,
        &.disabled > a:focus,
        &.disabled > a:hover {
            color: lighten(@color-body, 50%);
        }
    }

    &.pagination-sm {
        > li > a,
        > li > a:focus,
        > li > a:hover,
        > li > span,
        > li > span:focus,
        > li > span:hover {
            .text-sm;
            line-height: 1.5rem;
            padding: 5px 10px;
        }

        > li span.pagination-detail {
            min-width: 90px;
        }
    }
}

.alternate {
    .pagination {
        background-color: @color-data-entry-button-background;
        > li > a {
            &:hover,
            &:focus {
                background-color: @color-white;
            }
        }
    }

    .pagination > li .page-number-input {
        background-color: @color-white;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .pagination {
            > li > a:focus,
            > li > a:hover,
            > li > span:focus,
            > li > span:hover {
                border-color: @theme-color;
                color: @theme-color;
                z-index: 15;
            }

            > li > a:focus > [class^="cp-icon-"],
            > li > a:hover > [class^="cp-icon-"] {
                color: @theme-color;
            }

            > li .pagination-detail {
                &:focus,
                &:hover {
                    border-color: @color-medium-grey !important;
                    color: @color-body !important;
                }
            }

            > li.disabled > a {
                .button-shadow(transparent);
            }
        }
    }

    .pagination {
        &.pagination-@{name} {
            > li > a:focus,
            > li > a:hover,
            > li > span:focus,
            > li > span:hover {
                border-color: @theme-color !important;
                color: @theme-color !important;
            }

            > li > a:focus > [class^="cp-icon-"],
            > li > a:hover > [class^="cp-icon-"] {
                color: @theme-color !important;
            }

            > li.disabled > a {
                .button-shadow(transparent) !important;
            }
        }
    }
}
