﻿@color-progress-bar: @color-info;
@color-attendance-full: #1ad44e;
@color-attendance-partial: #ffba3a;
@color-attendance-none: #ed3a3a;

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active {
        animation: progress-bar-stripes-custom linear 2s infinite;
    }
}

.progress-bar-horizontal {
    height: 10px;
    line-height: 1.46666666rem;
}

.progress-bar-info {
    display: inline-block;
    width: 90%;
}

.progress-bar-info-icon {
    display: inline-block;
    width: 8%;
    margin-bottom: 18px;
}

.progress-bar-section-pagination {
    height: 5px;
    line-height: 1.46666666rem;

    width: 90%;
    margin-left: 17px;
    margin-top: 10px;
}

.progress-bar-success {
    background-color: @color-progress-bar;
    border-color: @color-progress-bar;
}

.progress-bar-warning {
    background-color: lighten(@color-progress-bar, 13.5%);
    border-color: lighten(@color-progress-bar, 13.5%);
}

.progress-bar-default {
    background-color: darken(@color-progress-bar, 13.5%);
    border-color: darken(@color-progress-bar, 13.5%);
}

.progress-bar-pagination {
    background-color: @color-blue;
    border-color: @color-progress-bar;
}

.progress-bar-background {
    background-color: @color-light-grey;
    border-color: @color-light-grey;
}

.progress-bar-questions {
    background-color: transparent;
    border-color: transparent;
}

// Full attendance
.event-attendance-full {
    background-color: @color-attendance-full;
}

// Partial attendance
.event-attendance-partial {
    background-color: @color-attendance-partial;
}

// No attendance
.event-attendance-none {
    background-color: @color-attendance-none;
}

.label-progress {
    width: 26px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .progress {
            // Progress bar Primary
            &.progress-bar {
                background-color: @theme-color;
                border-color: @theme-color;
            }
        }
        .progress-collapse {
            &:hover {
                [class^="cp-icon-"] {
                    color: @color-blue;
                }
            }
        }
    }
}
