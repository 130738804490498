// Caselist bits
.flex-half {
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: center;

    .data {
        width: 110px;
    }

    .chart {
        width: ~"calc(100% - 150px)";
        margin: 0;
    }

    @media (max-width: 991px) {
        p {
            flex-grow: 1;
            margin: 0;
        }
    }

    @media (max-width: 1280px) {
        .data {
            width: 100%;
            margin-top: 15px;

            p {
                display: block;
            }
        }

        .chart {
            width: 100%;
            margin: 0;
        }
    }
}

.btn {
    &.btn-key-item:disabled:hover,
    &.btn-alert:disabled:hover {
        background-color: transparent;
        border-color: transparent;
        color: @color-body;
    }

    &.btn-key-item.active:disabled:hover,
    &.btn-alert.active:disabled:hover {
        background-color: @color-blue;
        color: @color-white;
    }
}

.event-actions,
.delete-event {
    .clearfix;
    margin-top: 25px;
}

.progress-bar-vertical {
    width: 10px;
    min-height: 100px;
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
    float: left;
}

.progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
}

.calendarHolder {
    article {
        background-color: @color-white;
    }

    article h1 {
        text-align: center;
    }

    article h2 {
        cursor: pointer;

        &.primary {
            color: @color-blue;
            font-size: @font-size-h3;
        }

        &.secondary {
            color: #acc2c2;
            font-size: @font-size-h4;
        }
    }

    table {
        width: 100%;
        margin: 4px;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 1px;
    }

    th {
        position: relative;
        width: 20px;
        height: 8px;
        padding: 0 0 3px;
        text-align: center;
    }

    th::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 5px;
        background-color: #acc2c2;
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;
    }

    td {
        position: relative;
        width: 20px;
        height: 16px;
        padding: 1px;
        vertical-align: top;
        background-color: #dfe7e7;
        line-height: 0px;
        cursor: pointer;
    }

    td:empty {
        background-color: transparent;
    }

    .has-events-quarter {
        background-color: #bccece;
    }

    .has-events-half {
        background-color: #99b5b5;
    }

    .has-events-threequarter {
        background-color: #769c9c;
    }

    .has-events-full {
        background-color: #548383;
    }

    .selected-date {
        border: solid 1.5px @color-blue;

        .day {
            margin-top: 5.5px !important;
        }
    }

    .day {
        color: #548383;
        text-align: center;
        margin-top: 7.5px;
        font-size: 12px;
        font-weight: normal;
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;

        &.has-events-quarter,
        &.has-events-half,
        &.has-events-threequarter,
        &.has-events-full {
            color: @color-white;
        }
    }

    .split {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }

    .inactive {
        pointer-events: none;
    }
}

.individual-contact {
    margin-bottom: 15px;
}

.individual-contact-container {
    border-radius: 0px;
    border: solid 1px @color-light-grey;
    background-color: @color-white;
    cursor: pointer;
    -webkit-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
    box-shadow: 5px 6px 15px -7px rgba(0, 0, 0, 0.17);
    margin: 8px;
    padding: 6px;

    @media (min-width: 1299px) {
        width: 45%;
    }

    @media (max-width: 1299px) {
        width: 90%;
    }

    &:focus,
    &:hover {
        border-color: lighten(@color-blue, 2%);
    }

    &.active,
    &.active .cp-icon-user {
        border-color: lighten(@color-blue, 2%);
        color: @color-blue;
    }

    .staff-name {
        margin-bottom: 0;
    }
}

.individual-contact-container:last-child:nth-child(odd) {
    width: 93%;
}

.page-caselist {
    .form-filter {
        background-color: @color-white;
        margin-bottom: 15px;
        padding: 15px 0;
    }

    .table-responsive {
        padding: 0;
        margin: 0;

        .table {
            &.table-striped {
                tr {
                    th,
                    td {
                        padding: 12px;
                    }

                    th {
                        background-color: @color-light-grey;
                        border-bottom-width: 1px;
                    }

                    td {
                        border: none;
                    }

                    .label-open {
                        background-color: @color-dark-grey !important;
                    }

                    .cp-icon-plus,
                    .icon-view-history {
                        color: inherit;

                        &:active,
                        &:focus,
                        &:hover {
                            background-color: transparent;
                            color: inherit;
                        }
                    }

                    .btn {
                        &.btn-link {
                            color: @color-blue;

                            &:active,
                            &:focus,
                            &:hover {
                                background-color: transparent;
                                color: @color-dark-grey;
                            }
                        }

                        &.btn-control {
                            &:active,
                            &:focus,
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .list-page-toolbar {
        .form-control-search {
            border-radius: 17px;

            &:not(:hover) {
                border-color: @color-medium-dark-grey;
            }

            .cp-icon-search {
                color: @color-medium-dark-grey;
            }

            & + .cp-icon-cross {
                padding: 0;
                border: none;
                background-color: transparent
            }
        }

        .btn-default {
            line-height: 32px !important;
            border: solid 2px @color-medium-dark-grey !important;

            &:hover {
                border-color: @color-blue !important;
            }
        }

        .pagination {
            border: 2px solid @color-medium-dark-grey;
            height: 34px;
            margin-top: -2px;
            margin-left: -2px;
            margin-right: -2px;
            border-radius: 17px;
        }
    }
}

#caselist-modal {
    .coc-form-group {
        .dropdown-input {
            width: 440px;
        }
    }

    .role-form-group {
        .dropdown-input {
            width: 500px;
        }
    }

    .staff-form-group {
        .dropdown-input {
            width: 445px;
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .horizontal-chevron {
            color: @theme-color;
            cursor: pointer;

            &.month-overview {
                margin-top: 70px;
            }

            &:focus,
            &.focus,
            &:hover {
                color: mix(@color-default, white, 90%);
            }
        }
    }
}

.table-case-list {
    .table {
        margin-bottom: 0;

        &.table-striped {
            tr {
                &:nth-of-type(odd) {
                    background-color: transparent;
                }

                &.alternate-row {
                    background-color: #f9f9f9;
                }
            }
        }
    }
}

.dropdown-left {
    min-width: 150px;
    text-align: left;
}
