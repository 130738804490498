﻿@import (reference) "../colorPalette.less";
// Tabs LESS

.nav-tabs {
    > li {
        cursor: pointer;
    }

    &.management-tabs {
        margin-bottom: 15px;

        > li > a {
            .text-sm;
            padding: 8px;
        }
    }
}

.blank-tabs-container {
    margin-bottom: 15px;
    position: relative;

    .menu-trigger {
        cursor: pointer;
    }

    .cp-icon-chevron-up {
        display: inline-block;
        margin-top: 0.8em;
    }
}

.blank-tabs {
    background: rgba(255, 255, 255, 0.96);
    height: 0;
    list-style: none;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    transition: opacity ease 0.2s;
    z-index: 15;

    &.open {
        border-bottom: solid 1px @color-light-grey;
        height: auto;
        opacity: 1;
    }

    > li {
        border-bottom: solid 1px @color-light-grey;
        margin: 0 auto;
        width: 85%;

        &:last-of-type {
            border-bottom: none;
        }

        &.active > a {
            color: @color-default;
        }
    }

    > li > a {
        color: @color-body;
        cursor: pointer;
        display: block;
        padding: 8px 0;

        &:hover {
            background-color: @color-extra-light-grey;
            color: @color-default;
            text-decoration: none;
        }

        &:focus {
            outline: none;
            text-decoration: none;
        }
    }
}

.cp-icon-chevron-up {
    cursor: pointer;
}

.worklist-tabs {
    > li {
        vertical-align: top;
        background-color: rgba(255, 255, 255, 0.6);

        &.active {
            background-color: @color-white;
        }

        .cp-icon-cross {
            cursor: pointer;
            visibility: hidden;
        }

        &:hover,
        &:focus {
            .cp-icon-cross {
                visibility: visible;
            }
        }
    }

    > li.closeable {
        position: relative;

        .cp-icon-cross {
            position: absolute;
            top: 11px;
            right: 4px;
        }
    }

    > li.tab-new-list {
        background-color: transparent;

        &:focus,
        &:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }

        &.active {
            background-color: @color-white;
        }

        .icon-pin {
            margin-right: 6px;
        }

        > a {
            padding-left: 8px;
            padding-right: 8px;
            color: @color-blue !important;

            &:hover,
            &:focus,
            &.active {
                color: lighten(@color-blue, 10%) !important;
            }
        }
    }
}

.admin-tabs {
    .clearfix;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        float: left;
        margin-right: 20px;
        padding-bottom: 5px;

        &.active a {
            color: @color-default;

            &::after {
                background-color: @color-default;
                bottom: -6px;
                content: "";
                display: block;
                height: 3px;
                left: 50%;
                margin-left: -10px;
                position: relative;
                width: 20px;
            }
        }
    }

    > li a {
        color: @color-body;
        text-decoration: none;
    }

    #chevron-toggle {
        float: right;
        height: 14px;
        margin-top: 1px;
        margin-left: 3px;
    }

    @media (min-width: @screen-lg-min) {
        > li > a {
            font-size: 1rem;
        }
    }
}

.admin-tabs-wrapper {
    border-bottom: solid 1px @color-light-grey;
    margin-bottom: 15px;

    &.secondary {
        border-bottom-color: @color-light-grey;

        .admin-tabs li.active a {
            &::after {
                display: none;
            }
        }
    }
}

.nav-tabs-full-width-wrapper {
    padding: 0;
    height: 43px;
    margin-bottom: 15px;

    .nav {
        margin: 0 auto;
        max-width: 1320px;
        padding: 0 30px;
        border-bottom-color: @color-light-grey;

        &.account-tabs {
            padding: 0 !important;
        }

        &.nav-tabs {
            > li {
                &.active {
                    > a {
                        background-color: @color-data-entry-button-border;
                        border-color: @color-light-grey;
                        border-bottom-color: transparent;
                    }
                }
            }
        }
    }
}

// Small Devices 760px
@media (min-width: @screen-sm-min) {
    .blank-tabs-container {
        border-bottom: solid 2px @color-light-grey;

        > .menu-trigger {
            display: none;
            visibility: hidden;
        }
    }

    .blank-tabs {
        background-color: transparent;
        border-bottom: none;
        opacity: 1;
        height: auto;
        position: relative;

        &.open {
            border-bottom: none;
        }

        > li {
            border: none;
            display: inline-block;
            width: auto;
        }

        > li > a {
            padding: 8px 10px;

            &:hover {
                background-color: transparent;
            }
        }
        // Apply animation
        &.animated {
            margin-bottom: -3px;
            overflow: hidden;

            > li:after {
                background-color: transparent;
                content: "";
                display: block;
                height: 4px;
                margin: auto;
                transition: transform 0.15s;
                transform: translateY(6px);
            }

            > li.active:after {
                background-color: @color-default;
                transform: translateY(0);
            }
        }
    }

    .su-tabs-container {
        padding: 0;

        .su-tabs {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            margin-bottom: -2px;

            > li {
                margin-right: -4px;
                flex-grow: 1;

                a {
                    padding: 8px 7px;
                }

                &.active {
                    a,
                    a:focus {
                        background-color: @color-extra-light-grey;
                        border-bottom-width: 2px;
                        border-bottom-style: solid;
                    }
                }
            }

            &.animated {
                > li:after {
                    display: none;
                }
            }
        }
    }

    .worklist-tabs {
        > li.closeable {
            > a {
                padding-left: 15px;
            }

            .cp-icon-cross {
                top: 11px;
                right: -2px;
            }
        }

        > li.tab-new-list {
            > a {
                .cp-icon-plus {
                    width: auto;
                }
            }
        }
    }
}

// Medium Devices 992px
@media (min-width: @screen-md-min) {
    .su-tabs-container {
        .su-tabs {
            > li {
                width: auto;
            }

            &.animated {
                margin-bottom: -3px;

                > li:after {
                    display: block;
                }
            }
        }
    }
}

// Large Devices 1280px
@media (min-width: @screen-lg-min) {
    .blank-tabs {
        > li > a {
            .text-lg;
            padding: 10px 14px;
        }
    }

    .worklist-tabs {
        > li.closeable {
            .cp-icon-cross {
                top: 14px;
            }
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .su-tabs {
        .tab-@{name} {
            &:hover > a,
            &:focus > a,
            > a:hover,
            > a:focus {
                background: none;
                color: @theme-color !important;
            }

            &.active > a {
                border-color: @theme-color;
                color: @theme-color;
            }

            > a:after {
                background: @theme-color;
            }

            &.active:after {
                background-color: @theme-color !important;
            }
        }
    }
}
