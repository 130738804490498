﻿@import (reference) "../colorPalette.less";
.note {
    .border-radius-xs;
    border: solid 1px @color-data-entry-button-background;
    background-color: @color-extra-light-grey;
    font-size: 0.86888887rem;
    line-height: 1.267rem;
    margin: 10px 0;
    overflow-y: auto;
    padding: 0;

    p {
        margin-bottom: 0;
    }

    .note-header {
        background-color: @color-data-entry-button-background;
        border-bottom: 1px solid @color-data-entry-button-background;
        font-weight: 600;
        line-height: 22px;
        padding: 0 8px;
    }

    .note-body {
        padding: 8px;
    }
}

.page-print .note,
.page-print .note span {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

@media print {
    .note,
    .page-print .note,
    .note,
    .page-print span .note span {
        border: none !important;
        font-size: 7.5pt !important;
        line-height: 11pt !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .print-note-label {
        display: inline-block !important;
        padding-right: 5pt !important;
    }
}
