﻿@import (reference) "../colorPalette.less";

.theme-default {
    .input-group-btn {
        .btn.btn-default.coding-system-type {
            &[disabled] {
                background-color: @color-data-entry-button-border;
                opacity: 1;
            }
        }
    }
}
