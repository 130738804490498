@import (reference) "../colorPalette.less";

@font-face {
    font-family: "ImosphereIcons";
    src: url("Content/Fonts/ImosphereIcons.woff?v11") format("woff");
    font-weight: normal;
    font-style: normal;
}

.imo {
    display: inline-block;
    font: normal normal normal 14px/1 "ImosphereIcons";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);

    &.text {
        font-size: 20px;

        @media (max-width: 1130px) {
            font-size: 16px;
        }
    }
}

.imo-lg {
    display: inline-block;
    font: normal normal normal 14px/1 "ImosphereIcons";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.imo-sm {
    display: inline-block;
    font: normal normal normal 14px/1 "ImosphereIcons";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.indicator {
    position: relative;
    display: inline-block;

    &:after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 50%;
    }

    &.warning:after {
        background-color: @color-warning;
    }

    &.info:after {
        background-color: @color-info;
    }

    &.success:after {
        background-color: @color-success;
    }

    &.overdue:after {
        background-color: @color-overdue;
    }
}

.imo-2x {
    font-size: 28px;
}

.imo-3x {
    font-size: 42px;
}

.imo-4x {
    font-size: 58px;
}

.imo-5x {
    font-size: 74px;
}

.imo-6x {
    font-size: 90px;
}

.error-page {
    font-size: 200px;
    color: #3d4d84;
}

.nav-icon {
    font-size: 22px;
}

.icon-general {
    font-size: 20px !important;
}

.icon-delete:before {
    content: '\e800';
}

.icon-folder-new:before {
    content: '\e801';
}

.icon-ambulance:before {
    content: '\e802';
}

.icon-bed:before {
    content: '\e803';
}

.icon-blood-pressure:before {
    content: '\e804';
}

.icon-brain:before {
    content: '\e805';
}

.icon-bone:before {
    content: '\e806';
}

.icon-breathe:before {
    content: '\e807';
}

.icon-burger:before {
    content: '\e808';
}

.icon-calculator:before {
    content: '\e809';
}

.icon-capsule-pot:before {
    content: '\e80a';
}

.icon-capsules:before {
    content: '\e80b';
}

.icon-cogs:before {
    content: '\e80c';
}

.icon-cigarette:before {
    content: '\e80d';
}

.icon-drugs:before {
    content: '\e80e';
}

.icon-ear:before {
    content: '\e80f';
}

.icon-eye:before {
    content: '\e810';
}

.icon-female:before {
    content: '\e811';
}

.icon-fracture:before {
    content: '\e812';
}

.icon-genome:before {
    content: '\e813';
}

.icon-hermaphrodite:before {
    content: '\e814';
}

.icon-infection-2:before {
    content: '\e815';
}

.icon-house:before {
    content: '\e816';
}

.icon-infection:before {
    content: '\e817';
}

.icon-joint:before {
    content: '\e818';
}

.icon-liver:before {
    content: '\e819';
}

.icon-lungs:before {
    content: '\e81a';
}

.icon-male:before {
    content: '\e81b';
}

.icon-monitoring:before {
    content: '\e81c';
}

.icon-mental-health:before {
    content: '\e81d';
}

.icon-mouth:before {
    content: '\e81e';
}

.icon-other-brain:before {
    content: '\e81f';
}

.icon-other-gender:before {
    content: '\e820';
}

.icon-blood-2:before {
    content: '\e821';
}

.icon-blood:before {
    content: '\e822';
}

.icon-compare-ab-series:before {
    content: '\e823';
}

.icon-calander:before {
    content: '\e824';
}

.icon-chevron-left:before {
    content: '\e825';
}

.icon-chevron-right:before {
    content: '\e826';
}

.icon-clinical-observations:before {
    content: '\e827';
}

.icon-myhub-view-icon:before {
    content: '\e828';
}

.icon-cohort-discovery:before {
    content: '\e829';
}

.icon-myhub-summary-icon:before {
    content: '\e82a';
}

.icon-myhub-rocket-rotated-icon:before {
    content: '\e82b';
}

.icon-column-chart-sm:before {
    content: '\e82c';
}

.icon-cross:before {
    content: '\e82d';
}

.icon-folder-move:before {
    content: '\e82e';
}

.icon-custom-reports-lg:before {
    content: '\e82f';
}

.icon-data-export-lg:before {
    content: '\e830';
}

.icon-count-coding-system-instance:before {
    content: '\e831';
}

.icon-count-latest-multiple-choice-answer:before {
    content: '\e832';
}

.icon-multiple-code-assignment:before {
    content: '\e833';
}

.icon-incidental-code-assignment:before {
    content: '\e834';
}

.icon-switcher-code-assignment:before {
    content: '\e835';
}

.icon-text-series:before {
    content: '\e836';
}

.icon-simple-pie-series:before {
    content: '\e837';
}

.icon-pyramid:before {
    content: '\e838';
}

.icon-postcode:before {
    content: '\e839';
}

.icon-pie-series:before {
    content: '\e83a';
}

.icon-lollipop-chart:before {
    content: '\e83b';
}

.icon-distributed-spot-series:before {
    content: '\e83c';
}

.icon-count-latest-answer:before {
    content: '\e83d';
}

.icon-count-form-instances:before {
    content: '\e83e';
}

.icon-box-series:before {
    content: '\e83f';
}

.icon-bar-series:before {
    content: '\e840';
}

.icon-count-admission-location:before {
    content: '\e841';
}

.icon-data-export:before {
    content: '\e842';
}

.icon-data-type:before {
    content: '\e843';
}

.icon-diagnosis-explorer-lg:before {
    content: '\e844';
}

.icon-diagnosis-explorer:before {
    content: '\e845';
}

.icon-custom-reports:before {
    content: '\e846';
}

.icon-wheelchair:before {
    content: '\e847';
}

.icon-tooth:before {
    content: '\e848';
}

.icon-grid_count-latest-date-answer:before {
    content: '\e849';
}

.icon-open-admission:before {
    content: '\e84a';
}

.icon-upperbound:before {
    content: '\e84b';
}

.icon-lowerbound:before {
    content: '\e84c';
}

.icon-datasets:before {
    content: '\e84d';
}

.icon-closed-admission:before {
    content: '\e84e';
}

.icon-top-percentage:before {
    content: '\e84f';
}

.icon-mapping-chart:before {
    content: '\e850';
}

.icon-malevfemale:before {
    content: '\e851';
}

.icon-imosphere-ident:before {
    content: '\e852';
}

.icon-imosphere-logotype:before {
    content: '\e853';
}

.icon-library:before {
    content: '\e854';
}

.icon-chn-compare-ab:before {
    content: '\e855';
}

.icon-genderless:before {
    content: '\e856';
}

.icon-clearsense-icon:before {
    content: '\e857';
}

.icon-helix:before {
    content: '\e858';
}

.icon-scope-admissions:before {
    content: '\e859';
}

.icon-scope-staff:before {
    content: '\e85a';
}

.icon-scope-timeframe:before {
    content: '\e85b';
}

.icon-view-history:before {
    content: '\e85c';
}

.icon-chart:before {
    content: '\e85d';
}

.icon-figures-group:before {
    content: '\e85e';
}

.icon-scope-focus:before {
    content: '\e85f';
}

.icon-base10:before {
    content: '\e860';
}

.icon-save:before {
    content: '\e861';
}

.icon-scope-focus-alt:before {
    content: '\e862';
}

.icon-myhub-panel-selected-icon:before {
    content: '\e863';
}

.icon-count-form-over-time:before {
    content: '\e864';
}

.icon-myhub-panel-tick:before {
    content: '\e865';
}

.icon-indexed-list:before {
    content: '\e866';
}

.icon-clearsense-logo:before {
    content: '\e867';
}

.icon-library-sm:before {
    content: '\e868';
}

.icon-lock:before {
    content: '\e869';
}

.icon-local-login:before {
    content: '\e86a';
}

.icon-my-forms:before {
    content: '\e86b';
}

.icon-flask:before {
    content: '\e86c';
}

.icon-multiline-text-answer:before {
    content: '\e86d';
}

.icon-not-in-library-sm:before {
    content: '\e86e';
}

.icon-ldap:before {
    content: '\e86f';
}

.icon-not-in-library-lg:before {
    content: '\e870';
}

.icon-open-id:before {
    content: '\e871';
}

.icon-single-line:before {
    content: '\e872';
}

.icon-tristate-checkbox:before {
    content: '\e873';
}

.icon-share:before {
    content: '\e874';
}

.icon-calculated-answer:before {
    content: '\e875';
}

.icon-calculated-date-range:before {
    content: '\e876';
}

.icon-comment:before {
    content: '\e877';
}

.icon-checkbox:before {
    content: '\e878';
}

.icon-document:before {
    content: '\e879';
}

.icon-download:before {
    content: '\e87a';
}

.icon-dropdown:before {
    content: '\e87b';
}

.icon-export:before {
    content: '\e87c';
}

.icon-numeric-answer:before {
    content: '\e87d';
}

.icon-ontology:before {
    content: '\e87e';
}

.icon-pin:before {
    content: '\e87f';
}

.icon-received:before {
    content: '\e880';
}

.icon-projects:before {
    content: '\e881';
}

.icon-sent:before {
    content: '\e882';
}

.icon-refresh:before {
    content: '\e883';
}

.icon-unlock:before {
    content: '\e884';
}

.icon-heading:before {
    content: '\e885';
}

.icon-file:before {
    content: '\e886';
}

.icon-organizations:before {
    content: '\e887';
}

.icon-checkbox-group:before {
    content: '\e888';
}

.icon-beginning-with:before {
    content: '\e889';
}

.icon-facecode:before {
    content: '\e88a';
}

.icon-selected-branch:before {
    content: '\e88b';
}

.icon-worklist:before {
    content: '\e88c';
}

.icon-registration:before {
    content: '\e88d';
}

.icon-move-activity:before {
    content: '\e88e';
}

.icon-count-admission-type:before {
    content: '\e88f';
}

.icon-discharges-over-time:before {
    content: '\e890';
}

.icon-age-banding:before {
    content: '\e891';
}

.icon-spline-chart:before {
    content: '\e892';
}

.icon-move-activity-1:before {
    content: '\e893';
}

.icon-spline-area-chart:before {
    content: '\e894';
}

.icon-word-cloud:before {
    content: '\e895';
}

.icon-question:before {
    content: '\e896';
}

.icon-childrens:before {
    content: '\e897';
}

.icon-tag:before {
    content: '\e898';
}

.icon-repeating-sections:before {
    content: '\e899';
}

.icon-table:before {
    content: '\e89a';
}

.icon-careplan:before {
    content: '\e89b';
}

.icon-radio-btn:before {
    content: '\e89c';
}

.icon-grid-layout:before {
    content: '\e89d';
}

.icon-split-first-diagnosis:before {
    content: '\e89e';
}

.icon-not-verified:before {
    content: '\e89f';
}

.icon-pending:before {
    content: '\e8a0';
}

.icon-switch:before {
    content: '\e8a1';
}

.icon-verified:before {
    content: '\e8a2';
}

.icon-import-from-form:before {
    content: '\e8a3';
}

.icon-data-entry-and-analytics:before {
    content: '\e8a4';
}

.icon-personal-budgets:before {
    content: '\e8a5';
}

.icon-revert:before {
    content: '\e8a6';
}

.icon-testing:before {
    content: '\e8a7';
}

.icon-divide:before {
    content: '\e8a8';
}

.icon-message:before {
    content: '\e8a9';
}

.icon-mediator-failure:before {
    content: '\e8aa';
}

.icon-timeline:before {
    content: '\e8ab';
}

.icon-not-equals:before {
    content: '\e8ac';
}

.icon-back-bracket-1:before {
    content: '\e8ad';
}

.icon-envelope-closed:before {
    content: '\e8ae';
}

.icon-envelope-open-1:before {
    content: '\e8af';
}

.icon-sent-group-1:before {
    content: '\e8b0';
}

.icon-sum-costs-for-time-unit:before {
    content: '\e8b1';
}

.icon-spot-series:before {
    content: '\e8b2';
}

.icon-front-bracket-1:before {
    content: '\e8b3';
}

.icon-absolute:before {
    content: '\e8b4';
}

.icon-addition:before {
    content: '\e8b5';
}

.icon-and:before {
    content: '\e8b6';
}

.icon-list-answer:before {
    content: '\e8b7';
}

.icon-brackets:before {
    content: '\e8b8';
}

.icon-coalesce:before {
    content: '\e8b9';
}

.icon-date-diff:before {
    content: '\e8ba';
}

.icon-logout:before {
    content: '\e8bb';
}

.icon-equal-to:before {
    content: '\e8bc';
}

.icon-forumula:before {
    content: '\e8bd';
}

.icon-my-sql:before {
    content: '\e8be';
}

.icon-postgre-sql:before {
    content: '\e8bf';
}

.icon-sql-server:before {
    content: '\e8c0';
}

.icon-activity-counter:before {
    content: '\e8c1';
}

.icon-activity-counter-lg:before {
    content: '\e8c2';
}

.icon-admin-settings:before {
    content: '\e8c3';
}

.icon-admin-user:before {
    content: '\e8c4';
}

.icon-all-the-answers:before {
    content: '\e8c5';
}

.icon-all-the-answers-lg:before {
    content: '\e8c6';
}

.icon-annotation:before {
    content: '\e8c7';
}

.icon-apps:before {
    content: '\e8c8';
}

.icon-area-chart-sm:before {
    content: '\e8c9';
}

.icon-banding:before {
    content: '\e8ca';
}

.icon-atmolytics:before {
    content: '\e8cb';
}

.icon-camera:before {
    content: '\e8cc';
}

.icon-chevron-down:before {
    content: '\e8cd';
}

.icon-money-bag-euros:before {
    content: '\e8ce';
}

.icon-money-bag-pound:before {
    content: '\e8cf';
}

.icon-chevron-up:before {
    content: '\e8d0';
}

.icon-money-bag-dollars:before {
    content: '\e8d1';
}

.icon-clinical-observations-lg:before {
    content: '\e8d2';
}

.icon-clone:before {
    content: '\e8d3';
}

.icon-cohort-discovery-sm:before {
    content: '\e8d4';
}

.icon-pain:before {
    content: '\e8d5';
}

.icon-pills:before {
    content: '\e8d6';
}

.icon-costs-and-charges:before {
    content: '\e8d7';
}

.icon-costs-and-charges-lg:before {
    content: '\e8d8';
}

.icon-pulse:before {
    content: '\e8d9';
}

.icon-psychology:before {
    content: '\e8da';
}

.icon-stomach:before {
    content: '\e8db';
}

.icon-surgery:before {
    content: '\e8dc';
}

.icon-temp:before {
    content: '\e8dd';
}

.icon-syringe:before {
    content: '\e8de';
}

.icon-test-tube:before {
    content: '\e8df';
}

.icon-transgender:before {
    content: '\e8e0';
}

.icon-events-and-processes:before {
    content: '\e8e1';
}

.icon-events-and-processes-lg:before {
    content: '\e8e2';
}

.icon-expand:before {
    content: '\e8e3';
}

.icon-filter:before {
    content: '\e8e4';
}

.icon-flag:before {
    content: '\e8e5';
}

.icon-folder:before {
    content: '\e8e6';
}

.icon-image:before {
    content: '\e8e7';
}

.icon-group:before {
    content: '\e8e8';
}

.icon-group-by:before {
    content: '\e8e9';
}

.icon-graph-type:before {
    content: '\e8ea';
}

.icon-health-dimensions:before {
    content: '\e8eb';
}

.icon-health-dimensions-lg:before {
    content: '\e8ec';
}

.icon-home-large:before {
    content: '\e8ed';
}

.icon-home:before {
    content: '\e8ee';
}

.icon-identity-check:before {
    content: '\e8ef';
}

.icon-identity-check-lg:before {
    content: '\e8f0';
}

.icon-lab-tests-explorer-lg:before {
    content: '\e8f1';
}

.icon-insights:before {
    content: '\e8f2';
}

.icon-lab-tests-explorer:before {
    content: '\e8f3';
}

.icon-line-chart-sm:before {
    content: '\e8f4';
}

.icon-list-and-explorer:before {
    content: '\e8f5';
}

.icon-medications-explorer-lg:before {
    content: '\e8f6';
}

.icon-medications-explorer:before {
    content: '\e8f7';
}

.icon-minus:before {
    content: '\e8f8';
}

.icon-mortality-rates:before {
    content: '\e8f9';
}

.icon-mortality-rates-lg:before {
    content: '\e8fa';
}

.icon-noreports:before {
    content: '\e8fb';
}

.icon-nogroups:before {
    content: '\e8fc';
}

.icon-neg-stack-sm:before {
    content: '\e8fd';
}

.icon-received-group:before {
    content: '\e8fe';
}

.icon-procedures-explorer-lg:before {
    content: '\e8ff';
}

.icon-rocket:before {
    content: '\e900';
}

.icon-run:before {
    content: '\e901';
}

.icon-reports:before {
    content: '\e902';
}

.icon-sent-group:before {
    content: '\e903';
}

.icon-schedule-alt:before {
    content: '\e904';
}

.icon-schedule:before {
    content: '\e905';
}

.icon-sequence:before {
    content: '\e906';
}

.icon-exports:before {
    content: '\e907';
}

.icon-procedures-explorer:before {
    content: '\e908';
}

.icon-star-empty:before {
    content: '\e909';
}

.icon-star-fill:before {
    content: '\e90a';
}

.icon-star-fill-border:before {
    content: '\e90b';
}

.icon-subgroup:before {
    content: '\e90c';
}

.icon-summary:before {
    content: '\e90d';
}

.icon-survival-rates:before {
    content: '\e90e';
}

.icon-survival-rates-lg:before {
    content: '\e90f';
}

.icon-user-icon:before {
    content: '\e910';
}

.icon-visits-revisited-lg:before {
    content: '\e911';
}

.icon-view:before {
    content: '\e912';
}

.icon-x-axis:before {
    content: '\e913';
}

.icon-warning:before {
    content: '\e914';
}

.icon-visits-revisited:before {
    content: '\e915';
}

.icon-view-as:before {
    content: '\e916';
}

.icon-reset:before {
    content: '\e917';
}

.icon-notification:before {
    content: '\e918';
}

.icon-number-format:before {
    content: '\e919';
}

.icon-pencil:before {
    content: '\e91a';
}

.icon-pie-chart-sm:before {
    content: '\e91b';
}

.icon-plus:before {
    content: '\e91c';
}

.icon-printer:before {
    content: '\e91d';
}

.icon-spider-chart-sm:before {
    content: '\e91e';
}

.icon-details:before {
    content: '\e91f';
}

.icon-foot:before {
    content: '\e920';
}

.icon-progress:before {
    content: '\e921';
}

.icon-hand:before {
    content: '\e922';
}

.icon-heart:before {
    content: '\e923';
}

.icon-workflow:before {
    content: '\e924';
}

.icon-data-entry:before {
    content: '\e925';
}

.icon-health-and-fitness:before {
    content: '\e926';
}

.icon-analytics:before {
    content: '\e927';
}

.icon-count-patients-data:before {
    content: '\e928';
}

.icon-inpatient:before {
    content: '\e929';
}

.icon-indent-left:before {
    content: '\e92a';
}

.icon-indent-right:before {
    content: '\e92b';
}

.icon-complete-not-verified:before {
    content: '\e92c';
}

.icon-complete-verified:before {
    content: '\e92d';
}

.icon-registry-circle:before {
    content: '\e92e';
}

.icon-registry-multi:before {
    content: '\e92f';
}

.icon-incomplete:before {
    content: '\e930';
}

.icon-adults:before {
    content: '\e931';
}

.icon-assessments:before {
    content: '\e932';
}

.icon-carers:before {
    content: '\e933';
}

.icon-outpatient:before {
    content: '\e934';
}

.icon-close-eye:before {
    content: '\e935';
}

.icon-nose:before {
    content: '\e936';
}

.icon-ras:before {
    content: '\e937';
}

.icon-oxygen:before {
    content: '\e938';
}

.icon-poison:before {
    content: '\e939';
}

.icon-back-bracket:before {
    content: '\e93a';
}

.icon-front-bracket:before {
    content: '\e93b';
}

.icon-prescription:before {
    content: '\e93c';
}

.icon-my-data-overview:before {
    content: '\e93d';
}

.icon-equals:before {
    content: '\e93e';
}

.icon-mediator-edw:before {
    content: '\e93f';
}

.icon-error:before {
    content: '\e940';
}

.icon-mediator-request:before {
    content: '\e941';
}

.icon-definition:before {
    content: '\e942';
}

.icon-venn:before {
    content: '\e943';
}

.icon-my-data-preferences:before {
    content: '\e944';
}

.icon-mediator-success:before {
    content: '\e945';
}

.icon-alcohol:before {
    content: '\e946';
}

.icon-abacus:before {
    content: '\e947';
}

.icon-under-18:before {
    content: '\e948';
}

.icon-couple---heterosexual:before {
    content: '\e949';
}

.icon-couple---homosexual---female:before {
    content: '\e94a';
}

.icon-couple---homosexual---male:before {
    content: '\e94b';
}

.icon-elderly---badge:before {
    content: '\e94c';
}

.icon-figure---female---young:before {
    content: '\e94d';
}

.icon-figure---female---elderly:before {
    content: '\e94e';
}

.icon-figure---female:before {
    content: '\e94f';
}

.icon-figure---male---elderly:before {
    content: '\e950';
}

.icon-figure---male---young:before {
    content: '\e951';
}

.icon-figure:before {
    content: '\e952';
}

.icon-figure---male:before {
    content: '\e953';
}

.icon-greater-than:before {
    content: '\e954';
}

.icon-greater-than-or-equal-to:before {
    content: '\e955';
}

.icon-less-than:before {
    content: '\e956';
}

.icon-less-than-or-equal-to:before {
    content: '\e957';
}

.icon-multiply:before {
    content: '\e958';
}

.icon-not:before {
    content: '\e959';
}

.icon-not-equal-to:before {
    content: '\e95a';
}

.icon-number:before {
    content: '\e95b';
}

.icon-n:before {
    content: '\e95c';
}

.icon-calculator-alt:before,
.icon-operator-selector:before {
    content: '\e95d';
}

.icon-or:before {
    content: '\e95e';
}

.icon-power:before {
    content: '\e95f';
}

.icon-list-question:before {
    content: '\e960';
}

.icon-round-up-down:before {
    content: '\e961';
}

.icon-square-root:before {
    content: '\e962';
}

.icon-subtraction:before {
    content: '\e963';
}

.icon-sum:before {
    content: '\e964';
}

.icon-patient-level-data:before {
    content: '\e965';
}

.icon-encounter-level-data:before {
    content: '\e966';
}

.icon-files:before {
    content: '\e967';
}

.icon-tour:before {
    content: '\e968';
}

.icon-emo-happy:before {
    content: '\e969';
}

.icon-emo-unhappy:before {
    content: '\e96a';
}

.icon-file-csv:before {
    content: '\e96e';
}

.icon-upload:before {
    content: '\e96f';
}

.icon-draggable:before {
    content: '\e970';
}

.icon-knowledge-base:before {
    content: '\e971';
}

.icon-cloud:before {
    content: '\e972';
}

.icon-excel:before {
    content: '\e973';
}

.icon-my-data-data:before {
    content: '\e974';
}

.icon-my-data-encounters:before {
    content: '\e975';
}

.icon-my-data-data-shield:before {
    content: '\e976';
}

.icon-my-data-encounters-shield:before {
    content: '\e977';
}

.icon-my-data-patients:before {
    content: '\e978';
}

.icon-my-data-patients-shield:before {
    content: '\e979';
}

.icon-my-data-sheild-background:before {
    content: '\e97a';
}

.icon-visibility:before {
    content: '\e97b';
}

.icon-my-data-preferences-shield:before {
    content: '\e97c';
}

.icon-success:before {
    content: '\e97d';
}

.icon-sad-face:before {
    content: '\e97e';
}

.icon-subscription-team:before {
    content: '\e97f';
}

.icon-count-patient-by-speciality:before {
    content: '\e981';
}

.icon-uk-map:before {
    content: '\e982';
}

.icon-us-map:before {
    content: '\e983';
}

.icon-info:before {
    content: '\e984';
}

.icon-quality:before {
    content: '\e985';
}

.icon-standard-deviation:before {
    content: '\e9b1';
}

.icon-minimum:before {
    content: '\e9b2';
}

.icon-mean:before {
    content: '\e9b3';
}

.icon-maximum:before {
    content: '\e9b4';
}

.icon-data-view:before {
    content: '\e9b5';
}

.icon-count-latest-indexed-list-answer:before {
    content: '\eb00';
}


.icon-pause:before {
    content: '\e986';
}

.icon-link:before {
    content: '\e987';
}

.icon-play:before {
    content: '\e988';
}

.icon-plugin:before {
    content: '\e989';
}

.icon-areas:before {
    content: '\e98a';
}

.icon-model:before {
    content: '\e98b';
}
