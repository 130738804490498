﻿@import (reference) "../colorPalette.less";

.coding-picker-wrapper {
    .input-group-btn:first-child > .btn {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-btn + .form-control {
        border-left: 1px solid @color-data-entry-button-background;
    }
}

.code-picker {
    .no-select;
    .border-radius(4px);
    border: 1px solid @color-data-entry-button-border;
    height: 282px;
    margin: 3px 0 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px 0;

    li {
        > div {
            padding: 5px;
            cursor: pointer;

            &.disabled,
            &.selected {
                cursor: default;
            }
        }

        > ul.children {
            margin: 0 0 0 20px;
            padding: 0;
            list-style-type: none;
            display: none;
        }

        &.show-children {
            > ul.children {
                display: block;
            }
        }
    }

    .label {
        display: inline-block;
        position: relative;
        top: -1px;
        min-width: 60px;
        padding: 4px 6px;

        &.label-primary {
            margin-left: 0px;

            &.last-child {
                margin-left: 15px;
            }
        }
    }

    .sk-spinner {
        margin: 2px 5px 0 0;
    }

    .diagnosis-detail,
    .diagnosis-name {
        display: table-cell;
    }

    .diagnosis-detail {
        padding-right: 4px;
        white-space: nowrap;
    }
}

.has-error {
    .code-picker,
    .input-group-btn .btn {
        border: solid 1px @color-has-error;
        box-shadow: 0 0 6px @color-has-error;
        opacity: 1;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .code-picker {
            li {
                > div {
                    &.selected,
                    &.selectable.selected:hover {
                        .border-radius(3px);
                        background: lighten(@theme-color, 30%);
                    }

                    &.selectable:hover {
                        background: @color-extra-light-grey;
                    }

                    &.disabled {
                        .label-primary {
                            background: @color-medium-grey;
                        }

                        &:hover {
                            background: none;
                        }
                    }
                }
            }

            .cp-icon-caret-right,
            .cp-icon-caret-down {
                color: @color-body!important;
            }

            .label {
                &.label-default {
                    background-color: fade(@theme-color, 40%);
                    display: inline-block;
                    min-width: 60px;
                    padding: 4px 6px;
                }
            }
        }
    }
}
