﻿@import (reference) "../colorPalette.less";
// Attachments / Drag & Drop

.AdditionalQuestionsSection {
    .filedrag {
        background-color: lighten(@color-extra-light-grey, 2%);
        border: 2px dashed darken(@color-medium-grey, 4%);
    }
}

.filedrag {
    .border-radius-sm;
    background-color: @color-data-entry-shaded-area;
    border: none;
    margin: 0;
    min-height: 70px;
    padding: 15px;
    transition: all 0.15s ease;
    width: 100%;

    .icon-container {
        width: 50px;
        display: inline-block;
    }

    .clearfix {
        > div {
            float: left;
        }
    }

    .clearfix:after {
        content: " ";
        display: block;
        height: 0;
        clear: both;
    }

    .imageholder {
        position: relative;
        height: 50px;
        width: 50px;

        div {
            display: table;
            margin: 0 auto;
            width: 25px;
        }

        img {
            cursor: pointer;
            position: absolute;
            top: 0;
        }

        .thumb {
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }
    }

    .drag-instructions {
        .text-ellipsis;
        display: inline-block;
        line-height: 1.15rem;
        margin-bottom: 0;
        max-width: 80%;
        position: relative;

        &.attached-file {
            top: 12px;
        }
    }

    .file-size {
        color: @color-medium-grey;
    }

    .btn-file {
        background-color: @color-data-entry-button-background;
        cursor: pointer;
        float: right;
        margin-top: 5px;
        vertical-align: top;
        transition: background-color linear 0.1s;
        padding: 0 15px;
        line-height: 32px;
        font-size: 12px;
        border-radius: 16px;

        input[type="file"] {
            cursor: pointer;
        }

        &:hover {
            background-color: @color-data-entry-button-border;
        }
    }

    .change-file,
    .delete-file {
        margin-top: 0px;
    }

    .sk-spinner {
        display: inline-block;

        .loading-message {
            .text-sm;
            .text-muted;
            background-color: transparent;
            margin-top: 3px;
            margin-left: 28px;
            position: static;
            text-align: left;
        }
    }

    &.hover {
        border: 2px dashed lighten(@color-blue, 16%);
        background-color: lighten(@color-blue, 46%);
        box-shadow: none;
    }

    &.disabled {
        pointer-events: none;
    }
}

.attachment-name {
    .text-ellipsis;
    float: left;
    width: 90%;
    word-break: break-word;
}

.attachment {
    .border-radius-sm;
    border: solid 1px darken(@color-extra-light-grey, 4%);
    margin-top: 2px;
    overflow: hidden;
    padding: 5px 8px;

    .cp-icon-paperclip {
        float: left;
        margin-top: 2px;
        margin-right: 5px;
    }
}

.attachment-details {
    .text-sm;
    .text-muted;
    display: block;
}

// Validation
.has-error {
    .filedrag {
        background-color: fade(@color-danger, 6%);
        border-color: lighten(@color-danger, 6%);

        .btn,
        .btn.btn-default {
            box-shadow: none;
        }

        .drag-instructions {
            color: lighten(@color-danger, 6%);
        }
    }
}

.current-attachment {
    display: flex;
    flex-direction: row;

    .icon-container {
        flex-basis: 50px;
        flex-grow: 0;
    }

    .delete-file-container {
        flex-basis: 100px;
        flex-grow: 0;
    }

    .instructions-container {
        flex-grow: 1;
        width: 0;
    }
}
