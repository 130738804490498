﻿@import (reference) "../colorPalette.less";

.full-width,
.max-width {
    .container,
    &.container {
        max-width: none !important;
    }

    .nav-tabs-full-width-wrapper {
        .nav {
            max-width: none !important;
        }
    }

    .event-dd {
        .dropdown-section {
            width: calc(100% - 50px);
        }

        .dropdown-menu {
            width: calc(100% - 50px);
        }
    }

    .btn {
        &.back-to-top {
            &.fixed {
                margin-left: -23px;
                z-index: 9999;

                @media screen and (max-width: 1024px) {
                    margin-left: -5px;
                }
            }
        }
    }

    .tab-pane {
        &.active {
            //max-height: 550px;
        }
    }

    .focus-area {
        float: left;
        position: relative;
        width: calc(100% - 400px);
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        @media screen and (max-width: 1024px) {
            width: 100%;
        }

        &.collapsed-sidebar {
            width: calc(100% - 50px);

            @media screen and (max-width: 1024px) {
                width: calc(100% - 65px);
            }
        }
    }

    .side-panel {
        top: 0;
        right: 0;
        width: 400px;
        position: relative;
        min-height: 1px;
        padding: 0;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            background: @color-white;
            bottom: 0;
            height: 100vh;
            z-index: -1;
        }

        &.short-content {
            &:after {
                display: none;
            }
        }

        .collapse-sidebar-component {
            background-color: @color-white;
            border-top: 2px solid @color-main-background;
            bottom: 0;
            position: fixed;
            width: 100%;

            .text {
                font-family: "Sofia Pro";
                font-size: 1.2em;
                margin-left: 10px;
            }

            @media screen and (max-width: 1140px) {
                display: none;
            }
        }

        .btn.btn-collapsed-sidebar {
            width: 400px;
            height: 50px;
            border-radius: 0;
            padding: 0;
            background-color: @color-white;
            box-shadow: none;
            border: none;
            color: #494949;
            display: inline-flex;
            align-items: center;
            padding-left: 15px;

            .progress-bar-sidebar {
                height: 8px;
                width: 34px;
                margin-bottom: 0;
                border: none;
                position: relative;
                border: 2px solid #494949;

                .progress-bar {
                    background-color: #494949;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
            }

            h4 {
                color: @color-medium-grey;
                padding-left: 10px;
            }

            &:hover,
            &:focus {
                background-color: #f6f7f8;
                color: @color-blue;

                .progress-bar-sidebar {
                    border-color: @color-blue;

                    .progress-bar {
                        background-color: @color-blue;
                    }
                }
            }
        }

        &.collapsed {
            width: 50px;
            float: right;

            .sidebar-container .fixed-sidebar {
                width: 50px !important;

                &.sticky {
                    margin-top: 4px;
                    z-index: 9999;
                }
            }

            .btn.btn-collapsed-sidebar {
                width: 50px;
                justify-content: center;
                padding-left: 0;
            }
        }

        .fixed-sidebar {
            width: 400px;
        }
    }

    .hover-menu {
        .hover-menu-content {
            position: absolute;
            display: none;
            min-width: 400px;
            right: 50px;
            border: 2px solid @color-main-background;
            background-color: @color-white;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
            padding: 10px 0;
            z-index: 99999;

            h3 {
                color: #3a3a3a;
                margin-bottom: 10px;
            }

            .progress-bar-info {
                width: 100%;
            }

            .list-item .link-item {
                margin-left: -15px;
                margin-right: -15px;
            }

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 0 8px 8px;
                border-color: transparent transparent transparent @color-white;
                position: absolute;
                top: 18px;
                right: -8px;
            }

            &.hover-menu-event-dropdown,
            &.hover-menu-state-component {
                padding: 0;
            }

            &.hover-menu-details {
                margin-top: -52px;

                &::after {
                    margin-top: 52px;
                }
            }

            .event-dd {
                height: 50px;

                .btn {
                    height: 50px;
                    margin-bottom: 0 !important;

                    &.remove-section {
                        padding: 10px;
                    }
                }
            }

            .state-component-buttons {
                margin-bottom: 0 !important;
            }
        }

        &:nth-child(1) .hover-menu-content {
            top: -2px;
        }

        &:nth-child(2) .hover-menu-content {
            top: 50px;
        }

        &:nth-child(3) .hover-menu-content {
            top: 102px;
        }

        &:nth-child(4) .hover-menu-content {
            top: 154px;
        }

        &:nth-child(5) .hover-menu-content {
            top: 206px;
        }

        &:nth-child(6) .hover-menu-content {
            top: 258px;
        }

        &:hover,
        &:focus {
            .btn {
                background-color: #f6f7f8;
                color: @color-blue;
            }

            .hover-menu-content {
                display: block;
            }
        }
    }

    .edit-form-row {
        margin-right: -30px;
    }

    .page-header {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1024px) {
    .full-width,
    .max-width {
        overflow-x: hidden;
        .page-activities {
            .side-panel {
                right: 20px;
            }
        }
    }
}
