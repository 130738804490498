﻿.sk-spinner {
    &.sk-spinner-double-bounce.sk-spinner {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 0 auto;
    }

    &.sk-spinner-double-bounce .sk-double-bounce1,
    &.sk-spinner-double-bounce .sk-double-bounce2 {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: @color-blue;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
        animation: sk-doubleBounce 2s infinite ease-in-out;
    }

    &.sk-spinner-double-bounce .sk-double-bounce2 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .title {
        clear: both;
        margin: 0 0 0 -80px;
        position: relative;
        top: 40px;
        text-align: center;
        white-space: nowrap;
        width: 200px;

        &.sm-container {
            margin: 0 0 0 -15px !important;
            width: auto !important;
        }
    }

    .loading-message {
        background-color: @color-white;
        clear: both;
        margin: 0 0 0 -80px;
        position: relative;
        top: 40px;
        text-align: center;
        white-space: nowrap;
        width: 200px;

        &.sm-container {
            margin: 0 0 0 -15px !important;
            width: auto !important;
        }
    }

    &.spinner-sm {
        &.sk-spinner-double-bounce.sk-spinner {
            width: 24px;
            height: 24px;
        }
    }

    &.sk-spinner.spinner-inline {
        margin: 0;

        .loading-message {
            left: 0;
            margin: 0;
            padding: 2px 0 0 28px;
            position: static;
            top: 0;
            text-align: left;
        }
    }
}

.spinner-xs {
    .sk-spinner-double-bounce.sk-spinner {
        width: 16px;
        height: 16px;
    }
}

.page-spinner {
    .sk-spinner-double-bounce.sk-spinner {
        margin-top: 5em;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .sk-spinner {
            &.sk-spinner-double-bounce .sk-double-bounce1,
            &.sk-spinner-double-bounce .sk-double-bounce2 {
                background-color: @theme-color;
            }
        }
    }

    .sk-spinner.theme-@{name} {
        &.sk-spinner-double-bounce .sk-double-bounce1,
        &.sk-spinner-double-bounce .sk-double-bounce2 {
            background-color: @theme-color;
        }
    }
}

@-webkit-keyframes sk-doubleBounce {
    0%,
    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-doubleBounce {
    0%,
    100% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
