﻿@import (reference) "../colorPalette.less";
// Fieldset LESS
@unit: 5px;

fieldset {
    background-color: @color-white;
    margin: 0 0 @unit*3;
    padding: @unit*10 @unit*4 @unit*3;
    position: relative;

    legend {
        border: none;
        color: @color-white;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        padding: 7px 40px 8px 15px;

        display: block;
        font-size: 18px;
        font-family: "Sofia Pro";
        margin-left: -20px;
    }

    .changed {
        background: fade(@color-blue, 22%);
        border-bottom-color: @color-blue;
    }

    .history-form-date {
        position: relative;
        top: -@unit*2;
    }

    &.new-activitiy-fieldset {
        padding-bottom: 20px;

        .form-group:last-of-type {
            margin-bottom: 0;
        }
    }
}
.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        legend {
            background-color: @theme-color;
        }
    }
}

body.admin {
    fieldset {
        border: 1px solid @color-light-grey;
    }

    .account-modal-content fieldset {
        border: none;

        legend {
            font-size: 15px;
        }
    }
}
