﻿// Table Controls
.table-controls {
    padding-bottom: 15px;

    .pagination {
        // Paging controls have an invisible bottom margin, expand filters to see
        margin-bottom: -6px;
        background-color: @color-white;
    }

    &:after {
        clear: both;
        content: "";
        display: table;
    }
}
