﻿/* Animations LESS */
@keyframes fade {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    75% {
        opacity: 0.5;
        transform: scale(1.32);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.shake {
    animation: shake 0.85s cubic-bezier(0.28, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-6px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(6px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-10px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(6px, 0, 0);
    }
}

.fade-in {
    transition: opacity 0.5s ease-in;
}

/* ~ Sliding Tabs
==================================================*/
.nav-tabs {
    &.tabs-borderless {
        .gu-sliding-bar {
            background: @color-blue;
            transition: all 0.15s ease;
            -webkit-transform: translate3d(
                0,
                0,
                0
            ); //Makes transition hardware accelerated
        }
    }
}

/* ~ Icons
==================================================*/
[class^="cp-icon-"] {
    &.rotate {
        display: inline-block;
        transition: all 0.1s linear;
    }

    &.collapsed {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

.collapsed {
    .rotate {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

// Progress Bar
@keyframes progress-bar-stripes-custom {
    from {
        background-position: 40px 0;
    }

    to {
        background-position: 0 0;
    }
}

// Vue Component Animations
