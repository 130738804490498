﻿@import (reference) "../colorPalette.less";

.btn-shadow(@shadow-color) {
    box-shadow: inset 1px 1px 4px -1px @shadow-color;
}

.btn-toggle {
    line-height: 1em;
    margin-bottom: 8px;

    .btn-toggle-option-text {
        vertical-align: top;
        margin-right: 8px;

        .help-component {
            top: -6px;
            position: relative;
        }
    }

    .btn-toggle-text {
        display: inline-block;
        cursor: pointer;
        vertical-align: top;

        &:first-of-type {
            margin-right: 4px;
        }

        &:last-of-type {
            line-height: 1.4em;
            margin-left: 4px;
        }

        &.as-heading {
            font-size: 1.2em;
            line-height: 1.2em;
            line-height: 1.2em;
        }
    }

    .btn-toggle-control {
        & + .btn-toggle-label {
            background: @color-medium-grey; //MW to do//
            border-radius: 20px;
            cursor: pointer;
            display: inline-block;
            height: 20px;
            outline: none;
            position: relative;
            padding: 1px;
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: bottom;
            width: 40px;
            overflow: hidden;

            &:after {
                background: @color-light-grey;
                border-radius: 50%;
                box-shadow: 0;
                content: "";
                display: block;
                height: 14px;
                left: 2px;
                position: relative;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
                width: 14px;
                margin-top: -12px;
            }

            &:before {
                content: "\f00d";
                font-family: "FontAwesome";
                color: @color-white;
                font-size: 14px;
                right: -22px;
                top: 1px;
                position: relative;
            }
        }

        &:checked {
            & + .btn-toggle-label {
                background: @color-blue;

                &:after {
                    left: 22px;
                    margin-top: -13px;
                }

                &:before {
                    color: @color-white;
                    content: "\E865";
                    font-family: "ImosphereIcons";
                    position: relative;
                    font-size: 12px;
                    right: -6px;
                    top: 1px;
                }
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        width: 0;
        height: 0;
        left: -99999px;
        position: relative;
    }
}

.btn {
    border-radius: 17px;
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    line-height: 32px;
    padding: 0 9px;
    transition: background-color linear 0.1s;
    border-width: 2px;
    border-style: solid;

    &:hover,
    &:focus,
    &.focus {
        text-decoration: none;
    }

    &:focus,
    &:active:focus,
    &.active:focus,
    &.focus,
    &:active.focus,
    &.active.focus {
        outline: none;
    }

    .sk-spinner.sk-spinner-double-bounce.sk-spinner {
        height: 23px;
        width: 23px;
    }

    &.btn-lg {
        border-radius: 24px;
        font-size: 1.26666666rem;
        height: 48px;
        line-height: 48px;
        padding: 0 20px;

        .sk-spinner.sk-spinner-double-bounce.sk-spinner {
            height: 26px;
            width: 26px;
        }
    }

    &.btn-sm {
        font-size: 0.8667rem;
    }

    &.btn-xs {
        border-radius: 12px;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        padding: 0 9px;
    }

    &.btn-warning {
        background-color: @color-white;
        border-color: #ccc;
        color: @color-black;

        &:focus,
        &.focus,
        &:hover {
            border-color: @color-red;
            color: @color-red;

            .sk-spinner {
                &.sk-spinner-double-bounce .sk-double-bounce1,
                &.sk-spinner-double-bounce .sk-double-bounce2 {
                    background-color: @color-white;
                }
            }
        }

        &:active,
        &.active {
            .btn-shadow(mix(@color-red, black, 50%));
            background-color: mix(@color-red, black, 90%);
            border-color: (mix(@color-red, black, 70%));
            color: @color-white;
        }

        &:active:hover,
        &.active:hover,
        &:active:focus,
        &.active:focus,
        &:active.focus,
        &.active.focus {
            .btn-shadow(mix(@color-red, black, 70%));
            background-color: @color-red;
            border-color: (mix(@color-red, black, 90%));
            color: @color-white;
        }

        &.disabled,
        &[disabled],
        &.disabled:hover,
        &[disabled]:hover,
        &.disabled:focus,
        &[disabled]:focus,
        &.disabled.focus,
        &[disabled].focus,
        &.disabled:active,
        &[disabled]:active,
        &.disabled.active,
        &[disabled].active {
            .btn-shadow(transparent);
            background-color: @color-white;
            border-color: @color-red;
            color: @color-red;
            cursor: auto;
        }

        .sk-spinner {
            &.sk-spinner-double-bounce .sk-double-bounce1,
            &.sk-spinner-double-bounce .sk-double-bounce2 {
                background-color: @color-red;
            }
        }
    }

    &.btn-danger {
        background-color: @color-red;
        border-color: @color-red;
        color: @color-white;

        &:focus,
        &.focus,
        &:hover {
            background: @color-white;
            border-color: @color-red;
            color: @color-red;

            .sk-spinner {
                &.sk-spinner-double-bounce .sk-double-bounce1,
                &.sk-spinner-double-bounce .sk-double-bounce2 {
                    background-color: @color-white;
                }
            }
        }

        &:active,
        &.active {
            .btn-shadow(mix(@color-red, black, 50%));
            background-color: mix(@color-red, black, 90%);
            border-color: (mix(@color-red, black, 70%));
            color: @color-white;
        }

        &:active:hover,
        &.active:hover,
        &:active:focus,
        &.active:focus,
        &:active.focus,
        &.active.focus {
            .btn-shadow(mix(@color-red, black, 70%));
            background-color: @color-red;
            border-color: (mix(@color-red, black, 90%));
            color: @color-white;
        }

        &.disabled,
        &[disabled],
        &.disabled:hover,
        &[disabled]:hover,
        &.disabled:focus,
        &[disabled]:focus,
        &.disabled.focus,
        &[disabled].focus,
        &.disabled:active,
        &[disabled]:active,
        &.disabled.active,
        &[disabled].active {
            .btn-shadow(transparent);
            background-color: @color-white;
            border-color: @color-red;
            color: @color-red;
            cursor: auto;
        }

        .sk-spinner {
            &.sk-spinner-double-bounce .sk-double-bounce1,
            &.sk-spinner-double-bounce .sk-double-bounce2 {
                background-color: @color-red;
            }
        }
    }

    &.btn-primary.btn-danger {
        background-color: @color-red;
        border-color: @color-red;
        color: @color-white;

        &:hover,
        &:focus {
            background-color: lighten(@color-red, 10%);
        }

        &.disabled,
        &[disabled],
        &.disabled:hover,
        &[disabled]:hover,
        &.disabled:focus,
        &[disabled]:focus,
        &.disabled.focus,
        &[disabled].focus,
        &.disabled:active,
        &[disabled]:active,
        &.disabled.active,
        &[disabled].active {
            background-color: @color-red;
            border-color: @color-red;
            color: @color-white;
            cursor: auto;
        }
    }

    &.btn-action {
        border-color: @color-warning;
    }

    &.btn-link {
        border: none;
        padding: 0;
        color: lighten(@color-blue, 2%);
        transition: color linear 0.2s;

        &:not([disabled]) {
            &:focus,
            &.focus,
            &:hover {
                text-decoration: underline;
                color: @color-black;
            }
        }

        &.btn-new-subform {
            &:hover,
            &:focus {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    &.btn-show-details {
        color: @color-link;

        &.collapsed:before {
            content: "Show details";
            display: block;
        }

        &:before {
            content: "Hide details";
        }
    }

    &.btn-icon {
        border-radius: 50%;
        padding: 0;
        text-align: center;
        width: 34px;
    }

    &:disabled,
    &.disabled {
        cursor: default;
        /*&:hover,
        &:focus,
        &.focus {
            background-color: transparent;
        }*/
    }

    &.print-contact-record {
        @media only screen and (max-width: @screen-md-min) {
            float: right;
        }
    }

    &.back-to-top {
        background-color: #ddd;
        border-radius: 50%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
        font-size: 1.6em;
        height: 48px;
        line-height: 48px;
        padding: 0;
        text-align: center;
        transition: background-color linear 0.1s;
        width: 48px;

        &.fixed {
            bottom: 50%;
            margin-left: -62px;
            position: fixed;
            display: none;

            &.shown {
                display: block;
                animation: fadeIn linear 0.3s;
            }
        }

        @media only screen and (max-width: @screen-xl-min) {
            &.back-to-top {
                height: 32px;
                width: 32px;
                line-height: 32px;
                font-size: 1.1em;

                &.fixed {
                    margin-left: -22px;
                }
            }
        }
    }

    &.btn-as-h2 {
        font: 600 @font-size-h2 / @line-height-h2 @heading-font-family;
        padding: 0;
        background-color: transparent;
        transition: color linear 0.1s;

        &:active,
        &:focus {
            box-shadow: none;
        }

        .imo {
            font-size: 0.8em;
        }
    }
}

.guidance-toggle {
    background: transparent;
    padding: 0;
    border: 0;
    font-size: 16px;
    transition: color linear 0.2s;
    color: @color-blue;
    cursor: pointer;

    &:hover,
    &:focus {
        color: darken(@color-blue, 20);
        outline: none;
    }

    &.collapsed {
        color: @color-blue;

        &:hover,
        &:focus {
            color: darken(@color-blue, 20);
            outline: none;
        }
    }
}

.btn {
    &.btn-text {
        line-height: inherit;
        padding: 0;
        margin: 0;
        border-radius: 0;
        height: inherit;
        background: transparent;

        &:focus,
        &:hover {
            box-shadow: none;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .btn {
            &.btn-text {
                &:hover,
                &:focus {
                    color: @theme-color;
                }
            }

            &.add-note,
            &.btn-view-history {
                color: @color-body;

                &.focus,
                &:focus,
                &:hover {
                    background-color: transparent;
                    color: @color-link;
                }

                &.disabled,
                &:disabled {
                    color: @color-body;
                }
            }

            &.add-note {
                margin-top: 0.125em;
                margin-left: -5px;
            }

            &.has-note {
                color: @color-link;
            }

            &.btn-key-item,
            &.btn-alert {
                color: @color-body;

                &:focus {
                    background-color: transparent;
                }

                &:hover {
                    background-color: transparent;
                    border: solid 1px @color-light-grey;
                    color: @color-link;
                }

                &:disabled {
                    &:hover {
                        background-color: transparent;
                        border: solid 1px @color-medium-grey;
                        color: @color-body;
                    }
                }

                &.active {
                    .btn-shadow(transparent);
                    background-color: @color-blue;
                    color: @color-white;

                    &:hover {
                        background-color: @color-blue;
                        border-color: @color-blue;
                        color: @color-white;

                        &:disabled {
                            border: solid 1px @color-medium-grey;
                            background-color: @color-blue;
                            color: @color-panel-background;
                        }
                    }

                    &:active {
                        .btn-shadow(mix(@color-blue, black, 45%));
                        background-color: mix(@color-blue, black, 90%);
                    }
                }
            }

            &.btn-key-item,
            &.btn-alert,
            &.btn-view-history {
                border: solid 1px @color-medium-grey;
                background-color: @color-panel-background;
            }

            &.btn-print-history {
                margin-right: 4px;
            }

            &.btn-show-more {
                color: @theme-color;

                &.collapsed:before {
                    content: "Show More";
                }

                &:before {
                    content: "Show Less";
                }
            }

            &.btn-summary-quick-link {
                background-color: @color-blue;
                border-color: @color-blue;
                color: @color-white;

                &.align-btn {
                    margin-left: 155px;
                    margin-top: -90px;
                    z-index: 2;
                }

                &:focus,
                &.focus,
                &:hover {
                    background-color: mix(@color-blue, black, 90%);
                    border-color: mix(@color-blue, black, 90%);
                    color: @color-white;
                }

                &:active,
                &.active {
                    .btn-shadow(mix(@color-blue, black, 45%));
                    background-color: mix(@color-blue, black, 90%);
                    border-color: mix(@color-blue, black, 70%);
                }

                &:active:hover,
                &.active:hover,
                &:active:focus,
                &.active:focus,
                &:active.focus,
                &.active.focus {
                    .btn-shadow(mix(@color-blue, black, 65%));
                    background-color: mix(@color-blue, black, 95%);
                    border-color: mix(@color-blue, black, 90%);
                }

                &.disabled,
                &[disabled],
                &.disabled:hover,
                &[disabled]:hover,
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled.focus,
                &[disabled].focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled.active,
                &[disabled].active {
                    .btn-shadow(transparent);
                    background-color: @color-blue;
                    border-color: @color-blue;
                    cursor: auto;
                }

                .sk-spinner {
                    &.sk-spinner-double-bounce .sk-double-bounce1,
                    &.sk-spinner-double-bounce .sk-double-bounce2 {
                        background-color: @color-white;
                    }
                }
            }

            &.btn-summary-trigger {
                background-color: #8f9397;
                border-color: #8f9397;
                color: @color-white;

                &.align-btn {
                    margin-left: 155px;
                    margin-top: -90px;
                    z-index: 2;
                }

                &:focus,
                &.focus,
                &:hover {
                    background-color: mix(#8f9397, black, 90%);
                    border-color: mix(#8f9397, black, 90%);
                    color: @color-white;
                }

                &:active,
                &.active {
                    .btn-shadow(mix(#8f9397, black, 45%));
                    background-color: mix(#8f9397, black, 90%);
                    border-color: mix(#8f9397, black, 70%);
                }

                &:active:hover,
                &.active:hover,
                &:active:focus,
                &.active:focus,
                &:active.focus,
                &.active.focus {
                    .btn-shadow(mix(#8f9397, black, 65%));
                    background-color: mix(#8f9397, black, 95%);
                    border-color: mix(#8f9397, black, 90%);
                }

                &.disabled,
                &[disabled],
                &.disabled:hover,
                &[disabled]:hover,
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled.focus,
                &[disabled].focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled.active,
                &[disabled].active {
                    .btn-shadow(transparent);
                    background-color: #8f9397;
                    border-color: #8f9397;
                    cursor: auto;
                }

                .sk-spinner {
                    &.sk-spinner-double-bounce .sk-double-bounce1,
                    &.sk-spinner-double-bounce .sk-double-bounce2 {
                        background-color: @color-white;
                    }
                }
            }
            // Btn Primary
            &.btn-primary {
                background-color: @theme-color;
                border-color: @theme-color;

                &:focus,
                &.focus,
                &:hover {
                    background-color: mix(@theme-color, white, 90%);
                    /*border-color: mix(@theme-color, black, 80%);*/
                    color: @color-white;
                }

                &:active,
                &.active {
                    .btn-shadow(mix(@theme-color, black, 45%));
                    background-color: mix(@theme-color, black, 90%);
                    border-color: mix(@theme-color, black, 70%);
                }

                &:active:hover,
                &.active:hover,
                &:active:focus,
                &.active:focus,
                &:active.focus,
                &.active.focus {
                    .btn-shadow(mix(@theme-color, black, 65%));
                    background-color: mix(@theme-color, black, 95%);
                    border-color: mix(@theme-color, black, 90%);
                }

                &.disabled,
                &[disabled],
                &.disabled:hover,
                &[disabled]:hover,
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled.focus,
                &[disabled].focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled.active,
                &[disabled].active {
                    .btn-shadow(transparent);
                    background-color: @theme-color;
                    border-color: @theme-color;
                    cursor: auto;
                }

                .sk-spinner {
                    &.sk-spinner-double-bounce .sk-double-bounce1,
                    &.sk-spinner-double-bounce .sk-double-bounce2 {
                        background-color: @color-white;
                    }
                }
            }
            // Btn Default
            &.btn-default {
                background-color: @color-white;
                border-color: @color-medium-dark-grey;
                color: lighten(@color-body, 6%);

                &.alternate {
                    background-color: @color-data-entry-button-border;
                    border-color: @color-data-entry-button-border;

                    &:hover,
                    &:focus {
                        background-color: #e0e0e0;
                        border-color: @color-white;
                        color: @color-body;
                    }

                    &:active,
                    &.active {
                        background-color: @color-white;
                        color: @color-body;
                    }
                }

                &.white-border {
                    border-color: @color-white;
                }

                &:focus,
                &.focus,
                &:hover {
                    background-color: @color-white;
                    border-color: mix(@theme-color, white, 78%);
                    color: @theme-color;

                    [class^="cp-icon-"] {
                        color: inherit;
                    }
                }

                &:active,
                &.active {
                    .btn-shadow(mix(@theme-color, black, 65%));
                    background-color: mix(@theme-color, white, 65%);
                    border-color: @theme-color;
                    color: @color-body;

                    [class^="cp-icon-"] {
                        color: inherit;
                    }
                }

                &:active:hover,
                &.active:hover,
                &:active:focus,
                &.active:focus,
                &:active.focus,
                &.active.focus {
                    box-shadow: none;
                    background-color: mix(@theme-color, white, 70%);
                    border-color: @theme-color;
                    color: @color-white;

                    [class^="cp-icon-"] {
                        color: inherit;
                    }
                }

                &.disabled,
                &[disabled],
                &.disabled:hover,
                &[disabled]:hover,
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled.focus,
                &[disabled].focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled.active,
                &[disabled].active {
                    .btn-shadow(rgba(0,0,0,0));
                    background-color: @color-white;
                    border-color: @color-light-grey;
                    color: lighten(@color-body, 6%);
                    cursor: auto;

                    [class^="cp-icon-"] {
                        color: lighten(@color-body, 6%);
                    }
                }

                &[disabled].active.active-disabled {
                    background-color: mix(@theme-color, white, 65%);
                }
            }
            // Btn Themed
            &.btn-themed-border {
                background-color: @color-white;
                border-color: @theme-color;
                color: @theme-color;
                cursor: auto;

                &:focus,
                &.focus,
                &:hover {
                    background-color: mix(@theme-color, white, 90%);
                    border-color: mix(@theme-color, black, 80%);
                    color: @color-white;
                }

                &:active,
                &.active {
                    .btn-shadow(mix(@theme-color, black, 45%));
                    background-color: mix(@theme-color, black, 90%);
                    border-color: mix(@theme-color, black, 70%);
                }

                &:active:hover,
                &.active:hover,
                &:active:focus,
                &.active:focus,
                &:active.focus,
                &.active.focus {
                    .btn-shadow(mix(@theme-color, black, 65%));
                    background-color: mix(@theme-color, black, 95%);
                    border-color: mix(@theme-color, black, 90%);
                }

                &.disabled,
                &[disabled],
                &.disabled:hover,
                &[disabled]:hover,
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled.focus,
                &[disabled].focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled.active,
                &[disabled].active {
                    .btn-shadow(transparent);
                    background-color: @theme-color;
                    border-color: @theme-color;
                    cursor: auto;
                }

                .sk-spinner {
                    &.sk-spinner-double-bounce .sk-double-bounce1,
                    &.sk-spinner-double-bounce .sk-double-bounce2 {
                        background-color: @color-white;
                    }
                }
            }

            &.btn-action {
                border-color: @color-warning;
            }

            &.btn-fa {
                border-color: none;
                background: none;

                .fa-btn-alert {
                    color: @color-tint-danger;
                }
            }
            // Btn Pin
            &.btn-pin {
                &.active {
                    .btn-shadow(transparent);
                    background-color: @theme-color;
                    color: @color-white;

                    &.focus,
                    &:focus,
                    &:hover {
                        .btn-shadow(transparent);
                        background-color: mix(@theme-color, white, 90%);
                        border-color: mix(@theme-color, black, 70%);
                    }

                    &:active {
                        .btn-shadow(mix(@theme-color, black, 45%));
                        background-color: mix(@theme-color, black, 90%);
                    }
                }
            }

            &.back-to-top {
                background-color: @theme-color;
                color: @color-white;

                &:hover {
                    background-color: darken(@theme-color, 10%);
                }
            }

            &.btn-as-h2 {
                &:active,
                &:focus,
                &:hover {
                    color: @theme-color;
                }
            }
        }

        .btn-group {
            &.primary {
                background-color: @theme-color;
            }

            .btn {
                &.btn-default {
                    &:first-child:last-child {
                        &:hover,
                        &:focus {
                            background-color: @color-white;
                            color: @theme-color;
                        }
                    }
                }
            }

            &.no-padding {
                padding: 0;

                .btn {
                    height: 34px;
                    line-height: 34px;
                    border-radius: 17px;

                    &.btn-default {
                        &.btn-icon {
                            &:hover,
                            &:focus {
                                border-color: @color-blue;
                            }
                        }
                    }
                }
            }
        }
    }

    .input-group-btn {
        .btn {
            &.btn-default {
                background-color: @color-data-entry-button-border;
                border: none;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                color: @color-body;
                height: 32px;
                line-height: 32px;
                min-width: 32px;
                padding: 0 8px;
                text-align: center;
                transition: background-color linear 0.1s;

                &:hover,
                &:focus,
                &:active {
                    background-color: @select2-tertiary-color;
                    color: @color-body;
                }
            }
        }
    }

    .btn-toolbar {
        .btn {
            margin-left: 10px;
            &.btn-default {
                border: none;
            }
        }
    }
}

.text-truncate {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}

.event-dd {
    position: relative;

    .btn {
        &.dropdown-section {
            border-radius: 0px;
            padding: 7px;
            padding-left: 10px;
            padding-right: 10px;
            height: 47px;
            background-color: @color-white;
            border: none;
            margin-bottom: 10px !important;
            width: calc(100% - 50px);
            box-shadow: none;
            -webkit-box-shadow: none;

            .imo {
                font-size: 22px;
                color: @color-blue;
                padding-top: 5px;
            }

            &:hover {
                background-color: #f6f7f8;
            }
        }

        &.remove-section {
            border-radius: 0px;
            padding: 7px;
            padding-left: 10px;
            padding-right: 10px;
            height: 47px;
            background-color: @color-white;
            border: none;
            margin-bottom: 10px !important;
            width: 46px;

            .imo {
                font-size: 22px;
                color: @color-red;
                padding-top: 5px;
            }

            &.disabled {
                .imo {
                    color: #c1c1c1;
                }
            }

            &:hover {
                background-color: #f9f9f9;

                &.disabled {
                    background-color: @color-white;
                }
            }
        }
    }

    .dropdown-menu {
        padding: 0 !important;
        top: auto;
        margin-top: -11px;
        border: 1px solid @color-white;
        border-radius: 0px;
        width: calc(100% - 50px);
        box-shadow: 0 7px 8px rgba(0, 0, 0, 0.175);
        -webkit-box-shadow: 0 7px 8px rgba(0, 0, 0, 0.175);
        max-height: 300px;
        overflow-y: auto;

        li {
            a {
                padding: 10px;
            }
        }

        &.no-results {
            padding: 10px !important;
        }
    }
}

.btn-filter {
    &.active {
        background-color: @color-medium-grey;
        border: solid 2px @color-white;
        color: @color-white;
        padding-right: 15px;
    }

    .indicator {
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: @color-white;
        top: 4px;
        right: 6px;
        border-radius: 50%;
    }
}