﻿//Removes the ability to select any inline content within the element
.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//Forces text to not wrap when required
.no-wrap {
    white-space: nowrap;
}

.pre-wrap {
    white-space: pre-wrap;
}

// These two rules are to break long words/urls. First rule, .word-break, is required for IE bug
.word-break {
    word-wrap: break-word;
}

.text-break {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: normal;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

//Sets the placeholder text colour for the element
.placeholder-colour(@colour) {
    ::-webkit-input-placeholder {
        color: @colour;
    }

    ::-moz-placeholder {
        color: @colour;
    }

    :-ms-input-placeholder {
        color: @colour;
    }
}

//Truncate with ellipsis - width needs to be applied in CSS Rule
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//Removes IEs default clear button
.select2-input::-ms-clear {
    display: none;
}
.form-control::-ms-clear {
    display: none;
}
.remove-ie-clear {
    &::-ms-clear {
        display: none;
    }
}

//Clearfix
.clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//Sets the border radius for the element
.border-radius(@radius) {
    border-radius: @radius;
}

.border-radius-lg(@radius:12px) {
    border-radius: @radius;
}

.border-radius-sm(@radius:6px) {
    border-radius: @radius;
}

.border-radius-xs(@radius:3px) {
    border-radius: @radius;
}

.border-radiuses(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    border-top-left-radius: @topleft;
}

.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,0.25)) {
    box-shadow: @shadow;
}

//Sets inset shadow on buttons
.button-shadow(@shadow-color) {
    box-shadow: inset 1px 1px 4px -1px @shadow-color;
}

.btn-shadow(@shadow-color) {
    box-shadow: inset 1px 1px 4px -1px @shadow-color;
}

.color-tint-danger {
    color: @color-tint-danger;
}

// Background blur
.blurred-background(@opacity: 95%) {
    background: fade(@color-white, @opacity);

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.75) !important;
    }
}

.theme-mixin(default, @color-default);

mark {
    background-color: @color-search-highlight;
    color: @color-body;
    padding: 0;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

@unit: 5px;

/* Media Queries */
@screen-xs-min: 480px;
@screen-sm-min: 768px;
@screen-md-min: 992px;
@screen-lg-min: 1200px;
@screen-xl-min: 1366px;

//inline styles
.inline {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.margin-top-xs {
    margin-top: 4px !important;
}

.margin-top-s {
    margin-top: 8px !important;
}

.margin-top-m {
    margin-top: 16px !important;
}

.margin-top-l {
    margin-top: 32px !important;
}

.margin-top-xl {
    margin-top: 50px !important;
}

.margin-left-xs {
    margin-left: 4px !important;
}

.margin-left-s {
    margin-left: 8px !important;
}

.margin-left-m {
    margin-left: 16px !important;
}

.margin-left-l {
    margin-left: 32px !important;
}

.margin-left-xl {
    margin-left: 50px !important;
}

.margin-bottom-xs {
    margin-bottom: 4px !important;
}

.margin-bottom-s {
    margin-bottom: 8px !important;
}

.margin-bottom-m {
    margin-bottom: 16px !important;
}

.margin-bottom-l {
    margin-bottom: 32px !important;
}

.margin-bottom-xl {
    margin-bottom: 50px !important;
}

.margin-bottom-300 {
    margin-bottom: 300px !important;
}

.margin-right-xs {
    margin-right: 4px !important;
}

.margin-right-s {
    margin-right: 8px !important;
}

.margin-right-m {
    margin-right: 16px !important;
}

.margin-right-l {
    margin-right: 32px !important;
}

.margin-right-xl {
    margin-right: 50px !important;
}

.margin-top-none {
    margin-top: 0 !important;
}

.margin-right-none {
    margin-right: 0 !important;
}

.margin-bottom-none {
    margin-bottom: 0 !important;
}

.margin-left-none {
    margin-left: 0 !important;
}

.margin-none {
    margin: 0px !important;
}

.padding-right-none {
    padding-right: 0 !important;
}

.padding-left-none {
    padding-left: 0 !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-none {
    padding: 0 !important;
}

.padding-xs {
    padding: 4px !important;
}

.padding-s {
    padding: 8px !important;
}

.padding-m {
    padding: 16px !important;
}

.padding-l {
    padding: 32px !important;
}

.padding-left-xs {
    padding-left: 4px !important;
}

.padding-left-s {
    padding-left: 8px !important;
}

.padding-left-m {
    padding-left: 16px !important;
}

.padding-left-l {
    padding-left: 32px !important;
}

.padding-right-l {
    padding-right: 32px !important;
}

.mixin-fixed-height-container(300);
.mixin-fixed-height-container(400);
