﻿@import (reference) "../colorPalette.less";
// Select2 Lss
@unit: 5px;

.select2 {
    width: 100% !important;
}

.select2-container--default {
    .select2-selection--single {
        border: none;
        background-color: @select2-primary-color;
        border: 2px solid @select2-secondary-color;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
        padding: 5px 32px 5px 10px;
        transition: border-color linear 0.1s;

        &[aria-expanded="true"] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: 0 @unit @unit rgba(0, 0, 0, 0.2);

            &:hover {
                background-color: @select2-primary-color;
            }
        }

        &[aria-disabled="true"] {
            background-color: #eeeeee;
            cursor: not-allowed;
        }

        &:hover:not([aria-disabled="true"]),
        &:focus:not([aria-disabled="true"]) {
            background-color: @select2-primary-color;
        }

        &:focus {
            border-color: @select2-primary-color;
            outline: none;
        }

        .select2-selection__arrow {
            height: 32px;
            right: 0;
            top: 0;
            width: 32px;

            b {
                border: none;
                height: 32px;
                left: auto;
                margin: 0;
                position: absolute;
                right: 0px;
                text-align: center;
                top: 0px;
                width: 32px;

                &:before {
                    border: none;
                    content: "\e8cd";
                    font-family: ImosphereIcons;
                    font-size: 13px;
                    line-height: 32px;
                }
            }
        }

        .select2-selection__rendered {
            line-height: 21px;
            padding-left: 0;
            padding-right: 8px;
            color: @color-dark-grey;
        }

        .select2-selection__clear {
            position: relative;
            right: -8px;
            top: 0;
            width: 16px;
            height: 16px;
            position: relative;
            font-size: 0;

            &:after {
                font-family: "ImosphereIcons";
                content: "\e82d";
                display: block;
                font-size: 12px;
                color: @color-body;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 0;
                left: 0;

                &:hover {
                    color: lighten(@color-body, 10%);
                }
            }

            &:hover {
                background-position-x: -12px;
            }
        }

        .select2-selection__placeholder {
            visibility: hidden;
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border: none;

                    &:before {
                        content: "\e8d0";
                        font-family: ImosphereIcons;
                    }
                }
            }
        }
    }

    .select2-dropdown {
        border: none;

        &.select2-dropdown--below {
            background-color: @select2-primary-color;
            border: 2px solid @select2-secondary-color;
            border-top-color: transparent;
            box-shadow: 0 @unit @unit rgba(0, 0, 0, 0.2);
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            top: -2px;
        }

        &.select2-dropdown--above {
            background-color: @select2-primary-color;
            border-bottom: none;
            box-shadow: 0 -@unit 0 -@unit*2 rgba(0, 0, 0, 0.2), 0 -@unit @unit 0 rgba(0, 0, 0, 0.2);
        }
    }

    .select2-search--dropdown {
        padding: @unit;
        position: relative;

        .select2-search__field {
            .border-radius-xs;
            border: 2px solid @select2-secondary-color;
            height: 32px;
            padding: 5px 10px 5px 32px;

            &:focus {
                outline: none;
            }
        }

        &:before {
            content: "\e8e4";
            display: block;
            font-family: ImosphereIcons;
            font-size: 16px;
            height: 32px;
            left: 5px;
            line-height: 32px;
            position: absolute;
            text-align: center;
            width: 32px;
        }
    }

    .select2-results__option {
        .text-break;
        transition: background-color linear 0.1s;

        .badge {
            background-color: @color-light-grey;
            color: @color-body;
            float: right;
        }

        .read-only {
            &:after {
                font-size: 11px;
                font-weight: 400;
                padding: 0.2em 0.6em;
                font-family: FSMeWeb !important;
                content: "read only";
                background-color: @color-blue;
                color: @color-white;
                border-radius: 0.25em;
            }

            .wrap-under {
                display: block;
            }

            .text-answer-guidance {
                color: #999;
            }
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: @select2-secondary-color;
        color: @color-body;

        .badge {
            background-color: @color-white;
            color: @color-body;
            float: right;
        }
    }

    .select2-results__option[aria-selected="true"],
    .select2-results__option[aria-selected="true"]:hover {
        .badge {
            background-color: @color-light-grey;
        }
    }

    .contact-type {
        font-weight: 400;
        margin-left: 4px;
    }

    dd {
        margin-left: 2px;
        margin-right: 10px;
    }
}

//Fixes "jumping" animation of bootstrap collapse when Select2 dropdowns are present
.collapse,
.collapsing {
    &:not(.in) {
        .select2-dropdown {
            display: none;
        }
    }
}

.select2-results {
    padding: @unit;
}

.option.load-more {
    .text-sm;
    .text-muted;
    padding: 6px;
}

// Multiple Choice
.select2-container--default,
.select2-container--default.select2-container--focus {
    .select2-selection--multiple {
        box-shadow: none;
        background-color: @select2-primary-color;
        border: 2px solid @select2-secondary-color;
        transition: background-color linear 0.1s;

        .select2-selection__clear {
            color: @color-body;
            font-size: 2em;
            height: 12px;
            margin-right: 5px;
            position: relative;
            top: 1px;
            width: 12px;

            &:hover {
                color: lighten(@color-body, 10%);
            }
        }
    }

    .select2-selection--multiple .select2-selection__choice {
        background-color: @select2-secondary-color;
        font-size: 1rem;
        line-height: 1.6em;
        border: none;
        margin: 7px 5px 0 2px;
        padding: 0px 8px;

        &:hover {
            background-color: darken(@select2-tertiary-color, 5%);
        }
    }

    .select2-selection--multiple .select2-selection__choice__remove {
        position: relative;
        top: 1px;
        width: 12px;
        height: 12px;
    }

    .select2-container .select2-search--inline {
        clear: both;
    }
}

.select2-container .select2-search--inline {
    float: none;
}

// Answer Guidance
.text-answer-guidance {
    .text-sm;
    display: block;

    &.text-muted {
        color: lighten(@color-body, 30%);
    }
}

// Basic/no styling
.select2-basic {
    .select2-container--default .select2-selection--single {
        border: none;
        box-shadow: none;
        color: @color-link;
        height: inherit;
        padding: 0;
        margin: 0;
        margin-top: -1px;
        margin-left: -2px;
        padding-left: 0;
        padding-right: 8px;

        &:hover {
            background-color: @color-extra-light-grey;
        }

        .select2-selection__clear {
            display: none;
        }
    }

    .select2-search {
        display: none !important;
    }

    .select2-results {
        padding: 0;
    }

    .select2-dropdown.select2-dropdown--below {
        .border-radius(4px);
        border: 1px solid fade(@color-medium-grey, 60%);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        margin: 0;
        min-width: 320px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .select2-results__option {
        padding: 5px 15px;
    }

    .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
        padding-right: 20px;
    }

    .select2-container--default
        .select2-selection--single
        .select2-selection__arrow
        b {
        border: none;
        font-family: "care-partner-font" !important;
        color: @color-medium-grey;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        height: 0;
        left: auto;
        top: 0;
        margin: 0;
        text-transform: none;
        line-height: 1;
        speak: none;

        &:before {
            font-family: "ImosphereIcons";
            content: "\e8cd";
        }
    }

    .select2-container--default.select2-container--open
        .select2-selection--single
        .select2-selection__arrow
        b {
        &:before {
            content: "\e8d0";
        }
    }
}

.select2-config-terms {
    margin-top: -2px;

    .select2 {
        width: 150px !important;
    }

    .select2-container--default .select2-selection--single {
        margin: 0;
        background-color: transparent;

        .select2-selection__arrow {
            height: 18px;
            top: 0px;
        }

        &:hover {
            background: none;

            .select2-selection__arrow b {
                color: @color-body;
            }
        }

        .select2-selection__rendered {
            color: @color-blue;
            padding-bottom: 4px;

            &::after {
                background-color: @color-default;
                bottom: -4px;
                content: "";
                display: block;
                height: 3px;
                left: 5px;
                position: relative;
                width: 20px;
            }
        }

        .select2-selection__arrow b {
            color: @color-medium-grey;
        }
    }

    .select2-dropdown.select2-dropdown--below {
        .blurred-background;
        margin-top: -4px;
    }

    .select2-container--default .select2-results > .select2-results__options {
        max-height: 405px;
    }
}

.select2-contact-record {
    + .select2-container--default {
        margin-top: 3px;
        margin-bottom: 2px;

        .select2-selection--single {
            border-color: @color-light-grey;
            box-shadow: none;
            height: 30px;
            padding: 3px 6px;

            &:hover,
            &:focus {
                border-color: darken(@color-light-grey, 10%);
            }

            .select2-selection__placeholder {
                color: @color-body;
            }
        }

        .select2-selection__arrow {
            right: 6px;

            b {
                border: none;
                margin-top: 0;

                &:before {
                    font-family: ImosphereIcons;
                    content: "\e8cd";
                }
            }
        }

        .select2-results__option {
            .clearfix;
        }

        .contact-record-name {
            display: block;
            width: 100%;

            @media (min-width: 1270px) {
                float: left;
                width: 54%;
            }
        }

        .date-assessed {
            @media (min-width: 1270px) {
                float: right;
            }
        }
    }

    + .select2-container--open {
        .select2-selection--single {
            border-color: @color-light-grey;
        }

        .select2-search--dropdown {
            display: none;
        }

        .select2-selection__arrow b:before {
            content: "\e92e";
        }

        .select2-results {
            padding: 0;
        }

        .select2-results__options {
            margin-top: 2px;
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .select2-container--default {
            .select2-selection--single:focus,
            .select2-search--dropdown .select2-search__field:focus {
                border-color: @theme-color;
            }

            .select2-results__option[aria-selected="true"],
            .select2-results__option--highlighted[aria-selected="true"] {
                background: @theme-color;
                color: @color-white;

                .text-muted {
                    color: @color-white !important;
                }
            }
        }

        .select2-container--default {
            .select2-results__option,
            .select2-results__option--highlighted[aria-selected="true"] {
                .badge {
                    background-color: lighten(@theme-color, 20%) !important;
                    color: @color-white !important;
                }
            }
        }

        .select2-basic {
            .select2-results__option[aria-selected="true"] {
                b,
                .text-emphasis,
                strong {
                    color: @color-white;
                }
            }
        }
    }
}
