﻿.status {
    .border-radius-sm;
    color: @color-white;
    font-weight: 400;
    margin: 0 0 20px;
    padding: 0.4375em 0.75em;

    &.status-closed {
        background: @color-status-closed;
    }

    &.status-draft {
        background: @color-status-draft;
        color: #20548a;
    }

    &.status-open {
        background-color: @color-status-open;
    }

    &.status-abandoned {
        background-color: @color-status-abandoned;
    }

    &.status-paused {
        background-color: @color-status-paused;
    }
}
