﻿.modal {
    &.in {
        padding-right: 17px; // Fixes JS adding padding double modals
    }

    &.v-modal {
        display: block;
        background-color: rgba(0, 0, 0, 0.5);

        .modal-dialog .modal-content {
            .modal-title {
                color: @color-black !important;
                font-family: @heading-font-family !important;
                font-size: 20px !important;

                small {
                    font-family: @base-font-family !important;
                    font-size: 14px;
                    color: @color-black !important;
                }
            }

            .modal-body {
                max-height: 65vh;
                overflow-y: auto;
            }
        }
    }

    .modal-dialog {
        margin-top: 80px;
    }

    // Changed Alerts
    .modal-alerts {
        table {
            tbody {
                td.pin {
                    padding-left: 0;
                    padding-right: 0;
                    width: 10px;
                }

                .alert-bullet {
                    background: @color-white;
                    border-radius: 50%;
                    border: solid 1px @color-medium-grey;
                    margin: 4px 0 0;
                    width: 10px;
                    height: 10px;

                    &.pinned {
                        border-color: @color-blue;
                    }
                }

                .alert-answer {
                    .text-muted;
                    .text-sm;
                    .text-pre-wrap;
                    .text-break;
                    margin-bottom: 0;
                }
            }
        }
    }

    .modal-content {
        border: none;
        box-shadow: none;
        border-radius: 12px;

        .modal-header {
            background-color: @color-light-grey;
            border-bottom: none;
            border-radius: 12px 12px 0 0;
            padding: 15px 25px;
        }

        .modal-body,
        .modal-footer {
            border: none;
            padding: 25px;
        }

        .modal-body {
            .history-filter {
                .clearfix;
                padding-bottom: 0.75em;
            }
        }

        .modal-footer {
            padding-top: 0;
        }

        .modal-title {
            .no-select;
            color: @color-blue;
            font-size: @font-size-h3;
            line-height: @line-height-h3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;

            .text-muted {
                font-weight: 400;
            }
        }
    }

    .modal-delete {
        .modal-content {
            .modal-title {
                color: @color-red;
            }

            .btn-primary {
                background-color: @color-red;
                border-color: @color-red;

                &:hover,
                &:focus {
                    background-color: lighten(@color-red, 10%);
                }
            }
        }
    }

    .modal-sensitive-data {
        .table {
            border: 1px solid #ddd;

            tbody {
                .remove-permission-cell {
                    vertical-align: middle;
                    width: 32px;
                }

                tr:only-child {
                    .remove-permission-cell {
                        width: 0;
                    }
                }

                tr:not(:first-child) {
                    td {
                        padding-top: 0;
                    }
                }

                .select2-search {
                    display: none !important;
                }
            }

            tfoot {
                td {
                    padding-top: 0;
                }

                .select2 {
                    width: 52% !important;
                    float: left;
                }

                .btn {
                    height: 32px;
                    line-height: 32px;
                }
            }

            tfoot,
            tbody {
                tr {
                    td {
                        border: none;
                    }
                }

                td {
                    .cp-icon-cross {
                        margin-left: -4px;
                    }
                }
            }
        }
    }

    .imagepreview {
        img {
            width: 100%;
        }
    }

    .cp-icon-cross {
        cursor: pointer;
        margin-top: 3px;
        margin-right: -4px;
        opacity: 1;

        &:focus,
        &:hover {
            color: @color-has-error;
        }
    }

    .modal-footer {
        text-align: left;
    }

    h3:first-of-type {
        margin-top: 0;
    }

    &.modal-full-screen {
        padding: 0 !important;
        z-index: 1060;

        .modal-dialog {
            margin: 0;
            width: 100%;
            height: 100%;

            .modal-content {
                border-radius: 0;
                height: 100%;

                .modal-header,
                .modal-body {
                    background: @color-white;
                    border-radius: 0;
                }

                h1 {
                    margin: 4.5% 0 0.75em;
                }

                p {
                    margin: 1em 0 2em;
                }
            }
        }
    }

    &.modal-error {
        background: @color-white;
        padding: 30px !important;
        overflow: auto !important;

        .modal-dialog {
            .modal-content {
                background: @color-extra-light-grey;

                .modal-body {
                    background: @color-extra-light-grey;
                    text-align: center;

                    .return-link {
                        margin-bottom: 10%;

                        @media only screen and (min-width: @screen-sm-min) {
                            margin-top: 5%;
                            margin-bottom: 8%;
                        }

                        @media only screen and (min-width: @screen-md-min) {
                            margin-top: 0;
                            margin-bottom: 10%;
                        }
                    }

                    .btn-return-to-cp,
                    .btn-show-error,
                    .btn-reload-page {
                        font-weight: 600;
                        padding: 0;
                    }

                    .btn-return-to-cp,
                    .btn-reload-page {
                        color: @color-medium-grey;

                        [class^="cp-icon-"] {
                            margin-right: 5px;
                            position: relative;
                            top: 2px;
                        }

                        &:focus,
                        &:hover {
                            color: @color-link;
                            text-decoration: none;

                            [class^="cp-icon-"],
                            [class*=" cp-icon-"] {
                                color: @color-link;
                            }
                        }
                    }

                    .btn-return-to-cp [class^="cp-icon-"] {
                        top: -1px;
                    }

                    .btn-show-error {
                        color: @color-link;

                        &.collapsed:before {
                            content: "Show error";
                            display: block;
                        }

                        &:before {
                            content: "Hide error";
                        }
                    }

                    p {
                        margin: 1em 0;
                    }

                    h2 {
                        color: @color-body !important;
                        line-height: 34px;
                    }

                    pre {
                        background: @color-white;
                        text-align: left;
                    }

                    @media only screen and (min-width: @screen-md-min) {
                        text-align: left;

                        .col-md-4 {
                            text-align: center;
                        }
                    }

                    @media only screen and (min-width: @screen-lg-min) {
                        .error-content,
                        .error-content .btn,
                        .error-content .btn-lg {
                            font-size: calc(@base-font-size*1.3);
                            line-height: calc(@base-line-height*1.3);

                            h2 {
                                font-size: calc(@font-size-h2*1.3);
                                line-height: calc(@line-height-h2*1.3);
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-profile-picture {
    text-align: center;

    &.modal-dialog {
        display: table;
        width: auto;
    }

    .cp-icon-cross {
        color: @color-light-grey;
        opacity: 1;
        position: absolute;
        right: -12px;
        text-shadow: 0 1px 0 @color-black;
        top: -14px;
        z-index: 10;
    }

    .modal-content {
        background-color: transparent;
        display: inline-block;

        .modal-body {
            padding: 0;
        }

        img {
            border-radius: 8px;
            -webkit-filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.6));
            max-width: 740px;
            width: 100%;
        }
    }
}

.modal-dialog {
    &.modal-updated-version {
        text-align: center;

        p {
            margin: auto;
            max-width: 500px;
        }

        .modal-footer {
            padding-bottom: 35px;
            text-align: center;
        }
    }
}

.blockUI {
    &.blockMsg {
        .border-radius-sm !important;
        background-color: transparent !important;
        border: none !important;
        color: @color-body !important;
        left: 45% !important;
        opacity: 1 !important;
        padding: 0 !important;
        width: auto !important;
        z-index: 200 !important;
    }

    &.blockOverlay {
        background: @color-white !important;
        opacity: 0.75 !important;
        z-index: 150 !important;
    }
}

.activitiy-modal-fieldset {
    padding-bottom: 20px;

    .form-group:last-of-type {
        margin-bottom: 0;
    }
}

.modal {
    &.modal-answer-error {
        .modal-title {
            color: @color-has-error !important;
        }

        .btn-primary {
            background-color: @color-has-error !important;
            margin-top: -22px;
            border-color: @color-has-error !important;
        }
    }
}

.knowledge-base-modal {
    h2 {
        font-size: 3em;
        color: @color-medium-grey;
        margin: 20px 0 10px;
    }

    .lead {
        margin: 20px 60px 20px;
        padding: 20px;
        background-color: @color-extra-light-grey;
        clear: both;
        display: block;
        line-height: 1.2em;
        border-radius: 8px;

        b {
            font-weight: 600;
            display: block;
        }
    }
}

.export-dialog-options-container {
    margin: 0 20px;
    max-height: 400px;
    border: 2px solid @color-medium-grey;
    margin-bottom: 20px;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            position: relative;

            a {
                padding: 8px 40px 8px 8px;
                position: relative;
                display: block;

                &.active {
                    background-color: #fafafa;

                    &:after {
                        width: 32px;
                        height: 32px;
                        border-radius: 16px;
                        content: "\e865";
                        font-family: "ImosphereIcons";
                        line-height: 32px;
                        font-size: 16px;
                        color: @color-white;
                        background-color: @color-green;
                        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                        position: absolute;
                        right: 12px;
                        top: 12px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .form-group-container {
        padding: 15px;
    }
}

@media only screen and (min-width: @screen-md-min) {
    .modal-md {
        width: 700px;
    }
}

@media only screen and (min-width: @screen-xl-min) {
    .modal-xl {
        width: 1100px;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .modal-dialog {
            .modal-content .modal-title {
                color: @theme-color;
            }
        }

        &.modal-alerts {
            .modal-content .modal-header .modal-title {
                color: @color-blue;
            }
        }
    }
}
