﻿// Calendar LESS
@color-attendance-full: #1ad44e;
@color-attendance-partial: #ffba3a;
@color-attendance-none: #ed3a3a;

#full-calendar {
    transition: 0.3s ease;

    &.is-loading {
        opacity: 0.5;
    }
}

.fc-event-inner {
    .line-height-sm;
    padding: 1px 2px 0 3px;
}

.event-time,
.event-category {
    color: lighten(@color-medium-grey, 2%);
}

.agenda-events {
    padding-top: 17px;
}

.calendar-view.is-hidden {
    height: 0;
    overflow: hidden;
}

// Continuation Events ==========================================================
.event-continuation-end {
    display: none;
    float: right;
}

.event-continuation-start {
    display: none;
    float: left;
    padding-right: @unit;
}

.fc-not-start .event-time-start,
.fc-not-end .event-time-end {
    display: none;
}

.fc-not-start .event-continuation-start,
.fc-not-end .event-continuation-end {
    display: block;
}

// Colors =======================================================================
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row {
    border-color: @color-light-grey; // sets all border colours
}

.fc-unthemed .fc-divider {
    background: @color-extra-light-grey;
}

.fc-unthemed .fc-today {
    background: fade(@color-blue, 6%);
}

.fc-highlight {
    /* when user is selecting cells */
    background: fade(@color-blue, 90%);
}

.fc-nonbusiness {
    /* default look for non-business-hours areas */
    /* will inherit .fc-bgevent's styles */
    background: #d7d7d7;
}

.fc-now-indicator {
    border-color: rgba(255, 0, 0, 0.65);
}

// Misc Reusable Components ============================================================================
hr.fc-divider {
    padding: 0 0 3px; /* height is unreliable across browsers, so use padding */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc th {
    padding: @unit 0;
    text-align: center;
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
    background-color: @color-white; /* default BACKGROUND color */
    border: 1px solid @color-light-grey; /* default BORDER color */
    border-left: solid 3px @color-blue;
    border-radius: 4px;
    box-shadow: 3px 3px 5px -3px @color-light-grey;
    cursor: pointer;
    font-size: 0.85em;
    font-weight: 400; /* undo jqui's ui-widget-header bold */
    line-height: 1.3;
    transition: background-color 0.2s ease;

    &:hover,
    &.event-hover {
        background-color: @color-extra-light-grey;

        &.birthday {
            color: darken(@color-blue, 5%);
        }
    }

    .event-header {
        display: -webkit-flex; /* Safari */
        display: flex;
        -webkit-flex-wrap: nowrap; /* Safari */
        flex-wrap: nowrap;

        .event-time-start {
            padding-right: 3px;
        }

        .event-time-end {
            padding-left: 3px;
        }

        .event-title {
            -webkit-flex-grow: 1; /* Safari */
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto;
        }

        .event-icons {
            -webkit-flex-shrink: 0; /* Safari */
            flex-shrink: 0;
            padding-right: 3px;
        }
    }

    &.event-past {
        background-color: lighten(@color-extra-light-grey, 2%);
        border-left-color: @color-medium-grey;

        [class^="cp-icon-"],
        [class*=" cp-icon-"] {
            color: @color-medium-grey;
        }

        &:hover,
        &.event-hover {
            background-color: darken(@color-extra-light-grey, 4%);

            [class^="cp-icon-"],
            [class*=" cp-icon-"] {
                color: @color-medium-grey;
            }
        }

        // Full attended
        &.event-attendance-full {
            border-left-color: @color-attendance-full;
        }

        // Partial attended
        &.event-attendance-partial {
            border-left-color: @color-attendance-partial;
        }

        // No attended
        &.event-attendance-none {
            border-left-color: @color-attendance-none;
        }
    }

    &.birthday {
        .no-select;
        background: fade(@color-blue, 40%);
        border-color: transparent;
        border-radius: 0;
        box-shadow: none;
        color: darken(@color-blue, 5%);
        cursor: default;
    }

    &.last-in-series {
        [class^="cp-icon-"],
        [class*=" cp-icon-"] {
            color: @color-warning;
            font-weight: 600;
        }
    }
}

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
    color: @color-body; /* default TEXT color */
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .fc-minor td {
    border-top-style: solid;
    border-color: lighten(@color-light-grey, 2%);
}

.fc-time-grid .fc-minor td.fc-axis {
    border-color: transparent;
}
