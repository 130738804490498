﻿// Filters LESS
#filters-toggle {
    position: relative;
    margin-bottom: 20px;
}

.filters {
    .filters-container {
        .border-radius(15px);
        background: @color-white;
        padding: 25px 25px 10px;
        position: relative;
    }
}

.modal {
    .filters {
        .filters-container {
            background-color: @color-extra-light-grey;
        }
    }
}

.collapsing .btn {
    position: static;
}

.filters {
    .filters-container {
        border-radius: 4px;
        padding: 10px;
        position: relative;
        background-color: #ECECEC;
    }
}