﻿// Labels LESS
.label {
    font-size: 11px;
    font-weight: 400;
    padding: 0.2em 0.6em;
    font-family: FSMeWeb !important;

    &.label-abandoned {
        background-color: red;
        color: @color-white;
    }

    &.label-open {
        background-color: #111;
        color: @color-white;
    }

    &.label-not-started {
        background-color: #d1d1d1;
        color: #0f0f0f;
    }

    &.label-in-progress {
        background-color: #ffc796;
        color: #a33600;
    }

    &.label-closed {
        color: @color-white;
        font-family: FSMeWeb !important;
        background-color: #333232;
    }

    &.label-warning {
        background-color: @color-warning;
        color: @color-dark-grey;
    }

    &.label-default {
        background-color: @color-medium-grey;
        color: @color-white;
    }

    &.label-tint {
        background: @color-blue;
        color: @color-white;
    }

    &.label-coc {
        background: @color-extra-light-grey;
        color: @color-medium-grey;
    }

    &.label-closed {
        background: darken(@color-medium-grey, 14%);
    }

    &.label-draft {
        background: #3a8dd6;
        color: @color-white;
    }

    &.label-field-type {
        display: inline-block;
        height: 24px;
        padding: 0;
        width: 24px;

        [class*="cp-icon-"] {
            position: relative;
            top: 3px;
        }
    }

    &.label-search {
        background: @color-extra-light-grey;
        color: @color-medium-grey;
        font-size: 1em;
        font-weight: 400;
        margin-right: 0.25em;
    }

    &.label-read-only {
        background-color: #0a8dd8;
        color: @color-white;
    }

    &.label-care-plan {
        background-color: @color-extra-light-grey;
        color: @color-indigo;
        font-size: 1em;
        margin: 0px 8px;
        padding: 0 0.3em 0 0.2em;
        top: 1px !important;

        .text {
            font-size: 75%;
            position: relative;
            top: -2px;
        }
    }

    &.large-label {
        line-height: 42px;
        font-size: 1.13333333rem;
    }

    &.small-label {
        font-size: 12px;
        color: black;
        font-weight: 600;

        &.has-error {
            color: #cf5d5c !important;
        }
    }

    &.label-outline {
        background-color: @color-white;
        color: @color-medium-grey;
        border: 1px solid @color-medium-grey;
    }

    &.label-from {
        &:after {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 7px 7px;
            border-color: transparent transparent transparent #707070;
            position: absolute;
            top: 2px;
            right: -7px;
            z-index: 1;
        }
    }

    &.label-overdue {
        background-color: @color-overdue;
        color: @color-white;
    }
}

.table tbody tr td .label {
    position: relative;
    top: -1px;
}

label {
    font-weight: 600;
    font-family: @heading-font-family;
    font-size: 1.067em;
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .label {
            &.label-open,
            &.label-primary,
            &.label-tint {
                background-color: @theme-color;
            }

            &.label-key-item,
            &.label-alert {
                background-color: @color-blue;
            }
        }
    }
}
