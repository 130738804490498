﻿@import (reference) "../colorPalette.less";
// Forms LESS
@unit: 5px;

.form-control {
    border: none;
    font-size: 1rem;
    height: 32px;
    background-color: @color-data-entry-button-background;
    border: 2px solid @color-data-entry-button-border;
    transition: border-color linear 0.1s;
    box-shadow: none;
    color: inherit;

    &::placeholder {
        color: #606060;
    }

    &:hover,
    &:focus {
        box-shadow: none;
    }

    &.input-lg {
        font-size: 1.26666666rem;
        height: 48px;
    }

    &.centre-of-care-select {
        margin-top: 5px;
        margin-bottom: 5px;

        @media only screen and (min-width: @screen-sm-min) {
            margin: 0 0 0 -15px;
        }
    }

    &.calculated-field,
    &.calculated-field:focus {
        padding: 0 12px 0 32px;
        line-height: 32px;
        position: relative;

        .label-field-type {
            left: 0;
            line-height: 30px;
            text-align: center;
            position: absolute;
            top: 0;
            width: 32px;
            color: @color-body;
        }
    }

    &.calculated-field:hover {
        background-color: @color-data-entry-button-background;
    }

    &.admin-tabs {
        background-color: @color-white;
        max-width: 190px;
    }

    &.text-input {
        &.readonly {
            pointer-events: none;

            .label.label-default {
                color: @color-black;
                background-color: transparent;
                border: solid 1px @color-black;
                border-radius: 4px;
                margin-right: 5px;
                padding: 2px 1px 1px 2px;
                font-size: 0.875em;
            }
        }
    }
}

.search-control {
    position: relative;

    .form-control-search {
        padding-left: 2em;
        padding-right: 1.75em;
    }

    .cp-icon-search {
        cursor: text;
        left: 8px;
        position: absolute;
        top: 8px;

        &:focus,
        &:hover {
            color: @color-medium-grey;
        }
    }

    .cp-icon-cross {
        position: absolute;
        right: 6px;
        top: 3px;
    }

    .btn.btn-link.cp-icon-cross {
        &:focus,
        &:hover {
            color: @color-tint-danger;
            text-decoration: none;
        }
    }

    &.alternate {
        .form-control {
            background-color: rgba(255, 255, 255, 0.7);
            transition: background-color linear 0.1s;

            &:focus {
                background-color: @color-white;
            }
        }
    }
}

.form-group {
    margin-bottom: @unit*4;

    &.form-group-sm {
        margin-bottom: 6px !important;
    }

    &.add-field {
        margin-top: -@unit*3;

        .btn {
            padding-left: 0;
            padding-right: 0;
        }

        .btn-link:focus {
            background-color: transparent;

            &:hover {
                background-color: @color-extra-light-grey;
            }
        }

        [class^="cp-icon-"] {
            color: @color-link;
        }
    }
    // Req fields
    &.form-group-required {
        label {
            &:after {
                content: "*";
                color: lighten(@color-red, 10%);
                padding-right: 2px;
            }
        }
    }

    .label-text-top {
        vertical-align: top;
        cursor: pointer;

        .text-muted {
            display: block;
            font-family: FSMeWeb;
            font-weight: 500;
            font-size: 0.875em;
        }
    }

    .tag {
        background-color: @color-black;
        border-radius: 4px;
        color: @color-white;
        font-size: 0.9em;
        font-weight: 600;
        padding: 2px;
    }
}

.form-horizontal {
    &.history-form-view {
        .form-group {
            border-top: solid 1px transparent;
            border-bottom: solid 1px @color-light-grey;
            margin: 0 -@unit*4;
            padding: 4px 0;

            &:last-of-type {
                border: none;
            }
        }
    }

    .cp-icon-user {
        margin-right: 0.25em;
        vertical-align: middle;

        &:focus,
        &:hover {
            color: @color-medium-grey;
        }
    }
}

.validation-block {
    display: block;
    margin: 3px 0;
    font-size: 0.875em;
}

.clearfix {
    .validation-block {
        margin: 0;
    }
}

.form-guidance {
    .text-muted;
    .text-sm;
    .markdown;
    clear: both;
    max-height: 400px;
    overflow-y: scroll;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 5px;

    &.gutruncate_closed {
        display: none;
    }

    &.radio-button-guidance {
        line-height: unset;
        padding-left: 0px;
    }

    &.bistate-guidance {
        padding-top: 8px;
    }
}

label {
    color: lighten(@color-body, 4%);
    margin-bottom: 2px;
}

textarea {
    resize: vertical;

    &.form-control {
        line-height: 22px;
        padding: 6px 12px;
    }
}

.disabled-control {
    opacity: 0.5;
    pointer-events: none;
}

.registration-form {
    margin-bottom: 15px;

    .mandatory-view {
        margin-bottom: 15px;

        fieldset {
            border: none;
        }
    }

    .panel-collapse {
        width: 100%;
        border-radius: 0;
    }

    fieldset {
        width: 100%;
    }
}

.service-user-personal-details {
    .panel .form-horizontal {
        width: 100%;
    }

    .mandatory-view {
        .panel-collapse {
            width: 100%;
            border-radius: 0;
        }

        fieldset {
            width: 100%;

            &.mandatory-information {
                .view-form {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Validation
.form-tracker-container {
    li.list-item a.link-item {
        &.has-error {
            color: @color-has-error;
            font-weight: 500;
        }
    }

    .list-item.active > a.link-item {
        &.has-error {
            color: @color-has-error;
            font-weight: 500;
        }
    }
}

.has-error {
    .form-control,
    .btn,
    .btn.btn-default,
    .input-group-addon,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default .select2-selection--single {
        background-color: @color-has-error-bg;
    }

    .btn.btn-link.btn-new-subform {
        background-color: rgba(255, 255, 255, 0.7) !important;
        border: 1px solid @color-has-error;

        &:hover {
            background-color: @color-extra-light-grey !important;
        }
    }

    .radio-button-label {
        .checkmark {
            background-color: @color-has-error-bg !important;
            border-color: @color-has-error-bg !important;

            &:after {
                background-color: @color-has-error-bg !important;
            }
        }
    }

        .form-control:focus,
        .form-control:focus,
        .input-group-addon:focus
            .select2-container--default
            .select2-selection--single {
        background-color: @color-has-error-bg;
    }

    .calculated-field {
        background-color: @color-has-error-bg;

        &:focus {
            background-color: none;
            box-shadow: none;
        }
    }

    .btn-group {
        border-radius: 4px;
        box-shadow: 0 0 6px @color-has-error;

        .btn,
        .btn.active,
        .btn-default.active {
            border-color: @color-has-error !important;
            box-shadow: none !important;
        }
    }

    .question-label {
        color: @color-has-error;
    }

    .validation-block {
        color: @color-has-error;
    }

    .help-block {
        color: @color-body;
    }
}

.auto-filled {
    color: @color-info;
    padding-bottom: 8px;
}

// File Upload
.file-upload-control {
    .form-control {
        overflow: hidden;
        width: auto;
        max-width: 100%;
    }

    .btn-file {
        float: right;
        margin-left: -1px;
    }

    p.form-control {
        margin: 0;
    }
}

// File Picker
.btn-file {
    overflow: hidden;
    position: relative;
}

.filedrag {
    position: relative;

    input[type="file"] {
        background: @color-data-entry-button-background;
        cursor: inherit;
        display: block;
        font-size: 100px;
        opacity: 0;
        outline: none;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        width: 0;
        height: 0;

        &:hover,
        &:focus {
            + .btn-file {
                background: @color-data-entry-button-border;
            }
        }
    }
}

// Input Addon
.input-group-addon {
    background: @color-data-entry-button-border;
    border: none;
    color: @color-body;
    font-size: 0.867em;
    line-height: 32px;
    padding: 0 8px;
}

textarea {
    &.clone {
        width: 300px;
        height: 250px;
        z-index: 9999;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    }

    &.edit-form-description,
    &.clone {
        resize: none;
    }
}

// Select2 Defaults
.select2-container {
    &.select2-container--default {
        .select2-selection--single {
            &[aria-expanded="true"] {
                border-color: @color-blue;
            }

            &[aria-disabled="true"] {
            }

            &:hover:not([aria-disabled="true"]),
            &:focus:not([aria-disabled="true"]) {
                border-color: @color-blue;
            }
        }

        .select2-dropdown.select2-dropdown--below {
            border-color: @color-blue;
        }

        .select2-selection--multiple {
            &[aria-expanded="true"] {
                border-color: @color-blue;
            }

            &[aria-disabled="true"] {
            }

            &:hover:not([aria-disabled="true"]),
            &:focus:not([aria-disabled="true"]) {
                border-color: @color-blue;
            }
        }
    }
}

// Form element hover and focus states
.form-control {
    &:hover:not([aria-disabled="true"]),
    &:focus:not([aria-disabled="true"]) {
        border-color: @color-blue;
    }

    &[aria-disabled="true"]:hover,
    &[aria-disabled="true"]:focus {
        border-color: @color-data-entry-button-border;
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        .form-control {
            &:focus {
                /*border-color: @theme-color;
                box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.10);*/
            }
        }
        // Profile Photo
        .profile-photo-upload {
            &.has-error {
                .btn-default {
                    border-color: fade(@theme-color, 80%);
                    box-shadow: 0 0 6px fade(@theme-color, 80%);
                }

                .btn-danger {
                    border-color: @color-red;
                    box-shadow: none;
                }
            }
        }
        // Select2 Overrides
        .select2-container {
            &.select2-container--default {
                .select2-selection--single {
                    &[aria-expanded="true"] {
                        border-color: @theme-color;
                    }

                    &[aria-disabled="true"] {
                    }

                    &:hover:not([aria-disabled="true"]),
                    &:focus:not([aria-disabled="true"]) {
                        border-color: @theme-color;
                    }
                }

                .select2-dropdown.select2-dropdown--below {
                    border-color: @theme-color;
                }

                .select2-selection--multiple {
                    &[aria-expanded="true"] {
                        border-color: @theme-color;
                    }

                    &[aria-disabled="true"] {
                    }

                    &:hover:not([aria-disabled="true"]),
                    &:focus:not([aria-disabled="true"]) {
                        border-color: @theme-color;
                    }
                }
            }
        }
        // Form element hover and focus states
        .form-control {
            &:hover:not([aria-disabled="true"]),
            &:focus:not([aria-disabled="true"]) {
                border-color: @theme-color;
            }
        }
    }
}

#guprogress-target {
    .guprogress-bar {
        background-color: @color-green;
        z-index: 100;
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
    }

    &.progress-danger {
        .guprogress-bar {
            background-color: @color-red;
        }
    }
}

.short-text-group {
    width: 100%;

    .form-control:only-child {
        border-radius: 4px;
    }

    &.shortDataInputNumeric {
        width: 50% !important;
    }
}

.form-item-row {
    .select2-container {
        position: relative;
        z-index: inherit;
        width: 100%;
        margin-bottom: 0;
        display: table;
        table-layout: fixed;
    }
}

.warning-badge {
    background-color: @color-white;
    position: relative;
    height: 21px;
    width: 21px;
    padding: 0px 1px;
    border-radius: 85px;
    padding-top: 0px;
    display: inline-block;

    .imo {
        font-size: 20px;
        color: @color-has-error;
    }
}

.radio-button-answers-container {
    .warning-badge {
    }
}

.not-nullable-asterisk {
    color: @color-has-error;
}

.mandatory-items-module {
    background: rgba(255, 0, 0, 0.1);
    padding: 4px 8px;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 8px;

    h3 {
        font-size: 1.1em;
        color: rgb(170, 0, 0);
    }

    .btn {
        &.btn-link {
            padding: 0;
            color: rgb(170, 0, 0);

            &:hover,
            &:focus {
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }
}

.btn-new-subform {
    max-width: 500px;
    height: auto;
    white-space: pre-wrap;
    text-align: center;
    display: inline-block;
    min-height: 34px;
    padding: 8px 4px;
    line-height: 1.2em;
}

.readonly-text-field {
    b {
        font-family: 'Sofia Pro';
        display: block;
        font-size: 14px;
        margin-bottom: 2px;
    }

    span {
        font-family: "FSMeWeb", helvetica, arial, sans-serif !important;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        box-shadow: none;
        display: block;
        width: 100%;
        padding: 6px 12px;
        background-color: #ddd;
        border-radius: 4px;
        color: #454545;

        mark {
            background-color: transparent;
            color: inherit;

            &:last-child {
                color: #000;
            }
        }
    }
}
