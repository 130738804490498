﻿@import "../colorPalette.less";
// Tables LESS
.activityIsOverdue {
    background-color: @color-alert-warning;
}

.table-responsive {
    border: none;
    padding: 30px;
    background-color: @color-white;
    margin-bottom: 15px;

    &.registry {
        overflow-x: visible;
        min-height: 120px !important;
        display: block;
    }

    &.table-scroll {
        overflow-y: auto;
        max-height: 500px;

        thead th {
            background-color: @color-white;
            position: sticky;
            top: 0;
            z-index: 1000;
        }
    }

    thead th {
        .checkbox-input-field {
            margin-top: -3px;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 10px;
    }
}

td {
    &.align-center {
        text-align: center;
    }

    input {
        &.offset-chevron {
            margin-right: 18px;
        }
    }
}

th {
    &.align-center {
        text-align: center;
    }
}

.coc-registry {
    table-layout: fixed;
    position: relative;
    border-collapse: collapse;
    border: none;
    font-size: 0.8em;

    thead {
        tr {
            th {
                position: sticky;
                top: 31px;
                background: #f4f4f4;
                z-index: 3;
                border: none;
                font-family: "FSMeWeb" !important;
                font-weight: 500 !important;
                text-align: center;

                &.show-section-marker,
                &.first-in-group {
                    &:not(:first-of-type) {
                        border-left: 2px solid #ccc;
                    }
                }

                &.border-left {
                    border-left: 1px solid #ddd !important;
                }
            }

            &:first-of-type {
                th {
                    top: 0;
                    background: @color-light-grey;
                    font-family: Sofia Pro !important;
                    font-weight: 600 !important;
                    text-align: left;
                }
            }
        }
    }

    tbody {
        th {
            position: sticky;
            left: 0;
            background: @color-light-grey;
            z-index: 3;
            border: none;
        }

        td {
            border: none;
            text-align: center;

            &.with-border {
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
            }

            &.marker {
                vertical-align: middle !important;
            }

            .imo {
                font-size: 20px !important;
                margin: 0;
            }

            &.first-in-group:not(:first-of-type) {
                border-left: 2px solid #ccc;
            }
        }
    }

    .service-user-name {
        width: 200px;
        word-break: break-all;

        &.no-border {
            border-color: transparent;
        }

        a {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 1.3em;
        }
    }

    tr {
        position: relative;

        th[scope="col"] {
            width: 100px;
            word-break: break-word;
            font-weight: 500;
        }
    }
}

.modal {
    .table-responsive {
        padding: 15px;
    }
}

body.admin {
    .table-responsive {
        padding: 0px;
        background-color: transparent;
    }

    .table-responsive-fixed {
        padding: 0px;
        background-color: transparent;

        .role-name-control {
            width: 40%;
        }

        .role-toggle-control {
            width: 50%;
        }
    }
}

body.management {
    .table-responsive {
        padding: 0px;
        background-color: transparent;
    }

    .table-responsive-fixed {
        padding: 0px;
        background-color: transparent;

        .role-name-control {
            width: 40%;
        }

        .role-toggle-control {
            width: 50%;
        }
    }
}

.table {
    > thead > tr > th {
        font-weight: 600;
        font-family: @heading-font-family;
        font-size: 1.067em;
        vertical-align: text-top;

        &.no-border {
            border: none;
            padding-bottom: 0 !important;
        }
    }

    > thead > tr {
        &.sub-heading {
            th {
                font-family: FSMeWeb;
                font-size: 1em;
                font-weight: normal;
                border-top: none;
                padding-top: 0 !important;
            }
        }
    }

    > tbody > tr.hidden-row > td {
        border: none;
        padding: 0;
    }

    > tbody > tr {
        &.row-status-success {
            background-color: lighten(@color-success, 55);
        }
    }

    &.no-header-row {
        tr:first-child td {
            border-top: none;
        }
    }

    &.table-borderless {
        tbody > tr > td {
            border: none;
        }
    }

    &.table-worklist {
        thead > tr > th.edge-trim {
            padding: 8px 0;
            width: 10px;
        }

        tbody > tr > td {
            padding: 10px 8px;

            &:first-child {
                padding: 8px 6px 8px 0;
            }

            &:last-child {
                padding-right: 20px;
            }

            .edge-trim {
                background-color: @color-light-grey;
                width: 4px;
                height: 23px;

                &.has-involvement {
                    background-color: @color-blue;
                }
            }

            .toggle-icon {
                font-size: 18px;
            }
        }

        tbody > tr > td > a {
            border-bottom: 2px solid transparent;
            font-weight: 600;

            &:hover {
                border-color: @color-blue;
                color: @color-blue;
                text-decoration: none;
            }
        }

        tbody > tr.deceased > td {
            background: darken(@color-light-grey, 2%);
            border-top-color: darken(@color-light-grey, 12%);

            .edge-trim {
                background-color: @color-body;
            }
        }
    }

    &.table-merge-su {
        td p {
            margin: 6px 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            &.title {
                margin: 0;
            }
        }
    }

    &.table-pathway-associate-activities {
        margin-top: 20px;
    }

    .table {
        border-top: solid 2px transparent;
        margin-top: -4px;
    }

    .cp-icon-cake {
        color: @color-blue;
        position: relative;
        top: -1px;

        &:focus,
        &:hover {
            color: @color-blue;
        }
    }

    .cp-icon-plus {
        color: @color-blue;
        text-decoration: none;
    }

    .icon-my-forms,
    .icon-view-history,
    .cp-icon-plus {
        font-size: 20px;

        &:hover {
            color: lighten(@color-blue, 10%);
        }
    }

    .cp-icon-star {
        font-size: 20px;
        position: relative;
        top: 1px;
    }

    .cp-icon-care-plan-associations {
        color: @color-medium-grey;
        margin-left: 3px;

        &:focus,
        &:hover {
            color: @color-medium-grey;
        }
    }

    @media only screen and (min-width: @screen-sm-min) {
        table-layout: fixed;
    }

    &.table-data-entry {
        table-layout: fixed;
        background-color: @color-white;

        tr {
            border: none;

            td,
            th {
                border: none !important;
                padding: 8px;
            }

            th {
                background-color: @color-data-entry-button-border;
                min-height: 34px;
                padding: 0px 5px 5px;
                word-break: break-word;
                width: 150px;

                &.remove-row-column {
                    width: 55px;
                }

                &.date {
                    width: 150px;
                }

                &.dropdown,
                &.text {
                    width: 250px;
                }

                &.long-text {
                    width: 300px;
                }

                &.attachment {
                    width: 300px;
                    border-radius: 0;
                }
            }

            td {
                .form-item-group {
                    position: relative;
                }

                textarea {
                    height: 32px;
                    resize: none;
                }

                .filedrag {
                    padding: 0;
                    display: flex;
                    min-height: inherit;
                    height: 32px;
                    background: transparent;

                    .drag-instructions {
                        width: auto !important;
                        margin-right: 8px;
                    }

                    .icon-container {
                        width: 30px;
                    }

                    .btn {
                        height: 32px;
                        margin: 0 !important;
                        font-size: 1em !important;
                        font-family: "Source Sans Pro" !important;

                        &:hover,
                        &:focus {
                            background-color: #c4d9d7;
                        }
                    }
                }
            }
        }
    }

    .no-data {
        height: 540px;

        td {
            background-color: @color-white;
        }
    }

    &.table-form-definitions {
        height: 600px;
        overflow-y: auto;

        tbody {
            width: 100%;
            display: table;
        }

        td {
            width: 100%;
        }
    }

    &.table-tooltip {
        width: 350px;
        border: none;

        tbody {
            tr {
                border: none;

                td {
                    border: none;
                }

                b {
                    font-weight: 500;
                }
            }
        }
    }

    &.table-budget-history {
        &.body {
            thead {
                tr {
                    th {
                        padding: 0;
                        height: 0;
                        border: none;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        thead {
            tr {
                th {
                    border: none;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    .change {
                        border-radius: 4px;
                        padding-left: 20px;
                        display: inline-block;
                        text-align: right;
                        position: relative;
                        text-decoration: none;

                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 6px;
                        }

                        &.up {
                            color: #006400;

                            &:before {
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 0 5px 6px 5px;
                                border-color: transparent transparent #006400 transparent;
                            }
                        }

                        &.down {
                            color: #ac0f0f;

                            &:before {
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 6px 5px 0 5px;
                                border-color: #ac0f0f transparent transparent transparent;
                            }
                        }

                        &.same {
                            color: #777;

                            &:before {
                                width: 10px;
                                height: 2px;
                                background-color: #777;
                                top: 8px;
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    td {
                        border-top-width: 0;
                    }
                }
            }
        }
    }
}

.table-data-entry-container {
    position: relative;
    border: 8px solid @color-data-entry-button-border;
    background-color: @color-data-entry-button-border;

    .table-data-entry-container-data {
        width: ~"calc(100% - 55px)";
        overflow-x: scroll;
        overflow-y: hidden;

        &.full-width {
            width: 100%;
        }
    }

    .table {
        table-layout: fixed;

        &.table-remove-rows {
            position: absolute;
            right: 0;
            bottom: 21px;
            width: 55px;
            z-index: 3;
            border: none;
            background: @color-white;

            @media screen and (-webkit-min-device-pixel-ratio: 0) {
                bottom: 17px;
            }

            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
                bottom: 0;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                bottom: 17px;
            }

            th {
                background-color: @color-data-entry-button-border;
            }

            tr {
                td {
                    line-height: 48px;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }
}

.page-print {
    .table {
        &.table-data-entry {
            table-layout: fixed;
            font-size: 0.9em;
            word-break: break-all;
        }
    }
}
// Contextual Colours
.table {
    > thead > tr.danger th,
    > thead > tr.danger td,
    > tbody > tr.danger th,
    > tbody > tr.danger td,
    > tfoot > tr.danger th,
    > tfoot > tr.danger td,
    > thead > tr > th.danger,
    > thead > tr > td.danger,
    > tbody > tr > th.danger,
    > tbody > tr > td.danger,
    > tfoot > tr > th.danger,
    > tfoot > tr > td.danger {
        background-color: #ffebeb;

        .text-danger {
            color: #f50000;
        }
    }
}

.theme-mixin(@name, @theme-color) {
    .theme-@{name} {
        table {
            thead tr th.sortable {
                .cp-icon-caret-up,
                .cp-icon-caret-down {
                    color: @theme-color;
                }

                &:hover {
                    color: @theme-color;
                    cursor: pointer;
                }

                button {
                    border: none;
                    background: transparent;
                    padding: 0;
                }
            }
        }
    }
}
// Tables that require touch scroll on larger than bootstrap 768px default
@media screen and (max-width: 850px) {
    .table-responsive {
        &.table-recent-activities,
        &.table-notifications,
        &.table-su-history {
            -ms-overflow-style: -ms-autohiding-scrollbar;
            margin-bottom: 15px;
            overflow-y: hidden;
            width: 100%;

            > .table {
                margin-bottom: 0;
                table-layout: auto;

                td {
                    padding-right: 16px;
                }
            }

            > .table > thead > tr > th,
            > .table > tbody > tr > th,
            > .table > tfoot > tr > th,
            > .table > thead > tr > td,
            > .table > tbody > tr > td,
            > .table > tfoot > tr > td {
                white-space: nowrap;
            }

            > .table-bordered {
                border: 0;
            }

            > .table-bordered > thead > tr > th:first-child,
            > .table-bordered > tbody > tr > th:first-child,
            > .table-bordered > tfoot > tr > th:first-child,
            > .table-bordered > thead > tr > td:first-child,
            > .table-bordered > tbody > tr > td:first-child,
            > .table-bordered > tfoot > tr > td:first-child {
                border-left: 0;
            }

            > .table-bordered > thead > tr > th:last-child,
            > .table-bordered > tbody > tr > th:last-child,
            > .table-bordered > tfoot > tr > th:last-child,
            > .table-bordered > thead > tr > td:last-child,
            > .table-bordered > tbody > tr > td:last-child,
            > .table-bordered > tfoot > tr > td:last-child {
                border-right: 0;
            }

            > .table-bordered > tbody > tr:last-child > th,
            > .table-bordered > tfoot > tr:last-child > th,
            > .table-bordered > tbody > tr:last-child > td,
            > .table-bordered > tfoot > tr:last-child > td {
                border-bottom: 0;
            }
        }
    }
}

.page-registry {
    .key {
        .circle {
            display: inline-block;
            height: 20px;
            width: 20px;
            top: 10px;
            position: relative;
        }
    }

    .form-table-registry {
        .table-head {
            background-color: rgba(0, 0, 0, 0.1);

            td {
                font-weight: 600;
                padding: 10px;
            }
        }

        .table-body {
            tr {
                background-color: @color-white;
                height: 40px;
                border: 2px solid @color-data-entry-button-border;

                td {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .icon-registry-circle {
        font-size: 30px;
        margin: 5px;
        color: #cfe6e4;

        &.legend {
            font-size: 20px !important;
            margin: 10px !important;
            margin-right: 0px !important;
        }

        &.complete {
            color: #23775d;
        }

        &.incomplete {
            color: #ff9b00;
        }
    }

    .icon-registry-multi {
        font-size: 30px;
        margin: 5px;

        &.legend {
            font-size: 20px !important;
            margin: 10px !important;
            margin-right: 0px !important;
        }

        &.complete {
            color: #23775d;
        }

        &.incomplete {
            color: #ff9b00;
        }

        &.mixed {
            color: #dc44b3;
        }
    }

    .table.table-registry {
        //border: 2px solid @color-data-entry-button-border;
        table-layout: inherit !important;
        width: 500px;

        thead {
            tr {
                th {
                    vertical-align: bottom;
                    text-align: left;
                    height: 170px;
                    border: none !important;
                    padding: 8px 16px;

                    &.stage-header {
                        height: auto;
                        text-align: center;
                        border: none !important;
                    }

                    &.stage-name {
                        background-color: #eaf1f0;
                        border: 2px solid @color-white !important;
                        padding: 0;
                        max-width: 20px;
                        overflow: hidden;
                        font-family: FSMeWeb;
                        font-weight: 500;
                        text-overflow: ellipsis;

                        div {
                            white-space: nowrap;
                            padding-top: 10px;
                            line-height: 40px;
                            transform: rotate(-90deg);
                            transform-origin: -25% 0%;
                            position: absolute;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0;
                    height: 42px;
                    background-color: @color-white;
                    width: 30px;
                    text-align: center;
                    border: 2px solid @color-data-entry-button-border;

                    &.marker:last-child {
                        border-right: @color-white;
                    }

                    &.form-name {
                        background-color: #f9fbfb;
                        border: 2px solid @color-white;
                        width: auto;
                        line-height: 20px;
                        padding: 7px 7px;
                        text-align: left;
                    }
                }
            }

            tr:last-child {
                .marker {
                    border-bottom: white !important;
                }
            }
        }
    }
}

.key {
    ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
            list-style: none;
            display: inline-block;

            &:first-child {
                .icon-registry-circle {
                    margin-left: 0 !important;
                }
            }
        }
    }
}

.table-striped {
    .expanded-row {
        th {
            background-color: @color-light-grey !important;
            transition: none;
        }

        td {
            border-top: none;

            .table-responsive {
                background-color: @color-light-grey;
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

                .table-striped {
                    margin-bottom: 0;
                }
            }

            .label {
                position: initial;
                top: initial;
            }
        }

        .table-body-container {
            table {
                border-collapse: separate !important;
            }

            max-height: 300px;
            overflow-y: scroll;
            position: relative;
            top: 0;
        }
    }

    .icon-cell {
        text-align: center;
        vertical-align: middle;
        width: 60px !important;
        max-width: 60px !important;
        padding: 12px 0 !important;

        &.active {
            background-color: @color-light-grey;

            .icon-view-history {
                color: @color-blue !important;
            }
        }

        a {
            width: 100%;
            height: 100%;
        }
    }
}

.table-striped-row {
    background-color: @color-data-entry-shaded-area;
}

.top-row {
    border-top: 2px solid @color-light-grey !important;
}

.form-border-left {
    border-left: 2px solid @color-light-grey !important;
}
