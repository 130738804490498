﻿// Calendar LESS
.calendar-padding {
    padding: 30px;
    padding-top: 10px;
}

.page-calendar {
    // Agenda
    .agenda-view {
        -webkit-display: flex;
        display: flex;

        .agenda-time {
            padding-top: 13px;
            width: 104px;
        }

        .agenda-event {
            -webkit-flex: 1;
            flex: 1;
        }

        .time-overlap {
            display: block;
        }
    }

    .date {
        margin: @unit*6 0 @unit*2;

        &:first-of-type {
            margin-top: -@unit*4;
        }
    }

    .cp-icon-cake {
        margin-right: 0.25em;
    }

    // Modal
    .modal {
        .event-title {
            margin-bottom: @unit*2;
        }

        .event-date {
            display: block;
        }

        dl {
            &.vertical {
                margin-bottom: 0;
            }

            &.event-details {
                margin: @unit*3 0;
            }
        }

        p {
            margin-bottom: 10px;

            &.scheduled-date {
                margin-bottom: 0;

                .btn {
                    border: 0;
                    margin: -3px 0 0 5px;
                    padding: 0;
                }
            }
        }

        .validation-block {
            margin-bottom: 0;
        }

        .note {
            margin-bottom: 0;
        }

        // Attendance
        .form-attendance {
            border-top: solid 1px @color-light-grey;
            margin-top: 25px;
            margin-left: -25px;
            margin-right: -25px;
            padding: 18px 25px 0;
        }

        .attendance-label {
            color: @color-blue;
            font-weight: 400;
        }

        .event-actions,
        .delete-event {
            .clearfix;
            margin-top: 25px;
        }

        .reason-for-deleting {
            border-top: solid 1px @color-light-grey;
            margin-top: @unit*5;
            padding-top: @unit*4;
        }
    }

    // Panels
    .panel {
        &.event {
            cursor: pointer;
            margin-bottom: @unit;
            transition: background-color 0.25s ease;

            .note {
                border: none;
                padding: 0;
            }

            &:hover {
                background-color: @color-extra-light-grey;
            }

            > .panel-body {
                padding: @unit*2;
            }

            &.panel-border-left {
                border: none;
                border-left: 4px solid @color-blue;
            }

            &.event-past {
                background-color: @color-extra-light-grey;
                border-left-color: @color-medium-grey;
                transition: background-color 0.25s ease;

                [class^="cp-icon-"] {
                    color: @color-medium-grey;
                }

                &:hover {
                    background-color: darken(@color-extra-light-grey, 4%);
                }
            }

            &.last-in-series {
                [class^="cp-icon-"],
                [class*=" cp-icon-"] {
                    color: @color-warning;
                    font-weight: 600;
                }
            }

            // Full attended
            &.event-attendance-full {
                border-left-color: @color-attendance-full;
            }

            // Partial attended
            &.event-attendance-partial {
                border-left-color: @color-attendance-partial;
            }

            // No attended
            &.event-attendance-none {
                border-left-color: @color-attendance-none;
            }
        }
    }

    // Title
    .calendar-title {
        padding: @unit*2 0;

        .calendar-times {
            padding-top: @unit;
        }
    }
}

@media print {
    .theme-default theme-calendar {
        .calendar-title {
            margin: 10px 0 !important;

            .calendar-times {
                padding: 2px 0 0 !important;
            }
        }

        .date,
        .date:first-of-type {
            font-size: 10pt !important;
            margin: 15px 0 10px !important;
        }

        .agenda-view {
            page-break-inside: avoid;
            padding: 0 0 6px !important;
        }

        .panel {
            border-bottom: dotted 1px #777 !important;
            padding-bottom: 5px !important;

            &.event {
                > .panel-body {
                    padding-left: 2px !important;
                }

                &.event-past {
                    background: white !important;
                }
            }
        }
    }
}
